// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Button = require("../../../../styleguide/components/Button/Button.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var SlvBookingSendInquiryButtonModuleScss = require("./SlvBookingSendInquiryButton.module.scss");

var css = SlvBookingSendInquiryButtonModuleScss;

function SlvBookingSendInquiryButton(Props) {
  var size = Props.size;
  var submit = Props.submit;
  var expanded = Props.expanded;
  var testId = Props.testId;
  var onClick = Props.onClick;
  var className = Props.className;
  var busy = Props.busy;
  var tmp = {
    size: size,
    color: /* Primary */0,
    submit: submit,
    children: React.createElement("div", {
          className: css.primaryButton
        }, React.createElement("span", undefined, "Send Inquiry"), React.createElement("span", {
              className: css.noObligationNote
            }, "No obligation"))
  };
  if (expanded !== undefined) {
    tmp.expanded = Caml_option.valFromOption(expanded);
  }
  if (busy !== undefined) {
    tmp.busy = Caml_option.valFromOption(busy);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (testId !== undefined) {
    tmp.testId = Caml_option.valFromOption(testId);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement(Button.make, tmp);
}

var make = SlvBookingSendInquiryButton;

exports.css = css;
exports.make = make;
/* css Not a pure module */
