// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Button = require("../../components/Button/Button.bs.js");
var Belt_Int = require("bs-platform/lib/js/belt_Int.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var NumberField = require("../NumberField/NumberField.bs.js");
var CounterModuleScss = require("./Counter.module.scss");

var css = CounterModuleScss;

function input(param) {
  if (param !== 0) {
    return /* LG */2;
  } else {
    return /* SM */0;
  }
}

function button(param) {
  if (param !== 0) {
    return /* LG */2;
  } else {
    return /* SM */0;
  }
}

var Size = {
  input: input,
  button: button
};

function Counter(Props) {
  var size = Props.size;
  var value = Props.value;
  var disabledOpt = Props.disabled;
  var ariaLabel = Props.ariaLabel;
  var decrementTestId = Props.decrementTestId;
  var incrementTestId = Props.incrementTestId;
  var classNameOpt = Props.className;
  var onChange = Props.onChange;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var incrementValue = React.useCallback((function (param) {
          if (!disabled) {
            return Curry._1(onChange, value + 1 | 0);
          }
          
        }), /* tuple */[
        value,
        disabled,
        onChange
      ]);
  var decrementValue = React.useCallback((function (param) {
          if (!disabled) {
            return Curry._1(onChange, value - 1 | 0);
          }
          
        }), /* tuple */[
        value,
        disabled,
        onChange
      ]);
  var updateValue = React.useCallback((function ($$event) {
          if (disabled) {
            return ;
          }
          var x = $$event.target.value;
          var tmp = x === "" ? 0 : Belt_Option.getWithDefault(Belt_Int.fromString(x), 0);
          return Curry._1(onChange, tmp);
        }), /* tuple */[
        value,
        disabled,
        onChange
      ]);
  var tmp = {
    size: button(size),
    color: /* Silver */1,
    ghost: true,
    visuallyDisabled: disabled,
    className: Cn.$plus(Cn.$plus(css.button, css.buttonLeft), Cn.on(css.buttonDisabled, disabled)),
    onClick: decrementValue,
    children: null
  };
  if (decrementTestId !== undefined) {
    tmp.testId = Caml_option.valFromOption(decrementTestId);
  }
  var tmp$1 = Belt_Option.map(ariaLabel, (function (label) {
          return "Decrement " + (String(label) + "");
        }));
  if (tmp$1 !== undefined) {
    tmp.ariaLabel = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    size: input(size),
    value: String(value),
    disabled: disabled,
    className: css.input,
    onChange: updateValue
  };
  if (ariaLabel !== undefined) {
    tmp$2.ariaLabel = Caml_option.valFromOption(ariaLabel);
  }
  var tmp$3 = {
    size: button(size),
    color: /* Silver */1,
    ghost: true,
    visuallyDisabled: disabled,
    className: Cn.$plus(css.button, css.buttonRight),
    onClick: incrementValue,
    children: null
  };
  if (incrementTestId !== undefined) {
    tmp$3.testId = Caml_option.valFromOption(incrementTestId);
  }
  var tmp$4 = Belt_Option.map(ariaLabel, (function (label) {
          return "Increment " + (String(label) + "");
        }));
  if (tmp$4 !== undefined) {
    tmp$3.ariaLabel = Caml_option.valFromOption(tmp$4);
  }
  return React.createElement("div", {
              className: Cn.$plus(css.counter, className)
            }, React.createElement(Button.make, tmp, "–", React.createElement("div", {
                      "aria-hidden": true,
                      className: Cn.$plus(css.buttonText, css.minusAdjustment)
                    }, "–")), React.createElement(NumberField.make, tmp$2), React.createElement(Button.make, tmp$3, "+", React.createElement("div", {
                      "aria-hidden": true,
                      className: css.buttonText
                    }, "+")));
}

var make = Counter;

exports.css = css;
exports.Size = Size;
exports.make = make;
/* css Not a pure module */
