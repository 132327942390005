// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var SlvGallery = require("../Photos/SlvGallery.bs.js");
var SlvHeroPhoto = require("./SlvHeroPhoto.bs.js");
var SlvNoPhotosYet = require("./SlvNoPhotosYet.bs.js");
var SlvHeroModuleScss = require("./SlvHero.module.scss");

var css = SlvHeroModuleScss;

function SlvHero(Props) {
  var listing = Props.listing;
  var photos = listing.photos;
  if (photos !== undefined) {
    return React.createElement(SlvGallery.make, {
                id: "listing--gallery--hero",
                listingId: listing.id,
                className: css.container,
                isManagedByCurrentUser: listing.userContext.isManagedByCurrentUser,
                children: Belt_Array.mapWithIndex(photos, (function (idx, photo) {
                        return React.createElement(SlvHeroPhoto.make, {
                                    photo: photo,
                                    hero: idx === 0,
                                    key: photo.cloudUrl
                                  });
                      }))
              });
  } else {
    return React.createElement("div", {
                className: css.container
              }, React.createElement(SlvNoPhotosYet.make, { }));
  }
}

var make = SlvHero;

exports.css = css;
exports.make = make;
/* css Not a pure module */
