// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function usePrevious(v) {
  var x = React.useRef(undefined);
  React.useEffect((function () {
          x.current = Caml_option.some(v);
          
        }));
  return x.current;
}

function useReducer(initialState, reducer) {
  var match = React.useReducer((function (fullState, action) {
          var sideEffects = fullState.sideEffects;
          var state = Curry._2(reducer, fullState.state, action);
          if (typeof state === "number") {
            return fullState;
          }
          switch (state.tag | 0) {
            case /* Update */0 :
                return {
                        state: state[0],
                        sideEffects: fullState.sideEffects
                      };
            case /* UpdateWithSideEffects */1 :
                return {
                        state: state[0],
                        sideEffects: {
                          contents: Belt_Array.concat(sideEffects.contents, [state[1]])
                        }
                      };
            case /* SideEffects */2 :
                return {
                        state: fullState.state,
                        sideEffects: {
                          contents: Belt_Array.concat(sideEffects.contents, [state[0]])
                        }
                      };
            
          }
        }), {
        state: initialState,
        sideEffects: {
          contents: []
        }
      });
  var dispatch = match[1];
  var match$1 = match[0];
  var sideEffects = match$1.sideEffects;
  var state = match$1.state;
  React.useEffect((function () {
          if (sideEffects.contents.length !== 0) {
            Belt_Array.forEach(sideEffects.contents, (function (fn) {
                    return Curry._1(fn, {
                                state: state,
                                dispatch: dispatch
                              });
                  }));
            sideEffects.contents = [];
          }
          
        }), [sideEffects.contents]);
  return /* tuple */[
          state,
          dispatch
        ];
}

exports.usePrevious = usePrevious;
exports.useReducer = useReducer;
/* react Not a pure module */
