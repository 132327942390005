// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var A = require("../../../../styleguide/components/Link/A.bs.js");
var Html = require("../../../../libs/Html.bs.js");
var Modal = require("../../../../styleguide/components/Modal/Modal.bs.js");
var Route = require("../../../../Route.bs.js");
var React = require("react");
var Tooltip = require("../../../../styleguide/components/Tooltip/Tooltip.bs.js");
var PhoneNumber = require("../../../../styleguide/components/PhoneNumber/PhoneNumber.bs.js");
var TooltipQuestionIcon = require("../../../../styleguide/icons/TooltipQuestionIcon.bs.js");
var SlvBookingInquiryModalSuccessMessage = require("./SlvBookingInquiryModalSuccessMessage.bs.js");
var SlvBookingInquiryModalSuccessForNewUserModuleScss = require("./SlvBookingInquiryModalSuccessForNewUser.module.scss");

var css = SlvBookingInquiryModalSuccessForNewUserModuleScss;

function SlvBookingInquiryModalSuccessForNewUser(Props) {
  var booking = Props.booking;
  var bookingId = Props.bookingId;
  var conversationId = Props.conversationId;
  var user = Props.user;
  var phone = user.phone;
  return React.createElement("div", {
              className: css.wrapper
            }, React.createElement(Modal.PanelsTitle.make, {
                  className: css.title,
                  children: "Your inquiry has been sent!"
                }), React.createElement(Modal.MainPanel.make, {
                  children: React.createElement(SlvBookingInquiryModalSuccessMessage.make, {
                        booking: booking,
                        bookingId: bookingId,
                        conversationId: conversationId
                      })
                }), React.createElement(Modal.SidePanel.make, {
                  className: css.sidePanel,
                  children: null
                }, React.createElement("div", {
                      className: css.signedUpMessage
                    }, "We", Html.apos, "ve also created you a HiChee account and logged you in so you can send inquiries and message hosts faster."), React.createElement("div", {
                      className: css.contactInfoHeader
                    }, "Contact information that you", Html.apos, "ve provided:"), React.createElement("div", undefined, user.firstName, " ", user.lastName), React.createElement("div", undefined, user.email), phone !== undefined ? React.createElement(PhoneNumber.make, {
                        value: phone
                      }) : null, React.createElement("div", undefined, React.createElement(A.make, {
                          href: Route.editAccount,
                          color: /* General */0,
                          children: "Edit"
                        }), " ", React.createElement(Tooltip.make, {
                          id: "listing--booking-confirmation-modal--contact-info-tooltip",
                          content: "Update the host with correct information and edit it in your profile and account settings if you made a mistake.",
                          side: /* Bottom */1,
                          children: React.createElement(TooltipQuestionIcon.make, {
                                size: /* SM */1,
                                color: /* Gray */8
                              })
                        }))));
}

var make = SlvBookingInquiryModalSuccessForNewUser;

exports.css = css;
exports.make = make;
/* css Not a pure module */
