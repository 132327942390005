// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function PoolIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var className = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "Pool";
  var tmp = {
    title: title,
    size: size,
    children: React.createElement("g", {
          fill: "none",
          fillRule: "evenodd"
        }, React.createElement("path", {
              style: Icon.style(color),
              d: "M9.5,2 C10.5,2 11,2.52793296 11,3.5 L11,3.5 L11,9 C11,9.27614237 10.7761424,9.5 10.5,9.5 C10.2238576,9.5 10,9.27614237 10,9 L10,9 L10,8 L6,8 L6,11.5 C6,11.7761424 5.77614237,12 5.5,12 C5.25454011,12 5.05039163,11.8231248 5.00805567,11.5898756 L5,11.5 L5,3.5 C5,3.1 4.8,3 4.5,3 C4.2,3 4,3.1 4,3.5 C4,3.75 3.77605989,3.99310176 3.5,3.99985065 C3.22394011,4.00659954 3,3.75 3,3.5 C3,2.5 3.5,2 4.5,2 C5.44117647,2 5.93944637,2.46764996 5.99480969,3.3330862 L6,3.5 L6,5 L10,5 L10,3.5 C10,3.1 9.8,3 9.5,3 C9.2,3 9,3.1 9,3.5 C9,3.75 8.77605989,3.99310176 8.5,3.99985065 C8.22394011,4.00659954 8,3.75 8,3.5 C8,2.5 8.5,2 9.5,2 Z M10,7 L10,6 L6,6 L6,7 L10,7 Z"
            }), React.createElement("path", {
              style: Icon.style(color),
              d: "M2.16729584,12.8732398 C4.34042446,14.8103563 6.48040414,14.8103563 8.36117438,12.8457645 C9.85696591,11.283309 11.3836529,11.283309 13.1672958,12.8732398 C13.3734305,13.0569872 13.6894924,13.0388388 13.8732398,12.8327042 C14.0569872,12.6265695 14.0388388,12.3105076 13.8327042,12.1267602 C11.6595755,10.1896437 9.51959586,10.1896437 7.63882562,12.1542355 C6.14303409,13.716691 4.61634711,13.716691 2.83270416,12.1267602 C2.62656952,11.9430128 2.31050765,11.9611612 2.12676021,12.1672958 C1.94301278,12.3734305 1.96116119,12.6894924 2.16729584,12.8732398 Z",
              fillRule: "nonzero"
            }))
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Icon.make, tmp);
}

var make = PoolIcon;

exports.make = make;
/* Icon Not a pure module */
