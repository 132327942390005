// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Svg = require("../components/Svg/Svg.bs.js");
var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");

function Bubble$Mobile(Props) {
  var titleOpt = Props.title;
  var classNameOpt = Props.className;
  var color = Props.color;
  var title = titleOpt !== undefined ? titleOpt : "Bubble";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Svg.make, {
              title: title,
              viewBoxWidth: "65",
              viewBoxHeight: "152",
              className: className,
              children: React.createElement("g", {
                    fill: "none",
                    fillRule: "evenodd"
                  }, React.createElement("path", {
                        style: Icon.style(color),
                        d: "M64.4545508,152 C51.5142098,152 22.4077679,142.712257 22.4077679,116 C29.6210253,116 43.6366196,116 64.4545508,116 C64.4545508,135.691408 64.4545508,147.691408 64.4545508,152 Z"
                      }), React.createElement("path", {
                        style: Icon.style(color),
                        d: "M3.15121417,9.68698396 C18.5493721,1.28326122 39.7888655,0 64.4077679,0 C64.4077679,2.29440699 64.4077679,16.9610737 64.4077679,44 C43.3211355,44 29.3463109,44 22.4832941,44 C22.4077679,16.2464224 -10.0320195,16.8818857 3.15121417,9.68698396 Z"
                      }), React.createElement("rect", {
                        style: Icon.style(color),
                        height: "72",
                        width: "42",
                        x: "22.4077679",
                        y: "44"
                      }))
            });
}

var Mobile = {
  make: Bubble$Mobile
};

function Bubble$Small(Props) {
  var titleOpt = Props.title;
  var classNameOpt = Props.className;
  var color = Props.color;
  var text = Props.text;
  var title = titleOpt !== undefined ? titleOpt : "SmallBubble";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Svg.make, {
              title: title,
              viewBoxWidth: "52",
              viewBoxHeight: "37",
              className: className,
              children: null
            }, React.createElement("path", {
                  style: Icon.style(color),
                  d: "M1.21930945,4.76407718 C14.5846975,-2.5522087 49.1647178,-2.92102989 51.6540706,14.29657 C54.1434234,31.51417 42.8196424,35.7140915 29.8313122,36.9464532 C24.1032892,37.4899408 6.94733245,34.0292207 9.81134499,20.4418327 C12.6753565,6.85444458 -4.63990553,7.97144301 1.21930945,4.76407718 Z"
                }), React.createElement("text", {
                  fill: "#506477",
                  fontFamily: "Helvetica",
                  fontSize: "16",
                  fontWeight: "normal"
                }, React.createElement("tspan", {
                      x: "22",
                      y: "20"
                    }, text)));
}

var Small = {
  make: Bubble$Small
};

function Bubble(Props) {
  var titleOpt = Props.title;
  var classNameOpt = Props.className;
  var color = Props.color;
  var title = titleOpt !== undefined ? titleOpt : "Bubble";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Svg.make, {
              title: title,
              viewBoxWidth: "64",
              viewBoxHeight: "104",
              className: className,
              children: React.createElement("g", undefined, React.createElement("rect", {
                        style: Icon.style(color),
                        height: "24",
                        width: "42",
                        x: "22.4077679",
                        y: "44"
                      }), React.createElement("path", {
                        style: Icon.style(color),
                        d: "M64.4545508,104 C51.5142098,104 22.4077679,94.7122567 22.4077679,68 C29.6210253,68 43.6366196,68 64.4545508,68 C64.4545508,87.6914078 64.4545508,99.6914078 64.4545508,104 Z"
                      }), React.createElement("path", {
                        style: Icon.style(color),
                        d: "M3.15121417,9.68698396 C18.5493721,1.28326122 39.7888655,0 64.4077679,0 C64.4077679,2.29440699 64.4077679,16.9610737 64.4077679,44 C43.3211355,44 29.3463109,44 22.4832941,44 C22.4077679,16.2464224 -10.0320195,16.8818857 3.15121417,9.68698396 Z"
                      }))
            });
}

var make = Bubble;

exports.Mobile = Mobile;
exports.Small = Small;
exports.make = make;
/* Svg Not a pure module */
