// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var PhoneInputJsx = require("app/libs/components/PhoneInput/PhoneInput.jsx");

function toJs(param) {
  if (param) {
    return "md";
  } else {
    return "sm";
  }
}

var Size = {
  toJs: toJs
};

var PhoneInputJs = { };

function PhoneInput(Props) {
  var id = Props.id;
  var size = Props.size;
  var className = Props.className;
  var value = Props.value;
  var onChange = Props.onChange;
  return React.createElement(PhoneInputJsx.default, {
              id: id,
              size: size ? "md" : "sm",
              className: className,
              value: value,
              onChange: onChange
            });
}

var make = PhoneInput;

exports.Size = Size;
exports.PhoneInputJs = PhoneInputJs;
exports.make = make;
/* react Not a pure module */
