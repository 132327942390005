// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Time = require("../../../../libs/Time.bs.js");
var React = require("react");
var SlvBlock = require("../../components/SlvBlock/SlvBlock.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReactIntl = require("react-intl");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var SystemHouseRule = require("../../../../models/SystemHouseRule.bs.js");
var SlvRulesModuleScss = require("./SlvRules.module.scss");

var css = SlvRulesModuleScss;

function SlvRules$Block(Props) {
  var listing = Props.listing;
  var systemHouseRules = React.useMemo((function () {
          return Belt_Array.keep(SystemHouseRule.All.toArray(listing.systemHouseRules), (function (rule) {
                        var match = rule.status;
                        return match < 2;
                      }));
        }), ([]));
  var checkInHour = listing.checkInHour;
  var tmp;
  if (checkInHour !== undefined) {
    var checkInHour$1 = Time.formatHourAmPm(checkInHour);
    tmp = React.createElement("li", {
          className: css.rule
        }, "Check in from: " + (String(checkInHour$1) + ""));
  } else {
    tmp = null;
  }
  var checkOutHour = listing.checkOutHour;
  var tmp$1;
  if (checkOutHour !== undefined) {
    var checkOutHour$1 = Time.formatHourAmPm(checkOutHour);
    tmp$1 = React.createElement("li", {
          className: css.rule
        }, "Check out before: " + (String(checkOutHour$1) + ""));
  } else {
    tmp$1 = null;
  }
  var customHouseRules = listing.customHouseRules;
  return React.createElement(SlvBlock.make, {
              id: /* Rules */75748311,
              children: null
            }, React.createElement(SlvBlock.Title.make, {
                  children: React.createElement(ReactIntl.FormattedMessage, {
                        id: "slv.rules.title",
                        defaultMessage: "House rules"
                      })
                }), React.createElement("ul", {
                  className: css.rules
                }, tmp, tmp$1, systemHouseRules.length !== 0 ? Belt_Array.map(systemHouseRules, (function (rule) {
                          return React.createElement("li", {
                                      key: String(rule.id),
                                      className: css.rule
                                    }, Belt_Option.getExn(SystemHouseRule.display(rule)));
                        })) : null, customHouseRules.length !== 0 ? React.createElement(React.Fragment, undefined, React.createElement(SlvBlock.SubTitle.make, {
                            className: css.subtitle,
                            children: React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.rules.custom-rules-title",
                                  defaultMessage: "Additional rules"
                                })
                          }), Belt_Array.map(customHouseRules, (function (rule) {
                              return React.createElement("li", {
                                          key: String(rule.id),
                                          className: css.rule
                                        }, rule.name);
                            }))) : null));
}

var Block = {
  make: SlvRules$Block
};

function SlvRules(Props) {
  var listing = Props.listing;
  var match = listing.systemHouseRules;
  var match$1 = listing.customHouseRules;
  var match$2 = listing.checkInHour;
  var match$3 = listing.checkOutHour;
  if (match.children.status >= 2 && match.infants.status >= 2 && match.pets.status >= 2 && match.smoking.status >= 2 && match.events.status >= 2 && match$1.length === 0 && match$2 === undefined && match$3 === undefined) {
    return null;
  }
  return React.createElement(SlvRules$Block, {
              listing: listing
            });
}

var make = SlvRules;

exports.css = css;
exports.Block = Block;
exports.make = make;
/* css Not a pure module */
