// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Modal = require("../../../../styleguide/components/Modal/Modal.bs.js");
var React = require("react");
var SlvBookingInquiryModalSuccessMessage = require("./SlvBookingInquiryModalSuccessMessage.bs.js");
var SlvBookingInquiryModalSuccessForExistingUserModuleScss = require("./SlvBookingInquiryModalSuccessForExistingUser.module.scss");

var css = SlvBookingInquiryModalSuccessForExistingUserModuleScss;

function SlvBookingInquiryModalSuccessForExistingUser(Props) {
  var booking = Props.booking;
  var screen = Props.screen;
  var bookingId = Props.bookingId;
  var conversationId = Props.conversationId;
  return React.createElement("div", {
              className: css.wrapper
            }, React.createElement(Modal.Title.make, {
                  screen: screen,
                  className: css.title,
                  children: "Your inquiry has been sent!"
                }), React.createElement(SlvBookingInquiryModalSuccessMessage.make, {
                  booking: booking,
                  bookingId: bookingId,
                  conversationId: conversationId
                }));
}

var make = SlvBookingInquiryModalSuccessForExistingUser;

exports.css = css;
exports.make = make;
/* css Not a pure module */
