// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReactGoogleMaps = require("react-google-maps");
var WithGoogleMapJs = require("reason/styleguide/components/GoogleMap/WithGoogleMap.js");

var $$Map = { };

function GoogleMap(Props) {
  var center = Props.center;
  var zoom = Props.zoom;
  var className = Props.className;
  var children = Props.children;
  var tmp = {
    center: center,
    zoom: zoom,
    containerElement: React.createElement("div", {
          className: className
        }),
    mapElement: React.createElement("div", {
          className: className
        })
  };
  if (children !== undefined) {
    tmp.children = Caml_option.valFromOption(children);
  }
  return React.createElement(WithGoogleMapJs.default, tmp);
}

function GoogleMap$Marker(Props) {
  var shape = Props.shape;
  if (shape.tag) {
    var match = shape[0];
    return React.createElement(ReactGoogleMaps.Circle, {
                center: match.center,
                radius: match.radius,
                options: {
                  strokeColor: "#000",
                  strokeOpacity: 1.0,
                  strokeWeight: 1.0,
                  fillColor: "#000",
                  fillOpacity: 0.1
                }
              });
  }
  var match$1 = shape[0];
  return React.createElement(ReactGoogleMaps.Marker, {
              position: match$1.position,
              draggable: match$1.draggable,
              icon: {
                path: "M16 40s16-11.163 16-24S24.837 0 16 0 0 3.163 0 16s16 24 16 24z",
                strokeColor: "#e54d50",
                strokeOpacity: 1.0,
                strokeWeight: 8.0,
                fillColor: "#fff",
                fillOpacity: 1.0,
                scale: 0.7,
                anchor: new (google.maps.Point)(32 / 2, 40 + 8)
              }
            });
}

var Marker = {
  make: GoogleMap$Marker
};

var make = GoogleMap;

exports.$$Map = $$Map;
exports.make = make;
exports.Marker = Marker;
/* react Not a pure module */
