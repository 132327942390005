// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function LaundryIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var className = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "Laundry";
  var tmp = {
    title: title,
    size: size,
    children: React.createElement("g", {
          fill: "none",
          fillRule: "evenodd"
        }, React.createElement("path", {
              style: Icon.style(color),
              d: "M13,1 C13.5522847,1 14,1.44771525 14,2 L14,14 C14,14.5522847 13.5522847,15 13,15 L3,15 C2.44771525,15 2,14.5522847 2,14 L2,2 C2,1.44771525 2.44771525,1 3,1 L13,1 Z M13,2 L3,2 L3,14 L13,14 L13,2 Z M8,5 C10.209139,5 12,6.790861 12,9 C12,11.209139 10.209139,13 8,13 C5.790861,13 4,11.209139 4,9 C4,6.790861 5.790861,5 8,5 Z M8,6 C6.34314575,6 5,7.34314575 5,9 C5,10.6568542 6.34314575,12 8,12 C9.65685425,12 11,10.6568542 11,9 C11,7.34314575 9.65685425,6 8,6 Z M7,3 L7,4 L4,4 L4,3 L7,3 Z M12,3 L12,4 L11,4 L11,3 L12,3 Z",
              fillRule: "nonzero"
            }))
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Icon.make, tmp);
}

var make = LaundryIcon;

exports.make = make;
/* Icon Not a pure module */
