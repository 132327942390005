// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var SlvHero = require("./Hero/SlvHero.bs.js");
var SlvRates = require("./Rates/SlvRates.bs.js");
var SlvRules = require("./Rules/SlvRules.bs.js");
var SlvNavbar = require("./Navbar/SlvNavbar.bs.js");
var SlvPhotos = require("./Photos/SlvPhotos.bs.js");
var SlvScreen = require("./SlvScreen.bs.js");
var SlvBooking = require("./SlvBooking.bs.js");
var SlvDetails = require("./Details/SlvDetails.bs.js");
var SlvListing = require("./SlvListing.bs.js");
var SlvReviews = require("./Reviews/SlvReviews.bs.js");
var SlvCalendar = require("./Calendar/SlvCalendar.bs.js");
var SlvHeadline = require("./Headline/SlvHeadline.bs.js");
var SlvLocation = require("./Location/SlvLocation.bs.js");
var SlvAmenities = require("./Amenities/SlvAmenities.bs.js");
var SlvHostKnown = require("./Host/SlvHostKnown.bs.js");
var SlvDescription = require("./Description/SlvDescription.bs.js");
var SlvHostUnknown = require("./Host/SlvHostUnknown.bs.js");
var SlvBookingFooter = require("./Booking/SlvBookingFooter.bs.js");
var SlvBookingWidget = require("./Booking/SlvBookingWidget.bs.js");
var SlvModuleScss = require("./Slv.module.scss");
var SlvDealsOnOtherSites = require("./DealsOnOtherSites/SlvDealsOnOtherSites.bs.js");
var UnclaimedListingModal = require("../../../styleguide/components/UnclaimedListingModal/UnclaimedListingModal.bs.js");
var SlvBookingInquiryModal = require("./Booking/SlvBookingInquiryModal.bs.js");
var SlvBookingMessageModal = require("./Booking/SlvBookingMessageModal.bs.js");
var SlvBookingOwnListingWarningModal = require("./Booking/SlvBookingOwnListingWarningModal.bs.js");
var Photoswipe = require("app/libs/utils/vendor/photoswipe");
var PhotoswipeScss = require("app/assets/styles/vendor/photoswipe/photoswipe.scss");

var css = SlvModuleScss;

function Slv(Props) {
  var listing = Props.listing;
  var user = Props.user;
  var meta = Props.meta;
  var viewContext = Props.viewContext;
  var match = SlvListing.useListing(user.id, listing);
  var listing$1 = match.listing;
  var booking = SlvBooking.useBooking(listing$1, user, viewContext);
  var screen = SlvScreen.useScreen(undefined);
  var unclaimedListingModal = UnclaimedListingModal.useModal(undefined);
  React.useEffect((function () {
          Photoswipe.galleries.initAll();
          
        }), [screen]);
  React.useEffect((function () {
          var __x = window;
          __x.scrollTo(window.scrollX, window.scrollY - 1);
          
        }), ([]));
  var match$1 = listing$1.kind;
  var match$2 = listing$1.kind;
  var match$3 = listing$1.kind;
  var match$4 = listing$1.kind;
  var match$5 = listing$1.kind;
  var match$6 = listing$1.kind;
  return React.createElement(React.Fragment, undefined, React.createElement(SlvNavbar.make, {
                  listing: listing$1,
                  user: user,
                  meta: meta,
                  screen: screen,
                  viewContext: viewContext,
                  updateListingFavorited: match.updateListingFavorited
                }), React.createElement("div", {
                  className: css.container
                }, React.createElement(SlvHero.make, {
                      listing: listing$1
                    }), React.createElement("div", {
                      className: css.content
                    }, React.createElement("div", {
                          className: css.mainCol
                        }, React.createElement(SlvHeadline.make, {
                              listing: listing$1,
                              unclaimedListingModal: unclaimedListingModal
                            }), screen !== undefined && screen < 2 ? React.createElement(SlvBookingWidget.SmallScreen.make, {
                                listing: listing$1,
                                booking: booking,
                                viewContext: viewContext,
                                unclaimedListingModal: unclaimedListingModal
                              }) : null, React.createElement(SlvDealsOnOtherSites.make, {
                              listing: listing$1,
                              booking: booking
                            }), match$1 ? null : React.createElement(SlvDetails.make, {
                                listing: listing$1
                              }), React.createElement(SlvAmenities.make, {
                              listing: listing$1,
                              screen: screen
                            }), match$2 ? null : React.createElement(SlvRates.make, {
                                listing: listing$1
                              }), match$3 ? null : React.createElement(SlvCalendar.make, {
                                listing: listing$1,
                                booking: booking,
                                screen: screen
                              }), React.createElement(SlvPhotos.make, {
                              listing: listing$1,
                              screen: screen
                            }), match$4 ? null : React.createElement(SlvDescription.make, {
                                listing: listing$1
                              }), React.createElement(SlvLocation.make, {
                              listing: listing$1,
                              viewContext: viewContext
                            }), match$5 ? null : React.createElement(SlvRules.make, {
                                listing: listing$1
                              }), React.createElement(SlvReviews.make, {
                              listing: listing$1,
                              booking: booking
                            }), match$6 ? (
                            match$6[0] >= 809617083 ? React.createElement(SlvHostKnown.make, {
                                    listing: listing$1,
                                    booking: booking,
                                    screen: screen,
                                    viewContext: viewContext
                                  }) : React.createElement(SlvHostUnknown.make, {
                                    listing: listing$1,
                                    booking: booking,
                                    unclaimedListingModal: unclaimedListingModal
                                  })
                          ) : React.createElement(SlvHostKnown.make, {
                                listing: listing$1,
                                booking: booking,
                                screen: screen,
                                viewContext: viewContext
                              })), screen !== undefined ? (
                        screen >= 2 ? React.createElement(SlvBookingWidget.LargeScreen.make, {
                                listing: listing$1,
                                booking: booking,
                                viewContext: viewContext,
                                className: css.widgetsCol,
                                unclaimedListingModal: unclaimedListingModal
                              }) : null
                      ) : React.createElement("div", {
                            className: css.widgetsCol
                          }))), screen !== undefined && screen < 2 ? React.createElement(SlvBookingFooter.SmallScreen.make, {
                    listing: listing$1,
                    booking: booking,
                    unclaimedListingModal: unclaimedListingModal
                  }) : null, React.createElement(SlvBookingInquiryModal.make, {
                  listing: listing$1,
                  booking: booking,
                  user: user,
                  screen: screen,
                  viewContext: viewContext,
                  unclaimedListingModal: unclaimedListingModal
                }), React.createElement(SlvBookingMessageModal.make, {
                  listing: listing$1,
                  booking: booking,
                  user: user,
                  screen: screen,
                  viewContext: viewContext
                }), React.createElement(SlvBookingOwnListingWarningModal.make, {
                  booking: booking
                }), React.createElement(UnclaimedListingModal.make, {
                  modal: unclaimedListingModal
                }));
}

var make = Slv;

exports.css = css;
exports.make = make;
/* css Not a pure module */
