// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");

function dataLayer$1(param) {
  if (typeof dataLayer !== "undefined") {
    return Caml_option.some(dataLayer);
  }
  
}

var DataLayer = { };

function send(param) {
  var dataLayer$2 = dataLayer$1(undefined);
  if (dataLayer$2 === undefined) {
    return ;
  }
  var $$location = window.location;
  var payload = {
    event: "[PAGEVIEW]",
    url: $$location.href,
    hostname: $$location.hostname,
    path: $$location.pathname
  };
  Caml_option.valFromOption(dataLayer$2).push(payload);
  
}

var Pageview = {
  send: send
};

function send$1(category, action, label, value, param) {
  var dataLayer$2 = dataLayer$1(undefined);
  if (dataLayer$2 === undefined) {
    return ;
  }
  var tmp = {
    event: "[EVENT] " + (String(category) + (": " + (String(action) + ""))),
    eventCategory: category,
    eventAction: action
  };
  if (label !== undefined) {
    tmp.eventLabel = Caml_option.valFromOption(label);
  }
  if (value !== undefined) {
    tmp.eventValue = Caml_option.valFromOption(value);
  }
  var payload = tmp;
  Caml_option.valFromOption(dataLayer$2).push(payload);
  
}

var $$Event = {
  send: send$1
};

function phoneShown(listingId) {
  return send$1("Inquiries", "Phone Shown", String(listingId), undefined, undefined);
}

function bookingWidgetInteraction(listingId) {
  return send$1("Inquiries", "Booking Widget Interaction", String(listingId), undefined, undefined);
}

function inquiryModalShown(listingId) {
  return send$1("Inquiries", "Inquiry Form Shown", String(listingId), undefined, undefined);
}

function inquiryModalFormInteraction(listingId) {
  return send$1("Inquiries", "Inquiry Form Interaction", String(listingId), undefined, undefined);
}

function inquiryModalFormSubmitted(listingId) {
  return send$1("Inquiries", "Inquiry Form Submitted", String(listingId), undefined, undefined);
}

function messageModalShown(listingId) {
  return send$1("Inquiries", "Message Form Shown", String(listingId), undefined, undefined);
}

function messageModalFormInteraction(listingId) {
  return send$1("Inquiries", "Message Form Interaction", String(listingId), undefined, undefined);
}

function messageModalFormSubmitted(listingId) {
  return send$1("Inquiries", "Message Form Submitted", String(listingId), undefined, undefined);
}

function favoriteButtonClick(favorited) {
  return send$1("SLV", "Favorite Button Click", favorited ? "favorited" : "unfavorited", undefined, undefined);
}

function galleryOpened(listingId) {
  return send$1("SLV", "Gallery Open", String(listingId), undefined, undefined);
}

var Slv = {
  phoneShown: phoneShown,
  bookingWidgetInteraction: bookingWidgetInteraction,
  inquiryModalShown: inquiryModalShown,
  inquiryModalFormInteraction: inquiryModalFormInteraction,
  inquiryModalFormSubmitted: inquiryModalFormSubmitted,
  messageModalShown: messageModalShown,
  messageModalFormInteraction: messageModalFormInteraction,
  messageModalFormSubmitted: messageModalFormSubmitted,
  favoriteButtonClick: favoriteButtonClick,
  galleryOpened: galleryOpened
};

exports.dataLayer = dataLayer$1;
exports.DataLayer = DataLayer;
exports.Pageview = Pageview;
exports.$$Event = $$Event;
exports.Slv = Slv;
/* No side effect */
