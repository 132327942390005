// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");

function AngleLeftIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var title = Props.title;
  return React.createElement(Icon.make, {
              title: title,
              size: size,
              children: React.createElement("path", {
                    style: Icon.style(color),
                    d: "M10.5 16l1-1-7-7 7-7-1-1-8 8 8 8z"
                  })
            });
}

var make = AngleLeftIcon;

exports.make = make;
/* Icon Not a pure module */
