// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Api = require("../../../api/Api.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Hooks = require("../../../libs/Hooks.bs.js");
var React = require("react");
var $$Promise = require("../../../bindings/Promise.bs.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");

function useListing(userId, listing) {
  var mounted = React.useRef(false);
  var match = Hooks.useReducer(listing, (function (listing, action) {
          if (action.tag) {
            return /* Update */Block.__(0, [action[0]]);
          }
          var cb = action[1];
          var newrecord = Caml_obj.caml_obj_dup(listing);
          var init = listing.userContext;
          newrecord.userContext = {
            listingRelationshipType: init.listingRelationshipType,
            favorited: action[0],
            isManagedByCurrentUser: init.isManagedByCurrentUser
          };
          return /* UpdateWithSideEffects */Block.__(1, [
                    newrecord,
                    (function (param) {
                        return Curry._1(cb, undefined);
                      })
                  ]);
        }));
  var dispatch = match[1];
  var listing$1 = match[0];
  React.useEffect((function () {
          if (mounted.current) {
            $$Promise.wait(Api.getContextualListing(listing$1.id), (function (listing) {
                    if (listing.tag) {
                      return ;
                    } else {
                      return Curry._1(dispatch, /* UpdateListing */Block.__(1, [listing[0]]));
                    }
                  }));
          } else {
            mounted.current = true;
          }
          
        }), [userId]);
  return {
          listing: listing$1,
          updateListingFavorited: (function (status, cb) {
              return Curry._1(dispatch, /* UpdateFavorited */Block.__(0, [
                            status,
                            cb
                          ]));
            })
        };
}

exports.useListing = useListing;
/* Api Not a pure module */
