// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReactDom = require("react-dom");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var BrowserLogger = require("bs-log/src/BrowserLogger.bs.js");
var Webapi__Dom__Document = require("bs-webapi/src/Webapi/Webapi__Dom/Webapi__Dom__Document.js");

function Portal(Props) {
  var children = Props.children;
  var container = React.useMemo((function () {
          return document.createElement("div");
        }), ([]));
  React.useEffect((function () {
          var __x = Belt_Option.getExn(Caml_option.nullable_to_opt(Belt_Option.getExn(Webapi__Dom__Document.asHtmlDocument(document)).body));
          __x.appendChild(container);
          return (function (param) {
                    var x;
                    try {
                      x = Belt_Option.getExn(Caml_option.nullable_to_opt(Belt_Option.getExn(Webapi__Dom__Document.asHtmlDocument(document)).body)).removeChild(container);
                    }
                    catch (exn){
                      BrowserLogger.errorWithData("Portal", "PortalContainerRemovalError", /* tuple */[
                            "",
                            ""
                          ]);
                      return ;
                    }
                    
                  });
        }), [container]);
  return ReactDom.createPortal(children, container);
}

var make = Portal;

exports.make = make;
/* react Not a pure module */
