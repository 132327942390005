// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

var Target = { };

function subscribeToClick(handler) {
  var __x = window;
  __x.addEventListener("click", handler);
  return (function (param) {
            var __x = window;
            __x.removeEventListener("click", handler);
            
          });
}

function subscribeToMouseDown(handler) {
  var __x = window;
  __x.addEventListener("mousedown", handler);
  return (function (param) {
            var __x = window;
            __x.removeEventListener("mousedown", handler);
            
          });
}

function subscribeToKeyDown(handler) {
  var __x = window;
  __x.addEventListener("keydown", handler);
  return (function (param) {
            var __x = window;
            __x.removeEventListener("keydown", handler);
            
          });
}

function subscribeToKeyDownCOMPAT(handler) {
  window.addEventListener("keydown", handler);
  return (function (param) {
      window.removeEventListener("keydown", handler);
      
    });
}

function subscribeToScroll(handler) {
  var __x = window;
  __x.addEventListener("scroll", handler);
  return (function (param) {
            var __x = window;
            __x.removeEventListener("scroll", handler);
            
          });
}

function isDomKey($$event, key) {
  return $$event.key === key;
}

function isReactKey($$event, key) {
  return $$event.key === key;
}

var escKey = "Escape";

var enterKey = "Enter";

var spaceKey = " ";

function isDomEscKey($$event) {
  return $$event.key === escKey;
}

function isReactEscKey($$event) {
  return $$event.key === escKey;
}

function onDomEscKey($$event, handler) {
  if ($$event.key === escKey) {
    return Curry._1(handler, undefined);
  }
  
}

function onReactEscKey($$event, handler) {
  if ($$event.key === escKey) {
    return Curry._1(handler, undefined);
  }
  
}

function isDomEnterKey($$event) {
  return $$event.key === enterKey;
}

function isReactEnterKey($$event) {
  return $$event.key === enterKey;
}

function onDomEnterKey($$event, handler) {
  if ($$event.key === enterKey) {
    return Curry._1(handler, undefined);
  }
  
}

function onReactEnterKey($$event, handler) {
  if ($$event.key === enterKey) {
    return Curry._1(handler, undefined);
  }
  
}

function isDomSpaceKey($$event) {
  return $$event.key === spaceKey;
}

function isReactSpaceKey($$event) {
  return $$event.key === spaceKey;
}

function onDomSpaceKey(handler, $$event) {
  if ($$event.key === spaceKey) {
    return Curry._1(handler, undefined);
  }
  
}

function onReactSpaceKey($$event, handler) {
  if ($$event.key === spaceKey) {
    return Curry._1(handler, undefined);
  }
  
}

exports.Target = Target;
exports.subscribeToClick = subscribeToClick;
exports.subscribeToMouseDown = subscribeToMouseDown;
exports.subscribeToKeyDown = subscribeToKeyDown;
exports.subscribeToKeyDownCOMPAT = subscribeToKeyDownCOMPAT;
exports.subscribeToScroll = subscribeToScroll;
exports.isDomKey = isDomKey;
exports.isReactKey = isReactKey;
exports.escKey = escKey;
exports.enterKey = enterKey;
exports.spaceKey = spaceKey;
exports.isDomEscKey = isDomEscKey;
exports.isReactEscKey = isReactEscKey;
exports.onDomEscKey = onDomEscKey;
exports.onReactEscKey = onReactEscKey;
exports.isDomEnterKey = isDomEnterKey;
exports.isReactEnterKey = isReactEnterKey;
exports.onDomEnterKey = onDomEnterKey;
exports.onReactEnterKey = onReactEnterKey;
exports.isDomSpaceKey = isDomSpaceKey;
exports.isReactSpaceKey = isReactSpaceKey;
exports.onDomSpaceKey = onDomSpaceKey;
exports.onReactSpaceKey = onReactSpaceKey;
/* No side effect */
