// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var A = require("../../../../styleguide/components/Link/A.bs.js");
var Cn = require("re-classnames/src/Cn.bs.js");
var Opt = require("../../../../libs/Opt.bs.js");
var $$Date = require("../../../../libs/Date.bs.js");
var Form = require("../../../../styleguide/forms/Form/Form.bs.js");
var I18n = require("../../../../libs/I18n.bs.js");
var Alert = require("../../../../styleguide/components/Alert/Alert.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Label = require("../../../../styleguide/forms/Label/Label.bs.js");
var Modal = require("../../../../styleguide/components/Modal/Modal.bs.js");
var Route = require("../../../../Route.bs.js");
var React = require("react");
var Button = require("../../../../styleguide/components/Button/Button.bs.js");
var ReactRef = require("../../../../bindings/ReactRef.bs.js");
var TextArea = require("../../../../styleguide/forms/TextArea/TextArea.bs.js");
var DateField = require("../../../../styleguide/forms/DateField/DateField.bs.js");
var TextField = require("../../../../styleguide/forms/TextField/TextField.bs.js");
var GuestsForm = require("../../../../styleguide/components/GustsForm/GuestsForm.bs.js");
var PhoneInput = require("../../../../styleguide/forms/PhoneInput/PhoneInput.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var SlvBookingModalFormModuleScss = require("./SlvBookingModalForm.module.scss");

var css = SlvBookingModalFormModuleScss;

function reducer(_state, action) {
  return action[0];
}

function userFieldDomId(field) {
  return "listing--message-host-modal--register-user--" + (String(field) + "");
}

function SlvBookingMessageModalForm(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var user = Props.user;
  var screen = Props.screen;
  var viewContext = Props.viewContext;
  var checkInField = React.useRef(null);
  var checkOutField = React.useRef(null);
  var initialState = React.useMemo((function () {
          var initialDate = function (date) {
            var match = booking.status;
            if (!match) {
              return date;
            }
            var match$1 = match[0];
            if (match$1[0] < 59168818) {
              return date;
            }
            var match$2 = match$1[1];
            if (!match$2.tag) {
              return date;
            }
            var match$3 = match$2[0];
            if (typeof match$3 === "number") {
              return date;
            }
            var match$4 = match$3[1];
            if (typeof match$4 === "number") {
              if (match$4 >= 459612177) {
                if (match$4 >= 710459028) {
                  return date;
                } else {
                  return ;
                }
              } else if (match$4 !== 183515014) {
                return date;
              } else {
                return ;
              }
            } else {
              return date;
            }
          };
          return {
                  checkIn: initialDate(booking.input.checkIn),
                  checkOut: initialDate(booking.input.checkOut),
                  adults: booking.input.adults,
                  children: booking.input.children,
                  infants: booking.input.infants,
                  pets: booking.input.pets
                };
        }), ([]));
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var calNote = React.useMemo((function () {
          return Belt_Option.map(listing.minimumStay, (function (x) {
                        var nights = I18n.inflect(x, "night", "nights");
                        return "" + (String(nights) + " minimum stay");
                      }));
        }), [listing.minimumStay]);
  var isCheckInDay = React.useCallback((function (day) {
          return Belt_Option.mapWithDefault(booking.input.checkIn, false, (function (checkIn) {
                        return $$Date.Naive.isSameDay(day, checkIn);
                      }));
        }), [booking.input.checkIn]);
  var isCheckOutDay = React.useCallback((function (day) {
          return Belt_Option.mapWithDefault(booking.input.checkOut, false, (function (checkOut) {
                        return $$Date.Naive.isSameDay(day, checkOut);
                      }));
        }), [booking.input.checkOut]);
  var isDayInBetween = React.useCallback((function (day) {
          var match = booking.input.checkIn;
          var match$1 = booking.input.checkOut;
          if (match !== undefined && match$1 !== undefined && $$Date.Naive.isAfter(day, Caml_option.valFromOption(match))) {
            return $$Date.Naive.isBefore(day, Caml_option.valFromOption(match$1));
          } else {
            return false;
          }
        }));
  var isDayDisabled = React.useCallback((function (day) {
          return Curry._2(booking.isDayInPastRelativeToListingTimezone, day, listing.currentDate);
        }));
  var updateCheckIn = React.useCallback((function (day) {
          Curry._1(dispatch, /* UpdateInput */[{
                  checkIn: Caml_option.some(day),
                  checkOut: state.checkOut,
                  adults: state.adults,
                  children: state.children,
                  infants: state.infants,
                  pets: state.pets
                }]);
          return Curry._1(booking.notifyGtmOnMessageModalFormInteraction, undefined);
        }), [state]);
  var updateCheckOut = React.useCallback((function (day) {
          Curry._1(dispatch, /* UpdateInput */[{
                  checkIn: state.checkIn,
                  checkOut: Caml_option.some(day),
                  adults: state.adults,
                  children: state.children,
                  infants: state.infants,
                  pets: state.pets
                }]);
          return Curry._1(booking.notifyGtmOnMessageModalFormInteraction, undefined);
        }), [state]);
  var clearDates = React.useCallback((function (param) {
          Curry._1(dispatch, /* UpdateInput */[{
                  checkIn: undefined,
                  checkOut: undefined,
                  adults: state.adults,
                  children: state.children,
                  infants: state.infants,
                  pets: state.pets
                }]);
          return Curry._1(booking.notifyGtmOnMessageModalFormInteraction, undefined);
        }), [state]);
  var updateAdults = React.useCallback((function (adults) {
          Curry._1(dispatch, /* UpdateInput */[{
                  checkIn: state.checkIn,
                  checkOut: state.checkOut,
                  adults: adults > 0 ? adults : 1,
                  children: state.children,
                  infants: state.infants,
                  pets: state.pets
                }]);
          return Curry._1(booking.notifyGtmOnMessageModalFormInteraction, undefined);
        }), [state]);
  var updateChildren = React.useCallback((function (children) {
          Curry._1(dispatch, /* UpdateInput */[{
                  checkIn: state.checkIn,
                  checkOut: state.checkOut,
                  adults: state.adults,
                  children: children >= 0 ? children : 0,
                  infants: state.infants,
                  pets: state.pets
                }]);
          return Curry._1(booking.notifyGtmOnMessageModalFormInteraction, undefined);
        }), [state]);
  var updateInfants = React.useCallback((function (infants) {
          Curry._1(dispatch, /* UpdateInput */[{
                  checkIn: state.checkIn,
                  checkOut: state.checkOut,
                  adults: state.adults,
                  children: state.children,
                  infants: infants >= 0 ? infants : 0,
                  pets: state.pets
                }]);
          return Curry._1(booking.notifyGtmOnMessageModalFormInteraction, undefined);
        }), [state]);
  var updatePets = React.useCallback((function (pets) {
          Curry._1(dispatch, /* UpdateInput */[{
                  checkIn: state.checkIn,
                  checkOut: state.checkOut,
                  adults: state.adults,
                  children: state.children,
                  infants: state.infants,
                  pets: pets
                }]);
          return Curry._1(booking.notifyGtmOnMessageModalFormInteraction, undefined);
        }), [state]);
  var match$1 = user.id;
  var tmp;
  if (match$1 !== undefined) {
    tmp = null;
  } else {
    var match$2 = booking.newUser.results.firstName;
    var tmp$1;
    tmp$1 = match$2 !== undefined && match$2.tag ? /* Failure */1 : undefined;
    var tmp$2 = {
      id: userFieldDomId("firstName"),
      size: /* MD */1,
      value: Opt.toString(booking.newUser.input.firstName),
      onChange: (function ($$event) {
          return Curry._2(booking.updateNewUserFirstName, $$event.target.value, /* MessageModal */722162950);
        })
    };
    if (tmp$1 !== undefined) {
      tmp$2.status = Caml_option.valFromOption(tmp$1);
    }
    var match$3 = booking.newUser.results.firstName;
    var tmp$3;
    tmp$3 = match$3 !== undefined && match$3.tag ? React.createElement("div", {
            className: css.failure
          }, match$3[0]) : null;
    var match$4 = booking.newUser.results.lastName;
    var tmp$4;
    tmp$4 = match$4 !== undefined && match$4.tag ? /* Failure */1 : undefined;
    var tmp$5 = {
      id: userFieldDomId("lastName"),
      size: /* MD */1,
      value: Opt.toString(booking.newUser.input.lastName),
      onChange: (function ($$event) {
          return Curry._2(booking.updateNewUserLastName, $$event.target.value, /* MessageModal */722162950);
        })
    };
    if (tmp$4 !== undefined) {
      tmp$5.status = Caml_option.valFromOption(tmp$4);
    }
    var match$5 = booking.newUser.results.lastName;
    var tmp$6;
    tmp$6 = match$5 !== undefined && match$5.tag ? React.createElement("div", {
            className: css.failure
          }, match$5[0]) : null;
    var match$6 = booking.newUser.results.email;
    var tmp$7;
    tmp$7 = match$6 !== undefined && match$6.tag ? /* Failure */1 : undefined;
    var tmp$8 = {
      id: userFieldDomId("email"),
      size: /* MD */1,
      value: Opt.toString(booking.newUser.input.email),
      onChange: (function ($$event) {
          return Curry._2(booking.updateNewUserEmail, $$event.target.value, /* MessageModal */722162950);
        })
    };
    if (tmp$7 !== undefined) {
      tmp$8.status = Caml_option.valFromOption(tmp$7);
    }
    var match$7 = booking.newUser.results.email;
    var tmp$9;
    tmp$9 = match$7 !== undefined && match$7.tag ? React.createElement("div", {
            className: css.failure
          }, match$7[0]) : null;
    tmp = React.createElement("div", undefined, React.createElement("div", {
              className: css.introduceNote
            }, React.createElement("span", {
                  className: css.bold
                }, "Please introduce yourself"), " so that the host can get in touch with you."), React.createElement("div", {
              className: css.flexGrid
            }, React.createElement("div", {
                  className: css.col
                }, React.createElement(Label.make, {
                      forId: userFieldDomId("firstName"),
                      placement: /* Top */0,
                      className: css.label,
                      children: "First Name"
                    }), React.createElement(TextField.make, tmp$2), tmp$3), React.createElement("div", {
                  className: css.col
                }, React.createElement(Label.make, {
                      forId: userFieldDomId("lastName"),
                      placement: /* Top */0,
                      className: css.label,
                      children: "Last Name"
                    }), React.createElement(TextField.make, tmp$5), tmp$6), React.createElement("div", {
                  className: css.col
                }, React.createElement(Label.make, {
                      forId: userFieldDomId("email"),
                      placement: /* Top */0,
                      className: css.label,
                      children: "Email"
                    }), React.createElement(TextField.make, tmp$8), tmp$9), React.createElement("div", {
                  className: css.col
                }, React.createElement(Label.make, {
                      forId: userFieldDomId("phone"),
                      placement: /* Top */0,
                      className: css.label,
                      children: "Phone"
                    }), React.createElement(PhoneInput.make, {
                      id: userFieldDomId("phone"),
                      size: /* MD */1,
                      className: css.phoneInput,
                      value: Opt.toString(booking.newUser.input.phone),
                      onChange: (function (value) {
                          return Curry._2(booking.updateNewUserPhone, value, /* MessageModal */722162950);
                        })
                    }))));
  }
  var match$8 = state.checkIn;
  var match$9 = state.checkOut;
  var match$10 = state.checkIn;
  var match$11 = state.checkOut;
  var tmp$10 = {
    size: /* MD */1,
    neighbors: /* Right */1,
    calPlacement: /* Left */0,
    calModifiers: /* :: */[
      /* tuple */[
        /* Disabled */0,
        /* `Fn */[
          15720,
          isDayDisabled
        ]
      ],
      /* :: */[
        /* tuple */[
          /* SecondarySelected */1,
          /* `Fn */[
            15720,
            isCheckOutDay
          ]
        ],
        /* :: */[
          /* tuple */[
            /* TertiarySelected */2,
            /* `Fn */[
              15720,
              isDayInBetween
            ]
          ],
          /* [] */0
        ]
      ]
    ],
    date: state.checkIn,
    initialMonth: match$8 !== undefined ? /* Specified */[Caml_option.valFromOption(match$8)] : (
        match$9 !== undefined ? /* Specified */[Caml_option.valFromOption(match$9)] : /* Auto */0
      ),
    placeholder: "Check in",
    onDayClick: (function (day) {
        Curry._1(updateCheckIn, day);
        if (Belt_Option.isNone(state.checkOut)) {
          return ReactRef.focus(checkOutField);
        }
        
      }),
    ref: checkInField
  };
  if (calNote !== undefined) {
    tmp$10.calNote = Caml_option.valFromOption(calNote);
  }
  var tmp$11 = match$10 !== undefined || match$11 !== undefined ? clearDates : undefined;
  if (tmp$11 !== undefined) {
    tmp$10.onDatesClear = Caml_option.valFromOption(tmp$11);
  }
  var match$12 = state.checkIn;
  var match$13 = state.checkOut;
  var match$14 = state.checkIn;
  var match$15 = state.checkOut;
  var tmp$12 = {
    size: /* MD */1,
    neighbors: /* Left */0,
    calPlacement: /* Right */2,
    calModifiers: /* :: */[
      /* tuple */[
        /* Disabled */0,
        /* `Fn */[
          15720,
          isDayDisabled
        ]
      ],
      /* :: */[
        /* tuple */[
          /* SecondarySelected */1,
          /* `Fn */[
            15720,
            isCheckInDay
          ]
        ],
        /* :: */[
          /* tuple */[
            /* TertiarySelected */2,
            /* `Fn */[
              15720,
              isDayInBetween
            ]
          ],
          /* [] */0
        ]
      ]
    ],
    date: state.checkOut,
    initialMonth: match$12 !== undefined ? (
        match$13 !== undefined ? /* Specified */[Caml_option.valFromOption(match$13)] : /* Specified */[Caml_option.valFromOption(match$12)]
      ) : (
        match$13 !== undefined ? /* Specified */[Caml_option.valFromOption(match$13)] : /* Auto */0
      ),
    placeholder: "Check out",
    onDayClick: (function (day) {
        Curry._1(updateCheckOut, day);
        if (Belt_Option.isNone(state.checkIn)) {
          return ReactRef.focus(checkInField);
        }
        
      }),
    ref: checkOutField
  };
  if (calNote !== undefined) {
    tmp$12.calNote = Caml_option.valFromOption(calNote);
  }
  var tmp$13 = match$14 !== undefined || match$15 !== undefined ? clearDates : undefined;
  if (tmp$13 !== undefined) {
    tmp$12.onDatesClear = Caml_option.valFromOption(tmp$13);
  }
  var match$16 = booking.message.result;
  var tmp$14;
  tmp$14 = match$16 !== undefined && match$16.tag ? /* Failure */1 : undefined;
  var tmp$15 = {
    testId: "message-host-modal-message",
    rows: 3,
    size: /* MD */1,
    value: Belt_Option.getWithDefault(booking.input.comment, ""),
    placeholder: "Add a message for the host",
    className: css.messageInput,
    onChange: (function ($$event) {
        return Curry._2(booking.updateComment, $$event.target.value, /* MessageModal */722162950);
      })
  };
  if (tmp$14 !== undefined) {
    tmp$15.status = Caml_option.valFromOption(tmp$14);
  }
  var match$17 = booking.message.result;
  var tmp$16;
  tmp$16 = match$17 !== undefined && match$17.tag ? React.createElement("div", {
          className: css.failure
        }, match$17[0]) : null;
  var match$18 = booking.modal.messageStatus;
  var tmp$17;
  tmp$17 = typeof match$18 === "number" || !(match$18.tag && match$18[0].tag) ? null : React.createElement(Modal.Block.make, {
          children: React.createElement(Alert.make, {
                level: /* Danger */3,
                children: "Oops, something went wrong"
              })
        });
  var match$19 = booking.modal.messageStatus;
  var match$20 = user.id;
  var match$21 = listing.kind;
  return React.createElement(Modal.Panels.make, {
              className: css.wrapper,
              children: null
            }, React.createElement(Modal.PanelsTitle.make, {
                  className: css.title,
                  children: "Message host"
                }), React.createElement(Modal.MainPanel.make, {
                  children: React.createElement(Form.make, {
                        onSubmit: (function (param) {
                            return Curry._1(booking.submitMessage, state);
                          }),
                        children: null
                      }, tmp, React.createElement(Modal.Block.make, {
                            children: null
                          }, React.createElement("h3", {
                                className: css.secondaryTitle
                              }, "Tell the host details of your trip"), React.createElement("div", {
                                className: Cn.$plus(css.fieldsRow, css.detailsFieldsRow)
                              }, React.createElement("div", {
                                    className: css.dateFields
                                  }, React.createElement("div", {
                                        className: css.field
                                      }, React.createElement(DateField.make, tmp$10)), React.createElement("div", {
                                        className: css.field
                                      }, React.createElement(DateField.make, tmp$12))), React.createElement("div", {
                                    className: css.guestsField
                                  }, React.createElement(GuestsForm.make, {
                                        input: {
                                          adults: state.adults,
                                          children: state.children,
                                          infants: state.infants,
                                          pets: state.pets
                                        },
                                        houseRules: listing.systemHouseRules,
                                        popoverPlacement: screen !== undefined && screen >= 2 ? /* Center */1 : /* Right */2,
                                        onAdultsChange: updateAdults,
                                        onChildrenChange: updateChildren,
                                        onInfantsChange: updateInfants,
                                        onPetsChange: updatePets
                                      })))), React.createElement(Modal.Block.make, {
                            children: null
                          }, React.createElement("h3", {
                                className: css.secondaryTitle
                              }, "What do you want to say to the host?"), React.createElement(TextArea.make, tmp$15), tmp$16), tmp$17, React.createElement(Modal.Footer.make, {
                            className: css.footer,
                            children: null
                          }, React.createElement(Button.make, {
                                size: /* LG */2,
                                color: /* Primary */0,
                                submit: true,
                                busy: typeof match$19 === "number" ? true : false,
                                className: css.confirmButton,
                                children: "Send Message"
                              }), match$20 !== undefined || viewContext >= 2 ? null : React.createElement("div", {
                                  className: css.footerNote
                                }, "By clicking on the button I confirm that I agree to the House Rules, ", React.createElement(A.make, {
                                      href: Route.termsOfService,
                                      target: /* Blank */[/* ReferrerAllowed */781962729],
                                      color: /* General */0,
                                      children: "Terms and Conditions"
                                    }), ", and ", React.createElement(A.make, {
                                      href: Route.privacyPolicy,
                                      target: /* Blank */[/* ReferrerAllowed */781962729],
                                      color: /* General */0,
                                      children: "Privacy Policy"
                                    }), ".")))
                }), React.createElement(Modal.SidePanel.make, {
                  children: React.createElement(Modal.Block.make, {
                        className: css.messageSidePanel,
                        children: null
                      }, React.createElement("div", undefined, match$21 && match$21[0] < 809617083 ? "By sending an inquiry, you have the best chance of booking this rental direct with the host at a better price than is available elsewhere. We have not previously had direct contact with the host of this rental, but we will do our best to make contact within the next few hours. We will keep you informed of our progress." : null, React.createElement("br", undefined), React.createElement("br", undefined)), "Share some details of your trip with the host so they can help answer any questions and share some local knowledge:", React.createElement("ul", undefined, React.createElement("li", undefined, "What date would you like to arrive?"), React.createElement("li", undefined, "How many people are coming?"), React.createElement("li", undefined, "List anything special you would like to see or do while there.")))
                }));
}

var dateTemplate = "MMM DD, YYYY";

var make = SlvBookingMessageModalForm;

exports.css = css;
exports.reducer = reducer;
exports.dateTemplate = dateTemplate;
exports.userFieldDomId = userFieldDomId;
exports.make = make;
/* css Not a pure module */
