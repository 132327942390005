// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var A = require("../../../../styleguide/components/Link/A.bs.js");
var Cn = require("re-classnames/src/Cn.bs.js");
var $$Date = require("../../../../libs/Date.bs.js");
var Html = require("../../../../libs/Html.bs.js");
var Vrbp = require("../../../../models/Vrbp.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Route = require("../../../../Route.bs.js");
var React = require("react");
var Listing = require("../../../../models/Listing.bs.js");
var SlvVrbp = require("../SlvVrbp.bs.js");
var Spinner = require("../../../../styleguide/components/Spinner/Spinner.bs.js");
var Tooltip = require("../../../../styleguide/components/Tooltip/Tooltip.bs.js");
var Currency = require("../../../../styleguide/components/Currency/Currency.bs.js");
var SlvBlock = require("../../components/SlvBlock/SlvBlock.bs.js");
var VrboIcon = require("../../../../styleguide/icons/VrboIcon.bs.js");
var AirbnbIcon = require("../../../../styleguide/icons/AirbnbIcon.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReactIntl = require("react-intl");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var MascotWithMessage = require("../../../../styleguide/components/MascotWithMessage/MascotWithMessage.bs.js");
var TooltipQuestionIcon = require("../../../../styleguide/icons/TooltipQuestionIcon.bs.js");
var RightAngleBracketIcon = require("../../../../styleguide/icons/RightAngleBracketIcon.bs.js");
var SlvDealsOnOtherSitesModuleScss = require("./SlvDealsOnOtherSites.module.scss");

var css = SlvDealsOnOtherSitesModuleScss;

function SlvDealsOnOtherSites$StayDetails$DateWithYear(Props) {
  var children = Props.children;
  return React.createElement(ReactIntl.FormattedDate, {
              value: children,
              year: "numeric",
              month: "short",
              day: "2-digit"
            });
}

var DateWithYear = {
  make: SlvDealsOnOtherSites$StayDetails$DateWithYear
};

function SlvDealsOnOtherSites$StayDetails$DateWithoutYear(Props) {
  var children = Props.children;
  return React.createElement(ReactIntl.FormattedDate, {
              value: children,
              month: "short",
              day: "2-digit"
            });
}

var DateWithoutYear = {
  make: SlvDealsOnOtherSites$StayDetails$DateWithoutYear
};

function data(sampleData, bookingStatus) {
  var quote;
  if (!bookingStatus) {
    return ;
  }
  var match = bookingStatus[0];
  if (match[0] >= 59168818) {
    var match$1 = match[1];
    if (match$1.tag) {
      return ;
    }
    var match$2 = match$1[0];
    if (typeof match$2 === "number") {
      var match$3 = sampleData.sampleCheckIn;
      var match$4 = sampleData.sampleCheckOut;
      if (match$3 === undefined) {
        return ;
      }
      if (match$4 === undefined) {
        return ;
      }
      var match$5 = Listing.Contextual.VrbpData.sampleGuests;
      return {
              source: /* SampleQuote */-647433582,
              checkIn: Caml_option.valFromOption(match$3),
              checkOut: Caml_option.valFromOption(match$4),
              guests: (match$5.adults + match$5.children | 0) + match$5.infants | 0
            };
    }
    quote = match$2[1];
  } else {
    var match$6 = match[1][0];
    if (typeof match$6 === "number") {
      return ;
    }
    quote = match$6[1][0];
  }
  return {
          source: /* LiveQuote */325827728,
          checkIn: quote.checkIn,
          checkOut: quote.checkOut,
          guests: (quote.adults + quote.children | 0) + quote.infants | 0
        };
}

function SlvDealsOnOtherSites$StayDetails(Props) {
  var source = Props.source;
  var checkIn = Props.checkIn;
  var checkOut = Props.checkOut;
  var guests = Props.guests;
  var checkInYear = $$Date.Naive.year(checkIn);
  var checkOutYear = $$Date.Naive.year(checkOut);
  return React.createElement("div", {
              className: css.details
            }, React.createElement("div", {
                  className: css.detailsBadge
                }, React.createElement("strong", {
                      className: css.detailsLabel
                    }, source >= 325827728 ? "Your stay details:" : "Sample dates details:"), checkInYear === checkOutYear ? React.createElement(SlvDealsOnOtherSites$StayDetails$DateWithoutYear, {
                        children: checkIn
                      }) : React.createElement(SlvDealsOnOtherSites$StayDetails$DateWithYear, {
                        children: checkIn
                      }), Html.ndash, React.createElement(SlvDealsOnOtherSites$StayDetails$DateWithYear, {
                      children: checkOut
                    }), ", ", React.createElement(ReactIntl.FormattedMessage, {
                      id: "slv.deals-on-other-sites.details.guests",
                      defaultMessage: "{guests, plural, one {# guest} few {# guests} many {# guests} other {# guests}}",
                      values: {
                        guests: guests
                      }
                    })));
}

var StayDetails = {
  DateWithYear: DateWithYear,
  DateWithoutYear: DateWithoutYear,
  data: data,
  make: SlvDealsOnOtherSites$StayDetails
};

function status(provider, sampleCheckIn, sampleCheckOut, sampleProviderData, bookingStatus) {
  var fromQuote = function (quote) {
    return Belt_Option.map(Belt_Option.flatMap(Belt_Array.getBy(quote.providers, (function (provider$prime) {
                          return provider$prime.name === provider;
                        })), (function (provider) {
                      return provider.quote;
                    })), (function (providerQuote) {
                  return /* Ready */[/* Ok */Block.__(0, [{
                                checkIn: quote.checkIn,
                                checkOut: quote.checkOut,
                                adults: quote.adults,
                                children: quote.children,
                                infants: quote.infants,
                                pets: quote.pets,
                                total: providerQuote.total,
                                pricePerNight: providerQuote.pricePerNight,
                                notIncludedInPriceComparison: providerQuote.bestPriceSkipped
                              }])];
                }));
  };
  if (!bookingStatus) {
    return /* Processing */0;
  }
  var match = bookingStatus[0];
  if (match[0] >= 59168818) {
    var match$1 = match[1];
    if (match$1.tag) {
      return /* Ready */[/* Error */Block.__(1, [undefined])];
    }
    var match$2 = match$1[0];
    if (typeof match$2 !== "number") {
      return Belt_Option.getWithDefault(fromQuote(match$2[1]), /* Ready */[/* Ok */Block.__(0, [undefined])]);
    }
    var match$3 = sampleProviderData.price;
    var tmp;
    if (match$3 !== undefined && sampleCheckIn !== undefined && sampleCheckOut !== undefined) {
      var match$4 = Listing.Contextual.VrbpData.sampleGuests;
      tmp = {
        checkIn: Caml_option.valFromOption(sampleCheckIn),
        checkOut: Caml_option.valFromOption(sampleCheckOut),
        adults: match$4.adults,
        children: match$4.children,
        infants: match$4.infants,
        pets: match$4.pets,
        total: match$3.total,
        pricePerNight: match$3.perNight,
        notIncludedInPriceComparison: false
      };
    } else {
      tmp = undefined;
    }
    return /* Ready */[/* Ok */Block.__(0, [tmp])];
  }
  var match$5 = match[1][0];
  if (typeof match$5 === "number") {
    return /* Processing */0;
  } else {
    return Belt_Option.getWithDefault(fromQuote(match$5[1][0]), /* Processing */0);
  }
}

function SlvDealsOnOtherSites$ProviderRow(Props) {
  var listingId = Props.listingId;
  var provider = Props.provider;
  var icon = Props.icon;
  var status = Props.status;
  var transferPageUrl = React.useMemo((function () {
          if (!status) {
            return Route.transferPage(listingId, provider);
          }
          var match = status[0];
          if (match.tag) {
            return Route.transferPage(listingId, provider);
          }
          var data = match[0];
          if (data !== undefined) {
            return Route.transferPageWithBookingData(listingId, provider, data.checkIn, data.checkOut, data.adults, data.children, data.infants, data.pets);
          } else {
            return Route.transferPage(listingId, provider);
          }
        }), /* tuple */[
        provider,
        status
      ]);
  var tmp;
  if (status) {
    var match = status[0];
    tmp = match.tag || match[0] === undefined ? css.providerRowNoPrice : Cn.none;
  } else {
    tmp = Cn.none;
  }
  var tmp$1;
  if (status) {
    var match$1 = status[0];
    var exit = 0;
    if (match$1.tag) {
      exit = 1;
    } else {
      var match$2 = match$1[0];
      if (match$2 !== undefined) {
        tmp$1 = React.createElement("div", {
              className: css.providerDataPrices
            }, React.createElement("div", {
                  className: css.providerDataPrimaryPrice
                }, React.createElement(Currency.WithoutCents.make, {
                      children: match$2.total
                    })), React.createElement("div", {
                  className: css.providerDataSecondaryPrice
                }, React.createElement(Currency.WithoutCents.make, {
                      children: match$2.pricePerNight
                    }), " per night"), match$2.notIncludedInPriceComparison ? React.createElement("div", {
                    className: css.providerDataPriceNotIncludedNote
                  }, "This quote may have fees not included in the total. Shown for comparison.") : null);
      } else {
        exit = 1;
      }
    }
    if (exit === 1) {
      tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("span", {
                className: css.providerDataFadedLabel
              }, "No price"), React.createElement(Tooltip.make, {
                content: "We didn\'t manage to receive the live total price. Selected dates can be unavailable or there was an error.",
                side: /* Left */2,
                className: css.providerDataTooltip,
                children: React.createElement("span", {
                      onClick: (function (e) {
                          e.preventDefault();
                          
                        })
                    }, React.createElement(TooltipQuestionIcon.make, {
                          size: /* MD */2,
                          color: /* LightGray */9
                        }))
              }));
    }
    
  } else {
    tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: css.providerDataSpinner
            }, React.createElement(Spinner.make, {
                  size: /* MD */1,
                  color: /* Teal */1
                })), React.createElement("span", {
              className: css.providerDataFadedLabel
            }, "Fetching totals"));
  }
  return React.createElement(A.Unstyled.make, {
              href: transferPageUrl,
              target: /* Blank */[/* ReferrerAllowed */781962729],
              className: Cn.$plus(css.providerRow, tmp),
              children: null
            }, React.createElement("div", {
                  className: css.providerLabel
                }, React.createElement(icon.make, {
                      size: /* SM */1,
                      className: css.providerIcon
                    }), Vrbp.Provider.toView(provider)), React.createElement("div", {
                  className: css.providerData
                }, tmp$1, React.createElement(RightAngleBracketIcon.make, {
                      size: /* SM */1,
                      color: /* Teal */1,
                      title: "Go to website",
                      className: css.providerDataArrowIcon
                    })));
}

var ProviderRow = {
  status: status,
  make: SlvDealsOnOtherSites$ProviderRow
};

function SlvDealsOnOtherSites$Footer(Props) {
  var status = Props.status;
  var listingKind = Props.listingKind;
  if (!status) {
    return React.createElement(Spinner.make, {
                size: /* MD */1,
                color: /* White */4,
                centered: true
              });
  }
  var match = status[0];
  var directPrice = match.directPrice;
  var bestPrice = match.bestPrice;
  var tmp;
  if (typeof bestPrice === "number") {
    tmp = "Chee hoo! HiChee seems to be the only place to book this property!";
  } else if (bestPrice.tag) {
    tmp = "Hmm... Our price is not the best. I’ll inform the host about it.";
  } else {
    var profit = bestPrice[/* profit */0];
    var exit = 0;
    if (listingKind && listingKind[0] < 809617083) {
      tmp = React.createElement(React.Fragment, undefined, "Chee Hoo! ", React.createElement(Currency.WithoutCents.make, {
                children: profit
              }), " lower should be possible direct from the host.");
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = React.createElement(React.Fragment, undefined, "Chee hoo! You save ", React.createElement(Currency.WithoutCents.make, {
                children: profit
              }), " by booking through HiChee!");
    }
    
  }
  return React.createElement(React.Fragment, undefined, React.createElement(MascotWithMessage.make, {
                  message: tmp,
                  className: css.mascotWithMessage,
                  bubbleWrapperClassName: css.bubbleWrapper,
                  bubbleColor: /* White */0
                }), React.createElement("div", {
                  className: css.footerPrices
                }, React.createElement("div", {
                      className: css.footerPrimaryPrice
                    }, React.createElement("span", {
                          className: css.footerPrimaryPriceLabel
                        }, "Direct to host:"), React.createElement("span", {
                          className: css.footerPrimaryPriceValue
                        }, React.createElement(Currency.WithoutCents.make, {
                              children: directPrice.total
                            }))), React.createElement("div", {
                      className: css.footerSecondaryPrice
                    }, React.createElement(Currency.WithoutCents.make, {
                          children: directPrice.perNight
                        }), " per night")));
}

var Footer = {
  make: SlvDealsOnOtherSites$Footer
};

function SlvDealsOnOtherSites$Block(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var airbnb = React.useMemo((function () {
          return Belt_Option.map(listing.vrbpData.airbnb, (function (sampleData) {
                        return status(/* Airbnb */1, listing.vrbpData.sampleCheckIn, listing.vrbpData.sampleCheckOut, sampleData, booking.status);
                      }));
        }), /* tuple */[
        listing.vrbpData.airbnb,
        booking.status
      ]);
  var vrbo = React.useMemo((function () {
          return Belt_Option.map(listing.vrbpData.vrbo, (function (sampleData) {
                        return status(/* Vrbo */2, listing.vrbpData.sampleCheckIn, listing.vrbpData.sampleCheckOut, sampleData, booking.status);
                      }));
        }), /* tuple */[
        listing.vrbpData.vrbo,
        booking.status
      ]);
  var match = data(listing.vrbpData, booking.status);
  var status$1 = SlvVrbp.status(booking.status, listing.vrbpData);
  return React.createElement(SlvBlock.make, {
              id: /* DealsOnOtherSites */668849890,
              children: null
            }, React.createElement(SlvBlock.Title.make, {
                  children: React.createElement(ReactIntl.FormattedMessage, {
                        id: "slv.deals-on-other-sites.title",
                        defaultMessage: "Deals on other websites"
                      })
                }), React.createElement("div", {
                  className: css.subTitleText
                }, "Feel free to check prices, read reviews and learn more about the property. Don’t forget to come back to HiChee to contact the host directly!"), match !== undefined ? React.createElement(SlvDealsOnOtherSites$StayDetails, {
                    source: match.source,
                    checkIn: match.checkIn,
                    checkOut: match.checkOut,
                    guests: match.guests
                  }) : null, airbnb !== undefined ? React.createElement(SlvDealsOnOtherSites$ProviderRow, {
                    listingId: listing.id,
                    provider: /* Airbnb */1,
                    icon: AirbnbIcon,
                    status: airbnb
                  }) : null, vrbo !== undefined ? React.createElement(SlvDealsOnOtherSites$ProviderRow, {
                    listingId: listing.id,
                    provider: /* Vrbo */2,
                    icon: VrboIcon,
                    status: vrbo
                  }) : null, status$1 !== undefined ? React.createElement(SlvBlock.Footer.make, {
                    className: css.footer,
                    children: React.createElement(SlvDealsOnOtherSites$Footer, {
                          status: status$1,
                          listingKind: listing.kind
                        })
                  }) : null);
}

var Block$1 = {
  make: SlvDealsOnOtherSites$Block
};

function SlvDealsOnOtherSites(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var match = listing.vrbpData;
  if (match.airbnb === undefined && match.vrbo === undefined) {
    return null;
  }
  return React.createElement(SlvDealsOnOtherSites$Block, {
              listing: listing,
              booking: booking
            });
}

var make = SlvDealsOnOtherSites;

exports.css = css;
exports.StayDetails = StayDetails;
exports.ProviderRow = ProviderRow;
exports.Footer = Footer;
exports.Block = Block$1;
exports.make = make;
/* css Not a pure module */
