// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Env = require("../libs/Env.bs.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Browser = require("@sentry/browser");

var Scope = { };

function toString(param) {
  switch (param) {
    case /* Debug */0 :
        return "debug";
    case /* Info */1 :
        return "info";
    case /* Warning */2 :
        return "warning";
    case /* Error */3 :
        return "error";
    
  }
}

var Level = {
  toString: toString
};

function captureMessage(message, level) {
  Browser.captureMessage(message, toString(level));
  
}

function init(param) {
  if (Env.env >= 2) {
    if (Env.sentryDsn !== undefined) {
      var dsn = Env.sentryDsn;
      Browser.init({
            dsn: dsn
          });
      return ;
    } else {
      return Pervasives.failwith("SENTRY_DSN is not set");
    }
  }
  
}

var fingerprintKey = "fingerprint";

function setUser(user) {
  Browser.configureScope((function (scope) {
          scope.setUser(user);
          
        }));
  
}

function format(message, __module__) {
  return "[" + (String(__module__) + ("] " + (String(message) + "")));
}

function updateScope(scope, param) {
  var data = param[1];
  var label = param[0];
  if (label === fingerprintKey) {
    scope.setFingerprint(data);
    return ;
  } else {
    scope.setExtra(label, data);
    return ;
  }
}

function warn(__module__, message) {
  return captureMessage(format(message, __module__), /* Warning */2);
}

function warnWithData(__module__, message, param) {
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope((function (scope) {
          updateScope(scope, /* tuple */[
                label1,
                data1
              ]);
          return captureMessage(format(message, __module__), /* Warning */2);
        }));
  
}

function warnWithData2(__module__, message, param, param$1) {
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope((function (scope) {
          updateScope(scope, /* tuple */[
                label1,
                data1
              ]);
          updateScope(scope, /* tuple */[
                label2,
                data2
              ]);
          return captureMessage(format(message, __module__), /* Warning */2);
        }));
  
}

function warnWithData3(__module__, message, param, param$1, param$2) {
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope((function (scope) {
          updateScope(scope, /* tuple */[
                label1,
                data1
              ]);
          updateScope(scope, /* tuple */[
                label2,
                data2
              ]);
          updateScope(scope, /* tuple */[
                label3,
                data3
              ]);
          return captureMessage(format(message, __module__), /* Warning */2);
        }));
  
}

function warnWithData4(__module__, message, param, param$1, param$2, param$3) {
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope((function (scope) {
          updateScope(scope, /* tuple */[
                label1,
                data1
              ]);
          updateScope(scope, /* tuple */[
                label2,
                data2
              ]);
          updateScope(scope, /* tuple */[
                label3,
                data3
              ]);
          updateScope(scope, /* tuple */[
                label4,
                data4
              ]);
          return captureMessage(format(message, __module__), /* Warning */2);
        }));
  
}

function warnWithData5(__module__, message, param, param$1, param$2, param$3, param$4) {
  var data5 = param$4[1];
  var label5 = param$4[0];
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope((function (scope) {
          updateScope(scope, /* tuple */[
                label1,
                data1
              ]);
          updateScope(scope, /* tuple */[
                label2,
                data2
              ]);
          updateScope(scope, /* tuple */[
                label3,
                data3
              ]);
          updateScope(scope, /* tuple */[
                label4,
                data4
              ]);
          updateScope(scope, /* tuple */[
                label5,
                data5
              ]);
          return captureMessage(format(message, __module__), /* Warning */2);
        }));
  
}

function warnWithData6(__module__, message, param, param$1, param$2, param$3, param$4, param$5) {
  var data6 = param$5[1];
  var label6 = param$5[0];
  var data5 = param$4[1];
  var label5 = param$4[0];
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope((function (scope) {
          updateScope(scope, /* tuple */[
                label1,
                data1
              ]);
          updateScope(scope, /* tuple */[
                label2,
                data2
              ]);
          updateScope(scope, /* tuple */[
                label3,
                data3
              ]);
          updateScope(scope, /* tuple */[
                label4,
                data4
              ]);
          updateScope(scope, /* tuple */[
                label5,
                data5
              ]);
          updateScope(scope, /* tuple */[
                label6,
                data6
              ]);
          return captureMessage(format(message, __module__), /* Warning */2);
        }));
  
}

function warnWithData7(__module__, message, param, param$1, param$2, param$3, param$4, param$5, param$6) {
  var data7 = param$6[1];
  var label7 = param$6[0];
  var data6 = param$5[1];
  var label6 = param$5[0];
  var data5 = param$4[1];
  var label5 = param$4[0];
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope((function (scope) {
          updateScope(scope, /* tuple */[
                label1,
                data1
              ]);
          updateScope(scope, /* tuple */[
                label2,
                data2
              ]);
          updateScope(scope, /* tuple */[
                label3,
                data3
              ]);
          updateScope(scope, /* tuple */[
                label4,
                data4
              ]);
          updateScope(scope, /* tuple */[
                label5,
                data5
              ]);
          updateScope(scope, /* tuple */[
                label6,
                data6
              ]);
          updateScope(scope, /* tuple */[
                label7,
                data7
              ]);
          return captureMessage(format(message, __module__), /* Warning */2);
        }));
  
}

function error(__module__, message) {
  return captureMessage(format(message, __module__), /* Error */3);
}

function errorWithData(__module__, message, param) {
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope((function (scope) {
          updateScope(scope, /* tuple */[
                label1,
                data1
              ]);
          return captureMessage(format(message, __module__), /* Error */3);
        }));
  
}

function errorWithData2(__module__, message, param, param$1) {
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope((function (scope) {
          updateScope(scope, /* tuple */[
                label1,
                data1
              ]);
          updateScope(scope, /* tuple */[
                label2,
                data2
              ]);
          return captureMessage(format(message, __module__), /* Error */3);
        }));
  
}

function errorWithData3(__module__, message, param, param$1, param$2) {
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope((function (scope) {
          updateScope(scope, /* tuple */[
                label1,
                data1
              ]);
          updateScope(scope, /* tuple */[
                label2,
                data2
              ]);
          updateScope(scope, /* tuple */[
                label3,
                data3
              ]);
          return captureMessage(format(message, __module__), /* Error */3);
        }));
  
}

function errorWithData4(__module__, message, param, param$1, param$2, param$3) {
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope((function (scope) {
          updateScope(scope, /* tuple */[
                label1,
                data1
              ]);
          updateScope(scope, /* tuple */[
                label2,
                data2
              ]);
          updateScope(scope, /* tuple */[
                label3,
                data3
              ]);
          updateScope(scope, /* tuple */[
                label4,
                data4
              ]);
          return captureMessage(format(message, __module__), /* Error */3);
        }));
  
}

function errorWithData5(__module__, message, param, param$1, param$2, param$3, param$4) {
  var data5 = param$4[1];
  var label5 = param$4[0];
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope((function (scope) {
          updateScope(scope, /* tuple */[
                label1,
                data1
              ]);
          updateScope(scope, /* tuple */[
                label2,
                data2
              ]);
          updateScope(scope, /* tuple */[
                label3,
                data3
              ]);
          updateScope(scope, /* tuple */[
                label4,
                data4
              ]);
          updateScope(scope, /* tuple */[
                label5,
                data5
              ]);
          return captureMessage(format(message, __module__), /* Error */3);
        }));
  
}

function errorWithData6(__module__, message, param, param$1, param$2, param$3, param$4, param$5) {
  var data6 = param$5[1];
  var label6 = param$5[0];
  var data5 = param$4[1];
  var label5 = param$4[0];
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope((function (scope) {
          updateScope(scope, /* tuple */[
                label1,
                data1
              ]);
          updateScope(scope, /* tuple */[
                label2,
                data2
              ]);
          updateScope(scope, /* tuple */[
                label3,
                data3
              ]);
          updateScope(scope, /* tuple */[
                label4,
                data4
              ]);
          updateScope(scope, /* tuple */[
                label5,
                data5
              ]);
          updateScope(scope, /* tuple */[
                label6,
                data6
              ]);
          return captureMessage(format(message, __module__), /* Error */3);
        }));
  
}

function errorWithData7(__module__, message, param, param$1, param$2, param$3, param$4, param$5, param$6) {
  var data7 = param$6[1];
  var label7 = param$6[0];
  var data6 = param$5[1];
  var label6 = param$5[0];
  var data5 = param$4[1];
  var label5 = param$4[0];
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope((function (scope) {
          updateScope(scope, /* tuple */[
                label1,
                data1
              ]);
          updateScope(scope, /* tuple */[
                label2,
                data2
              ]);
          updateScope(scope, /* tuple */[
                label3,
                data3
              ]);
          updateScope(scope, /* tuple */[
                label4,
                data4
              ]);
          updateScope(scope, /* tuple */[
                label5,
                data5
              ]);
          updateScope(scope, /* tuple */[
                label6,
                data6
              ]);
          updateScope(scope, /* tuple */[
                label7,
                data7
              ]);
          return captureMessage(format(message, __module__), /* Error */3);
        }));
  
}

exports.Scope = Scope;
exports.Level = Level;
exports.captureMessage = captureMessage;
exports.init = init;
exports.fingerprintKey = fingerprintKey;
exports.setUser = setUser;
exports.format = format;
exports.updateScope = updateScope;
exports.warn = warn;
exports.warnWithData = warnWithData;
exports.warnWithData2 = warnWithData2;
exports.warnWithData3 = warnWithData3;
exports.warnWithData4 = warnWithData4;
exports.warnWithData5 = warnWithData5;
exports.warnWithData6 = warnWithData6;
exports.warnWithData7 = warnWithData7;
exports.error = error;
exports.errorWithData = errorWithData;
exports.errorWithData2 = errorWithData2;
exports.errorWithData3 = errorWithData3;
exports.errorWithData4 = errorWithData4;
exports.errorWithData5 = errorWithData5;
exports.errorWithData6 = errorWithData6;
exports.errorWithData7 = errorWithData7;
/* Env Not a pure module */
