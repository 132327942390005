// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var A = require("../../../../styleguide/components/Link/A.bs.js");
var Hr = require("../../../../styleguide/components/Hr/Hr.bs.js");
var Opt = require("../../../../libs/Opt.bs.js");
var $$Date = require("../../../../libs/Date.bs.js");
var Form = require("../../../../styleguide/forms/Form/Form.bs.js");
var Html = require("../../../../libs/Html.bs.js");
var Time = require("../../../../libs/Time.bs.js");
var Alert = require("../../../../styleguide/components/Alert/Alert.bs.js");
var Badge = require("../../../../styleguide/components/Badge/Badge.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Label = require("../../../../styleguide/forms/Label/Label.bs.js");
var Modal = require("../../../../styleguide/components/Modal/Modal.bs.js");
var Route = require("../../../../Route.bs.js");
var React = require("react");
var Booking = require("../../../../models/Booking.bs.js");
var Control = require("../../../../styleguide/components/Control/Control.bs.js");
var Tooltip = require("../../../../styleguide/components/Tooltip/Tooltip.bs.js");
var TextArea = require("../../../../styleguide/forms/TextArea/TextArea.bs.js");
var TextField = require("../../../../styleguide/forms/TextField/TextField.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var PhoneInput = require("../../../../styleguide/forms/PhoneInput/PhoneInput.bs.js");
var SlvBooking = require("../SlvBooking.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var SlvBookingQuote = require("./SlvBookingQuote.bs.js");
var SystemHouseRule = require("../../../../models/SystemHouseRule.bs.js");
var TooltipQuestionIcon = require("../../../../styleguide/icons/TooltipQuestionIcon.bs.js");
var SlvBookingSendInquiryButton = require("./SlvBookingSendInquiryButton.bs.js");
var SlvBookingModalFormModuleScss = require("./SlvBookingModalForm.module.scss");

var css = SlvBookingModalFormModuleScss;

function reducer(_state, action) {
  return {
          houseRules: /* Expanded */-746533543
        };
}

var dateTemplate = "MMM DD, YYYY";

function userFieldDomId(field) {
  return "listing--booking-confirmation-modal--register-user--" + (String(field) + "");
}

function SlvBookingInquiryModalForm(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var user = Props.user;
  var viewContext = Props.viewContext;
  var unclaimedListingModal = Props.unclaimedListingModal;
  var match = React.useReducer(reducer, {
        houseRules: /* Collapsed */-538653129
      });
  var dispatch = match[1];
  var state = match[0];
  var allHouseRules = React.useMemo((function () {
          return Belt_Array.concat(Belt_Array.map(Belt_Array.keep(SystemHouseRule.All.toArray(listing.systemHouseRules), (function (rule) {
                                var match = rule.status;
                                return match < 2;
                              })), (function (rule) {
                            return /* `System */[
                                    662113039,
                                    rule
                                  ];
                          })), Belt_Array.map(listing.customHouseRules, (function (rule) {
                            return /* `Custom */[
                                    -198771759,
                                    rule
                                  ];
                          })));
        }), ([]));
  var displayedHouseRules = React.useMemo((function () {
          var match = state.houseRules;
          if (match >= -538653129 && allHouseRules.length > 3) {
            return Belt_Array.slice(allHouseRules, 0, 3);
          } else {
            return allHouseRules;
          }
        }), [state.houseRules]);
  var match$1 = user.id;
  var tmp;
  if (match$1 !== undefined) {
    tmp = null;
  } else {
    var match$2 = booking.newUser.results.firstName;
    var tmp$1;
    tmp$1 = match$2 !== undefined && match$2.tag ? /* Failure */1 : undefined;
    var tmp$2 = {
      id: userFieldDomId("firstName"),
      size: /* MD */1,
      value: Opt.toString(booking.newUser.input.firstName),
      onChange: (function ($$event) {
          return Curry._2(booking.updateNewUserFirstName, $$event.target.value, /* InquiryModal */-481285946);
        })
    };
    if (tmp$1 !== undefined) {
      tmp$2.status = Caml_option.valFromOption(tmp$1);
    }
    var match$3 = booking.newUser.results.firstName;
    var tmp$3;
    tmp$3 = match$3 !== undefined && match$3.tag ? React.createElement("div", {
            className: css.failure
          }, match$3[0]) : null;
    var match$4 = booking.newUser.results.lastName;
    var tmp$4;
    tmp$4 = match$4 !== undefined && match$4.tag ? /* Failure */1 : undefined;
    var tmp$5 = {
      id: userFieldDomId("lastName"),
      size: /* MD */1,
      value: Opt.toString(booking.newUser.input.lastName),
      onChange: (function ($$event) {
          return Curry._2(booking.updateNewUserLastName, $$event.target.value, /* InquiryModal */-481285946);
        })
    };
    if (tmp$4 !== undefined) {
      tmp$5.status = Caml_option.valFromOption(tmp$4);
    }
    var match$5 = booking.newUser.results.lastName;
    var tmp$6;
    tmp$6 = match$5 !== undefined && match$5.tag ? React.createElement("div", {
            className: css.failure
          }, match$5[0]) : null;
    var match$6 = booking.newUser.results.email;
    var tmp$7;
    tmp$7 = match$6 !== undefined && match$6.tag ? /* Failure */1 : undefined;
    var tmp$8 = {
      id: userFieldDomId("email"),
      size: /* MD */1,
      value: Opt.toString(booking.newUser.input.email),
      onChange: (function ($$event) {
          return Curry._2(booking.updateNewUserEmail, $$event.target.value, /* InquiryModal */-481285946);
        })
    };
    if (tmp$7 !== undefined) {
      tmp$8.status = Caml_option.valFromOption(tmp$7);
    }
    var match$7 = booking.newUser.results.email;
    var tmp$9;
    tmp$9 = match$7 !== undefined && match$7.tag ? React.createElement("div", {
            className: css.failure
          }, match$7[0]) : null;
    tmp = React.createElement("div", undefined, React.createElement("div", {
              className: css.introduceNote
            }, React.createElement("span", {
                  className: css.bold
                }, "Please introduce yourself"), " so that the host can get in touch with you."), React.createElement("div", {
              className: css.flexGrid
            }, React.createElement("div", {
                  className: css.col
                }, React.createElement(Label.make, {
                      forId: userFieldDomId("firstName"),
                      placement: /* Top */0,
                      className: css.label,
                      children: "First Name"
                    }), React.createElement(TextField.make, tmp$2), tmp$3), React.createElement("div", {
                  className: css.col
                }, React.createElement(Label.make, {
                      forId: userFieldDomId("lastName"),
                      placement: /* Top */0,
                      className: css.label,
                      children: "Last Name"
                    }), React.createElement(TextField.make, tmp$5), tmp$6), React.createElement("div", {
                  className: css.col
                }, React.createElement(Label.make, {
                      forId: userFieldDomId("email"),
                      placement: /* Top */0,
                      className: css.label,
                      children: "Email"
                    }), React.createElement(TextField.make, tmp$8), tmp$9), React.createElement("div", {
                  className: css.col
                }, React.createElement(Label.make, {
                      forId: userFieldDomId("phone"),
                      placement: /* Top */0,
                      className: css.label,
                      children: "Phone"
                    }), React.createElement(PhoneInput.make, {
                      id: userFieldDomId("phone"),
                      size: /* MD */1,
                      className: css.phoneInput,
                      value: Opt.toString(booking.newUser.input.phone),
                      onChange: (function (value) {
                          return Curry._2(booking.updateNewUserPhone, value, /* InquiryModal */-481285946);
                        })
                    }))));
  }
  var match$8 = listing.checkInHour;
  var match$9 = listing.checkOutHour;
  var tmp$10;
  var exit = 0;
  if (displayedHouseRules.length !== 0 || match$8 !== undefined || match$9 !== undefined) {
    exit = 1;
  } else {
    tmp$10 = null;
  }
  if (exit === 1) {
    var match$10 = listing.kind;
    var tmp$11;
    var exit$1 = 0;
    if (match$10 && match$10[0] < 809617083) {
      tmp$11 = "By sending an inquiry, you have the best chance of booking this rental direct with the host at a better price than is available elsewhere. We have not previously had direct contact with the host of this rental, but we will do our best to make contact within the next few hours. We will keep you informed of our progress.";
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      var checkInHour = listing.checkInHour;
      var tmp$12;
      if (checkInHour !== undefined) {
        var checkInHour$1 = Time.formatHourAmPm(checkInHour);
        tmp$12 = React.createElement("li", {
              className: css.rule
            }, "Check in from: " + (String(checkInHour$1) + ""));
      } else {
        tmp$12 = null;
      }
      var checkOutHour = listing.checkOutHour;
      var tmp$13;
      if (checkOutHour !== undefined) {
        var checkOutHour$1 = Time.formatHourAmPm(checkOutHour);
        tmp$13 = React.createElement("li", {
              className: css.rule
            }, "Check out before: " + (String(checkOutHour$1) + ""));
      } else {
        tmp$13 = null;
      }
      var match$11 = state.houseRules;
      tmp$11 = React.createElement(React.Fragment, undefined, React.createElement("h3", {
                className: css.secondaryTitle
              }, "Review House Rules"), React.createElement("ul", {
                className: css.rules
              }, tmp$12, tmp$13, Belt_Array.map(displayedHouseRules, (function (rule) {
                      if (rule[0] >= 662113039) {
                        var rule$1 = rule[1];
                        return React.createElement("li", {
                                    key: String(rule$1.id),
                                    className: css.rule
                                  }, Belt_Option.getExn(SystemHouseRule.display(rule$1)));
                      }
                      var rule$2 = rule[1];
                      return React.createElement("li", {
                                  key: String(rule$2.id),
                                  className: css.rule
                                }, rule$2.name);
                    })), match$11 >= -538653129 && allHouseRules.length > 3 ? React.createElement("li", {
                      className: css.rule
                    }, React.createElement(Control.Underlined.make, {
                          color: /* Teal */0,
                          onClick: (function (param) {
                              return Curry._1(dispatch, /* ExpandHouseRules */0);
                            }),
                          children: "See all house rules"
                        })) : null));
    }
    tmp$10 = React.createElement(React.Fragment, undefined, React.createElement(Modal.Block.make, {
              children: tmp$11
            }), React.createElement(Hr.make, { }));
  }
  var match$12 = booking.modal.inquiryStatus;
  var tmp$14;
  tmp$14 = typeof match$12 === "number" || !(match$12.tag && match$12[0].tag) ? null : React.createElement(Modal.Block.make, {
          children: React.createElement(Alert.make, {
                level: /* Danger */3,
                children: "Oops, something went wrong"
              })
        });
  var match$13 = booking.modal.inquiryStatus;
  var match$14 = user.id;
  var quote = SlvBooking.Status.quote(booking.status);
  return React.createElement(Modal.Panels.make, {
              className: css.wrapper,
              children: null
            }, React.createElement(Modal.PanelsTitle.make, {
                  className: css.title,
                  children: "Send Inquiry or Ask a Question"
                }), React.createElement(Modal.MainPanel.make, {
                  children: React.createElement(Form.make, {
                        onSubmit: booking.submitInquiry,
                        children: null
                      }, tmp, React.createElement(Modal.Block.make, {
                            children: null
                          }, React.createElement("h3", {
                                className: css.secondaryTitle
                              }, "Say ", Html.quot, "Aloha", Html.quot, "to your host", React.createElement("span", {
                                    className: css.normalFont
                                  }, ", ask any questions and let them know a bit about yourself and why you are coming.")), React.createElement(TextArea.make, {
                                testId: "booking-confirmation-modal-message",
                                rows: 3,
                                size: /* MD */1,
                                value: Belt_Option.getWithDefault(booking.input.comment, ""),
                                placeholder: "Add a message for the host",
                                className: css.messageInput,
                                onChange: (function ($$event) {
                                    return Curry._2(booking.updateComment, $$event.target.value, /* InquiryModal */-481285946);
                                  })
                              })), tmp$10, tmp$14, React.createElement(Modal.Footer.make, {
                            className: css.footer,
                            children: null
                          }, React.createElement(SlvBookingSendInquiryButton.make, {
                                size: /* LG */2,
                                submit: true,
                                className: css.confirmButton,
                                busy: typeof match$13 === "number" ? true : false
                              }), match$14 !== undefined || viewContext >= 2 ? null : React.createElement("div", {
                                  className: css.footerNote
                                }, "By clicking on the button I confirm that I agree to the House Rules, ", React.createElement(A.make, {
                                      href: Route.termsOfService,
                                      target: /* Blank */[/* ReferrerAllowed */781962729],
                                      color: /* General */0,
                                      children: "Terms and Conditions"
                                    }), ", and ", React.createElement(A.make, {
                                      href: Route.privacyPolicy,
                                      target: /* Blank */[/* ReferrerAllowed */781962729],
                                      color: /* General */0,
                                      children: "Privacy Policy"
                                    }), ".")))
                }), React.createElement(Modal.SidePanel.make, {
                  children: null
                }, React.createElement(Modal.Block.make, {
                      className: css.details,
                      children: null
                    }, React.createElement("div", {
                          className: css.detailsRow
                        }, React.createElement("div", {
                              className: css.detailsCol
                            }, React.createElement("div", {
                                  className: css.detailsLabel
                                }, "Check In"), React.createElement("div", {
                                  className: css.detailsValue
                                }, Belt_Option.mapWithDefault(booking.input.checkIn, null, (function (date) {
                                        return $$Date.Naive.format(date, dateTemplate);
                                      })))), React.createElement("div", {
                              className: css.datesArrow
                            }, Html.rarr), React.createElement("div", {
                              className: css.detailsCol
                            }, React.createElement("div", {
                                  className: css.detailsLabel
                                }, "Check Out"), React.createElement("div", {
                                  className: css.detailsValue
                                }, Belt_Option.mapWithDefault(booking.input.checkOut, null, (function (date) {
                                        return $$Date.Naive.format(date, dateTemplate);
                                      }))))), booking.input.flexibleDates ? React.createElement("div", {
                            className: css.detailsRow
                          }, React.createElement("div", {
                                className: css.detailsValue
                              }, React.createElement(Badge.make, {
                                    children: null
                                  }, "Flexible dates", React.createElement(Tooltip.make, {
                                        content: "You’ve indicated that you are flexible when it comes to check-in and check-out. Hosts sometimes will offer a better price if you are able to change dates.",
                                        side: /* Bottom */1,
                                        children: React.createElement(TooltipQuestionIcon.make, {
                                              size: /* SM */1,
                                              color: /* White */0
                                            })
                                      })))) : null, React.createElement("div", {
                          className: css.detailsRow
                        }, React.createElement("div", {
                              className: css.detailsCol
                            }, React.createElement("div", {
                                  className: css.detailsLabel
                                }, "Guests"), React.createElement("div", {
                                  className: css.detailsValue
                                }, Booking.guestsLabel(booking.input.adults, booking.input.children, booking.input.infants, booking.input.pets)))), React.createElement(Control.Underlined.make, {
                          color: /* Teal */0,
                          onClick: (function (param) {
                              return Curry._1(booking.hideModal, undefined);
                            }),
                          children: "Edit trip"
                        })), quote !== undefined ? React.createElement(Modal.Block.make, {
                        className: css.quote,
                        children: React.createElement(SlvBookingQuote.make, {
                              listing: listing,
                              quote: quote,
                              context: /* InquiryModal */-481285946,
                              unclaimedListingModal: unclaimedListingModal
                            })
                      }) : null));
}

var initialRulesQuantity = 3;

var make = SlvBookingInquiryModalForm;

exports.css = css;
exports.reducer = reducer;
exports.initialRulesQuantity = initialRulesQuantity;
exports.dateTemplate = dateTemplate;
exports.userFieldDomId = userFieldDomId;
exports.make = make;
/* css Not a pure module */
