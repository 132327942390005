// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var A = require("../../../../styleguide/components/Link/A.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Modal = require("../../../../styleguide/components/Modal/Modal.bs.js");
var Route = require("../../../../Route.bs.js");
var React = require("react");
var Button = require("../../../../styleguide/components/Button/Button.bs.js");
var CheckMarkIcon = require("../../../../styleguide/icons/CheckMarkIcon.bs.js");
var SlvBookingMessageModalSuccessMessageModuleScss = require("./SlvBookingMessageModalSuccessMessage.module.scss");

var css = SlvBookingMessageModalSuccessMessageModuleScss;

function SlvBookingMessageModalSuccessMessage(Props) {
  var booking = Props.booking;
  var conversationId = Props.conversationId;
  return React.createElement("div", {
              className: css.modalBody
            }, React.createElement(Modal.Block.make, {
                  children: React.createElement("div", {
                        className: css.iconCircle
                      }, React.createElement(CheckMarkIcon.make, {
                            size: /* XL */4,
                            color: /* Teal */1,
                            title: "Ok",
                            className: css.iconCheckMark
                          }))
                }), React.createElement("div", {
                  className: css.message
                }, React.createElement("div", {
                      className: css.messageHostReply
                    }, "The host will reply you soon."), "You can ", React.createElement(A.make, {
                      href: Route.conversation(conversationId),
                      color: /* General */0,
                      children: "chat with the host"
                    }), " if you have any further questions."), React.createElement("div", undefined, React.createElement(Button.make, {
                      size: /* MD */1,
                      color: /* Primary */0,
                      className: css.buttonOk,
                      onClick: (function (param) {
                          return Curry._1(booking.hideModal, undefined);
                        }),
                      children: "OK, I Got It"
                    })));
}

var make = SlvBookingMessageModalSuccessMessage;

exports.css = css;
exports.make = make;
/* css Not a pure module */
