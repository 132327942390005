// @flow
import _ from 'lodash/fp';

// FIXME: https://trello.com/c/bVeS3t8M
const showIntercomButton = () => {
  // Intercom button is hidden only at SLV
  if (!document.querySelector('body.contextual-listings.contextual-listings-show')) return;

  const intercomButtonFrame = document.getElementsByClassName('intercom-launcher-frame-mobile')[0];

  if (intercomButtonFrame) {
    intercomButtonFrame.style.cssText = 'display:block !important';
  }
};
// FIXME: end here

// see https://docs.intercom.io/
export default function intercomMessenger(...args: Array<string | {}>) {
  const intercom = _.get('Intercom', window);

  if (intercom) {
    // FIXME: https://trello.com/c/bVeS3t8M
    showIntercomButton();
    intercom(...args);
  } else {
    // eslint-disable-next-line no-console
    console.log(
      '[Intercom]: Intercom is disabled for this environment. If enabled, Intercom would ' +
        'have been triggered with the following:\n',
      ...args,
    );
  }
}

export const showIntercom = () => intercomMessenger('show');

export const updateIntercom = () => intercomMessenger('update');

// Intercom locks down the messenger for 30 minutes if we try to update more than 10 times on same
// page load.
const MAX_UPDATES_PER_PAGE = 10;

// Useful for retrieving Intercom messages that are in response to something you just did, as
// the message won't be there until our back end job hits Intercom, so we keep re-updating
export function repeatUpdateIntercomMessenger(times: number, interval: number) {
  if (times > MAX_UPDATES_PER_PAGE) {
    throw new Error(`More than ${MAX_UPDATES_PER_PAGE} updates on a page will lock the messenger`);
  }

  updateIntercom(); // head call

  const delayedUpdateIntercom = (index: number) => {
    const wait = interval * (index + 1);

    // eslint-disable-next-line lodash-fp/no-unused-result
    _.delay(wait, updateIntercom);
  };

  // eslint-disable-next-line lodash-fp/no-unused-result
  _.times(delayedUpdateIntercom, times - 1); // subtract 1 to account for head call
}
