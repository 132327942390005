// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Rest = require("../transport/Rest.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function toString(param) {
  return "show_phone";
}

function toJson(item) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "item",
                "show_phone"
              ],
              /* [] */0
            ]);
}

var Req = {
  toString: toString,
  toJson: toJson
};

function exec(listingId, action) {
  return Rest.$$fetch(/* `V1 */[
              19227,
              "/listings/" + (String(listingId) + "/listing-item-clicks")
            ], /* `Post */[
              892711040,
              toJson(action)
            ], /* Empty */50834029, undefined, undefined);
}

var Hc = {
  exec: exec
};

function exec$1(listingId, action) {
  return Rest.$$fetch(/* `Integrations */[
              -623562945,
              "/microsites/listings/" + (String(listingId) + "/listing-item-clicks")
            ], /* `Post */[
              892711040,
              toJson(action)
            ], /* Empty */50834029, undefined, undefined);
}

var Microsites = {
  exec: exec$1
};

exports.Req = Req;
exports.Hc = Hc;
exports.Microsites = Microsites;
/* Rest Not a pure module */
