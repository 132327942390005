// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Modal = require("../../../../styleguide/components/Modal/Modal.bs.js");
var React = require("react");
var Button = require("../../../../styleguide/components/Button/Button.bs.js");
var SlvBookingOwnListingWarningModalModuleScss = require("./SlvBookingOwnListingWarningModal.module.scss");

var css = SlvBookingOwnListingWarningModalModuleScss;

function SlvBookingOwnListingWarningModal(Props) {
  var booking = Props.booking;
  var match = booking.modal;
  var match$1 = match.current;
  return React.createElement(Modal.make, {
              shown: match$1 !== undefined ? match$1 >= 2 : false,
              hide: booking.hideModal,
              className: css.modal,
              children: null
            }, React.createElement(Modal.Block.make, {
                  children: "You can not submit inquiry nor message because you are the owner of this listing."
                }), React.createElement(Button.make, {
                  size: /* MD */1,
                  color: /* Teal */2,
                  onClick: (function (param) {
                      return Curry._1(booking.hideModal, undefined);
                    }),
                  children: "Ok"
                }));
}

var make = SlvBookingOwnListingWarningModal;

exports.css = css;
exports.make = make;
/* css Not a pure module */
