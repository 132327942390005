// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromJs(js) {
  return {
          id: js.id,
          firstName: js.firstName,
          lastName: js.lastName
        };
}

function fromJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          firstName: Json_decode.field("firstName", Json_decode.string, json),
          lastName: Json_decode.field("lastName", Json_decode.string, json)
        };
}

exports.fromJs = fromJs;
exports.fromJson = fromJson;
/* No side effect */
