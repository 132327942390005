// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var WithTestId = require("../WithTestId/WithTestId.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ControlModuleScss = require("./Control.module.scss");

var css = ControlModuleScss;

function Control$Base(Props) {
  var id = Props.id;
  var testId = Props.testId;
  var inlineOpt = Props.inline;
  var classNameOpt = Props.className;
  var onClick = Props.onClick;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var onKeyUp = Props.onKeyUp;
  var onKeyDown = Props.onKeyDown;
  var onMouseEnter = Props.onMouseEnter;
  var onMouseLeave = Props.onMouseLeave;
  var children = Props.children;
  var inline = inlineOpt !== undefined ? inlineOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var buttonRef = React.useRef(null);
  var tmp = {
    ref: buttonRef,
    className: Cn.$plus(Cn.$plus(css.control, Cn.on(css.inline, inline)), className),
    type: "button",
    onClick: (function ($$event) {
        Belt_Option.map(onClick, (function (fn) {
                return Curry._1(fn, $$event);
              }));
        Belt_Option.map(Caml_option.nullable_to_opt(buttonRef.current), (function (el) {
                return el.blur();
              }));
        
      })
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (onKeyDown !== undefined) {
    tmp.onKeyDown = Caml_option.valFromOption(onKeyDown);
  }
  if (onKeyUp !== undefined) {
    tmp.onKeyUp = Caml_option.valFromOption(onKeyUp);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (onMouseEnter !== undefined) {
    tmp.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
  }
  if (onMouseLeave !== undefined) {
    tmp.onMouseLeave = Caml_option.valFromOption(onMouseLeave);
  }
  return React.createElement(WithTestId.Optional.make, {
              id: testId,
              children: React.createElement("button", tmp, children)
            });
}

var Base = {
  make: Control$Base
};

function Control$Underlined(Props) {
  Props.color;
  var inline = Props.inline;
  var classNameOpt = Props.className;
  var onClick = Props.onClick;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    className: Cn.$plus(Cn.$plus(css.underlined, css.teal), className),
    children: children
  };
  if (inline !== undefined) {
    tmp.inline = Caml_option.valFromOption(inline);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement(Control$Base, tmp);
}

var Underlined = {
  make: Control$Underlined
};

var make = Control$Base;

exports.css = css;
exports.Base = Base;
exports.make = make;
exports.Underlined = Underlined;
/* css Not a pure module */
