// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Env = require("../libs/Env.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function token(param) {
  if (Env.env === 1) {
    return "";
  }
  var __x = Array.prototype.slice.call(document.querySelectorAll("meta"));
  return Belt_Option.flatMap(Caml_option.undefined_to_opt(__x.find((function (node) {
                        if (!node.hasAttribute("name")) {
                          return false;
                        }
                        var match = node.getAttribute("name");
                        if ((match == null) || (
                            (match == null) ? undefined : Caml_option.some(match)
                          ) !== "csrf-token") {
                          return false;
                        } else {
                          return true;
                        }
                      }))), (function (node) {
                return Caml_option.nullable_to_opt(node.getAttribute("content"));
              }));
}

exports.token = token;
/* Env Not a pure module */
