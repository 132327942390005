// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Slv = require("./Slv.bs.js");
var React = require("react");
var Listing = require("../../../models/Listing.bs.js");
var SlvMeta = require("./SlvMeta.bs.js");
var SlvUser = require("./SlvUser.bs.js");
var RailsContext = require("../../../models/RailsContext.bs.js");
var SlvViewContext = require("./SlvViewContext.bs.js");

function Slv__JsBridge$default(Props) {
  var listing = Props.listing;
  var user = Props.user;
  var railsContext = Props.railsContext;
  var viewContext = Props.viewContext;
  var authenticateNewUser = Props.authenticateNewUser;
  var authenticateWithCallback = Props.authenticateWithCallback;
  var viewContext$1 = React.useMemo((function () {
          return SlvViewContext.fromJs(viewContext);
        }), ([]));
  var railsContext$1 = React.useMemo((function () {
          return RailsContext.fromJson(railsContext);
        }), ([]));
  var listing$1 = React.useMemo((function () {
          return Listing.Contextual.fromJson(listing);
        }), ([]));
  var user$1 = React.useMemo((function () {
          return SlvUser.fromJson(user, authenticateNewUser, authenticateWithCallback);
        }), /* tuple */[
        user,
        authenticateNewUser
      ]);
  var meta = React.useMemo((function () {
          return SlvMeta.make(railsContext$1.scheme, railsContext$1.host, railsContext$1.port, railsContext$1.referer);
        }), ([]));
  return React.createElement(Slv.make, {
              listing: listing$1,
              user: user$1,
              meta: meta,
              viewContext: viewContext$1
            });
}

var $$default = Slv__JsBridge$default;

exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* Slv Not a pure module */
