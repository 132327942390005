// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function focus(theRef) {
  return Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(theRef.current), undefined, (function (x) {
                x.focus();
                
              }));
}

function blur(theRef) {
  return Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(theRef.current), undefined, (function (x) {
                x.blur();
                
              }));
}

exports.focus = focus;
exports.blur = blur;
/* No side effect */
