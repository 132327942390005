// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Html = require("../../../../libs/Html.bs.js");
var I18n = require("../../../../libs/I18n.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Money = require("../../../../libs/Money.bs.js");
var React = require("react");
var Control = require("../../../../styleguide/components/Control/Control.bs.js");
var Tooltip = require("../../../../styleguide/components/Tooltip/Tooltip.bs.js");
var WithTestId = require("../../../../styleguide/components/WithTestId/WithTestId.bs.js");
var ExpanderIcon = require("../../../../styleguide/icons/ExpanderIcon.bs.js");
var EstimatedPriceBadge = require("../../../../styleguide/components/EstimatedPriceBadge/EstimatedPriceBadge.bs.js");
var TooltipQuestionIcon = require("../../../../styleguide/icons/TooltipQuestionIcon.bs.js");
var SlvBookingQuoteModuleScss = require("./SlvBookingQuote.module.scss");

var css = SlvBookingQuoteModuleScss;

function reducer(state, action) {
  if (state) {
    return /* Expanded */0;
  } else {
    return /* Collapsed */1;
  }
}

function SlvBookingQuote(Props) {
  var listing = Props.listing;
  var quote = Props.quote;
  var context = Props.context;
  var unclaimedListingModal = Props.unclaimedListingModal;
  var initialState = React.useMemo((function () {
          if (context >= 294421725) {
            return /* Collapsed */1;
          } else {
            return /* Expanded */0;
          }
        }), ([]));
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var nights = I18n.inflect(quote.numberOfNights, "night", "nights");
  var match$1 = listing.kind;
  var tmp;
  if (state) {
    tmp = null;
  } else {
    var nights$1 = I18n.inflect(quote.numberOfNights, "night", "nights");
    var price = Money.formatWithoutCents(quote.avgPricePerNight);
    var match$2 = quote.turnoverFeesAndTaxes;
    var match$3 = quote.savings;
    var tmp$1;
    if (match$3 !== 0.0) {
      var price$1 = Money.formatWithCents(quote.savings);
      tmp$1 = React.createElement("div", {
            className: css.quoteRow
          }, React.createElement("div", {
                className: css.quoteLabel
              }, "Discount:"), React.createElement(WithTestId.make, {
                id: "booking-quote-savings-value",
                children: React.createElement("div", {
                      className: css.quoteMoney
                    }, React.createElement("span", {
                          className: css.green
                        }, "–" + (String(price$1) + "")))
              }));
    } else {
      tmp$1 = null;
    }
    var match$4 = quote.deposit;
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: css.quoteRow
            }, React.createElement("div", {
                  className: css.quoteLabel
                }, React.createElement(React.Fragment, undefined, "" + (String(price) + " "), Html.times, " " + (String(nights$1) + ""))), React.createElement(WithTestId.make, {
                  id: "booking-quote-pernight-times-nights-value",
                  children: React.createElement("div", {
                        className: css.quoteMoney
                      }, Money.formatWithCents(quote.avgPricePerNight * quote.numberOfNights))
                })), match$2 !== 0.0 ? React.createElement("div", {
                className: css.quoteRow
              }, React.createElement("div", {
                    className: css.quoteLabel
                  }, "Turnover fees and taxes:"), React.createElement(WithTestId.make, {
                    id: "booking-quote-fees-taxes-value",
                    children: React.createElement("div", {
                          className: css.quoteMoney
                        }, Money.formatWithCents(quote.turnoverFeesAndTaxes))
                  })) : null, tmp$1, match$4 !== 0.0 ? React.createElement("div", {
                className: css.quoteRow
              }, React.createElement("div", {
                    className: css.quoteLabel
                  }, "Refundable damage deposit (not included in total):", React.createElement(Tooltip.make, {
                        id: "tooltip-booking-widget-deposit",
                        content: "Serves to cover any damage to the property during your stay. The amount will be refunded\n                  upon check-out",
                        side: /* Top */0,
                        className: css.iconTooltip,
                        children: React.createElement(TooltipQuestionIcon.make, {
                              size: /* MD */2,
                              color: /* Gray */8
                            })
                      })), React.createElement(WithTestId.make, {
                    id: "booking-quote-deposit-value",
                    children: React.createElement("div", {
                          className: css.quoteMoney
                        }, Money.formatWithCents(quote.deposit))
                  })) : null);
  }
  return React.createElement("div", {
              className: css.quote
            }, React.createElement("div", {
                  className: Cn.$plus(css.quoteRow, css.quoteMainRow)
                }, React.createElement("div", {
                      className: css.quoteMainRowText
                    }, React.createElement("div", {
                          className: css.quoteLabel
                        }, "Total for " + (String(nights) + ":")), React.createElement("div", {
                          className: css.quoteMoney
                        }, React.createElement(Control.make, {
                              className: css.buttonPrice,
                              onClick: (function (param) {
                                  return Curry._1(dispatch, /* Toggle */0);
                                }),
                              children: null
                            }, React.createElement(WithTestId.make, {
                                  id: "booking-quote-total-value",
                                  children: React.createElement("strong", undefined, Money.formatWithoutCents(quote.total))
                                }), React.createElement("div", {
                                  className: Cn.$plus(css.iconExpander, state ? Cn.none : css.flip)
                                }, React.createElement(ExpanderIcon.make, {
                                      size: /* XS */0,
                                      color: /* Gray */8,
                                      title: "Expand"
                                    }))))), match$1 && match$1[0] < 809617083 ? React.createElement(EstimatedPriceBadge.make, {
                        unclaimedListingModal: unclaimedListingModal,
                        withLearnMoreLink: context >= 294421725,
                        withTextTemplateFor: /* Slv */1
                      }) : null), tmp);
}

var make = SlvBookingQuote;

exports.css = css;
exports.reducer = reducer;
exports.make = make;
/* css Not a pure module */
