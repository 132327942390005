// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var React = require("react");
var HeadingModuleScss = require("./Heading.module.scss");

var css = HeadingModuleScss;

function H2(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("h2", {
              className: Cn.$plus(css.h1, className)
            }, children);
}

var make = H2;

exports.css = css;
exports.make = make;
/* css Not a pure module */
