// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var SlvBlock = require("../../components/SlvBlock/SlvBlock.bs.js");
var ReactIntl = require("react-intl");
var SlvHtmlText = require("../../components/SlvHtmlText/SlvHtmlText.bs.js");

function SlvDescription$Block(Props) {
  var description = Props.description;
  return React.createElement(SlvBlock.make, {
              id: /* Description */-1018191652,
              children: null
            }, React.createElement(SlvBlock.Title.make, {
                  children: React.createElement(ReactIntl.FormattedMessage, {
                        id: "slv.description.title",
                        defaultMessage: "Description"
                      })
                }), React.createElement(SlvHtmlText.make, {
                  htmlText: description
                }));
}

var Block = {
  make: SlvDescription$Block
};

function SlvDescription(Props) {
  var listing = Props.listing;
  var description = listing.description;
  if (description !== undefined) {
    return React.createElement(SlvDescription$Block, {
                description: description
              });
  } else {
    return null;
  }
}

var make = SlvDescription;

exports.Block = Block;
exports.make = make;
/* react Not a pure module */
