// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var PhoneJsx = require("app/libs/components/Phone/Phone.jsx");

var JsComponent = { };

function PhoneNumber(Props) {
  var value = Props.value;
  return React.createElement(PhoneJsx.default, {
              phoneNumber: value
            });
}

var make = PhoneNumber;

exports.JsComponent = JsComponent;
exports.make = make;
/* react Not a pure module */
