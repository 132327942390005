// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Hooks = require("../../../libs/Hooks.bs.js");
var React = require("react");
var Button = require("../Button/Button.bs.js");
var Events = require("../../../libs/Events.bs.js");
var Control = require("../Control/Control.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReactContext = require("../../../bindings/ReactContext.bs.js");
var Webapi__Dom__Element = require("bs-webapi/src/Webapi/Webapi__Dom/Webapi__Dom__Element.js");
var PopoverModuleScss = require("./Popover.module.scss");

var css = PopoverModuleScss;

function defaultCtx_show(prim) {
  
}

function defaultCtx_hide(prim) {
  
}

function defaultCtx_toggle(prim) {
  
}

var defaultCtx = {
  shown: false,
  show: defaultCtx_show,
  hide: defaultCtx_hide,
  toggle: defaultCtx_toggle
};

var Context = ReactContext.Make({
      defaultValue: defaultCtx
    });

function reducer(state, action) {
  switch (action) {
    case /* Show */0 :
        return {
                shown: true
              };
    case /* Hide */1 :
        return {
                shown: false
              };
    case /* Toggle */2 :
        return {
                shown: !state.shown
              };
    
  }
}

function Popover$Container(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useReducer(reducer, {
        shown: false
      });
  var dispatch = match[1];
  return React.createElement(Context.Provider.make, {
              value: {
                shown: match[0].shown,
                show: (function (param) {
                    return Curry._1(dispatch, /* Show */0);
                  }),
                hide: (function (param) {
                    return Curry._1(dispatch, /* Hide */1);
                  }),
                toggle: (function (param) {
                    return Curry._1(dispatch, /* Toggle */2);
                  })
              },
              children: React.createElement("div", {
                    className: Cn.$plus(css.container, className)
                  }, children)
            });
}

var Container = {
  reducer: reducer,
  make: Popover$Container
};

function Popover$ButtonTrigger(Props) {
  var size = Props.size;
  var color = Props.color;
  var className = Props.className;
  var children = Props.children;
  var ctx = React.useContext(Context.x);
  var tmp = {
    size: size,
    color: color,
    onClick: (function (param) {
        return Curry._1(ctx.toggle, undefined);
      }),
    onKeyDown: (function ($$event) {
        Events.onReactEnterKey($$event, ctx.toggle);
        return Events.onReactSpaceKey($$event, ctx.toggle);
      }),
    children: children
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Button.make, tmp);
}

var ButtonTrigger = {
  make: Popover$ButtonTrigger
};

function Popover$ControlTrigger(Props) {
  var className = Props.className;
  var children = Props.children;
  var ctx = React.useContext(Context.x);
  var tmp = {
    onClick: (function (param) {
        return Curry._1(ctx.toggle, undefined);
      }),
    onKeyDown: (function ($$event) {
        Events.onReactEnterKey($$event, ctx.toggle);
        return Events.onReactSpaceKey($$event, ctx.toggle);
      }),
    children: children
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Control.make, tmp);
}

var ControlTrigger = {
  make: Popover$ControlTrigger
};

function Popover$Body(Props) {
  var placement = Props.placement;
  var withTriangle = Props.withTriangle;
  var classNameOpt = Props.className;
  var onShow = Props.onShow;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var ctx = React.useContext(Context.x);
  var container = React.useRef(null);
  var prevShown = Hooks.usePrevious(ctx.shown);
  React.useEffect((function () {
          var match = ctx.shown;
          if (prevShown !== undefined && !(prevShown || !(match && onShow !== undefined))) {
            Curry._1(onShow, undefined);
          }
          
        }), /* tuple */[
        ctx.shown,
        prevShown
      ]);
  React.useEffect((function () {
          if (ctx.shown) {
            return Events.subscribeToKeyDown((function ($$event) {
                          return Events.onDomEscKey($$event, ctx.hide);
                        }));
          }
          
        }), [ctx.shown]);
  React.useEffect((function () {
          if (!ctx.shown) {
            return ;
          }
          var container$1 = Belt_Option.flatMap(Caml_option.nullable_to_opt(container.current), Webapi__Dom__Element.asHtmlElement);
          if (container$1 === undefined) {
            return ;
          }
          var container$2 = Caml_option.valFromOption(container$1);
          return Events.subscribeToMouseDown((function ($$event) {
                        var target = $$event.target;
                        if (!container$2.contains(target)) {
                          return Curry._1(ctx.hide, undefined);
                        }
                        
                      }));
        }), [ctx.shown]);
  if (!ctx.shown) {
    return null;
  }
  var tmp;
  switch (placement) {
    case /* Left */0 :
        tmp = Cn.$plus(css.placement_left, Cn.on(css.placementWithTriangle_left, withTriangle));
        break;
    case /* Center */1 :
        tmp = Cn.$plus(css.placement_center, Cn.on(css.placementWithTriangle_center, withTriangle));
        break;
    case /* Right */2 :
        tmp = Cn.$plus(css.placement_right, Cn.on(css.placementWithTriangle_right, withTriangle));
        break;
    
  }
  return React.createElement("div", {
              ref: container,
              className: Cn.$plus(Cn.$plus(Cn.$plus(css.body, Cn.on(css.triangle, withTriangle)), tmp), className)
            }, children);
}

var Body = {
  make: Popover$Body
};

var make = Popover$Container;

exports.css = css;
exports.defaultCtx = defaultCtx;
exports.Context = Context;
exports.Container = Container;
exports.ButtonTrigger = ButtonTrigger;
exports.ControlTrigger = ControlTrigger;
exports.Body = Body;
exports.reducer = reducer;
exports.make = make;
/* css Not a pure module */
