// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var SlvBlock = require("../../components/SlvBlock/SlvBlock.bs.js");
var GoogleMap = require("../../../../styleguide/components/GoogleMap/GoogleMap.bs.js");
var ReactIntl = require("react-intl");
var OpenStreetMap = require("../../../../styleguide/components/OpenStreetMap/OpenStreetMap.bs.js");
var SlvLocationModuleScss = require("./SlvLocation.module.scss");

var css = SlvLocationModuleScss;

function SlvLocation$GoogleMaps(Props) {
  var listing = Props.listing;
  var $$location = React.useMemo((function () {
          if (listing.showApproximateLocation) {
            return /* Approximate */0;
          } else {
            return /* Exact */1;
          }
        }), [listing.showApproximateLocation]);
  var center = React.useMemo((function () {
          return {
                  lat: listing.lat,
                  lng: listing.lng
                };
        }), /* tuple */[
        listing.lat,
        listing.lng
      ]);
  return React.createElement(SlvBlock.make, {
              id: /* Location */1056451157,
              children: null
            }, React.createElement(SlvBlock.Title.make, {
                  children: React.createElement(ReactIntl.FormattedMessage, {
                        id: "slv.location.title",
                        defaultMessage: "Location"
                      })
                }), React.createElement(GoogleMap.make, {
                  center: center,
                  zoom: 14,
                  className: css.map,
                  children: React.createElement(GoogleMap.Marker.make, {
                        shape: $$location ? /* Point */Block.__(0, [{
                                position: center,
                                draggable: false
                              }]) : /* Circle */Block.__(1, [{
                                center: center,
                                radius: 1000
                              }])
                      })
                }));
}

var GoogleMaps = {
  make: SlvLocation$GoogleMaps
};

function SlvLocation$OpenStreetMaps(Props) {
  var listing = Props.listing;
  return React.createElement(SlvBlock.make, {
              id: /* Location */1056451157,
              children: null
            }, React.createElement(SlvBlock.Title.make, {
                  children: React.createElement(ReactIntl.FormattedMessage, {
                        id: "slv.location.title",
                        defaultMessage: "Location"
                      })
                }), React.createElement(OpenStreetMap.make, {
                  lat: listing.lat,
                  lng: listing.lng,
                  zoom: 14,
                  className: css.map
                }));
}

var OpenStreetMaps = {
  make: SlvLocation$OpenStreetMaps
};

function SlvLocation(Props) {
  var listing = Props.listing;
  var viewContext = Props.viewContext;
  if (viewContext >= 2) {
    return React.createElement(SlvLocation$OpenStreetMaps, {
                listing: listing
              });
  } else {
    return React.createElement(SlvLocation$GoogleMaps, {
                listing: listing
              });
  }
}

var zoomLevel = 14;

var make = SlvLocation;

exports.css = css;
exports.zoomLevel = zoomLevel;
exports.GoogleMaps = GoogleMaps;
exports.OpenStreetMaps = OpenStreetMaps;
exports.make = make;
/* css Not a pure module */
