// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function pxOfInt(x) {
  return String(x) + "px";
}

function pxOfFloat(x) {
  return String(x) + "px";
}

exports.pxOfInt = pxOfInt;
exports.pxOfFloat = pxOfFloat;
/* No side effect */
