// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Leaflet = require("leaflet");
var MarkerIconPng = require("./images/marker-icon.png");
var LeafletCss = require("leaflet/dist/leaflet.css");
var MarkerIconXPng = require("./images/marker-icon-2x.png");

var markerIcon = MarkerIconPng;

var markerIcon2x = MarkerIconXPng;

function make(container, lat, lng, zoom) {
  var map = Leaflet.map(container, {
        center: /* tuple */[
          lat,
          lng
        ],
        zoom: zoom,
        scrollWheelZoom: false
      });
  Leaflet.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: "&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors"
        }).addTo(map);
  return map;
}

function addMarker(map, lat, lng) {
  return Leaflet.marker(/* tuple */[
                lat,
                lng
              ], {
                icon: Leaflet.icon({
                      iconUrl: markerIcon,
                      iconRetinaUrl: markerIcon2x,
                      iconSize: /* tuple */[
                        25,
                        41
                      ],
                      iconAnchor: /* tuple */[
                        12,
                        41
                      ]
                    })
              }).addTo(map);
}

var $$Map = {
  make: make,
  addMarker: addMarker
};

function OpenStreetMap(Props) {
  var lat = Props.lat;
  var lng = Props.lng;
  var zoom = Props.zoom;
  var className = Props.className;
  var mapContainer = React.useRef(null);
  React.useEffect((function () {
          var container = mapContainer.current;
          if (!(container == null)) {
            var map = make(container, lat, lng, zoom);
            addMarker(map, lat, lng);
          }
          
        }), ([]));
  return React.createElement("div", {
              ref: mapContainer,
              className: className
            });
}

var make$1 = OpenStreetMap;

exports.markerIcon = markerIcon;
exports.markerIcon2x = markerIcon2x;
exports.$$Map = $$Map;
exports.make = make$1;
/* markerIcon Not a pure module */
