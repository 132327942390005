// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Input = require("../Input/Input.bs.js");

var defaultValue = Input.Select.defaultValue;

var make = Input.Select.make;

var $$Option = Input.Select.$$Option;

var DefaultOption = Input.Select.DefaultOption;

exports.defaultValue = defaultValue;
exports.make = make;
exports.$$Option = $$Option;
exports.DefaultOption = DefaultOption;
/* Input Not a pure module */
