// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Api = require("../../../../api/Api.bs.js");
var React = require("react");
var $$Promise = require("../../../../bindings/Promise.bs.js");

function SlvClickableItem(Props) {
  var viewContext = Props.viewContext;
  var action = Props.action;
  var listingId = Props.listingId;
  var children = Props.children;
  return React.createElement("div", {
              role: "presentation",
              onClick: (function (param) {
                  if (viewContext >= 2) {
                    return $$Promise.wait(Api.postListingItemClickFromMicrosite(listingId, action), (function (prim) {
                                  
                                }));
                  } else {
                    return $$Promise.wait(Api.postListingItemClickFromHc(listingId, action), (function (prim) {
                                  
                                }));
                  }
                })
            }, children);
}

var make = SlvClickableItem;

exports.make = make;
/* Api Not a pure module */
