// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Rest = require("../transport/Rest.bs.js");
var Booking = require("../../models/Booking.bs.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromJson(json) {
  return Json_decode.field("quote", Booking.Quote.fromJson, json);
}

var Res = {
  fromJson: fromJson
};

function exec(quoteId) {
  return Rest.$$fetch(/* `V1 */[
              19227,
              "/trips/quote-results/" + String(quoteId)
            ], /* Get */3553398, /* `Json */[
              826371656,
              fromJson
            ], [
              "trips",
              "quote-results"
            ], undefined);
}

exports.Res = Res;
exports.exec = exec;
/* Rest Not a pure module */
