// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Events = require("../../../../libs/Events.bs.js");
var Layout = require("../../../../styleguide/styles/Layout.bs.js");
var SlvBlock = require("../../components/SlvBlock/SlvBlock.bs.js");
var WithTestId = require("../../../../styleguide/components/WithTestId/WithTestId.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var SlvBookingWidgetForm = require("./SlvBookingWidgetForm.bs.js");
var SlvMessageOrCallHost = require("./SlvMessageOrCallHost.bs.js");
var SlvBookingWidgetHeadline = require("./SlvBookingWidgetHeadline.bs.js");
var SlvBookingWidgetModuleScss = require("./SlvBookingWidget.module.scss");

var css = SlvBookingWidgetModuleScss;

function SlvBookingWidget$SmallScreen(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var viewContext = Props.viewContext;
  var unclaimedListingModal = Props.unclaimedListingModal;
  var match = listing.legalRestriction;
  return React.createElement(SlvBlock.NoIdent.make, {
              children: null
            }, React.createElement(SlvBlock.Title.make, {
                  children: "Send Inquiry"
                }), match !== undefined ? React.createElement(SlvMessageOrCallHost.make, {
                    listing: listing,
                    booking: booking,
                    viewContext: viewContext
                  }) : React.createElement(SlvBookingWidgetForm.make, {
                    listing: listing,
                    booking: booking,
                    unclaimedListingModal: unclaimedListingModal
                  }));
}

var SmallScreen = {
  make: SlvBookingWidget$SmallScreen
};

function reducer(_state, action) {
  return action[0];
}

function scrollableState(widgetHeight) {
  var windowHight = window.innerHeight;
  var widgetSpace = windowHight - 2 - Layout.navbarHeight;
  if (widgetSpace < widgetHeight) {
    return /* `Scrollable */[
            167760967,
            {
              widgetHeight: widgetSpace
            }
          ];
  } else {
    return /* NonScrollable */741912436;
  }
}

function SlvBookingWidget$LargeScreen(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var viewContext = Props.viewContext;
  var className = Props.className;
  var unclaimedListingModal = Props.unclaimedListingModal;
  var container = React.useRef(null);
  var widget = React.useRef(null);
  var match = React.useReducer(reducer, /* Top */0);
  var dispatch = match[1];
  var position = match[0];
  React.useEffect((function () {
          return Events.subscribeToScroll((function (param) {
                        var match = container.current;
                        var match$1 = widget.current;
                        if (match == null) {
                          return ;
                        }
                        if (match$1 == null) {
                          return ;
                        }
                        var containerRect = match.getBoundingClientRect();
                        var widgetRect = match$1.getBoundingClientRect();
                        if (widgetRect.height >= containerRect.height) {
                          return ;
                        }
                        var scrolled = window.pageYOffset;
                        var topBreakpoint = containerRect.top + scrolled + 2 - Layout.navbarHeight;
                        var bottomBreakpoint = containerRect.bottom + scrolled;
                        var widgetTopPosition = widgetRect.top + scrolled + 2 - Layout.navbarHeight;
                        var widgetBottomPosition = widgetRect.bottom + scrolled;
                        var exit = 0;
                        var exit$1 = 0;
                        if (typeof position === "number" && position !== 0) {
                          if (scrolled <= widgetTopPosition) {
                            return Curry._1(dispatch, /* UpdatePosition */[/* Sticky */[scrollableState(widgetRect.height)]]);
                          }
                          exit = 2;
                        } else {
                          exit$1 = 3;
                        }
                        if (exit$1 === 3) {
                          if (widgetBottomPosition >= bottomBreakpoint) {
                            return Curry._1(dispatch, /* UpdatePosition */[/* Bottom */1]);
                          }
                          exit = 2;
                        }
                        if (exit === 2) {
                          var exit$2 = 0;
                          if (typeof position === "number" && position === 0) {
                            if (scrolled >= topBreakpoint) {
                              return Curry._1(dispatch, /* UpdatePosition */[/* Sticky */[scrollableState(widgetRect.height)]]);
                            }
                            
                          } else {
                            exit$2 = 3;
                          }
                          if (exit$2 === 3 && scrolled < topBreakpoint) {
                            return Curry._1(dispatch, /* UpdatePosition */[/* Top */0]);
                          }
                          
                        }
                        if (typeof position === "number") {
                          return ;
                        }
                        var match$2 = scrollableState(widgetRect.height);
                        if (typeof position[0] === "number" && typeof match$2 !== "number") {
                          return Curry._1(dispatch, /* UpdatePosition */[/* Sticky */[/* `Scrollable */[
                                          167760967,
                                          match$2[1]
                                        ]]]);
                        }
                        
                      }));
        }), [position]);
  var tmp;
  if (typeof position === "number") {
    tmp = undefined;
  } else {
    var match$1 = position[0];
    tmp = typeof match$1 === "number" ? undefined : ({
          height: String(match$1[1].widgetHeight) + "px",
          overflow: "auto"
        });
  }
  var tmp$1 = {
    ref: widget,
    className: Cn.$plus(css.widget, typeof position === "number" ? (
            position !== 0 ? css.positionBottom : css.positionTop
          ) : css.positionSticky)
  };
  if (tmp !== undefined) {
    tmp$1.style = Caml_option.valFromOption(tmp);
  }
  var match$2 = listing.legalRestriction;
  return React.createElement("div", {
              ref: container,
              className: className
            }, React.createElement(WithTestId.make, {
                  id: "listing--booking--widget",
                  children: React.createElement("div", tmp$1, React.createElement(SlvBookingWidgetHeadline.make, {
                            listing: listing,
                            booking: booking
                          }), match$2 !== undefined ? React.createElement(SlvMessageOrCallHost.make, {
                              listing: listing,
                              booking: booking,
                              viewContext: viewContext
                            }) : React.createElement(SlvBookingWidgetForm.make, {
                              listing: listing,
                              booking: booking,
                              unclaimedListingModal: unclaimedListingModal
                            }))
                }));
}

var LargeScreen = {
  widgetMargin: 2,
  reducer: reducer,
  scrollableState: scrollableState,
  make: SlvBookingWidget$LargeScreen
};

exports.css = css;
exports.SmallScreen = SmallScreen;
exports.LargeScreen = LargeScreen;
/* css Not a pure module */
