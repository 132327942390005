// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function fromString(param) {
  switch (param) {
    case "development" :
        return /* Development */0;
    case "test" :
        return /* Test */1;
    default:
      return /* Production */2;
  }
}

var x = fromString(process.env.NODE_ENV);

var x$1 = process.env.GATSBY_API_HOST;

var apiHost = (x$1 == null) || x$1 === "" ? undefined : x$1;

var x$2 = process.env.SENTRY_DSN;

var sentryDsn = (x$2 == null) || x$2 === "" ? undefined : x$2;

var env = x;

exports.fromString = fromString;
exports.x = x;
exports.apiHost = apiHost;
exports.sentryDsn = sentryDsn;
exports.env = env;
/* x Not a pure module */
