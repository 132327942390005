// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Api = require("../../../../api/Api.bs.js");
var Gtm = require("../../../../libs/Gtm.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Hooks = require("../../../../libs/Hooks.bs.js");
var React = require("react");
var Control = require("../../../../styleguide/components/Control/Control.bs.js");
var $$Promise = require("../../../../bindings/Promise.bs.js");
var Debouncer = require("re-debouncer/src/Debouncer.bs.js");
var HeartIcon = require("../../../../styleguide/icons/HeartIcon.bs.js");
var ReactIntl = require("react-intl");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var SlvNavbarFavoriteToggleModuleScss = require("./SlvNavbarFavoriteToggle.module.scss");

var css = SlvNavbarFavoriteToggleModuleScss;

var persistFavoritedStatus = Debouncer.make(500, (function (param) {
        var onError = param[3];
        var onOk = param[2];
        var listingId = param[0];
        return $$Promise.wait(param[1] ? Api.unfavoriteListing(listingId) : Api.favoriteListing(listingId), (function (param) {
                      if (param.tag) {
                        return Curry._1(onError, undefined);
                      } else {
                        return Curry._1(onOk, undefined);
                      }
                    }));
      }));

function SlvNavbarFavoriteToggle$SmallScreen(Props) {
  var listing = Props.listing;
  var user = Props.user;
  var updateListingFavorited = Props.updateListingFavorited;
  var match = Hooks.useReducer({
        interimFavoritedStatus: undefined
      }, (function (state, action) {
          switch (action) {
            case /* ToggleFavoritedStatus */0 :
                var match = state.interimFavoritedStatus;
                var match$1 = listing.userContext.favorited;
                var nextStatus = match !== undefined ? (
                    match ? /* Favorited */0 : /* Unfavorited */1
                  ) : (
                    match$1 ? /* Unfavorited */1 : /* Favorited */0
                  );
                return /* UpdateWithSideEffects */Block.__(1, [
                          {
                            interimFavoritedStatus: nextStatus
                          },
                          (function (param) {
                              var dispatch = param.dispatch;
                              return Curry._1(persistFavoritedStatus, /* tuple */[
                                          listing.id,
                                          nextStatus,
                                          (function (param) {
                                              return Curry._2(updateListingFavorited, nextStatus ? false : true, (function (param) {
                                                            return Curry._1(dispatch, /* SucceedFavoritedStatusUpdate */1);
                                                          }));
                                            }),
                                          (function (param) {
                                              return Curry._1(dispatch, /* FailFavoritedStatusUpdate */2);
                                            })
                                        ]);
                            })
                        ]);
            case /* SucceedFavoritedStatusUpdate */1 :
                return /* Update */Block.__(0, [{
                            interimFavoritedStatus: undefined
                          }]);
            case /* FailFavoritedStatusUpdate */2 :
                return /* UpdateWithSideEffects */Block.__(1, [
                          {
                            interimFavoritedStatus: undefined
                          },
                          (function (prim) {
                              
                            })
                        ]);
            
          }
        }));
  var dispatch = match[1];
  var favoritedStatus = Belt_Option.getWithDefault(match[0].interimFavoritedStatus, listing.userContext.favorited ? /* Favorited */0 : /* Unfavorited */1);
  return React.createElement(Control.make, {
              id: "listing-favorite-button",
              onClick: (function (param) {
                  var match = user.id;
                  if (match !== undefined) {
                    Curry._1(dispatch, /* ToggleFavoritedStatus */0);
                  } else {
                    Curry._1(user.authenticateWithCallback, (function (param) {
                            return Curry._1(dispatch, /* ToggleFavoritedStatus */0);
                          }));
                  }
                  return Gtm.Slv.favoriteButtonClick(!(
                              favoritedStatus ? false : true
                            ));
                }),
              children: React.createElement("span", {
                    className: css.wrapper
                  }, React.createElement(HeartIcon.make, {
                        size: /* MD */2,
                        favorited: favoritedStatus ? false : true
                      }))
            });
}

var SmallScreen = {
  persistFavoritedStatus: persistFavoritedStatus,
  make: SlvNavbarFavoriteToggle$SmallScreen
};

var persistFavoritedStatus$1 = Debouncer.make(500, (function (param) {
        var onError = param[3];
        var onOk = param[2];
        var listingId = param[0];
        return $$Promise.wait(param[1] ? Api.unfavoriteListing(listingId) : Api.favoriteListing(listingId), (function (param) {
                      if (param.tag) {
                        return Curry._1(onError, undefined);
                      } else {
                        return Curry._1(onOk, undefined);
                      }
                    }));
      }));

function SlvNavbarFavoriteToggle$LargeScreen(Props) {
  var listing = Props.listing;
  var user = Props.user;
  var updateListingFavorited = Props.updateListingFavorited;
  var match = Hooks.useReducer({
        interimFavoritedStatus: undefined,
        hoveredStatus: /* Unhovered */1
      }, (function (state, action) {
          if (typeof action !== "number") {
            return /* Update */Block.__(0, [{
                        interimFavoritedStatus: state.interimFavoritedStatus,
                        hoveredStatus: action[0]
                      }]);
          }
          switch (action) {
            case /* ToggleFavoritedStatus */0 :
                var match = state.interimFavoritedStatus;
                var match$1 = listing.userContext.favorited;
                var nextStatus = match !== undefined ? (
                    match ? /* Favorited */0 : /* Unfavorited */1
                  ) : (
                    match$1 ? /* Unfavorited */1 : /* Favorited */0
                  );
                return /* UpdateWithSideEffects */Block.__(1, [
                          {
                            interimFavoritedStatus: nextStatus,
                            hoveredStatus: state.hoveredStatus
                          },
                          (function (param) {
                              var dispatch = param.dispatch;
                              return Curry._1(persistFavoritedStatus$1, /* tuple */[
                                          listing.id,
                                          nextStatus,
                                          (function (param) {
                                              return Curry._2(updateListingFavorited, nextStatus ? false : true, (function (param) {
                                                            return Curry._1(dispatch, /* SucceedFavoritedStatusUpdate */1);
                                                          }));
                                            }),
                                          (function (param) {
                                              return Curry._1(dispatch, /* FailFavoritedStatusUpdate */2);
                                            })
                                        ]);
                            })
                        ]);
            case /* SucceedFavoritedStatusUpdate */1 :
                return /* Update */Block.__(0, [{
                            interimFavoritedStatus: undefined,
                            hoveredStatus: state.hoveredStatus
                          }]);
            case /* FailFavoritedStatusUpdate */2 :
                return /* UpdateWithSideEffects */Block.__(1, [
                          {
                            interimFavoritedStatus: undefined,
                            hoveredStatus: state.hoveredStatus
                          },
                          (function (prim) {
                              
                            })
                        ]);
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var favoritedStatus = Belt_Option.getWithDefault(state.interimFavoritedStatus, listing.userContext.favorited ? /* Favorited */0 : /* Unfavorited */1);
  var match$1 = state.hoveredStatus;
  return React.createElement(Control.make, {
              id: "listing-favorite-button",
              onClick: (function (param) {
                  var match = user.id;
                  if (match !== undefined) {
                    Curry._1(dispatch, /* ToggleFavoritedStatus */0);
                  } else {
                    Curry._1(user.authenticateWithCallback, (function (param) {
                            return Curry._1(dispatch, /* ToggleFavoritedStatus */0);
                          }));
                  }
                  return Gtm.Slv.favoriteButtonClick(!(
                              favoritedStatus ? false : true
                            ));
                }),
              onFocus: (function (param) {
                  return Curry._1(dispatch, /* UpdateHoverStatus */[/* Hovered */0]);
                }),
              onBlur: (function (param) {
                  return Curry._1(dispatch, /* UpdateHoverStatus */[/* Unhovered */1]);
                }),
              onMouseEnter: (function (param) {
                  return Curry._1(dispatch, /* UpdateHoverStatus */[/* Hovered */0]);
                }),
              onMouseLeave: (function (param) {
                  return Curry._1(dispatch, /* UpdateHoverStatus */[/* Unhovered */1]);
                }),
              children: React.createElement("span", {
                    className: Cn.$plus(css.wrapper, css.largeScreenWrapper)
                  }, favoritedStatus ? (
                      match$1 ? React.createElement(React.Fragment, undefined, React.createElement(HeartIcon.make, {
                                  size: /* MD */2,
                                  favorited: false
                                }), React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.favorite-button.favorite",
                                  defaultMessage: "Favorite"
                                })) : React.createElement(React.Fragment, undefined, React.createElement(HeartIcon.make, {
                                  size: /* MD */2,
                                  favorited: true
                                }), React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.favorite-button.favorite",
                                  defaultMessage: "Favorite"
                                }))
                    ) : (
                      match$1 ? React.createElement(React.Fragment, undefined, React.createElement(HeartIcon.make, {
                                  size: /* MD */2,
                                  favorited: true
                                }), React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.favorite-button.unfavorite",
                                  defaultMessage: "Unfavorite"
                                })) : React.createElement(React.Fragment, undefined, React.createElement(HeartIcon.make, {
                                  size: /* MD */2,
                                  favorited: false
                                }), React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.favorite-button.unfavorite",
                                  defaultMessage: "Unfavorite"
                                }))
                    ))
            });
}

var LargeScreen = {
  persistFavoritedStatus: persistFavoritedStatus$1,
  make: SlvNavbarFavoriteToggle$LargeScreen
};

exports.css = css;
exports.SmallScreen = SmallScreen;
exports.LargeScreen = LargeScreen;
/* css Not a pure module */
