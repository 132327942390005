import Environment from 'app/enums/Environment';

const { NODE_ENV, RAILS_ENV, SERVER_RENDERING, DRY_RUN } = process.env;

export const isDev = NODE_ENV === Environment.DEV;
export const isTest = NODE_ENV === Environment.TEST;
export const isProduction = !!RAILS_ENV && RAILS_ENV === Environment.PRODUCTION;
export const isStaging = !!RAILS_ENV && RAILS_ENV === Environment.STAGING;
export const isProductionLike = !isDev && !isTest;
export const isServerRendering = SERVER_RENDERING;
export const isDryRun = DRY_RUN && DRY_RUN === 'true';
