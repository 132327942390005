// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var nbsp = '\u00a0';

var apos = '\u0027';

var times = '\u00d7';

var quot = '\u0022';

var rarr = '\u2192';

var ndash = '\u2013';

exports.nbsp = nbsp;
exports.apos = apos;
exports.times = times;
exports.quot = quot;
exports.rarr = rarr;
exports.ndash = ndash;
/* No side effect */
