// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var PoolIcon = require("../styleguide/icons/PoolIcon.bs.js");
var WifiIcon = require("../styleguide/icons/WifiIcon.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var KitchenIcon = require("../styleguide/icons/KitchenIcon.bs.js");
var LaundryIcon = require("../styleguide/icons/LaundryIcon.bs.js");
var ParkingIcon = require("../styleguide/icons/ParkingIcon.bs.js");
var AirConditioningIcon = require("../styleguide/icons/AirConditioningIcon.bs.js");

function toString(param) {
  switch (param) {
    case /* Common */0 :
        return "common";
    case /* GoodFor */1 :
        return "good_for";
    case /* Spaces */2 :
        return "spaces";
    case /* Safety */3 :
        return "safety";
    
  }
}

var Group = {
  toString: toString
};

var all_goodFor = [
  /* Regular */Block.__(0, [
      /* Couples */0,
      {
        id: 1,
        label: "Couples"
      }
    ]),
  /* Regular */Block.__(0, [
      /* SoloAdventurers */1,
      {
        id: 2,
        label: "Solo adventurers"
      }
    ]),
  /* Regular */Block.__(0, [
      /* BusinessTravelers */2,
      {
        id: 3,
        label: "Business travelers"
      }
    ]),
  /* Regular */Block.__(0, [
      /* FamiliesWithKids */3,
      {
        id: 4,
        label: "Families (with kids)"
      }
    ]),
  /* Regular */Block.__(0, [
      /* BigGroups */4,
      {
        id: 5,
        label: "Big groups"
      }
    ]),
  /* Regular */Block.__(0, [
      /* Pets */5,
      {
        id: 6,
        label: "Furry friends (pets)"
      }
    ])
];

var all_common = [
  /* Regular */Block.__(0, [
      /* Essentials */6,
      {
        id: 7,
        label: "Essentials (towels, bed sheets, soap, and toilet paper)"
      }
    ]),
  /* Prioritized */Block.__(1, [
      /* Wifi */7,
      {
        id: 8,
        label: "Wifi",
        icon: WifiIcon
      }
    ]),
  /* Regular */Block.__(0, [
      /* Shampoo */8,
      {
        id: 9,
        label: "Shampoo"
      }
    ]),
  /* Regular */Block.__(0, [
      /* ClosetDrawers */9,
      {
        id: 10,
        label: "Closet/drawers"
      }
    ]),
  /* Regular */Block.__(0, [
      /* Tv */10,
      {
        id: 11,
        label: "TV"
      }
    ]),
  /* Regular */Block.__(0, [
      /* Heat */11,
      {
        id: 12,
        label: "Heat"
      }
    ]),
  /* Prioritized */Block.__(1, [
      /* AirConditioning */12,
      {
        id: 13,
        label: "Air conditioning",
        icon: AirConditioningIcon
      }
    ]),
  /* Regular */Block.__(0, [
      /* Breakfast */13,
      {
        id: 14,
        label: "Breakfast, coffee, tea"
      }
    ]),
  /* Regular */Block.__(0, [
      /* Workspace */14,
      {
        id: 15,
        label: "Desk/workspace"
      }
    ]),
  /* Regular */Block.__(0, [
      /* Fireplace */15,
      {
        id: 16,
        label: "Fireplace"
      }
    ]),
  /* Regular */Block.__(0, [
      /* Iron */16,
      {
        id: 17,
        label: "Iron"
      }
    ]),
  /* Regular */Block.__(0, [
      /* Hairdryer */17,
      {
        id: 18,
        label: "Hairdryer"
      }
    ]),
  /* Regular */Block.__(0, [
      /* PetsInHouse */18,
      {
        id: 19,
        label: "Pets in the house"
      }
    ]),
  /* Regular */Block.__(0, [
      /* WheelchairAccessible */19,
      {
        id: 20,
        label: "Wheelchair accessible"
      }
    ])
];

var all_spaces = [
  /* Prioritized */Block.__(1, [
      /* Kitchen */20,
      {
        id: 21,
        label: "Kitchen",
        icon: KitchenIcon
      }
    ]),
  /* Prioritized */Block.__(1, [
      /* LaundryWasher */21,
      {
        id: 22,
        label: "Laundry - washer",
        icon: LaundryIcon
      }
    ]),
  /* Prioritized */Block.__(1, [
      /* LaundryDryer */22,
      {
        id: 23,
        label: "Laundry - dryer",
        icon: LaundryIcon
      }
    ]),
  /* Prioritized */Block.__(1, [
      /* ParkingSpace */23,
      {
        id: 24,
        label: "Parking space",
        icon: ParkingIcon
      }
    ]),
  /* Regular */Block.__(0, [
      /* Elevator */24,
      {
        id: 25,
        label: "Elevator"
      }
    ]),
  /* Prioritized */Block.__(1, [
      /* Pool */25,
      {
        id: 26,
        label: "Pool",
        icon: PoolIcon
      }
    ]),
  /* Regular */Block.__(0, [
      /* HotTub */26,
      {
        id: 27,
        label: "Hot Tub"
      }
    ]),
  /* Regular */Block.__(0, [
      /* Gym */27,
      {
        id: 28,
        label: "Gym"
      }
    ])
];

var all_safety = [
  /* Regular */Block.__(0, [
      /* SmokeDetector */28,
      {
        id: 29,
        label: "Smoke detector"
      }
    ]),
  /* Regular */Block.__(0, [
      /* CarbonMonoxideDetector */29,
      {
        id: 30,
        label: "Carbon monoxide detector"
      }
    ]),
  /* Regular */Block.__(0, [
      /* FirstAidKit */30,
      {
        id: 31,
        label: "First aid kit"
      }
    ]),
  /* Regular */Block.__(0, [
      /* SafetyCard */31,
      {
        id: 32,
        label: "Safety card"
      }
    ]),
  /* Regular */Block.__(0, [
      /* FireExtinguisher */32,
      {
        id: 33,
        label: "Fire extinguisher"
      }
    ]),
  /* Regular */Block.__(0, [
      /* BedroomLock */33,
      {
        id: 34,
        label: "Lock on bedroom door"
      }
    ])
];

var all = [
  /* tuple */[
    /* Common */0,
    all_common
  ],
  /* tuple */[
    /* Spaces */2,
    all_spaces
  ],
  /* tuple */[
    /* Safety */3,
    all_safety
  ],
  /* tuple */[
    /* GoodFor */1,
    all_goodFor
  ]
];

var match = Belt_Array.reduce(all, /* tuple */[
      [],
      []
    ], (function (param, param$1) {
        var match = Belt_Array.reduce(param$1[1], /* tuple */[
              [],
              []
            ], (function (param, amenity) {
                var regular = param[1];
                var prioritized = param[0];
                if (amenity.tag) {
                  return /* tuple */[
                          Belt_Array.concat(prioritized, [amenity[1]]),
                          regular
                        ];
                } else {
                  return /* tuple */[
                          prioritized,
                          Belt_Array.concat(regular, [amenity[1]])
                        ];
                }
              }));
        return /* tuple */[
                Belt_Array.concat(param[0], match[0]),
                Belt_Array.concat(param[1], [/* tuple */[
                        param$1[0],
                        match[1]
                      ]])
              ];
      }));

var regular = match[1];

var prioritized = match[0];

function prioritized$1(ids) {
  return Belt_Array.keep(prioritized, (function (amenity) {
                return ids.includes(amenity.id);
              }));
}

function regular$1(ids) {
  return Belt_Array.map(regular, (function (param) {
                return /* tuple */[
                        param[0],
                        Belt_Array.keep(param[1], (function (amenity) {
                                return ids.includes(amenity.id);
                              }))
                      ];
              }));
}

exports.Group = Group;
exports.all = all;
exports.prioritized = prioritized$1;
exports.regular = regular$1;
/* match Not a pure module */
