// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Control = require("../Control/Control.bs.js");
var Tooltip = require("../Tooltip/Tooltip.bs.js");
var WithTestId = require("../WithTestId/WithTestId.bs.js");
var TooltipQuestionIcon = require("../../icons/TooltipQuestionIcon.bs.js");
var EstimatedPriceBadgeModuleScss = require("./EstimatedPriceBadge.module.scss");

var css = EstimatedPriceBadgeModuleScss;

function EstimatedPriceBadge(Props) {
  var unclaimedListingModal = Props.unclaimedListingModal;
  var withLearnMoreLinkOpt = Props.withLearnMoreLink;
  var withTextTemplateFor = Props.withTextTemplateFor;
  var withLearnMoreLink = withLearnMoreLinkOpt !== undefined ? withLearnMoreLinkOpt : true;
  return React.createElement(Tooltip.make, {
              id: "estimated-price-tooltip",
              content: React.createElement("div", {
                    className: css.estimatedPriceBadgeTooltip
                  }, React.createElement("div", {
                        className: css.estimatedPriceBadgeTooltipText
                      }, withTextTemplateFor ? "The listing has not yet been claimed by the owner. All info was scraped from open sources." : "The listing has not yet been claimed by the owner so we don't have the exect total price for your stay. The estimated price is calculated based on the lowest Airbnb or VRBO price minus the website service fees."), withLearnMoreLink ? React.createElement("div", {
                          className: css.estimatedPriceBadgeTooltipLearnMoreLink
                        }, React.createElement(Control.make, {
                              testId: "estimated-price-learn-more",
                              onClick: (function (param) {
                                  return Curry._1(unclaimedListingModal.show, undefined);
                                }),
                              children: "Learn more"
                            })) : null),
              side: /* Top */0,
              children: React.createElement(WithTestId.make, {
                    id: "estimated-price-badge",
                    children: React.createElement("div", {
                          className: css.estimatedPriceBadge
                        }, React.createElement("span", {
                              className: css.estimatedPriceBadgeText
                            }, "Estimated price"), React.createElement(TooltipQuestionIcon.make, {
                              size: /* SM */1,
                              color: /* Magenta */4
                            }))
                  })
            });
}

var make = EstimatedPriceBadge;

exports.css = css;
exports.make = make;
/* css Not a pure module */
