// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");

function MessageHostIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var title = Props.title;
  return React.createElement(Icon.make, {
              title: title,
              size: size,
              children: React.createElement("path", {
                    style: Icon.style(color),
                    d: "M14.627 15.466a.486.486 0 0 1-.18.435.457.457 0 0 1-.456.065l-3.913-1.597c-.758.148-1.43.227-2.078.227-4.406 0-8-3.025-8-6.798C0 4.024 3.594 1 8 1c4.406 0 8 3.025 8 6.798 0 1.574-.62 3.043-1.758 4.247l.385 3.42zm-1.338-3.54a.487.487 0 0 1 .14-.4c1.066-1.06 1.637-2.349 1.637-3.728 0-3.197-3.14-5.839-7.066-5.839C4.074 1.96.934 4.601.934 7.798c0 3.197 3.14 5.839 7.066 5.839.618 0 1.273-.08 2.032-.237a.455.455 0 0 1 .263.025l3.315 1.353-.321-2.853zM4.731 8.74h3.736v.816H4.73V8.74zm0-2.399h6.538v.816H4.73V6.34z"
                  })
            });
}

var make = MessageHostIcon;

exports.make = make;
/* Icon Not a pure module */
