// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var GoogleMaps = require("../../../bindings/GoogleMaps.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var StylesJson = require("./styles.json");
var ReactGoogleMaps = require("react-google-maps");

var styles = StylesJson;

function GoogleMap__Map$default(Props) {
  var center = Props.center;
  var zoom = Props.zoom;
  var children = Props.children;
  if (GoogleMaps.defined(undefined)) {
    return React.createElement(ReactGoogleMaps.GoogleMap, {
                center: center,
                options: {
                  draggable: true,
                  disableDefaultUI: true,
                  zoomControl: true,
                  zoomControlOptions: {
                    position: GoogleMaps.topLeftZoomControlPosition(undefined)
                  },
                  scrollwheel: false,
                  styles: styles
                },
                zoom: zoom,
                children: Belt_Option.getWithDefault(children, null)
              });
  } else {
    return null;
  }
}

var $$default = GoogleMap__Map$default;

exports.styles = styles;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* styles Not a pure module */
