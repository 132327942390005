// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var $$Date = require("../../../../libs/Date.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Money = require("../../../../libs/Money.bs.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Spinner = require("../../../../styleguide/components/Spinner/Spinner.bs.js");
var SlvBlock = require("../../components/SlvBlock/SlvBlock.bs.js");
var ReactIntl = require("react-intl");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var ReactDayPicker = require("../../../../bindings/ReactDayPicker.bs.js");
var ReactDayPicker$1 = require("react-day-picker");
var SlvCalendarModuleScss = require("./SlvCalendar.module.scss");

var css = SlvCalendarModuleScss;

function SlvCalendar$Block(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var screen = Props.screen;
  var numberOfMonths = screen !== undefined && screen !== 0 ? 2 : 1;
  var disabled = function (date) {
    var calendarDay = Belt_MapString.get(booking.calendar.days, $$Date.Naive.toString(date));
    if (Curry._2(booking.isDayInPastRelativeToListingTimezone, date, listing.currentDate) || calendarDay === undefined) {
      return true;
    } else if (calendarDay.morningState.tag) {
      if (calendarDay.eveningState.tag) {
        return true;
      } else {
        return !listing.sameDayCheckInAndOut;
      }
    } else if (calendarDay.eveningState.tag) {
      return !listing.sameDayCheckInAndOut;
    } else {
      return false;
    }
  };
  var renderDay = React.useCallback((function (date) {
          return (function (modifiers) {
              var dayOfMonth = $$Date.Naive.date(date);
              var calendarDay = Belt_MapString.get(booking.calendar.days, $$Date.Naive.toString(date));
              return React.createElement("div", undefined, React.createElement("div", undefined, String(dayOfMonth)), calendarDay !== undefined && !Belt_Option.getWithDefault(Js_dict.get(modifiers, css.disabled), false) ? React.createElement("div", {
                                className: Cn.$plus(Cn.on(css.today, $$Date.Naive.isSameDay(date, $$Date.Naive.make(undefined))), css.dayPrice)
                              }, Money.formatWithoutCents(calendarDay.price)) : null);
            });
        }), [booking.calendar.days]);
  var match = booking.input.checkIn;
  var match$1 = booking.input.checkOut;
  var match$2 = booking.status;
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  if (match !== undefined) {
    if (match$2) {
      var match$3 = match$2[0];
      if (typeof match$3 === "number" || match$3[0] !== 59168818) {
        exit$1 = 2;
      } else {
        var match$4 = match$3[1];
        if (match$4.tag) {
          var match$5 = match$4[0];
          if (typeof match$5 === "number" || match$5[0] !== -58570577 || match$5[1] !== 459612177) {
            exit$1 = 2;
          } else {
            tmp = new Date();
          }
        } else {
          exit = 1;
        }
      }
    } else {
      exit = 1;
    }
  } else if (match$1 !== undefined) {
    exit$1 = 2;
  } else {
    tmp = new Date();
  }
  if (exit$1 === 2) {
    if (match$1 !== undefined && match$2) {
      var match$6 = match$2[0];
      if (typeof match$6 === "number" || match$6[0] !== 59168818) {
        exit = 1;
      } else {
        var match$7 = match$6[1];
        if (match$7.tag) {
          var match$8 = match$7[0];
          if (typeof match$8 === "number" || match$8[0] !== -58570577 || match$8[1] !== 459612177) {
            exit = 1;
          } else {
            tmp = new Date();
          }
        } else {
          exit = 1;
        }
      }
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tmp = match !== undefined ? Caml_option.valFromOption(match) : Caml_option.valFromOption(match$1);
  }
  var match$9 = booking.status;
  var match$10 = booking.calendar.status;
  return React.createElement(SlvBlock.make, {
              id: /* Calendar */-656729378,
              children: null
            }, React.createElement(SlvBlock.Title.make, {
                  children: React.createElement(ReactIntl.FormattedMessage, {
                        id: "slv.calendar.title",
                        defaultMessage: "Availability"
                      })
                }), React.createElement("div", {
                  className: css.calendar
                }, React.createElement(ReactDayPicker$1.default, ReactDayPicker.makeProps(Caml_option.some(tmp), Caml_option.some(new Date()), numberOfMonths, false, undefined, undefined, Caml_option.some(Js_dict.fromList(/* :: */[
                                  /* tuple */[
                                    css.disabled,
                                    /* `Fn */[
                                      15720,
                                      disabled
                                    ]
                                  ],
                                  /* [] */0
                                ])), Caml_option.some(css), undefined, (function (date) {
                            return Curry._2(booking.fetchCalendarDays, date, numberOfMonths);
                          }), Curry.__2(renderDay), match$9 ? (
                            screen !== undefined ? "state-1 screen-1" : "state-1 screen-0"
                          ) : (
                            screen !== undefined ? "state-0 screen-1" : "state-0 screen-0"
                          ), undefined)), match$10 ? null : React.createElement(Spinner.make, {
                        size: /* MD */1,
                        color: /* Teal */1,
                        bg: /* OpaqueWhiteBg */1,
                        centered: true
                      })));
}

var Block = {
  make: SlvCalendar$Block
};

function SlvCalendar(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var screen = Props.screen;
  var match = listing.legalRestriction;
  if (match !== undefined) {
    return null;
  } else {
    return React.createElement(SlvCalendar$Block, {
                listing: listing,
                booking: booking,
                screen: screen
              });
  }
}

var make = SlvCalendar;

exports.css = css;
exports.Block = Block;
exports.make = make;
/* css Not a pure module */
