// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var I18n = require("../../../../libs/I18n.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Money = require("../../../../libs/Money.bs.js");
var React = require("react");
var Button = require("../../../../styleguide/components/Button/Button.bs.js");
var Spinner = require("../../../../styleguide/components/Spinner/Spinner.bs.js");
var EstimatedPriceBadge = require("../../../../styleguide/components/EstimatedPriceBadge/EstimatedPriceBadge.bs.js");
var SlvBookingSendInquiryButton = require("./SlvBookingSendInquiryButton.bs.js");
var SlvBookingFooterModuleScss = require("./SlvBookingFooter.module.scss");

var css = SlvBookingFooterModuleScss;

function SlvBookingFooter$SmallScreen(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var unclaimedListingModal = Props.unclaimedListingModal;
  var match = listing.legalRestriction;
  if (match !== undefined) {
    return React.createElement("div", {
                className: Cn.$plus(css.container, css.withoutPrice)
              }, React.createElement(Button.make, {
                    size: /* MD */1,
                    color: /* Primary */0,
                    onClick: (function (param) {
                        return Curry._1(booking.showMessageModal, undefined);
                      }),
                    children: "Send inquiry"
                  }));
  }
  var match$1 = booking.status;
  var tmp;
  var exit = 0;
  if (match$1) {
    var match$2 = match$1[0];
    var exit$1 = 0;
    var quote;
    if (match$2[0] >= 59168818) {
      var match$3 = match$2[1];
      if (match$3.tag) {
        tmp = "Ask host for the price";
      } else {
        var match$4 = match$3[0];
        if (typeof match$4 === "number") {
          var samplePrice = listing.samplePrice;
          if (samplePrice !== undefined) {
            var match$5 = listing.kind;
            tmp = React.createElement("div", undefined, React.createElement("span", undefined, React.createElement("span", {
                          className: css.price
                        }, Money.formatWithoutCents(samplePrice)), " per night"), match$5 ? React.createElement(EstimatedPriceBadge.make, {
                        unclaimedListingModal: unclaimedListingModal,
                        withTextTemplateFor: /* Slv */1
                      }) : null);
          } else {
            tmp = "Ask host for the price";
          }
        } else {
          quote = match$4[1];
          exit$1 = 2;
        }
      }
    } else {
      var match$6 = match$2[1][0];
      if (typeof match$6 === "number") {
        exit = 1;
      } else {
        quote = match$6[1][0];
        exit$1 = 2;
      }
    }
    if (exit$1 === 2) {
      var match$7 = listing.kind;
      tmp = React.createElement("div", {
            className: css.pricesWithQuote
          }, React.createElement("div", {
                className: css.pricesWithQuoteMainLine
              }, React.createElement("span", {
                    className: css.pricesWithQuoteMainLineAmount
                  }, Money.formatWithoutCents(quote.total)), " for ", I18n.inflect(quote.numberOfNights, "night", "nights")), match$7 ? React.createElement(EstimatedPriceBadge.make, {
                  unclaimedListingModal: unclaimedListingModal,
                  withTextTemplateFor: /* Slv */1
                }) : React.createElement("div", {
                  className: css.pricesWithQuoteSecondaryLine
                }, Money.formatWithoutCents(quote.pricePerNight), " per night"));
    }
    
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Spinner.make, {
          size: /* SM */0,
          color: /* Teal */1,
          centered: false
        });
  }
  return React.createElement("div", {
              className: Cn.$plus(css.container, css.withPrice)
            }, React.createElement("div", {
                  className: css.priceContainer
                }, tmp), React.createElement(SlvBookingSendInquiryButton.make, {
                  size: /* MD */1,
                  submit: false,
                  expanded: false,
                  onClick: (function (param) {
                      return Curry._1(booking.showInquiryModal, undefined);
                    })
                }));
}

var SmallScreen = {
  make: SlvBookingFooter$SmallScreen
};

exports.css = css;
exports.SmallScreen = SmallScreen;
/* css Not a pure module */
