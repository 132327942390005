// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Number = require("./Number.bs.js");

function formatWithCents(money) {
  return "$" + $$Number.formatWithFractionDigits(money, 2);
}

function formatWithoutCents(money) {
  return "$" + $$Number.formatWithoutFractionDigits(money);
}

exports.formatWithCents = formatWithCents;
exports.formatWithoutCents = formatWithoutCents;
/* No side effect */
