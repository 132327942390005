// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Navbar = require("../../../../styleguide/components/Navbar/Navbar.bs.js");
var SlvNavbarNavigation = require("./SlvNavbarNavigation.bs.js");
var SlvNavbarFavoriteToggle = require("./SlvNavbarFavoriteToggle.bs.js");
var SlvNavbarEditListingButton = require("./SlvNavbarEditListingButton.bs.js");
var SlvNavbarBackToEditingButton = require("./SlvNavbarBackToEditingButton.bs.js");

function SlvNavbar$SmallScreen(Props) {
  var listing = Props.listing;
  var user = Props.user;
  var meta = Props.meta;
  var viewContext = Props.viewContext;
  var updateListingFavorited = Props.updateListingFavorited;
  if (viewContext >= 2) {
    return null;
  }
  var match = listing.userContext;
  var tmp;
  switch (viewContext) {
    case /* Live */0 :
        tmp = match.isManagedByCurrentUser ? React.createElement(SlvNavbarEditListingButton.make, {
                listingId: listing.id
              }) : React.createElement(SlvNavbarFavoriteToggle.SmallScreen.make, {
                listing: listing,
                user: user,
                updateListingFavorited: updateListingFavorited
              });
        break;
    case /* Preview */1 :
        tmp = React.createElement(SlvNavbarBackToEditingButton.make, {
              listingId: listing.id
            });
        break;
    case /* Microsite */2 :
        tmp = null;
        break;
    
  }
  return React.createElement(Navbar.Sticky.make, {
              children: null
            }, React.createElement(Navbar.Block.make, {
                  children: React.createElement(SlvNavbarNavigation.SmallScreen.make, {
                        meta: meta
                      })
                }), React.createElement(Navbar.Block.make, {
                  children: tmp
                }));
}

var SmallScreen = {
  make: SlvNavbar$SmallScreen
};

function SlvNavbar$LargeScreen(Props) {
  var listing = Props.listing;
  var user = Props.user;
  var viewContext = Props.viewContext;
  var updateListingFavorited = Props.updateListingFavorited;
  var match = listing.userContext;
  var tmp;
  switch (viewContext) {
    case /* Live */0 :
        tmp = match.isManagedByCurrentUser ? React.createElement(SlvNavbarEditListingButton.make, {
                listingId: listing.id
              }) : React.createElement(SlvNavbarFavoriteToggle.LargeScreen.make, {
                listing: listing,
                user: user,
                updateListingFavorited: updateListingFavorited
              });
        break;
    case /* Preview */1 :
        tmp = React.createElement(SlvNavbarBackToEditingButton.make, {
              listingId: listing.id
            });
        break;
    case /* Microsite */2 :
        tmp = "";
        break;
    
  }
  return React.createElement(Navbar.Sticky.make, {
              children: null
            }, React.createElement(Navbar.Block.make, {
                  children: React.createElement(SlvNavbarNavigation.LargeScreen.make, { })
                }), React.createElement(Navbar.Block.make, {
                  children: tmp
                }));
}

var LargeScreen = {
  make: SlvNavbar$LargeScreen
};

function SlvNavbar(Props) {
  var listing = Props.listing;
  var user = Props.user;
  var meta = Props.meta;
  var screen = Props.screen;
  var viewContext = Props.viewContext;
  var updateListingFavorited = Props.updateListingFavorited;
  if (screen !== undefined) {
    if (screen >= 2) {
      return React.createElement(SlvNavbar$LargeScreen, {
                  listing: listing,
                  user: user,
                  viewContext: viewContext,
                  updateListingFavorited: updateListingFavorited
                });
    } else {
      return React.createElement(SlvNavbar$SmallScreen, {
                  listing: listing,
                  user: user,
                  meta: meta,
                  viewContext: viewContext,
                  updateListingFavorited: updateListingFavorited
                });
    }
  } else {
    return null;
  }
}

var make = SlvNavbar;

exports.SmallScreen = SmallScreen;
exports.LargeScreen = LargeScreen;
exports.make = make;
/* react Not a pure module */
