// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function status(bookingStatus, vrbpData) {
  if (!bookingStatus) {
    return ;
  }
  var match = bookingStatus[0];
  if (match[0] >= 59168818) {
    var match$1 = match[1];
    if (match$1.tag) {
      return ;
    }
    var match$2 = match$1[0];
    if (typeof match$2 === "number") {
      var match$3 = vrbpData.bestPrice;
      var match$4 = vrbpData.hc;
      if (match$3 === undefined) {
        return ;
      }
      if (match$4 === undefined) {
        return ;
      }
      var directPrice = match$4.price;
      if (directPrice !== undefined) {
        return /* Ready */[{
                  bestPrice: match$3,
                  directPrice: directPrice
                }];
      } else {
        return ;
      }
    }
    var quote = match$2[1];
    var bestPrice = quote.bestPrice;
    if (bestPrice !== undefined) {
      return /* Ready */[{
                bestPrice: bestPrice,
                directPrice: {
                  total: quote.total,
                  perNight: quote.avgPricePerNight
                }
              }];
    } else {
      return ;
    }
  }
  var match$5 = match[1][0];
  if (typeof match$5 === "number") {
    return /* Processing */0;
  }
  var quote$1 = match$5[1][0];
  var bestPrice$1 = quote$1.bestPrice;
  if (bestPrice$1 !== undefined) {
    return /* Ready */[{
              bestPrice: bestPrice$1,
              directPrice: {
                total: quote$1.total,
                perNight: quote$1.avgPricePerNight
              }
            }];
  }
  
}

exports.status = status;
/* No side effect */
