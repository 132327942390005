// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function Make(Config) {
  var x = React.createContext(Config.defaultValue);
  var make = x.Provider;
  var Provider = {
    make: make
  };
  return {
          x: x,
          Provider: Provider
        };
}

exports.Make = Make;
/* react Not a pure module */
