// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function toFetch(param) {
  if (typeof param === "number") {
    return /* Get */0;
  }
  var variant = param[0];
  if (variant >= 527250507) {
    if (variant >= 892711040) {
      return /* Post */2;
    } else {
      return /* Delete */4;
    }
  } else if (variant >= 4004527) {
    return /* Put */3;
  } else {
    return /* Patch */8;
  }
}

function toString(param) {
  if (typeof param === "number") {
    return "GET";
  }
  var variant = param[0];
  if (variant >= 527250507) {
    if (variant >= 892711040) {
      return "POST";
    } else {
      return "DELETE";
    }
  } else if (variant >= 4004527) {
    return "PUT";
  } else {
    return "PATCH";
  }
}

function body(param) {
  if (typeof param === "number") {
    return ;
  }
  if (param[0] !== 527250507) {
    return Caml_option.some(param[1]);
  }
  var body$1 = param[1];
  if (body$1 !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(body$1));
  }
  
}

var Method = {
  toFetch: toFetch,
  toString: toString,
  body: body
};

function toString$1(x) {
  if (typeof x === "number") {
    if (x !== 0) {
      return "application/json";
    } else {
      return "text/plain";
    }
  } else {
    return x[0];
  }
}

function fromString(x) {
  if (x.includes("application/json")) {
    return /* Json */1;
  } else if (x.includes("text/plain")) {
    return /* Text */0;
  } else {
    return /* Other */[x];
  }
}

function fromHeaders(x) {
  return Belt_Option.map(Caml_option.null_to_opt(x.get("Content-Type")), fromString);
}

var ContentType = {
  toString: toString$1,
  fromString: fromString,
  fromHeaders: fromHeaders
};

function toContentType(param) {
  if (param[0] >= 936573133) {
    return /* Text */0;
  } else {
    return /* Json */1;
  }
}

var $$Response = {
  toContentType: toContentType
};

function client(code) {
  if (code >= 400) {
    return code < 500;
  } else {
    return false;
  }
}

function server(code) {
  if (code >= 500) {
    return code < 600;
  } else {
    return false;
  }
}

var $$Error = {
  client: client,
  server: server
};

exports.Method = Method;
exports.ContentType = ContentType;
exports.$$Response = $$Response;
exports.$$Error = $$Error;
/* No side effect */
