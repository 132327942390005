import _ from 'lodash/fp';

const AJAX_PENDING_ATTRIBUTE = 'data-are_ajax_requests_pending';
const AJAX_LOG_ATTRIBUTE = 'data-ajax_requests_log';

const pendingAjaxRequestUuids = [];

const addRequest = uuid => {
  if (!pendingAjaxRequestUuids.includes(uuid)) {
    pendingAjaxRequestUuids.push(uuid);
  }
};

const removeRequest = uuid => {
  if (pendingAjaxRequestUuids.includes(uuid)) {
    pendingAjaxRequestUuids.splice(pendingAjaxRequestUuids.indexOf(uuid), 1);
  }
};

const hasPendingRequests = () => pendingAjaxRequestUuids.length > 0;

export default function createAjaxRequestTracker(reqUrl, reqParams) {
  const uuid = _.uniqueId();
  const bodyEl = document.body;

  const updateBodyAttribute = () => bodyEl.setAttribute(AJAX_PENDING_ATTRIBUTE, hasPendingRequests());
  const addToLog = message =>
    bodyEl.setAttribute(AJAX_LOG_ATTRIBUTE, (bodyEl.getAttribute(AJAX_LOG_ATTRIBUTE) || '') + message);

  return {
    start() {
      addToLog(`${uuid}\tstarted\t${reqUrl}\t${JSON.stringify(reqParams)}\n`);
      addRequest(uuid);
      updateBodyAttribute();
    },

    end(status) {
      addToLog(`${uuid}\tended\t${status}\n`);
      removeRequest(uuid);
      updateBodyAttribute();
    },
  };
}
