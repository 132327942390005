// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var A = require("../../../../styleguide/components/Link/A.bs.js");
var I18n = require("../../../../libs/I18n.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Route = require("../../../../Route.bs.js");
var React = require("react");
var Avatar = require("../../../../styleguide/components/Avatar/Avatar.bs.js");
var Button = require("../../../../styleguide/components/Button/Button.bs.js");
var Control = require("../../../../styleguide/components/Control/Control.bs.js");
var SlvBlock = require("../../components/SlvBlock/SlvBlock.bs.js");
var MaskedPhone = require("../../../../styleguide/components/MaskedPhone/MaskedPhone.bs.js");
var MessageHostIcon = require("../../../../styleguide/icons/MessageHostIcon.bs.js");
var SlvClickableItem = require("../../components/SlvClickableItem/SlvClickableItem.bs.js");
var SlvHostKnownModuleScss = require("./SlvHostKnown.module.scss");

var css = SlvHostKnownModuleScss;

function SlvHostKnown(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var screen = Props.screen;
  var viewContext = Props.viewContext;
  var tmp;
  if (listing.userContext.isManagedByCurrentUser) {
    var owner = listing.owner;
    switch (owner.tag | 0) {
      case /* Owner */0 :
          tmp = React.createElement(Button.AsNativeLink.make, {
                href: Route.editProfile(owner[0].url),
                size: /* MD */1,
                color: /* Teal */2,
                ghost: true,
                className: css.button,
                children: "Edit Profile"
              });
          break;
      case /* MetaOwnerUnclaimed */1 :
      case /* MetaOwnerClaimed */2 :
          tmp = null;
          break;
      
    }
  } else {
    tmp = null;
  }
  var owner$1 = listing.owner;
  var tmp$1;
  switch (owner$1.tag | 0) {
    case /* Owner */0 :
        var owner$2 = owner$1[0];
        var details = owner$2.aboutMeDetails;
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: css.nameAndTotalListingsContainer
                }, React.createElement(A.make, {
                      href: owner$2.url,
                      color: /* General */0,
                      className: css.name,
                      children: null
                    }, owner$2.firstName, " ", owner$2.lastName), React.createElement(A.make, {
                      href: Route.userListings(owner$2.id),
                      color: /* General */0,
                      children: I18n.inflect(owner$2.totalListingCount, "listing", "listings")
                    })), details !== undefined ? React.createElement("div", {
                    className: css.aboutMe
                  }, details) : null);
        break;
    case /* MetaOwnerUnclaimed */1 :
        tmp$1 = null;
        break;
    case /* MetaOwnerClaimed */2 :
        var owner$3 = owner$1[0];
        tmp$1 = React.createElement("div", {
              className: css.name
            }, owner$3.firstName, " ", owner$3.lastName);
        break;
    
  }
  var match = listing.contactPhone;
  var phone = listing.contactPhone;
  return React.createElement(SlvBlock.make, {
              id: /* Host */803994504,
              children: null
            }, React.createElement(SlvBlock.Title.make, {
                  children: "About the host"
                }), React.createElement("div", {
                  className: css.user
                }, React.createElement("div", {
                      className: css.photo
                    }, screen !== undefined ? React.createElement(Avatar.User.make, {
                            user: listing.owner,
                            size: screen >= 2 ? 150 : 60,
                            className: css.userAvatar
                          }) : null, tmp), React.createElement("div", {
                      className: css.details
                    }, tmp$1, React.createElement("div", {
                          className: css.contactWidget
                        }, React.createElement("div", {
                              className: css.contactWidgetIconContainer
                            }, React.createElement(MessageHostIcon.make, {
                                  size: /* XL */4,
                                  color: /* Teal */1,
                                  title: "Contact"
                                })), React.createElement("div", {
                              className: css.contactWidgetDetails
                            }, React.createElement("div", {
                                  className: css.contactWidgetText
                                }, React.createElement(Control.Underlined.make, {
                                      color: /* Teal */0,
                                      className: css.contactWidgetMessageLink,
                                      onClick: (function (param) {
                                          return Curry._1(booking.showMessageModal, undefined);
                                        }),
                                      children: "Message the host"
                                    }), match !== undefined ? " or call" : null, " if you have any questions on this listing."), phone !== undefined ? React.createElement("div", {
                                    className: css.contactWidgetPhoneContainer
                                  }, React.createElement(SlvClickableItem.make, {
                                        viewContext: viewContext,
                                        action: /* ShowPhone */304810257,
                                        listingId: listing.id,
                                        children: React.createElement(MaskedPhone.make, {
                                              phone: phone,
                                              className: css.contactWidgetPhone,
                                              onShown: booking.notifyGtmOnPhoneShow
                                            })
                                      })) : null)))));
}

var userAvatarSizeLargeScreen = 150;

var userAvatarSizeSmallScreen = 60;

var make = SlvHostKnown;

exports.css = css;
exports.userAvatarSizeLargeScreen = userAvatarSizeLargeScreen;
exports.userAvatarSizeSmallScreen = userAvatarSizeSmallScreen;
exports.make = make;
/* css Not a pure module */
