// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function CalendarIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var className = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "Calendar";
  var tmp = {
    title: title,
    size: size,
    children: React.createElement("path", {
          style: Icon.style(color),
          d: "M0 1l1-1v16l-1-1h16l-1 1V0l1 1H0zm16-1v16H0V0h16zM0 0h16v3H0V0zm3 5v2h2V5H3zm4 0v2h2V5H7zm4 0v2h2V5h-2zM3 8v2h2V8H3zm4 0v2h2V8H7zm4 0v2h2V8h-2zm-8 3v2h2v-2H3zm4 0v2h2v-2H7z"
        })
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Icon.make, tmp);
}

var make = CalendarIcon;

exports.make = make;
/* Icon Not a pure module */
