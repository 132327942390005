// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Link = require("./Link.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var LinkModuleScss = require("./Link.module.scss");

var css = LinkModuleScss;

function target(param) {
  if (param) {
    return "_blank";
  }
  
}

function rel(param) {
  if (param) {
    if (param[0] >= 781962729) {
      return "noopener";
    } else {
      return "noopener noreferrer";
    }
  }
  
}

var Target = {
  target: target,
  rel: rel
};

function A(Props) {
  var href = Props.href;
  var targetOpt = Props.target;
  var color = Props.color;
  var classNameOpt = Props.className;
  var onClick = Props.onClick;
  var children = Props.children;
  var target$1 = targetOpt !== undefined ? targetOpt : /* Self */0;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    className: Cn.$plus(Cn.$plus(css.link, Link.Color.className(color)), className),
    href: href
  };
  var tmp$1 = rel(target$1);
  if (tmp$1 !== undefined) {
    tmp.rel = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = target(target$1);
  if (tmp$2 !== undefined) {
    tmp.target = Caml_option.valFromOption(tmp$2);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("a", tmp, children);
}

function A$Unstyled(Props) {
  var href = Props.href;
  var targetOpt = Props.target;
  var className = Props.className;
  var onClick = Props.onClick;
  var children = Props.children;
  var target$1 = targetOpt !== undefined ? targetOpt : /* Self */0;
  var tmp = {
    className: Cn.$plus(css.unstyled, className),
    href: href
  };
  var tmp$1 = rel(target$1);
  if (tmp$1 !== undefined) {
    tmp.rel = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = target(target$1);
  if (tmp$2 !== undefined) {
    tmp.target = Caml_option.valFromOption(tmp$2);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("a", tmp, children);
}

var Unstyled = {
  make: A$Unstyled
};

var make = A;

exports.css = css;
exports.Target = Target;
exports.make = make;
exports.Unstyled = Unstyled;
/* css Not a pure module */
