/* eslint id-length: 0 */
import PhotoSwipe from 'photoswipe/dist/photoswipe';
import _ from 'lodash/fp';
import PhotoSwipeUIDefault from './photoswipe-ui-default';

export const galleries = {
  initAll() {
    const domGalleries = document.querySelectorAll('.fng-gallery');
    for (let i = 0; i < domGalleries.length; i++) {
      this.galleryInitter(`#${domGalleries[i].id}`);
    }
  },

  galleryInitter(gallerySelector) {
    // Loop through all gallery elements and bind events
    const galleryElements = document.querySelectorAll(gallerySelector);

    for (let i = 0, len = galleryElements.length; i < len; i++) {
      galleryElements[i].setAttribute('data-pswp-uid', i + 1);
      galleryElements[i].onclick = this.onThumbnailsClick.bind(this);
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    const hashData = this.photoswipeParseHash();
    if (hashData.pid && hashData.gid) {
      this.openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
    }
  },

  // Parse slide data (url, title, size ...) from DOM elements
  // (children of gallerySelector)
  parseThumbnailElements(el) {
    const thumbElements = el.childNodes;
    const numNodes = thumbElements.length;
    const items = [];

    for (let i = 0; i < numNodes; i++) {
      const figureEl = thumbElements[i];

      // Include only element nodes
      if (figureEl.nodeType !== 1 || figureEl.nodeName === 'DIV') {
        continue;
      }

      const linkEl = figureEl.children[0]; // <a> element

      const size = linkEl.getAttribute('data-size').split('x');

      // Create slide object
      const item = {
        originalUrl: linkEl.getAttribute('data-photourl'),
        src: linkEl.getAttribute('href'),
        w: parseInt(size[0], 10),
        h: parseInt(size[1], 10),
      };

      if (figureEl.children.length > 1) {
        // <figcaption> content
        item.title = figureEl.children[1].innerHTML;
      }

      if (linkEl.children.length > 0) {
        // <img> thumbnail element, retrieving thumbnail url
        item.msrc = linkEl.children[0].getAttribute('src');
      }

      item.el = figureEl; // save link to element for getThumbBoundsFn
      items.push(item);
    }

    return items;
  },

  onThumbnailsClick(e = window.event) {
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false; // eslint-disable-line no-param-reassign
    }

    const eTarget = e.target || e.srcElement;

    // find root element of slide
    const clickedListItem = this.closest(eTarget, el => el.tagName && el.tagName.toUpperCase() === 'FIGURE');

    if (!clickedListItem) return;

    // find index of clicked item by looping through all child nodes
    // alternatively, you may define index via data- attribute
    const clickedGallery = clickedListItem.parentNode;
    const childNodes = clickedListItem.parentNode.childNodes;
    const numChildNodes = childNodes.length;

    let nodeIndex = 0;
    let index;

    for (let i = 0; i < numChildNodes; i++) {
      if (childNodes[i].nodeType !== 1) continue;

      if (childNodes[i] === clickedListItem) {
        index = nodeIndex;
        break;
      }

      nodeIndex++;
    }

    if (index >= 0) {
      // Open PhotoSwipe if valid index found
      this.openPhotoSwipe(index, clickedGallery);
    }
  },

  // Find nearest parent element
  closest(el, cb) {
    return el && (cb(el) ? el : this.closest(el.parentNode, cb));
  },

  // Parse picture index and gallery index from URL (#&pid=1&gid=2)
  photoswipeParseHash() {
    const hash = window.location.hash.substring(1);
    const params = {};

    if (hash.length < 5) return params;

    const vars = hash.split('&');
    for (let i = 0; i < vars.length; i++) {
      if (!vars[i]) continue;
      const pair = vars[i].split('=');
      if (pair.length < 2) continue;
      // eslint-disable-next-line prefer-destructuring
      params[pair[0]] = pair[1];
    }

    if (params.gid) {
      params.gid = parseInt(params.gid, 10);
    }

    return params;
  },

  openPhotoSwipe(index, galleryElement, disableAnimation, fromURL) {
    const pswpElement = document.querySelectorAll('.pswp')[0];
    const items = this.parseThumbnailElements(galleryElement);

    const options = {
      // define gallery index (for URL)
      galleryUID: galleryElement.getAttribute('data-pswp-uid'),

      getThumbBoundsFn(idx) {
        const thumbnail = items[idx].el.getElementsByTagName('img')[0];
        const rect = thumbnail.getBoundingClientRect();
        const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;

        return {
          x: rect.left,
          y: rect.top + pageYScroll,
          w: rect.width,
        };
      },

      bgOpacity: 0.95,
      showAnimationDuration: 0,
      hideAnimationDuration: 0,
      loop: false,
      history: false,

      clickToCloseNonZoomable: false,
      closeOnScroll: false,
      shareEl: false,
    };

    // PhotoSwipe opened from URL
    if (fromURL) {
      if (options.galleryPIDs) {
        // Parse real index when custom PIDs are used
        // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
        for (let j = 0; j < items.length; j++) {
          if (parseInt(items[j].pid, 10) === parseInt(index, 10)) {
            options.index = j;
            break;
          }
        }
      } else {
        // In URL indexes start from 1
        options.index = parseInt(index, 10) - 1;
      }
    } else {
      options.index = parseInt(index, 10);
    }

    // Exit if index not found
    if (_.isNaN(options.index)) return;

    if (disableAnimation) {
      options.showAnimationDuration = 0;
    }

    // Pass data to PhotoSwipe and initialize it
    const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUIDefault, items, options);

    gallery.init();
  },
};
