// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var NoPhotoIcon = require("../../../../styleguide/icons/NoPhotoIcon.bs.js");
var SlvNoPhotosYetModuleScss = require("./SlvNoPhotosYet.module.scss");

var css = SlvNoPhotosYetModuleScss;

function SlvNoPhotosYet(Props) {
  return React.createElement("div", {
              className: css.wrapper
            }, React.createElement("div", {
                  className: css.content
                }, React.createElement(NoPhotoIcon.make, {
                      size: /* XL */4,
                      color: /* LightGray */9
                    }), React.createElement("div", {
                      className: css.label
                    }, "No photos yet :(")));
}

var make = SlvNoPhotosYet;

exports.css = css;
exports.make = make;
/* css Not a pure module */
