// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Fn = require("../../../../libs/Fn.bs.js");
var Gtm = require("../../../../libs/Gtm.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

var notifyGaOnGalleryOpened = Fn.once((function (listingId) {
        return Gtm.Slv.galleryOpened(listingId);
      }));

function SlvGallery(Props) {
  var id = Props.id;
  var listingId = Props.listingId;
  var className = Props.className;
  var isManagedByCurrentUser = Props.isManagedByCurrentUser;
  var children = Props.children;
  var notifyGaOnGalleryOpened$1 = React.useCallback((function (param) {
          return notifyGaOnGalleryOpened(listingId);
        }), ([]));
  var tmp = {
    className: Cn.$plus("fng-gallery", className),
    id: id
  };
  var tmp$1 = !isManagedByCurrentUser ? notifyGaOnGalleryOpened$1 : undefined;
  if (tmp$1 !== undefined) {
    tmp.onClick = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("div", tmp, children);
}

var make = SlvGallery;

exports.notifyGaOnGalleryOpened = notifyGaOnGalleryOpened;
exports.make = make;
/* notifyGaOnGalleryOpened Not a pure module */
