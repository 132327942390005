// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Button = require("../../../../styleguide/components/Button/Button.bs.js");
var MaskedPhone = require("../../../../styleguide/components/MaskedPhone/MaskedPhone.bs.js");
var SlvClickableItem = require("../../components/SlvClickableItem/SlvClickableItem.bs.js");
var SlvMessageOrCallHostModuleScss = require("./SlvMessageOrCallHost.module.scss");

var css = SlvMessageOrCallHostModuleScss;

function SlvMessageOrCallHost(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var viewContext = Props.viewContext;
  var phone = listing.contactPhone;
  return React.createElement("div", {
              className: css.container
            }, React.createElement(Button.make, {
                  size: /* LG */2,
                  color: /* Primary */0,
                  expanded: true,
                  onClick: (function (param) {
                      return Curry._1(booking.showMessageModal, undefined);
                    }),
                  children: "Send Inquiry"
                }), phone !== undefined ? React.createElement("div", {
                    className: css.phone
                  }, React.createElement("div", {
                        className: css.phoneNote
                      }, "Or call to make inquiry or ask questions"), React.createElement(SlvClickableItem.make, {
                        viewContext: viewContext,
                        action: /* ShowPhone */304810257,
                        listingId: listing.id,
                        children: React.createElement(MaskedPhone.make, {
                              phone: phone,
                              className: css.contactWidgetPhone,
                              onShown: booking.notifyGtmOnPhoneShow
                            })
                      })) : null);
}

var make = SlvMessageOrCallHost;

exports.css = css;
exports.make = make;
/* css Not a pure module */
