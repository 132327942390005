// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromString(x) {
  switch (x) {
    case "available_by_default" :
        return /* Available */Block.__(0, [/* ByDefault */-735444086]);
    case "available_by_manual" :
        return /* Available */Block.__(0, [/* ByHost */1056308479]);
    case "blocked_by_booking" :
        return /* Blocked */Block.__(1, [/* ByBooking */901706530]);
    case "blocked_by_external_event" :
        return /* Blocked */Block.__(1, [/* ByExternalEvent */-908087976]);
    case "blocked_by_manual" :
        return /* Blocked */Block.__(1, [/* ByHost */1056308479]);
    case "blocked_by_vrs_import" :
        return /* Blocked */Block.__(1, [/* ByVrsImport */-581577339]);
    default:
      return Pervasives.failwith("Unknown calendar day state: " + (String(x) + ""));
  }
}

function fromJson(json) {
  return fromString(Json_decode.string(json));
}

var State = {
  fromString: fromString,
  fromJson: fromJson
};

function fromJson$1(json) {
  return {
          onDate: Json_decode.field("onDate", Json_decode.string, json),
          price: Json_decode.field("price", Json_decode.$$float, json),
          morningState: Json_decode.field("morningState", fromJson, json),
          eveningState: Json_decode.field("eveningState", fromJson, json),
          minimumStay: Json_decode.field("minimumStay", Json_decode.$$int, json)
        };
}

var Guest = {
  fromJson: fromJson$1
};

exports.State = State;
exports.Guest = Guest;
/* No side effect */
