// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var LabelModuleScss = require("./Label.module.scss");

var css = LabelModuleScss;

function className(param) {
  switch (param) {
    case /* Top */0 :
        return css.top;
    case /* Right */1 :
        return css.right;
    case /* Bottom */2 :
        return css.bottom;
    case /* Left */3 :
        return css.left;
    
  }
}

var Placement = {
  className: className
};

function Label(Props) {
  var forId = Props.forId;
  var placement = Props.placement;
  var classNameOpt = Props.className;
  var children = Props.children;
  var className$1 = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    className: Cn.$plus(Cn.$plus(css.label, className(placement)), className$1)
  };
  if (forId !== undefined) {
    tmp.htmlFor = Caml_option.valFromOption(forId);
  }
  return React.createElement("label", tmp, children);
}

var make = Label;

exports.css = css;
exports.Placement = Placement;
exports.make = make;
/* css Not a pure module */
