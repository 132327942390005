// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Money = require("../../../../libs/Money.bs.js");
var React = require("react");
var Spinner = require("../../../../styleguide/components/Spinner/Spinner.bs.js");
var Tooltip = require("../../../../styleguide/components/Tooltip/Tooltip.bs.js");
var TooltipWarningIcon = require("../../../../styleguide/icons/TooltipWarningIcon.bs.js");
var SlvBookingWidgetHeadlineModuleScss = require("./SlvBookingWidgetHeadline.module.scss");

var css = SlvBookingWidgetHeadlineModuleScss;

function SlvBookingWidgetHeadline(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var match = booking.status;
  var match$1 = listing.legalRestriction;
  var tmp;
  var exit = 0;
  if (match) {
    var match$2 = match[0];
    var exit$1 = 0;
    var quote;
    if (match$2[0] >= 59168818) {
      var match$3 = match$2[1];
      if (match$3.tag) {
        tmp = match$1 !== undefined ? "Please inquire for pricing" : "Ask host for the price";
      } else {
        var match$4 = match$3[0];
        if (typeof match$4 === "number") {
          if (match$1 !== undefined) {
            tmp = "Please inquire for pricing";
          } else {
            var samplePrice = listing.samplePrice;
            tmp = samplePrice !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("span", {
                        className: css.price
                      }, Money.formatWithoutCents(samplePrice)), "per night", React.createElement(Tooltip.make, {
                        content: "Base price: actual nightly price may vary depending on the dates you choose to stay.",
                        side: /* Right */3,
                        className: css.tooltip,
                        children: React.createElement(TooltipWarningIcon.make, {
                              size: /* SM */1,
                              color: /* LighterGray */10,
                              title: "Warning"
                            })
                      })) : "Ask host for the price";
          }
        } else {
          quote = match$4[1];
          exit$1 = 2;
        }
      }
    } else {
      var match$5 = match$2[1][0];
      if (typeof match$5 === "number") {
        exit = 1;
      } else {
        quote = match$5[1][0];
        exit$1 = 2;
      }
    }
    if (exit$1 === 2) {
      tmp = match$1 !== undefined ? "Please inquire for pricing" : React.createElement(React.Fragment, undefined, React.createElement("span", {
                  className: css.price
                }, Money.formatWithoutCents(quote.pricePerNight)), "per night");
    }
    
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Spinner.make, {
          size: /* SM */0,
          color: /* White */4
        });
  }
  return React.createElement("div", {
              className: css.container
            }, tmp);
}

var make = SlvBookingWidgetHeadline;

exports.css = css;
exports.make = make;
/* css Not a pure module */
