// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Svg = require("../components/Svg/Svg.bs.js");
var React = require("react");

function Mascot(Props) {
  var titleOpt = Props.title;
  var classNameOpt = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "Mascot";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Svg.make, {
              title: title,
              viewBoxWidth: "119",
              viewBoxHeight: "159",
              className: className,
              children: React.createElement("g", {
                    fill: "none",
                    fillRule: "evenodd"
                  }, React.createElement("path", {
                        d: "M9.18220517,102.163041 C9.14867687,103.247321 9.1520297,104.331602 9.1520297,105.415883 C9.1520297,106.500163 9.17885234,107.627288 9.19896932,108.737934 C9.2190863,109.930972 9.49066552,111.12401 9.03468065,112.280796 C8.67986019,113.22477 8.00493261,114.019715 7.1235676,114.531749 C6.06018485,115.111204 4.77887374,115.15531 3.67685845,114.650394 C3.26878177,114.46887 2.89028206,114.228982 2.55366043,113.938526 C2.35360726,113.768401 2.16957356,113.580886 2.00379633,113.378259 C1.6017964,112.915828 1.32706618,112.359847 1.20582281,111.763373 C1.02812282,110.636249 0.199973836,106.2431 0.0490964899,103.039693 C-0.919871354,82.7811137 12.6926181,77.148787 19.7670892,75.5899277 C21.1209491,75.2778552 22.4995431,75.0804895 23.8877172,75 L26,87.0061827 C8.26352977,90.1931107 9.27943723,98.8212157 9.18220517,102.163041 Z",
                        fill: "#F7BE9B",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M3.69913729,115 C3.28858798,114.81411 2.90779486,114.568452 2.56913358,114.271009 C2.36786825,114.096793 2.18271945,113.904767 2.01593774,113.697266 C1.61150202,113.223712 1.33510714,112.654358 1.21312914,112.043537 C1.03435243,110.889301 0.201185518,106.390483 0.0493939755,103.110024 C-0.925445043,82.3641579 12.7695252,76.596355 19.886862,75 L21,77.720216 C3.15943403,80.9838002 2.87946296,98.7900776 2.78501489,102.212285 C2.74791029,103.332771 3.52710687,113.40702 3.69913729,115 Z",
                        fill: "#FCD4BF",
                        fillRule: "nonzero"
                      }), React.createElement("ellipse", {
                        cx: "41.5",
                        cy: "156.5",
                        fill: "#3F3F44",
                        fillRule: "nonzero",
                        opacity: "0.21",
                        rx: "35.5",
                        ry: "2.5"
                      }), React.createElement("rect", {
                        height: "5",
                        width: "10",
                        fill: "#F6BD99",
                        fillRule: "nonzero",
                        x: "53",
                        y: "143"
                      }), React.createElement("rect", {
                        height: "5",
                        width: "10",
                        fill: "#F6BD99",
                        fillRule: "nonzero",
                        x: "53",
                        y: "148"
                      }), React.createElement("path", {
                        d: "M67,156.565385 L67,157 L54.317095,157 C53.5912849,157 53,155.973077 53,154.692308 L53,152 L64.390838,152 C65.8330726,152.019231 67,154.053846 67,156.565385 Z",
                        fill: "#F6BD99",
                        fillRule: "nonzero"
                      }), React.createElement("rect", {
                        height: "5",
                        width: "10",
                        fill: "#F6BD99",
                        fillRule: "nonzero",
                        x: "18",
                        y: "143"
                      }), React.createElement("rect", {
                        height: "5",
                        width: "10",
                        fill: "#F6BD99",
                        fillRule: "nonzero",
                        x: "18",
                        y: "148"
                      }), React.createElement("path", {
                        d: "M13,156.565385 L13,157 L27.4943581,157 C28.3255269,157 29,155.973077 29,154.692308 L29,152 L15.9908452,152 C14.3387268,152.019231 13,154.053846 13,156.565385 Z",
                        fill: "#F6BD99",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M41.4991094,132.972122 L41.4725624,132.972122 C33.0770773,133.315644 13.6181348,130.593643 12.0983197,118.697979 C12.0233023,118.135147 11.9911348,117.567568 12.0020869,117 L70.952993,117 L70.952993,117.065433 C70.952993,117.552906 70.9861768,118.099269 70.9961319,118.70125 C71.085728,123.674135 69.8579298,131.80742 41.4991094,132.972122 Z",
                        fill: "#5D8A6F",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M40,133 L40,117 L68.8589177,117 L68.9535264,117.06556 C68.9535264,117.553985 68.9861501,118.101414 68.9959372,118.704569 C69.0872836,123.683876 67.880207,131.833026 40,133 Z",
                        fill: "#437557",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M52.5782117,144 L62.9615319,144 C62.9615319,144 74.7883624,141.486003 69.7510087,117 L43.9237858,117 C43.9237858,117 38.8267005,138.847128 52.5782117,144 Z",
                        fill: "#D41034",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M28.4500535,144 L18.0962055,143.933086 C18.0962055,143.933086 6.66327994,141.032342 10.9696598,117 L37.0826735,117 C37.0826735,117 42.1536758,138.753903 28.4500535,144 Z",
                        fill: "#FF395D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M71,109.245092 C71,110.174499 70.9637661,111.09058 70.8912984,112 L11.0988197,112 C11.0988197,111.953363 11.0988197,111.903395 11.0988197,111.856758 C11.0329399,110.995086 11,110.12453 11,109.245092 C11.006137,107.49934 11.122763,105.755775 11.3491628,104.02509 C12.9467472,91.5230704 19.9135328,76.6858743 29.3013999,69.4305054 C31.0152839,68.0839747 32.9095738,66.9904144 34.9275323,66.1825785 C35.9783335,65.7694151 37.0659538,65.4592987 38.1754049,65.2565029 C39.1048258,65.0868086 40.0473188,65.00097 40.991765,65 C42.8201672,65.0055208 44.6348349,65.3196391 46.3609662,65.9294068 C47.9849961,66.495609 49.5338585,67.2620079 50.9725501,68.2112836 C60.5514686,74.4473031 67.9003568,88.6815508 70.2226187,101.316819 C70.7214253,103.929903 70.9816472,106.583827 71,109.245092 Z",
                        fill: "#F7BE9B",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M71,110.245677 C71,111.174887 70.9635762,112.090774 70.8907285,113 L40.8576159,113 L40.8576159,91.4749858 L38,66.2564484 C38.9342937,66.0867901 39.881728,66.0009698 40.8311258,66 C42.669114,66.0055196 44.4932956,66.3195712 46.2284768,66.9292092 C47.8610212,67.495291 49.418004,68.2615271 50.8642384,69.2106009 C60.4933775,75.4452948 67.8807947,89.6765164 70.2152318,102.309099 C70.718371,104.924803 70.9810686,107.581537 71,110.245677 L71,110.245677 Z",
                        fill: "#F7BE9B",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M71,112 C70.9636404,112.552194 70.9140591,113.096823 70.8578669,113.641452 C70.7355663,114.776097 70.5669899,115.910741 70.3620538,117 L11.6379462,117 C11.3292313,115.352515 11.1161172,113.683464 11,112.003782 L71,112 Z",
                        fill: "#D41034",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M46,68.9120031 C43.925072,77.73784 40.3907781,93 40.3907781,93 L34,69.1604341 C36.02784,68.404247 38.1866618,68.0107587 40.3665706,68 C42.284968,68.005781 44.1889072,68.3140121 46,68.9120031 L46,68.9120031 Z",
                        fill: "#F7BE9B",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M43.8934284,103.899819 C43.7582191,103.888313 43.6470256,103.802523 43.6182125,103.687479 C43.5571093,103.586681 43.5247886,103.474657 43.5239604,103.360802 C43.5239604,103.164796 43.4787195,103.034125 43.7426252,103.034125 C44.1573341,103.034125 44.0517718,103.703813 43.9726001,103.955354 C43.7630962,104.605752 43.1273958,105.085965 42.3552352,105.177125 C41.7331718,105.262061 41.9970775,106.078752 42.6153708,105.993816 C43.8189191,105.80698 44.7598953,104.989017 44.9716717,103.945554 C45.1300151,103.154996 44.6285943,101.67515 43.365617,102.063896 C42.3250746,102.390572 42.3363848,103.814883 42.9886089,104.419235 C43.7916362,105.14119 45.2506577,104.419235 44.7190762,103.465339 C44.4438603,102.975324 43.5880518,103.40327 43.874578,103.893285 L43.8934284,103.899819 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M36.4187339,99.8771839 C37.2895024,99.2946931 37.7226496,98.4437759 37.5831974,97.5895964 C37.4887814,96.9850197 36.9177897,95.8444939 35.8117742,96.017697 C34.7057587,96.1909001 34.9125746,97.1745627 35.3621744,97.6810999 C36.0376233,98.4589847 37.1799887,98.9584039 38.441933,99.0275086 C39.1882686,99.0830643 39.1837726,98.2333889 38.441933,98.1778332 C37.798712,98.1334155 37.1973036,97.9264089 36.7424458,97.5928644 C36.5388102,97.4010261 36.354017,97.1990007 36.189438,96.9882877 L36.189438,96.8085487 C35.9076888,96.8085487 35.8867075,96.876087 36.126494,97.0111636 C36.3390976,97.3530578 36.4479683,97.7242087 36.4457099,98.0994016 C36.379537,98.5555063 36.0793429,98.9767383 35.6049583,99.2791432 C35.0519505,99.6484252 35.8792142,100.259538 36.4322219,99.8804519 L36.4187339,99.8771839 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M37.1191152,87.4166109 C36.9727832,88.3878617 36.846775,89.4921606 37.4768157,90.3037538 C37.9645891,90.9290797 38.8872938,91.2838745 39.5335936,90.7117678 C40.021098,90.2526832 40.1420913,89.4810673 39.8221929,88.8712697 C39.3100308,87.9842826 38.3629374,88.4277761 37.7898036,88.9688382 C37.2166699,89.5099003 38.0255608,90.2682743 38.5377229,89.7848663 C38.663731,89.6651231 38.9238768,89.3413728 38.9442007,89.5985991 C38.9645246,89.8558253 38.7653505,89.8691301 38.5986946,89.8159109 C37.8426458,89.5808593 38.0418199,88.3257726 38.1353098,87.7226214 C38.2450588,86.9997269 37.2288642,86.6848465 37.1191152,87.4166109 L37.1191152,87.4166109 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M49.1191152,87.4166109 C48.9727832,88.3878617 48.846775,89.4921606 49.4768157,90.3037538 C49.9645891,90.9290797 50.8872938,91.2838745 51.5335936,90.7117678 C52.021098,90.2526832 52.1420913,89.4810673 51.8221929,88.8712697 C51.3100308,87.9842826 50.3629374,88.4277761 49.7898036,88.9688382 C49.2166699,89.5099003 50.0255608,90.2682743 50.5377229,89.7848663 C50.663731,89.6651231 50.9238768,89.3413728 50.9442007,89.5985991 C50.9645246,89.8558253 50.7653505,89.8691301 50.5986946,89.8159109 C49.8426458,89.5808593 50.0418199,88.3257726 50.1353098,87.7226214 C50.2450588,86.9997269 49.2288642,86.6848465 49.1191152,87.4166109 L49.1191152,87.4166109 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M52.0802838,102.214576 C51.9802601,102.706938 51.8956248,103.271892 52.33419,103.675881 C52.658467,104.024552 53.2588728,104.103642 53.6998975,103.855783 C54.0311623,103.615522 54.0967417,103.212007 53.85378,102.908933 C53.4960031,102.495475 52.8958612,102.722719 52.5226961,102.987837 C52.1495309,103.252955 52.6804257,103.638007 53.0189672,103.394982 C53.0805203,103.350796 53.2805676,103.170895 53.2959558,103.303454 C53.3113441,103.436013 53.1459203,103.439169 53.0343555,103.398139 C52.5688608,103.24033 52.6958139,102.672221 52.7573669,102.362916 C52.8304611,101.999957 52.153378,101.842149 52.0802838,102.208264 L52.0802838,102.214576 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M36.0027034,101.499544 C35.9777632,102.408504 36.1131527,103.344311 36.9254897,103.800708 C37.2484971,104.001406 37.6331344,104.053097 37.9920662,103.944046 C38.350998,103.834995 38.6537686,103.574454 38.8316314,103.221582 C39.0657979,102.809987 39.0549379,102.289064 38.8038542,101.889192 C38.5527704,101.489319 38.1077818,101.284264 37.665685,101.364712 C37.2235882,101.44516 36.8659391,101.79627 36.7509085,102.262764 C36.647585,102.887913 37.5383054,103.156382 37.641629,102.527397 C37.6570018,102.445815 37.7091892,102.377875 37.7807409,102.346296 C37.8522925,102.314717 37.9337656,102.323666 37.9979171,102.370151 C38.1760612,102.500551 38.0192944,102.834219 37.880342,102.937772 C37.6239526,103.06845 37.3174779,102.984767 37.1499513,102.738338 C36.8756094,102.404669 36.918364,101.894577 36.9290526,101.480368 C36.9290526,100.839877 36.0205178,100.839877 36.0027034,101.480368 L36.0027034,101.499544 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M45.0027034,91.4995443 C44.9777632,92.408504 45.1131527,93.3443106 45.9254897,93.8007081 C46.2484971,94.0014057 46.6331344,94.0530975 46.9920662,93.9440464 C47.350998,93.8349953 47.6537686,93.5744544 47.8316314,93.2215819 C48.0657979,92.8099871 48.0549379,92.2890642 47.8038542,91.8891917 C47.5527704,91.4893193 47.1077818,91.284264 46.665685,91.3647118 C46.2235882,91.4451596 45.8659391,91.7962698 45.7509085,92.2627636 C45.647585,92.8879132 46.5383054,93.1563823 46.641629,92.5273975 C46.6570018,92.4458145 46.7091892,92.3778747 46.7807409,92.3462957 C46.8522925,92.3147167 46.9337656,92.3236661 46.9979171,92.3701513 C47.1760612,92.5005506 47.0192944,92.8342193 46.880342,92.9377717 C46.6239526,93.0684501 46.3174779,92.9847668 46.1499513,92.7383375 C45.8756094,92.4046687 45.918364,91.8945774 45.9290526,91.4803679 C45.9290526,90.8398774 45.0205178,90.8398774 45.0027034,91.4803679 L45.0027034,91.4995443 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M42.0027034,86.4995443 C41.9777632,87.408504 42.1131527,88.3443106 42.9254897,88.8007081 C43.2484971,89.0014057 43.6331344,89.0530975 43.9920662,88.9440464 C44.350998,88.8349953 44.6537686,88.5744544 44.8316314,88.2215819 C45.0657979,87.8099871 45.0549379,87.2890642 44.8038542,86.8891917 C44.5527704,86.4893193 44.1077818,86.284264 43.665685,86.3647118 C43.2235882,86.4451596 42.8659391,86.7962698 42.7509085,87.2627636 C42.647585,87.8879132 43.5383054,88.1563823 43.641629,87.5273975 C43.6570018,87.4458145 43.7091892,87.3778747 43.7807409,87.3462957 C43.8522925,87.3147167 43.9337656,87.3236661 43.9979171,87.3701513 C44.1760612,87.5005506 44.0192944,87.8342193 43.880342,87.9377717 C43.6239526,88.0684501 43.3174779,87.9847668 43.1499513,87.7383375 C42.8756094,87.4046687 42.918364,86.8945774 42.9290526,86.4803679 C42.9290526,85.8398774 42.0205178,85.8398774 42.0027034,86.4803679 L42.0027034,86.4995443 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M38.5726355,94.6985027 C38.9836078,93.7193193 39.2601499,92.6333158 38.6225667,91.6941899 C38.3793968,91.3134433 38.0110678,91.0663237 37.6050497,91.0115166 C37.1990316,90.9567094 36.7915473,91.0991043 36.4793654,91.4048857 C36.0967861,91.7461896 35.9220878,92.3154475 36.0327974,92.8600368 C36.143507,93.4046261 36.5191148,93.8236578 36.9929353,93.931176 C37.4667557,94.0386941 37.9510103,93.8147802 38.2307988,93.3588017 C38.614885,92.7490375 37.7353275,92.1704291 37.3704455,92.7757425 C37.30131,92.8870134 37.0631765,92.7757425 37.0170862,92.6911767 C36.8672925,92.4241266 37.2475379,92.1303716 37.4511036,92.1392733 C37.723863,92.1948557 37.9407416,92.4356321 38.000347,92.7490375 C38.0518282,93.230171 37.9523146,93.7167161 37.719964,94.1198943 C37.4434219,94.7875194 38.2999343,95.3750295 38.5803173,94.7029536 L38.5726355,94.6985027 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M49.5726355,99.6985027 C49.9836078,98.7193193 50.2601499,97.6333158 49.6225667,96.6941899 C49.3793968,96.3134433 49.0110678,96.0663237 48.6050497,96.0115166 C48.1990316,95.9567094 47.7915473,96.0991043 47.4793654,96.4048857 C47.0967861,96.7461896 46.9220878,97.3154475 47.0327974,97.8600368 C47.143507,98.4046261 47.5191148,98.8236578 47.9929353,98.931176 C48.4667557,99.0386941 48.9510103,98.8147802 49.2307988,98.3588017 C49.614885,97.7490375 48.7353275,97.1704291 48.3704455,97.7757425 C48.30131,97.8870134 48.0631765,97.7757425 48.0170862,97.6911767 C47.8672925,97.4241266 48.2475379,97.1303716 48.4511036,97.1392733 C48.723863,97.1948557 48.9407416,97.4356321 49.000347,97.7490375 C49.0518282,98.230171 48.9523146,98.7167161 48.719964,99.1198943 C48.4434219,99.7875194 49.2999343,100.375029 49.5803173,99.7029536 L49.5726355,99.6985027 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M33.5726355,98.6985027 C33.9836078,97.7193193 34.2601499,96.6333158 33.6225667,95.6941899 C33.3793968,95.3134433 33.0110678,95.0663237 32.6050497,95.0115166 C32.1990316,94.9567094 31.7915473,95.0991043 31.4793654,95.4048857 C31.0967861,95.7461896 30.9220878,96.3154475 31.0327974,96.8600368 C31.143507,97.4046261 31.5191148,97.8236578 31.9929353,97.931176 C32.4667557,98.0386941 32.9510103,97.8147802 33.2307988,97.3588017 C33.614885,96.7490375 32.7353275,96.1704291 32.3704455,96.7757425 C32.30131,96.8870134 32.0631765,96.7757425 32.0170862,96.6911767 C31.8672925,96.4241266 32.2475379,96.1303716 32.4511036,96.1392733 C32.723863,96.1948557 32.9407416,96.4356321 33.000347,96.7490375 C33.0518282,97.230171 32.9523146,97.7167161 32.719964,98.1198943 C32.4434219,98.7875194 33.2999343,99.3750295 33.5803173,98.7029536 L33.5726355,98.6985027 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M51.0027034,92.4995443 C50.9777632,93.408504 51.1131527,94.3443106 51.9254897,94.8007081 C52.2484971,95.0014057 52.6331344,95.0530975 52.9920662,94.9440464 C53.350998,94.8349953 53.6537686,94.5744544 53.8316314,94.2215819 C54.0657979,93.8099871 54.0549379,93.2890642 53.8038542,92.8891917 C53.5527704,92.4893193 53.1077818,92.284264 52.665685,92.3647118 C52.2235882,92.4451596 51.8659391,92.7962698 51.7509085,93.2627636 C51.647585,93.8879132 52.5383054,94.1563823 52.641629,93.5273975 C52.6570018,93.4458145 52.7091892,93.3778747 52.7807409,93.3462957 C52.8522925,93.3147167 52.9337656,93.3236661 52.9979171,93.3701513 C53.1760612,93.5005506 53.0192944,93.8342193 52.880342,93.9377717 C52.6239526,94.0684501 52.3174779,93.9847668 52.1499513,93.7383375 C51.8756094,93.4046687 51.918364,92.8945774 51.9290526,92.4803679 C51.9290526,91.8398774 51.0205178,91.8398774 51.0027034,92.4803679 L51.0027034,92.4995443 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M40.0033637,108.324329 C40.0033637,108.933126 40.0773652,109.560042 40.6256487,109.871688 C40.844532,110.005437 41.1048859,110.036159 41.3454536,109.956626 C41.5860212,109.877092 41.7855624,109.694326 41.8971284,109.451329 C42.0467126,109.173931 42.0321834,108.827986 41.8599574,108.566275 C41.6877314,108.304564 41.389526,108.175284 41.0970399,108.235531 C40.8045538,108.295778 40.5716517,108.534456 40.5011917,108.846155 C40.4238266,109.262891 41.0158383,109.440457 41.0932034,109.023721 C41.1017341,108.972046 41.133324,108.928204 41.1776757,108.906486 C41.2220275,108.884767 41.2734599,108.887954 41.3152078,108.915007 C41.4632108,109.016474 41.3152078,109.277387 41.2042056,109.320872 C41.0173506,109.376505 40.8192757,109.288463 40.7231961,109.107068 C40.6155881,108.866166 40.5780686,108.595853 40.6155576,108.331577 C40.6155576,108.148452 40.4777604,108 40.3077788,108 C40.1377973,108 40,108.148452 40,108.331577 L40.0033637,108.324329 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M31.011315,106.769266 C31.1580735,107.839312 31.395477,108.950514 32.2846609,109.613577 C32.6483649,109.913875 33.1103438,110.047567 33.5676469,109.984861 C34.02495,109.922155 34.4395757,109.668261 34.7191257,109.279759 C34.9731718,108.927353 35.0609945,108.471148 34.9575004,108.041493 C34.8540063,107.611838 34.5705803,107.255998 34.1882052,107.075647 C33.1479463,106.618362 32.2630788,107.427756 31.658779,108.232577 C31.2271364,108.822474 32.0040932,109.668451 32.4530016,109.073981 C32.72062,108.717299 33.0659342,108.159411 33.5364247,108.159411 C34.0069152,108.159411 33.9205867,108.680716 33.5752726,108.822474 C33.1436299,109.005388 32.6558736,108.474938 32.4745837,108.104537 C32.2551658,107.574482 32.1237442,107.008286 32.0861053,106.430876 C31.982511,105.676356 30.9034043,105.996455 31.0069986,106.746402 L31.011315,106.769266 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M30.8660592,92.7792585 C31.3542241,91.925674 31.6749163,90.8993403 31.1760617,89.9780111 C30.8767489,89.425891 29.9467413,88.6231151 29.294667,89.1989459 C28.6425928,89.7747767 29.2055859,90.8044976 29.7044405,91.2719367 C30.459849,91.9798698 31.5430762,92.1322956 32.5550385,92.0611636 C33.1465375,92.0205167 33.1501008,91.1398344 32.5550385,91.1804812 C31.5502027,91.2516133 30.374331,91.0958002 29.9716841,90.076241 C29.8576601,89.7849384 29.9716841,89.906879 30.1249037,90.076241 C30.2818316,90.2365684 30.3955388,90.4306665 30.4562857,90.6419101 C30.6201951,91.2245154 30.3493883,91.8240568 30.0643285,92.33553 C29.7792687,92.8470033 30.5774361,93.2771827 30.8624959,92.7792585 L30.8660592,92.7792585 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M25.0756357,95.7834818 C24.901539,96.0865088 24.6345907,96.5915538 24.2322339,96.5673116 C23.8298771,96.5430694 24.0271867,96.0703473 24.3096102,96.0380245 C24.6616725,95.9976209 24.8899326,96.4905448 24.9131455,96.785491 C24.8886376,97.2888334 24.6711021,97.7606711 24.3096102,98.0945677 C23.8685652,98.5874916 24.5765585,99.3309178 25.0214723,98.8379939 C25.7101215,98.0622448 26.2362805,96.9349844 25.6907774,95.9087329 C25.2613389,95.1006609 24.2167587,94.6562214 23.4855525,95.3269211 C22.8704108,95.8925715 22.781428,96.9794283 23.55906,97.4400293 C24.5494768,98.0299219 25.4663861,97.1531638 25.9461193,96.3127689 C26.0656349,96.0650751 25.9801376,95.7630244 25.7508768,95.6230028 C25.521616,95.4829812 25.2276201,95.5532554 25.0795046,95.7834818 L25.0756357,95.7834818 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M42.0756357,95.7834818 C41.901539,96.0865088 41.6345907,96.5915538 41.2322339,96.5673116 C40.8298771,96.5430694 41.0271867,96.0703473 41.3096102,96.0380245 C41.6616725,95.9976209 41.8899326,96.4905448 41.9131455,96.785491 C41.8886376,97.2888334 41.6711021,97.7606711 41.3096102,98.0945677 C40.8685652,98.5874916 41.5765585,99.3309178 42.0214723,98.8379939 C42.7101215,98.0622448 43.2362805,96.9349844 42.6907774,95.9087329 C42.2613389,95.1006609 41.2167587,94.6562214 40.4855525,95.3269211 C39.8704108,95.8925715 39.781428,96.9794283 40.55906,97.4400293 C41.5494768,98.0299219 42.4663861,97.1531638 42.9461193,96.3127689 C43.0656349,96.0650751 42.9801376,95.7630244 42.7508768,95.6230028 C42.521616,95.4829812 42.2276201,95.5532554 42.0795046,95.7834818 L42.0756357,95.7834818 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M29.8984422,103.913397 C30.9061285,103.294712 31.7645279,102.38591 31.5281571,101.267383 C31.3581359,100.466937 30.1721347,99.4148234 29.3717913,100.39703 C27.9452723,102.144727 30.9517439,104.710348 32.8468577,103.043044 C33.3278932,102.620101 32.5648714,101.994425 32.083836,102.399891 C31.1051776,103.263254 30.0311416,102.050352 30.1514004,101.200971 C30.192869,100.903862 30.2177502,100.93532 30.3587433,101.138053 C30.504508,101.390277 30.5354916,101.678799 30.4458273,101.948985 C30.2580626,102.438489 29.8719857,102.856716 29.3552038,103.130429 C28.799525,103.479968 29.3552038,104.259442 29.8984422,103.913397 L29.8984422,103.913397 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M57.7740433,97.7717301 L57.7414934,97.7448539 L57.7984557,98.1710332 L57.7455621,98.2209461 L57.4607506,98.2555012 L57.5136441,98.2555012 L57.2410388,97.9176293 C57.2613825,98.1825515 56.4883225,98.4282765 56.2604733,98.4205976 C56.0326241,98.4129187 55.5932005,98.144157 55.7234001,97.8485191 C55.8902183,97.4645738 56.0773802,98.3553269 56.0692427,98.4858683 C56.0498432,98.8245965 55.9059705,99.1463597 55.662369,99.3958187 C55.3368701,99.7260117 55.8617371,100.221301 56.187236,99.8911082 C56.8003232,99.2933691 56.9921633,98.4151519 56.6795532,97.6373492 C56.5688223,97.2898008 56.2482037,97.0386835 55.8658059,97 C55.4955512,97.0142495 55.172477,97.2413762 55.0520586,97.5720785 C54.9539672,97.886871 54.9956268,98.2257629 55.1674799,98.511006 C55.3393329,98.7962492 55.6266284,99.0033599 55.9634555,99.084823 C56.651072,99.2384011 58.0629236,98.7277539 57.9978238,97.9329871 C57.9872334,97.7788773 57.879826,97.6457546 57.7252184,97.5951152 C57.5107844,97.5231771 57.2734502,97.616079 57.175939,97.8101246 C57.0935177,97.9485428 57.1166392,98.1215327 57.2329013,98.2363039 L57.2654512,98.2631801 C57.6112938,98.5741758 58.1402296,98.0788863 57.7903182,97.7678906 L57.7740433,97.7717301 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M47.273911,108.051489 C47.2170656,108.58347 46.7780931,108.239517 46.7496704,107.845117 C46.7395399,107.616987 46.7746084,107.389415 46.8507288,107.189313 C47.0149488,106.794914 47.2581206,106.923323 47.3623371,107.28562 C47.6402478,108.248689 47.1002168,109.523609 46.3454367,109.083349 C46.0012064,108.881563 45.8527768,109.693292 46.1906909,109.890492 C46.5702009,110.082474 46.9888179,110.01988 47.3334253,109.719625 C47.6780327,109.419369 47.9130617,108.912442 47.9750039,108.335824 C48.0981688,107.418615 47.7697289,105.708022 46.9107323,106.042803 C46.1970071,106.313379 45.9348868,108.028559 46.4212305,108.794428 C46.8317804,109.427302 47.7286739,109.253032 47.8297324,108.294549 C47.8865777,107.767155 47.3307564,107.537853 47.273911,108.069833 L47.273911,108.051489 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M78.6819814,121.778102 C104.801417,108.644961 122.579884,91.3833153 118.38767,83.2222422 C114.456215,75.5639997 92.5925118,78.6405291 68.3351678,89.8847478 C62.7689499,91.955616 44.3452698,103.672892 37.1643472,109.01216 C34.2659021,111.410529 31.5680415,113.282912 26.6971829,119.502132 C22.2742962,125.149052 22.5517713,128.374446 23.7920517,130.779432 C26.0185389,135.132886 34.0519454,136.01284 45.0138846,133.905583 C55.1333692,132.10267 74.7137528,123.776192 78.6819814,121.778102 Z",
                        fill: "#00ADAC",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M96.7297463,80 L96.7297463,80 C88.9549724,81.4746759 79.7193013,84.5783924 70,88.8973207 L72.0712718,90.1617978 C80.8401013,95.5175932 85.8819736,105.209455 85.1726499,115.34624 L84.9858685,118 C94.2682349,112.827139 102.22979,107.368539 108,102.06102 L108,101.716162 C107.899339,93.1401004 103.729033,85.1045181 96.7297463,80 L96.7297463,80 Z",
                        fill: "#FFB16F",
                        fillRule: "nonzero"
                      }), React.createElement("g", {
                        fill: "#000000",
                        fillRule: "nonzero",
                        opacity: "0.09",
                        transform: "translate(24.000000, 82.000000)"
                      }, React.createElement("path", {
                            d: "M21.2587413,50.5913924 C31.3101399,48.8010127 50.7652098,40.5172152 54.708042,38.5293671 C80.6603147,25.4635443 98.325,8.29037975 94.1596154,0.171139241 C94.133042,0.121772152 94.1064685,0.0756962025 94.0798951,0.0263291139 L0,47.1324051 C0.0531468531,47.2508861 0.109615385,47.3660759 0.169405594,47.4812658 C2.39493007,51.8124051 10.3736014,52.6878481 21.2587413,50.5913924 Z"
                          })), React.createElement("path", {
                        d: "M72.1664,102.163041 C72.1986554,103.247321 72.1954299,104.331602 72.1954299,105.415883 C72.1954299,106.500163 72.1696256,107.627288 72.1502723,108.737934 C72.1309191,109.930972 71.8696505,111.12401 72.3083237,112.280796 C72.6496733,113.22477 73.2989771,114.019715 74.1468808,114.531749 C75.1698919,115.111204 76.4025576,115.15531 77.4627346,114.650394 C77.8553185,114.46887 78.2194484,114.228982 78.5432901,113.938526 C78.7357481,113.768401 78.912795,113.580886 79.0722784,113.378259 C79.4590163,112.915828 79.7233163,112.359847 79.8399566,111.763373 C80.0109102,110.636249 80.8076182,106.2431 80.9527675,103.039693 C81.8849482,82.7811137 68.789261,77.148787 61.9833743,75.5899277 C60.6851387,75.2785989 59.3632308,75.0812378 58.0320894,75 L56,87.0061827 C73.0469721,90.1931107 72.0696339,98.8212157 72.1664,102.163041 Z",
                        fill: "#F7BE9B",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M78.3008627,115 C78.711412,114.81411 79.0922051,114.568452 79.4308664,114.271009 C79.6321318,114.096793 79.8172806,113.904767 79.9840623,113.697266 C80.388498,113.223712 80.6648929,112.654358 80.7868709,112.043537 C80.9656476,110.889301 81.7988145,106.390483 81.950606,103.110024 C82.925445,82.3641579 69.2304748,76.596355 62.113138,75 L61,77.720216 C78.840566,80.9838002 79.120537,98.7900776 79.2149851,102.212285 C79.2520897,103.332771 78.4728931,113.40702 78.3008627,115 Z",
                        fill: "#FCD4BF",
                        fillRule: "nonzero"
                      }), React.createElement("polyline", {
                        fill: "#000000",
                        fillRule: "nonzero",
                        opacity: "0.1",
                        points: "41 63.5 41 63.5 42 63.5"
                      }), React.createElement("path", {
                        d: "M67,72 C67,72 61.5241243,86.8292609 41.8578982,85.9635162 C41.6331791,85.9635162 41.1738268,85.9635162 41.1738268,85.9635162 C20.5855915,85.527262 17,73.2783263 17,73.2783263",
                        fill: "#000000",
                        fillRule: "nonzero",
                        opacity: "0.1"
                      }), React.createElement("path", {
                        d: "M64.6413381,12.0241232 C59.2721466,6.97501015 51.6866221,4.57865377 43.6444998,4.0921507 C42.6024345,4.02859849 41.5548144,3.99791812 40.5016397,4 C21.9977593,4 7,18.3420888 7,36.0238459 C6.99608084,39.6878202 7.64938471,43.3235896 8.92972711,46.7630726 C9.93233804,49.4017728 10.5419164,52.1698631 10.7394546,54.98103 C11.1960525,62.1010683 14.1622725,67.3474123 18.3383104,69.4019017 C35.8623383,78.014321 42.9746006,78.3824855 62.664969,69.4019017 C67.5409014,67.1764788 69.7572344,62.1700992 70.2504934,55.142102 C70.4241472,52.2079332 71.0385318,49.3154529 72.0735522,46.5592673 C72.5705136,45.2747278 72.9726136,43.9564145 73.2767044,42.6146478 C73.7622309,40.4561021 74.0047735,38.251304 74,36.0402818 C74.016593,27.0991442 70.7837465,17.8260011 64.6413381,12.0241232 Z",
                        fill: "#FCD4BF",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M64.6787804,12.0217271 C59.3576134,6.97205312 51.8245364,4.57543051 43.8444416,4.09216093 C42.8091264,4.02860167 41.7682924,3.99791789 40.7219397,4 C37.8176599,3.99750222 34.9247937,4.36094163 32.1127088,5.08171293 C27.0233288,26.7302175 32.1623774,56.6271804 36.6623562,75.7870111 C44.1225859,76.7075246 51.2881151,74.6560945 62.7284585,69.4025926 C67.5728065,67.1769225 69.7747829,62.1699866 70.2648468,55.1412087 C70.4399166,52.2039896 71.0536823,49.3089105 72.0860303,46.5508453 C72.5797615,45.2661594 72.9792567,43.9477004 73.2813889,42.6057876 C73.7637969,40.4470065 74.0047687,38.2419605 74,36.0306912 C74.006551,27.0984229 70.7979569,17.8242496 64.6787804,12.0217271 Z",
                        fill: "#F7BE9B",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M70,49.8244593 C70.1864372,47.0303375 70.8460493,44.2759146 71.9572622,41.651281 C72.4908071,40.4280522 72.9225075,39.1726616 73.2489837,37.894937 C73.3777981,37.7540741 76.1115245,34.7896927 80.0010017,36.5645652 C83.9727769,38.3770012 82.6989463,51.6713284 70,49.8244593 Z",
                        fill: "#F6BD99",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M13,49.8244593 C12.8135875,47.0303375 12.1540626,44.2759146 11.0429968,41.651281 C10.5095225,40.4280522 10.0778792,39.1726616 9.75144616,37.894937 C9.62264887,37.7540741 6.88928419,34.7896927 3.00032161,36.5645652 C-0.974505834,38.3770012 0.302733946,51.6713284 13,49.8244593 Z",
                        fill: "#FCD4BF",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M50,30 C50,30 51.4573816,24.6213005 56.4412256,23.2271891 C61.4250696,21.8330776 62,27.3106503 62,27.3106503 C62,27.3106503 58.1793872,26.3515807 50,30 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M33,28 C33,28 31.498749,22.5670245 26.5112594,21.2129496 C21.5237698,19.8588747 21,25.4052456 21,25.4052456 C21,25.4052456 24.8065054,24.3746812 33,28 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M77.5514491,54.3617048 C77.5514491,54.3617048 86.5132988,18.243505 63.5213369,4.16084187 C60.7034357,2.42362779 49.1315628,0.108443262 41.9449248,0.0093626118 C37.4277488,-0.0728145858 32.9169713,0.386218167 28.5087496,1.37667559 C28.5087496,1.37667559 22.8993444,2.60197297 20.3652132,4.14432842 C-2.62344912,18.2402023 6.33840056,54.3451913 6.33840056,54.3451913 C3.42029449,55.5465036 1.39173224,58.2483758 1.05062401,61.3880595 C0.709515775,64.5277432 2.1104382,67.6028953 4.70235554,69.4039423 C7.29427288,71.2049892 10.6624836,71.4437671 13.482143,70.0263556 C15.0145518,69.3638952 16.2565417,68.1700179 16.9797722,66.6642189 C17.4390743,65.867862 17.7666699,65.0023918 17.9498693,64.1013327 C19.3753181,58.7311615 18.3128308,51.2208482 16.7322984,46.4286474 C13.5217388,36.7088355 14.3037559,25.6778565 23.5526752,15.2842962 C32.8015944,4.89073599 41.8888308,17.7514044 41.9251269,17.5598485 C41.9251269,17.5103082 41.9251269,17.4640705 41.9482245,17.4145302 C41.9482245,17.4640705 41.9482245,17.5103082 41.971322,17.5598485 C42.0076182,17.7547071 51.0915549,4.89073599 60.3404742,15.2842962 C69.5893934,25.6778565 71.6120789,37.593956 68.404819,47.3203732 C66.8242866,52.112574 64.5178312,58.7410695 65.9465797,64.1079381 C66.1285803,65.0088497 66.4550679,65.8743366 66.9133772,66.6708242 C67.6370725,68.1735628 68.8776582,69.3648713 70.4077067,70.0263556 C73.2320849,71.4973391 76.6367379,71.2893009 79.261373,69.4853604 C81.886008,67.68142 83.3027078,64.5756894 82.9454452,61.4090302 C82.5881825,58.242371 80.5152051,55.5310714 77.5547488,54.3584021 L77.5514491,54.3617048 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M41.44,10 C41.35,9.75675676 41.27,9.50675676 41.17,9.27027027 L41.44,9.16216216 L41.83,9 C41.69,9.32432432 41.56,9.65765766 41.44,10 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M21,14 C16.8977742,16.8835677 13.9904298,21.1831398 12.8300654,26.0822368 L12.6339869,26.9868421 C12.5980392,27.1381579 12.5784314,27.2894737 12.5522876,27.4440789 L12.4803922,27.9013158 L12.4052288,28.3585526 L12.3562092,28.8190789 C12.3267974,29.1282895 12.2908497,29.4342105 12.2614379,29.7434211 C12.2352941,30.3651316 12.1797386,30.9835526 12.1895425,31.6085526 C12.1895425,31.9210526 12.1895425,32.2335526 12.1895425,32.5460526 L12.2320261,33.4835526 C12.3877436,36.0131951 12.7417865,38.5264986 13.2908497,41 C12.2371358,38.6422539 11.5314206,36.1421481 11.1960784,33.5789474 L11.0816993,32.6085526 C11.0555556,32.2796053 11.0457516,31.9506579 11.0261438,31.6217105 L11,31.1315789 C11,30.9671053 11,30.8026316 11,30.6414474 L11,29.6546053 C11.0603898,28.3417502 11.2386766,27.0370885 11.5326797,25.7565789 C11.6078431,25.4276316 11.7058824,25.1217105 11.7908497,24.8026316 C11.875817,24.4835526 11.9869281,24.1743421 12.0947712,23.8618421 L12.2581699,23.3947368 C12.3202614,23.2401316 12.3856209,23.0921053 12.4477124,22.9375 C12.5751634,22.6348684 12.6928105,22.3256579 12.8496732,22.0361842 C13.9804805,19.6634363 15.6320697,17.580306 17.6797386,15.9440789 C18.6899029,15.1417297 19.8077264,14.4872219 21,14 Z",
                        fill: "#4C312C",
                        fillRule: "nonzero",
                        opacity: "0.36"
                      }), React.createElement("path", {
                        d: "M17,14 C12.8897148,16.8835677 9.97665858,21.1831398 8.81401441,26.0822368 L8.61755075,26.9868421 C8.58153242,27.1381579 8.56188605,27.2894737 8.5356909,27.4440789 L8.46365422,27.9013158 L8.38834316,28.3585526 L8.33922724,28.8190789 C8.30975769,29.1282895 8.27373936,29.4342105 8.24426981,29.7434211 C8.21807466,30.3651316 8.16240995,30.9835526 8.17223314,31.6085526 C8.17223314,31.9210526 8.17223314,32.2335526 8.17223314,32.5460526 L8.21480026,33.4835526 C8.37082368,36.0131951 8.72556215,38.5264986 9.27570399,41 C8.22637612,38.6401647 7.52589743,36.1389659 7.19646365,33.5756579 L7.08185986,32.6052632 C7.0556647,32.2763158 7.04584152,31.9473684 7.02619515,31.6184211 L7,31.1282895 C7,30.9638158 7,30.7993421 7,30.6381579 L7,29.6513158 C7.06050843,28.3384607 7.23914556,27.033799 7.53372626,25.7532895 C7.60903733,25.4243421 7.70726916,25.1184211 7.79240341,24.7993421 C7.87753766,24.4802632 7.98886706,24.1710526 8.09692207,23.8585526 L8.26064178,23.3914474 C8.32285527,23.2368421 8.38834316,23.0888158 8.45055665,22.9342105 C8.57825802,22.6315789 8.69613621,22.3223684 8.85330714,22.0328947 C9.98633609,19.6601468 11.64117,17.5770166 13.6928618,15.9407895 C14.6991479,15.1411956 15.8124458,14.4878583 17,14 Z",
                        fill: "#4C312C",
                        fillRule: "nonzero",
                        opacity: "0.36"
                      }), React.createElement("path", {
                        d: "M83,62.3794536 C83,65.3921777 81.4304647,68.1862465 78.8607534,69.7491677 C76.2910421,71.312089 73.0937318,71.4171124 70.4274381,70.0261804 C68.897494,69.3644493 67.6571228,68.1716618 66.9341522,66.6669056 C66.476412,65.8703135 66.1503298,65.004713 65.9685553,64.1036832 C64.5415809,58.7328072 66.8451723,52.103442 68.4237421,47.3139155 C71.6303149,37.5928283 69.6101411,25.6751651 60.3694111,15.2736348 C51.1286812,4.87210453 42.0593199,17.7410667 42.0230689,17.5494857 C42.0230689,17.4999389 42.0065911,17.4536952 42,17.4041483 L42,0 C49.1777139,0.0924874081 60.7352175,2.40797573 63.5496195,4.13550839 C86.5130312,18.2332319 77.5623099,54.3429585 77.5623099,54.3429585 C80.851411,55.6459252 83.0089748,58.8346579 83,62.3794536 Z",
                        fill: "#2D1A15",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M62,14 C66.6129703,16.6452806 70.0521967,20.8250009 71.663841,25.7445456 L71.9409529,26.6534098 C71.9900614,26.8048872 72.0251388,26.9597307 72.0602163,27.1145742 L72.1724642,27.5757387 L72.2882198,28.0369031 L72.3759135,28.5047999 C72.4320374,28.8178531 72.4951768,29.1275402 72.5442853,29.4439596 C72.617948,30.0767984 72.7266881,30.7029049 72.7582578,31.342476 C72.782812,31.6622616 72.810874,31.978681 72.8284127,32.2984665 L72.852967,33.2578232 C72.8749721,35.8489662 72.6837907,38.4377331 72.2812043,41 C73.2284275,38.5112764 73.7909523,35.9029039 73.9508916,33.2578232 L74,32.2479741 C74,31.9113577 74,31.5747413 74,31.2381249 L74,30.7365665 C74,30.5682583 73.9754458,30.3999501 73.9649225,30.2350081 L73.8772289,29.225159 C73.7150571,27.8901797 73.4274199,26.5720082 73.017831,25.2867473 C72.9125987,24.9501309 72.7863198,24.6505423 72.6670564,24.3341229 C72.547793,24.0177035 72.4109909,23.7080165 72.2741888,23.3983294 L72.0637241,22.9337988 C71.9865536,22.7823214 71.9058755,22.630844 71.8287051,22.4827328 C71.6673487,22.1831442 71.5200234,21.8768233 71.3341128,21.5906994 C69.9502346,19.2515649 68.03125,17.2442983 65.7217188,15.7201097 C64.5720432,14.9809136 63.319014,14.4017866 62,14 Z",
                        fill: "#4C312C",
                        fillRule: "nonzero",
                        opacity: "0.33"
                      }), React.createElement("path", {
                        d: "M66,14 C70.6129429,16.6446587 74.0524773,20.8244579 75.6645237,25.7445456 L75.9415546,26.6534098 C75.9906487,26.8048872 76.025716,26.9597307 76.0607832,27.1145742 L76.1729982,27.5757387 L76.28872,28.0369031 L76.3763881,28.5047999 C76.4324956,28.8178531 76.4956166,29.1275402 76.5447107,29.4439596 C76.6183518,30.0767984 76.7270602,30.7029049 76.7586207,31.342476 C76.7831677,31.6622616 76.8112215,31.978681 76.8287551,32.2984665 L76.8533022,33.2578232 C76.8753009,35.8489662 76.6841753,38.4377331 76.2817066,41 C77.228653,38.5112764 77.7910134,35.9029039 77.9509059,33.2578232 L78,32.2479741 C78,31.9113577 78,31.5747413 78,31.2381249 L78,30.7365665 C78,30.5682583 77.975453,30.3999501 77.9649328,30.2350081 L77.8772648,29.225159 C77.7151404,27.8901797 77.4275872,26.5720082 77.0181181,25.2867473 C76.9129164,24.9501309 76.7866745,24.6505423 76.6674459,24.3341229 C76.5482174,24.0177035 76.4114553,23.7080165 76.2746932,23.3983294 L76.0642899,22.9337988 C75.987142,22.7823214 75.9064874,22.630844 75.8293396,22.4827328 C75.6680304,22.1831442 75.5207481,21.8768233 75.3348919,21.5906994 C73.9514181,19.2515649 72.0329942,17.2442983 69.7241379,15.7201097 C68.5737104,14.9806286 67.3198474,14.4014927 66,14 Z",
                        fill: "#4C312C",
                        fillRule: "nonzero",
                        opacity: "0.33"
                      }), React.createElement("path", {
                        d: "M59.4780506,13.1144143 C50.204709,2.58396674 41.0770914,15.6140153 41.0406604,15.4199365 C41.0406604,15.3697437 41.0241009,15.3228971 41.0174771,15.2727043 C41.0174771,15.3228971 41.0009175,15.3697437 40.9942937,15.4199365 C40.9578627,15.6173614 31.836869,2.58396674 22.5535917,13.1144143 C15.9695192,20.5764092 13.6644315,28.3830613 14.038677,35.7982096 C14.3698678,29.2764929 16.8471748,22.5841208 22.5569036,16.0925199 C31.8302452,5.56207233 40.9611747,18.5921209 40.9976056,18.3980421 C40.9976056,18.3478493 40.9976056,18.3010027 41.020789,18.2508099 C41.020789,18.3010027 41.020789,18.3478493 41.0439723,18.3980421 C41.0804033,18.595467 50.1980852,5.56207233 59.4813625,16.0925199 C65.6448227,23.0827025 68.6089801,30.752161 68.9633542,38 C69.364095,29.9055759 66.495983,21.0682985 59.4780506,13.1144143 Z",
                        fill: "#281916",
                        fillRule: "nonzero"
                      }), React.createElement("circle", {
                        cx: "25.5227273",
                        cy: "40.4805195",
                        fill: "#F8BF9D",
                        fillRule: "nonzero",
                        r: "12.4545455"
                      }), React.createElement("path", {
                        d: "M34.9718706,41.5053381 C34.9718706,35.1511153 29.8349709,30 23.4982839,30 C17.161597,30 12.0246973,35.1511153 12.0246973,41.5053381 C11.9917676,42.0154142 11.9917676,42.5270919 12.0246973,43.037168 L12.1340485,43.037168 C12.8997056,48.7067946 17.7030279,52.9511754 23.4084105,52.9995821 C29.1137932,53.0479888 33.9880477,48.8857164 34.8492647,43.2298928 L34.9784979,43.2298928 C35.00923,42.6553884 35.0070173,42.0795879 34.9718706,41.5053381 L34.9718706,41.5053381 Z",
                        fill: "#FFFFFF",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M12,41.1542794 C12.1067983,47.7287865 17.2241144,53 23.5000752,53 C29.776036,53 34.8933521,47.7287865 35,41.1542794 C35.0010668,40.7688224 34.9833455,40.3835582 34.9468562,40 C34.3950968,46.192595 29.4346524,50.9271887 23.498416,50.9271887 C17.5621796,50.9271887 12.6017352,46.192595 12.0499758,40 C12.0145713,40.383616 11.9979576,40.7688813 12,41.1542794 L12,41.1542794 Z",
                        fill: "#F2F2F2",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M27.5731385,40.2164765 C30.0953336,41.6548231 29.0567826,45.1392256 26.1544037,44.9956963 L25.4033088,44.7758216 C22.9305685,43.3405288 23.9289374,39.8897183 26.8127708,40.0027095 C27.0800502,40.0118148 27.3410483,40.0851909 27.5731385,40.2164765 L27.5731385,40.2164765 Z",
                        fill: "#3E2945",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M24.5630312,40.7522966 C25.1066026,41.4331672 25.1468239,42.3875691 24.6624656,43.1117543 C24.1781073,43.8359395 23.280631,44.1632572 22.4437381,43.9209442 C21.6068453,43.6786311 21.0231235,42.9224489 21.0006698,42.0515218 C20.9782161,41.1805948 21.5221977,40.3953425 22.3454929,40.1102329 C23.1460617,39.8329935 24.0344646,40.0902207 24.5630312,40.7522966 Z",
                        fill: "#FFFFFF",
                        fillRule: "nonzero"
                      }), React.createElement("ellipse", {
                        cx: "57.03",
                        cy: "40.4805195",
                        fill: "#F8BF9D",
                        fillRule: "nonzero",
                        rx: "12.7866667",
                        ry: "12.4545455"
                      }), React.createElement("path", {
                        d: "M70.9717708,41.5036762 C70.9717708,35.1503713 65.8349176,30 59.4982879,30 C53.1616583,30 48.024805,35.1503713 48.024805,41.5036762 C47.9917317,42.0790748 47.9917317,42.6559055 48.024805,43.2313041 L48.154037,43.2313041 C49.0152463,48.8863107 53.8894568,53.0479819 59.5947878,52.9995822 C65.3001189,52.9511825 70.1033978,48.7074148 70.869048,43.0386071 L70.9783981,43.0386071 C71.0092643,42.5273572 71.0070506,42.014639 70.9717708,41.5036762 L70.9717708,41.5036762 Z",
                        fill: "#FFFFFF",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M71,41.1542794 C70.8932017,47.7287865 65.7758856,53 59.4999248,53 C53.223964,53 48.1066479,47.7287865 48,41.1542794 C47.9989332,40.7688224 48.0166545,40.3835582 48.0531438,40 C48.6049032,46.192595 53.5653476,50.9271887 59.501584,50.9271887 C65.4378204,50.9271887 70.3982648,46.192595 70.9500242,40 C70.9854287,40.383616 71.0020424,40.7688813 71,41.1542794 Z",
                        fill: "#F2F2F2",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M58.202956,43.9391903 C55.3476987,44.4898725 53.8225099,41.1663433 56.105415,39.3814851 L56.8176034,39.0575543 C59.6435927,38.526308 61.1622774,41.8012475 58.9216484,43.5925844 C58.7143597,43.7652566 58.467499,43.8843108 58.202956,43.9391903 Z",
                        fill: "#3E2945",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M59.6403492,41.9672881 C58.7829822,41.8105438 58.1258543,41.1166191 58.0160229,40.2520058 C57.9061916,39.3873925 58.3689516,38.5512146 59.1598993,38.1850938 C59.950847,37.8189731 60.8878238,38.0072287 61.4759717,38.6504348 C62.0641196,39.2936408 62.1679856,40.2436641 61.7327137,40.9987563 C61.3102695,41.7315953 60.4724488,42.119413 59.6403492,41.9672881 L59.6403492,41.9672881 Z",
                        fill: "#FFFFFF",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M67.8671003,55 C67.8671003,55 48.4841559,64.135847 41.9908695,64.135847 C34.9996419,64.1130073 14.1128484,55 14.1128484,55 L13,69.2714981 C22.3104971,82.5674182 25.2814346,81.9964278 38.7325297,81.9964278 C39.5345826,81.9964278 40.2798234,81.9964278 41.0016709,81.9964278 C41.7235185,81.9964278 42.4654174,81.9964278 43.2674703,81.9964278 C56.7219073,81.9964278 59.6761353,82.5641554 69,69.2714981 L67.8671003,55 Z",
                        fill: "#35221D",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M66.8909874,55 C66.8909874,55 48.4668561,61.0670288 42.053727,61.0474472 C39.9182177,61.0474472 35.746218,63.2438487 32,62.0265266 C33.3367562,69.5328021 34.8880535,76.386358 36.2908224,81.996483 L38.1061703,81.996483 C38.8983222,81.996483 39.6343633,81.996483 40.3472999,81.996483 C41.0602365,81.996483 41.792977,81.996483 42.5851288,81.996483 C55.8734757,81.996483 58.791235,82.5643491 68,69.2684507 L66.8909874,55 Z",
                        fill: "#2D1A15",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M55.7016254,59.8475311 C51.9026024,56.293771 47.3101959,55.176084 42.0264593,56.6017405 C41.3511815,56.7710094 40.6474994,56.7710094 39.9722216,56.6017405 C34.6884851,55.176084 30.0960785,56.293771 26.2970555,59.8475311 C24.4074201,61.6296017 23.4165138,63.816531 24.3646235,66 C25.431247,65.3356164 33.2794886,63.6850384 41.0026325,63.6054508 C48.7257764,63.6850384 56.570726,65.3356164 57.6373495,66 C58.5821672,63.826912 57.5912608,61.6296017 55.7016254,59.8475311 Z",
                        fill: "#35221D"
                      }), React.createElement("path", {
                        d: "M58.6386408,66 C59.578637,63.816531 58.5960626,61.6296017 56.7160703,59.8475311 C52.9364342,56.293771 48.3674633,55.176084 43.1106904,56.6017405 C42.4388586,56.7710094 41.7387675,56.7710094 41.0669357,56.6017405 C38.1617907,55.812785 35.4662617,55.8058643 33,56.5532958 C33.383204,59.1485442 33.7958852,61.6918877 34.2249427,64.1660245 C36.8338855,63.8326108 39.4578205,63.6466416 42.0855378,63.6089111 C49.7725447,63.6850384 57.5905615,65.3321561 58.6386408,66 Z",
                        fill: "#2D1A15",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M6.64029629,62 C6.64040436,59.6167835 8.0465639,57.5649392 10,57.0975768 C8.3412461,56.7001471 6.64582186,57.5382088 5.70535223,59.2204556 C4.76488259,60.9027023 4.76488259,63.0972977 5.70535223,64.7795444 C6.64582186,66.4617912 8.3412461,67.2998529 10,66.9024232 C8.0465639,66.4350608 6.64040436,64.3832165 6.64029629,62 Z",
                        fill: "#281816",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M77.3597037,62 C77.3595956,64.3832165 75.9534361,66.4350608 74,66.9024232 C75.6587539,67.2998529 77.3541781,66.4617912 78.2946478,64.7795444 C79.2351174,63.0972977 79.2351174,60.9027023 78.2946478,59.2204556 C77.3541781,57.5382088 75.6587539,56.7001471 74,57.0975768 C75.9534361,57.5649392 77.3595956,59.6167835 77.3597037,62 Z",
                        fill: "#000000",
                        fillRule: "nonzero",
                        opacity: "0.22"
                      }), React.createElement("path", {
                        d: "M44.1744087,51.883208 L39.8606993,51.9994669 C39.5605501,52.0107423 39.2781754,51.8424911 39.1217796,51.5591862 C38.9653839,51.2758812 38.9592183,50.9214527 39.1056454,50.6317156 L41.8287906,45 L41.8287906,45 L44.8706674,50.4710049 C45.0325167,50.7531672 45.0431941,51.1101129 44.8985606,51.4034694 C44.753927,51.6968258 44.476723,51.8804689 44.1744087,51.883208 L44.1744087,51.883208 Z",
                        fill: "#E99780",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M46.6421267,66.0387817 L46.7472189,66.0784967 C44.7373296,69.3504034 39.1937134,69.1518283 36.1887318,68.595818 C35.081979,68.3880779 34.313492,68.1345127 34.1886949,67.9848177 C33.9522373,67.7037575 33.8898388,67.1660772 33.8832706,66.710882 C32.7535289,66.8330821 32.4940824,68.2964278 34.389027,69.8544786 C34.7465855,70.1467108 35.1299979,70.4103751 35.5351894,70.642669 C38.4981304,72.4266978 42.303364,72.4540915 45.2956322,70.712934 C47.8736765,69.2068183 49.1544883,65.5866414 46.6421267,66.0387817 Z",
                        fill: "#9B1010",
                        fillRule: "nonzero"
                      }), React.createElement("path", {
                        d: "M47,66.0383632 C44.9688537,70.6035805 39.3666071,70.3265132 36.3298443,69.5507246 C35.2113863,69.2608695 34.4347715,68.9070759 34.3086546,68.6982097 C34.0696962,68.3060528 34.0066377,67.5558397 34,66.9207161 C34.3243718,66.8845091 34.6504727,66.9579874 34.9458769,67.1338448 C34.9458769,67.1338448 35.2412561,67.2830349 35.7490426,67.4748508 L35.888435,67.5260017 C36.0776104,67.5942029 36.2933367,67.6666667 36.5289763,67.743393 L36.8409497,67.8371696 L37.1728364,67.9266837 L37.4018381,67.9820972 L37.7337248,68.0588235 L38.0224662,68.114237 L38.2846566,68.1611253 L38.4207302,68.1824382 L38.6762829,68.2208013 L38.9517488,68.2549019 C39.2836354,68.2975277 39.6155221,68.3231031 39.9474087,68.3316283 L40.2792954,68.3316283 C40.3655859,68.3316283 40.4551953,68.3316283 40.5481236,68.3316283 C40.6410518,68.3316283 40.7671688,68.3316283 40.8800102,68.3316283 L41.158795,68.3316283 L41.3413327,68.3316283 C41.7030891,68.2932651 42.0681644,68.2335891 42.4299209,68.1526002 C42.5527189,68.1227621 42.6721981,68.0929241 42.7916773,68.0588235 C42.9111565,68.0247229 43.0007659,68.00341 43.1036508,67.9650469 C43.5570236,67.8209292 44.0008996,67.631374 44.4311973,67.3981245 L44.6502425,67.2787724 C44.8715003,67.1537368 45.0872266,67.011651 45.2974215,66.8525149 C45.793836,66.4656194 46.3332598,66.1781476 46.8971151,66 L47,66.0383632 Z",
                        fill: "#FFFFFF",
                        fillRule: "nonzero"
                      }))
            });
}

var make = Mascot;

exports.make = make;
/* Svg Not a pure module */
