// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function formatHourAmPm(hour) {
  if (hour < 12) {
    var x = String(hour === 0 ? 12 : hour);
    return "" + (String(x) + " AM");
  }
  var x$1 = String(hour === 12 ? 12 : hour % 12);
  return "" + (String(x$1) + " PM");
}

exports.formatHourAmPm = formatHourAmPm;
/* No side effect */
