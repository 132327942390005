// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("bs-platform/lib/js/belt_List.js");

function withQuery(url, params) {
  return Belt_List.reduceWithIndex(params, url, (function (url, param, idx) {
                var delimiter = idx === 0 ? "?" : "&";
                return "" + (String(url) + ("" + (String(delimiter) + ("" + (String(param[0]) + ("=" + (String(param[1]) + "")))))));
              }));
}

function appendQueryParamToUrl(url, param) {
  if (url.indexOf("?") === -1) {
    return url + ("?" + param);
  } else {
    return url + ("&" + param);
  }
}

exports.withQuery = withQuery;
exports.appendQueryParamToUrl = appendQueryParamToUrl;
/* No side effect */
