// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Qs = require("qs");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

var Options = { };

function parseWithOptions(ignoreQueryPrefix, x) {
  var tmp = { };
  if (ignoreQueryPrefix !== undefined) {
    tmp.ignoreQueryPrefix = Caml_option.valFromOption(ignoreQueryPrefix);
  }
  return Qs.parse(x, tmp);
}

exports.Options = Options;
exports.parseWithOptions = parseWithOptions;
/* qs Not a pure module */
