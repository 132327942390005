// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var $$Date = require("../../../../libs/Date.bs.js");
var Form = require("../../../../styleguide/forms/Form/Form.bs.js");
var I18n = require("../../../../libs/I18n.bs.js");
var Alert = require("../../../../styleguide/components/Alert/Alert.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Label = require("../../../../styleguide/forms/Label/Label.bs.js");
var Money = require("../../../../libs/Money.bs.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Tooltip = require("../../../../styleguide/components/Tooltip/Tooltip.bs.js");
var Checkbox = require("../../../../styleguide/forms/Checkbox/Checkbox.bs.js");
var ReactRef = require("../../../../bindings/ReactRef.bs.js");
var DateField = require("../../../../styleguide/forms/DateField/DateField.bs.js");
var GuestsForm = require("../../../../styleguide/components/GustsForm/GuestsForm.bs.js");
var SlvBooking = require("../SlvBooking.bs.js");
var WithTestId = require("../../../../styleguide/components/WithTestId/WithTestId.bs.js");
var ReactIntl = require("react-intl");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var SlvBookingQuote = require("./SlvBookingQuote.bs.js");
var TooltipQuestionIcon = require("../../../../styleguide/icons/TooltipQuestionIcon.bs.js");
var SlvBookingSendInquiryButton = require("./SlvBookingSendInquiryButton.bs.js");
var SlvBookingWidgetFormModuleScss = require("./SlvBookingWidgetForm.module.scss");
var DateFieldModuleScss = require("reason/styleguide/forms/DateField/DateField.module.scss");

var css = SlvBookingWidgetFormModuleScss;

function SlvBookingWidgetForm(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var unclaimedListingModal = Props.unclaimedListingModal;
  var checkInField = React.useRef(null);
  var checkOutField = React.useRef(null);
  var calNote = React.useMemo((function () {
          return Belt_Option.map(listing.minimumStay, (function (x) {
                        var nights = I18n.inflect(x, "night", "nights");
                        return "" + (String(nights) + " minimum stay");
                      }));
        }), [listing.minimumStay]);
  var isCheckInDay = React.useCallback((function (day) {
          return Belt_Option.mapWithDefault(booking.input.checkIn, false, (function (checkIn) {
                        return $$Date.Naive.isSameDay(day, checkIn);
                      }));
        }), [booking.input.checkIn]);
  var isCheckOutDay = React.useCallback((function (day) {
          return Belt_Option.mapWithDefault(booking.input.checkOut, false, (function (checkOut) {
                        return $$Date.Naive.isSameDay(day, checkOut);
                      }));
        }), [booking.input.checkOut]);
  var isDayInBetween = React.useCallback((function (day) {
          var match = booking.input.checkIn;
          var match$1 = booking.input.checkOut;
          if (match !== undefined && match$1 !== undefined && $$Date.Naive.isAfter(day, Caml_option.valFromOption(match))) {
            return $$Date.Naive.isBefore(day, Caml_option.valFromOption(match$1));
          } else {
            return false;
          }
        }));
  var isDayDisabledInCheckInCal = React.useCallback((function (day) {
          if (Curry._2(booking.isDayInPastRelativeToListingTimezone, day, listing.currentDate)) {
            return true;
          }
          var calendarDay = Belt_MapString.get(booking.calendar.days, $$Date.Naive.toString(day));
          if (calendarDay !== undefined) {
            if (calendarDay.morningState.tag) {
              if (calendarDay.eveningState.tag) {
                return true;
              } else {
                return !listing.sameDayCheckInAndOut;
              }
            } else if (calendarDay.eveningState.tag) {
              return true;
            } else {
              return Belt_Option.mapWithDefault(booking.input.checkOut, false, (function (param) {
                            return $$Date.Naive.isSameDay(day, param);
                          }));
            }
          } else {
            return true;
          }
        }));
  var isDayDisabledInCheckOutCal = React.useCallback((function (day) {
          if (Curry._2(booking.isDayInPastRelativeToListingTimezone, day, listing.currentDate)) {
            return true;
          }
          var calendarDay = Belt_MapString.get(booking.calendar.days, $$Date.Naive.toString(day));
          if (calendarDay !== undefined && !calendarDay.morningState.tag) {
            if (calendarDay.eveningState.tag) {
              return !listing.sameDayCheckInAndOut;
            } else {
              return Belt_Option.mapWithDefault(booking.input.checkIn, false, (function (param) {
                            return $$Date.Naive.isSameDay(day, param);
                          }));
            }
          } else {
            return true;
          }
        }));
  var renderDay = React.useCallback((function (date) {
          return (function (modifiers) {
              var dayOfMonth = $$Date.Naive.date(date);
              var calendarDay = Belt_MapString.get(booking.calendar.days, $$Date.Naive.toString(date));
              var tmp;
              if (calendarDay !== undefined && !Belt_Option.getWithDefault(Js_dict.get(modifiers, DateFieldModuleScss.disabled), false)) {
                var match = booking.input.checkIn;
                var match$1 = booking.input.checkOut;
                var tmp$1;
                if (match !== undefined) {
                  var checkIn = Caml_option.valFromOption(match);
                  tmp$1 = match$1 !== undefined ? Cn.on(css.calendarDayPriceSelected, $$Date.Naive.isSameDay(date, checkIn) || $$Date.Naive.isSameDay(date, Caml_option.valFromOption(match$1))) : Cn.on(css.calendarDayPriceSelected, $$Date.Naive.isSameDay(date, checkIn));
                } else {
                  tmp$1 = match$1 !== undefined ? Cn.on(css.calendarDayPriceSelected, $$Date.Naive.isSameDay(date, Caml_option.valFromOption(match$1))) : Cn.none;
                }
                tmp = React.createElement(WithTestId.make, {
                      id: "booking-form-date-picker-day-price",
                      children: React.createElement("div", {
                            className: Cn.$plus(css.calendarDayPrice, tmp$1)
                          }, Money.formatWithoutCents(calendarDay.price))
                    });
              } else {
                tmp = null;
              }
              return React.createElement("div", {
                          className: css.calendarDayWrapper
                        }, React.createElement("div", {
                              className: css.calendarDayDate
                            }, String(dayOfMonth)), tmp);
            });
        }));
  var match = booking.calendar.status;
  var match$1 = booking.input.checkIn;
  var match$2 = booking.input.checkOut;
  var match$3 = booking.status;
  var tmp;
  if (match$3) {
    if (match$1 !== undefined) {
      var checkIn = Caml_option.valFromOption(match$1);
      var match$4 = match$3[0];
      if (match$4[0] >= 59168818) {
        var match$5 = match$4[1];
        if (match$5.tag) {
          var match$6 = match$5[0];
          if (match$6 !== -363007107 && match$6 !== 984370086) {
            if (match$2 !== undefined) {
              var match$7 = match$3[0][1][0][1];
              tmp = typeof match$7 === "number" && match$7 >= 459612177 && match$7 < 710459028 ? /* Specified */[Caml_option.valFromOption(match$2)] : /* Specified */[checkIn];
            } else {
              var match$8 = match$3[0][1][0][1];
              tmp = typeof match$8 === "number" && match$8 === 459612177 ? /* Auto */0 : /* Specified */[checkIn];
            }
          } else {
            tmp = /* Specified */[checkIn];
          }
        } else {
          tmp = /* Specified */[checkIn];
        }
      } else {
        tmp = /* Specified */[checkIn];
      }
    } else if (match$2 !== undefined) {
      var match$9 = match$3[0];
      var checkOut = Caml_option.valFromOption(match$2);
      if (match$9[0] >= 59168818) {
        var match$10 = match$9[1];
        if (match$10.tag) {
          var match$11 = match$10[0];
          if (typeof match$11 === "number") {
            tmp = /* Specified */[checkOut];
          } else {
            tmp = /* Specified */[checkOut];
          }
        } else {
          tmp = /* Specified */[checkOut];
        }
      } else {
        tmp = /* Specified */[checkOut];
      }
    } else {
      tmp = /* Auto */0;
    }
  } else {
    tmp = /* Auto */0;
  }
  var match$12 = booking.input.checkIn;
  var match$13 = booking.input.checkOut;
  var tmp$1 = {
    size: /* MD */1,
    neighbors: /* Right */1,
    className: css.dateFieldWrapper,
    calPlacement: /* Left */0,
    calModifiers: /* :: */[
      /* tuple */[
        /* Disabled */0,
        /* `Fn */[
          15720,
          isDayDisabledInCheckInCal
        ]
      ],
      /* :: */[
        /* tuple */[
          /* SecondarySelected */1,
          /* `Fn */[
            15720,
            isCheckOutDay
          ]
        ],
        /* :: */[
          /* tuple */[
            /* TertiarySelected */2,
            /* `Fn */[
              15720,
              isDayInBetween
            ]
          ],
          /* [] */0
        ]
      ]
    ],
    calBusy: match ? false : true,
    date: booking.input.checkIn,
    initialMonth: tmp,
    placeholder: "Check in",
    testId: "booking-widget-check-in",
    onDayClick: (function (day) {
        return Curry._2(booking.updateCheckIn, day, (function (param) {
                      if (typeof param === "number") {
                        if (param >= 459612177) {
                          if (param !== 580045672) {
                            return ;
                          } else {
                            return ReactRef.focus(checkOutField);
                          }
                        } else if (param >= 183515014) {
                          return ReactRef.focus(checkOutField);
                        } else {
                          return ;
                        }
                      }
                      
                    }));
      }),
    onMonthChange: (function (date) {
        return Curry._2(booking.fetchCalendarDays, date, 1);
      }),
    onCalendarShow: (function (param) {
        var date = booking.input.checkIn;
        return Curry._2(booking.fetchCalendarDays, date !== undefined ? $$Date.Naive.startOfMonth(Caml_option.valFromOption(date)) : $$Date.Naive.startOfMonth($$Date.Naive.make(undefined)), 1);
      }),
    renderDay: renderDay,
    ref: checkInField
  };
  if (calNote !== undefined) {
    tmp$1.calNote = Caml_option.valFromOption(calNote);
  }
  var tmp$2 = match$12 !== undefined || match$13 !== undefined ? booking.clearDates : undefined;
  if (tmp$2 !== undefined) {
    tmp$1.onDatesClear = Caml_option.valFromOption(tmp$2);
  }
  var match$14 = booking.calendar.status;
  var match$15 = booking.input.checkIn;
  var match$16 = booking.input.checkOut;
  var match$17 = booking.status;
  var tmp$3;
  var exit = 0;
  if (match$17) {
    if (match$16 !== undefined) {
      var match$18 = match$17[0];
      var checkOut$1 = Caml_option.valFromOption(match$16);
      if (match$18[0] >= 59168818) {
        var match$19 = match$18[1];
        if (match$19.tag) {
          var match$20 = match$19[0];
          if (match$20 !== -363007107 && match$20 !== 984370086) {
            exit = 1;
          } else {
            tmp$3 = /* Specified */[checkOut$1];
          }
        } else {
          tmp$3 = /* Specified */[checkOut$1];
        }
      } else {
        tmp$3 = /* Specified */[checkOut$1];
      }
    } else {
      exit = 1;
    }
  } else {
    tmp$3 = /* Auto */0;
  }
  if (exit === 1) {
    if (match$15 !== undefined) {
      var checkIn$1 = Caml_option.valFromOption(match$15);
      if (match$16 !== undefined) {
        var match$21 = match$17[0][1][0][1];
        var checkOut$2 = Caml_option.valFromOption(match$16);
        tmp$3 = typeof match$21 === "number" && match$21 >= 580045672 && match$21 < 710459028 ? /* Specified */[checkIn$1] : /* Specified */[checkOut$2];
      } else {
        var match$22 = match$17[0];
        if (match$22[0] >= 59168818) {
          var match$23 = match$22[1];
          if (match$23.tag) {
            var match$24 = match$23[0];
            if (typeof match$24 === "number") {
              tmp$3 = /* Specified */[checkIn$1];
            } else {
              var match$25 = match$24[1];
              tmp$3 = typeof match$25 === "number" && match$25 === 459612177 ? /* Auto */0 : /* Specified */[checkIn$1];
            }
          } else {
            tmp$3 = /* Specified */[checkIn$1];
          }
        } else {
          tmp$3 = /* Specified */[checkIn$1];
        }
      }
    } else if (match$16 !== undefined) {
      var checkOut$3 = Caml_option.valFromOption(match$16);
      tmp$3 = /* Specified */[checkOut$3];
    } else {
      tmp$3 = /* Auto */0;
    }
  }
  var match$26 = booking.input.checkIn;
  var match$27 = booking.input.checkOut;
  var tmp$4 = {
    size: /* MD */1,
    neighbors: /* Left */0,
    className: css.dateFieldWrapper,
    calPlacement: /* Right */2,
    calModifiers: /* :: */[
      /* tuple */[
        /* Disabled */0,
        /* `Fn */[
          15720,
          isDayDisabledInCheckOutCal
        ]
      ],
      /* :: */[
        /* tuple */[
          /* SecondarySelected */1,
          /* `Fn */[
            15720,
            isCheckInDay
          ]
        ],
        /* :: */[
          /* tuple */[
            /* TertiarySelected */2,
            /* `Fn */[
              15720,
              isDayInBetween
            ]
          ],
          /* [] */0
        ]
      ]
    ],
    calBusy: match$14 ? false : true,
    date: booking.input.checkOut,
    initialMonth: tmp$3,
    placeholder: "Check out",
    testId: "booking-widget-check-out",
    onDayClick: (function (day) {
        return Curry._2(booking.updateCheckOut, day, (function (param) {
                      if (typeof param === "number") {
                        if (param >= 459612177) {
                          if (param >= 710459028) {
                            return ;
                          } else {
                            return ReactRef.focus(checkInField);
                          }
                        } else if (param !== 129262778) {
                          return ;
                        } else {
                          return ReactRef.focus(checkInField);
                        }
                      }
                      
                    }));
      }),
    onMonthChange: (function (date) {
        return Curry._2(booking.fetchCalendarDays, date, 1);
      }),
    onCalendarShow: (function (param) {
        var date = booking.input.checkOut;
        return Curry._2(booking.fetchCalendarDays, date !== undefined ? $$Date.Naive.startOfMonth(Caml_option.valFromOption(date)) : $$Date.Naive.startOfMonth($$Date.Naive.make(undefined)), 1);
      }),
    renderDay: renderDay,
    ref: checkOutField
  };
  if (calNote !== undefined) {
    tmp$4.calNote = Caml_option.valFromOption(calNote);
  }
  var tmp$5 = match$26 !== undefined || match$27 !== undefined ? booking.clearDates : undefined;
  if (tmp$5 !== undefined) {
    tmp$4.onDatesClear = Caml_option.valFromOption(tmp$5);
  }
  var error = SlvBooking.Status.displayError(booking.status);
  var quote = SlvBooking.Status.quote(booking.status);
  return React.createElement("div", {
              className: css.container
            }, React.createElement(Form.make, {
                  className: css.form,
                  onSubmit: booking.showInquiryModal,
                  children: null
                }, React.createElement("div", {
                      className: css.fieldsRow
                    }, React.createElement("div", {
                          className: css.field
                        }, React.createElement(DateField.make, tmp$1)), React.createElement("div", {
                          className: css.field
                        }, React.createElement(DateField.make, tmp$4))), React.createElement("div", {
                      className: css.fieldsRow
                    }, React.createElement("div", {
                          className: css.checkboxWrapper
                        }, React.createElement(Checkbox.make, {
                              id: "booking-form-flexibleDates",
                              size: /* MD */1,
                              checked: booking.input.flexibleDates,
                              onChange: (function ($$event) {
                                  return Curry._1(booking.updateFlexibleDates, $$event.target.checked);
                                })
                            }), React.createElement(Label.make, {
                              forId: "booking-form-flexibleDates",
                              placement: /* Right */1,
                              className: css.checkboxLabel,
                              children: "My dates are flexible"
                            }), React.createElement(Tooltip.make, {
                              content: "Check to indicate to the host that you are flexible when it comes to check-in and check-out. Hosts sometimes will offer a better price if you are able to change dates.",
                              side: /* Right */3,
                              children: React.createElement(TooltipQuestionIcon.make, {
                                    size: /* MD */2,
                                    color: /* Gray */8
                                  })
                            }))), React.createElement("div", {
                      className: css.fieldsRow
                    }, React.createElement("div", {
                          className: css.field
                        }, React.createElement(GuestsForm.make, {
                              input: {
                                adults: booking.input.adults,
                                children: booking.input.children,
                                infants: booking.input.infants,
                                pets: booking.input.pets
                              },
                              houseRules: listing.systemHouseRules,
                              popoverPlacement: /* Center */1,
                              testId: "booking-widget-guests-dropdown",
                              onAdultsChange: booking.updateAdults,
                              onChildrenChange: booking.updateChildren,
                              onInfantsChange: booking.updateInfants,
                              onPetsChange: booking.updatePets
                            }))), error !== undefined ? React.createElement(Alert.make, {
                        level: /* Danger */3,
                        className: css.alert,
                        testId: "booking-form-error-message",
                        children: error
                      }) : null, quote !== undefined ? React.createElement(SlvBookingQuote.make, {
                        listing: listing,
                        quote: quote,
                        context: /* BookingWidget */294421725,
                        unclaimedListingModal: unclaimedListingModal
                      }) : null, React.createElement(SlvBookingSendInquiryButton.make, {
                      size: /* LG */2,
                      submit: true,
                      expanded: true,
                      testId: "booking-widget-submit"
                    }), React.createElement("div", {
                      className: css.buttonNote
                    }, React.createElement(ReactIntl.FormattedMessage, {
                          id: "slv.booking-widget.cta-note",
                          defaultMessage: "You may ask the host any questions. No credit card is required."
                        }))));
}

var make = SlvBookingWidgetForm;

exports.css = css;
exports.make = make;
/* css Not a pure module */
