// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          conversationId: Json_decode.field("conversationId", Json_decode.$$int, json)
        };
}

exports.fromJson = fromJson;
/* No side effect */
