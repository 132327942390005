// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function GuestsIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var className = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "Guests";
  var tmp = {
    title: title,
    size: size,
    children: React.createElement("g", {
          fillRule: "evenodd"
        }, React.createElement("path", {
              style: Icon.style(color),
              d: "M10.4845638,8.79907588 C9.96557642,9.23652926 9.28441863,9.5052642 8.39298327,9.5052642 C7.50138948,9.5052642 6.82017888,9.23658207 6.30113865,8.79912868 C3.89635962,9.61082544 2.05614047,11.7067362 2.05614047,13.7298261 C2.05614047,15.3485198 14.7298261,15.3485198 14.7298261,13.7298261 C14.7298261,11.7066834 12.8895013,9.61066702 10.4845638,8.79907588 Z M5.57879138,7.93209017 C4.85079376,6.70992442 4.69649163,5.00790125 4.69649163,3.69649163 C4.69649163,1.39410542 5.96898247,0 8.39298327,0 C10.8169841,0 12.0894749,1.39582692 12.0894749,3.69649163 C12.0894749,5.00705634 11.9351728,6.70950196 11.2069111,7.93203736 C13.8942605,8.99145186 15.7859665,11.4415393 15.7859665,13.7298261 C15.7859665,16.7567246 1,16.7567246 1,13.7298261 C1,11.4415921 2.89158454,8.99161028 5.57879138,7.93209017 Z M8.39298327,8.44912373 C6.15539127,8.44912373 5.7526321,5.64522922 5.7526321,3.69649163 C5.7526321,1.92652695 6.6022443,1.05614047 8.39298327,1.05614047 C10.1830357,1.05614047 11.0333344,1.92753028 11.0333344,3.69649163 C11.0333344,5.64412027 10.6305753,8.44912373 8.39298327,8.44912373 Z"
            }))
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Icon.make, tmp);
}

var make = GuestsIcon;

exports.make = make;
/* Icon Not a pure module */
