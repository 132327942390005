// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function email(param) {
  return /[\w.+]+@\S+\.\S+[\w]/;
}

exports.email = email;
/* No side effect */
