// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Input = require("../../forms/Input/Input.bs.js");
var Label = require("../../forms/Label/Label.bs.js");
var React = require("react");
var Button = require("../Button/Button.bs.js");
var Events = require("../../../libs/Events.bs.js");
var Select = require("../../forms/Select/Select.bs.js");
var Booking = require("../../../models/Booking.bs.js");
var Counter = require("../../forms/Counter/Counter.bs.js");
var Popover = require("../Popover/Popover.bs.js");
var RadioTabs = require("../../forms/RadioTabs/RadioTabs.bs.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var WithTestId = require("../WithTestId/WithTestId.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var GuestsFormModuleScss = require("./GuestsForm.module.scss");

var css = GuestsFormModuleScss;

var yes = "yes";

var no = "no";

function toString(pets) {
  if (pets) {
    return "yes";
  } else {
    return "no";
  }
}

function fromString(x) {
  switch (x) {
    case "no" :
        return false;
    case "yes" :
        return true;
    default:
      return Pervasives.failwith("Unknown pets value: " + (String(x) + ""));
  }
}

var Pets = {
  yes: yes,
  no: no,
  toString: toString,
  fromString: fromString
};

function GuestsForm$GuestsForm(Props) {
  var input = Props.input;
  var houseRules = Props.houseRules;
  var popoverPlacement = Props.popoverPlacement;
  var testId = Props.testId;
  var onAdultsChange = Props.onAdultsChange;
  var onChildrenChange = Props.onChildrenChange;
  var onInfantsChange = Props.onInfantsChange;
  var onPetsChange = Props.onPetsChange;
  var popover = React.useContext(Popover.Context.x);
  var childrenDisallowed = React.useMemo((function () {
          var match = houseRules.children.status;
          return match === 1;
        }), [houseRules.children.status]);
  var infantsDisallowed = React.useMemo((function () {
          var match = houseRules.infants.status;
          return match === 1;
        }), [houseRules.infants.status]);
  var petsDisallowed = React.useMemo((function () {
          var match = houseRules.pets.status;
          return match === 1;
        }), [houseRules.pets.status]);
  var tmp = {
    size: /* MD */1,
    readOnly: true,
    onChange: (function (prim) {
        
      }),
    onMouseDown: (function (param) {
        if (popover.shown) {
          return Curry._1(popover.hide, undefined);
        } else {
          return Curry._1(popover.show, undefined);
        }
      }),
    onKeyDown: (function ($$event) {
        return Events.onReactSpaceKey($$event, (function (param) {
                      if (popover.shown) {
                        return Curry._1(popover.hide, undefined);
                      } else {
                        return Curry._1(popover.show, undefined);
                      }
                    }));
      }),
    children: React.createElement(Input.Select.DefaultOption.make, {
          children: Booking.guestsLabel(input.adults, input.children, input.infants, input.pets)
        })
  };
  if (testId !== undefined) {
    tmp.testId = Caml_option.valFromOption(testId);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Select.make, tmp), React.createElement(Popover.Body.make, {
                  placement: popoverPlacement,
                  withTriangle: true,
                  className: css.body,
                  children: React.createElement("div", {
                        className: css.form
                      }, React.createElement("div", {
                            className: css.field
                          }, React.createElement(Label.make, {
                                placement: /* Left */3,
                                className: css.label,
                                children: "Adults"
                              }), React.createElement(Counter.make, {
                                size: /* SM */0,
                                value: input.adults,
                                ariaLabel: "Adults",
                                incrementTestId: "guests-dropdown-adults-increment",
                                className: css.control,
                                onChange: (function (x) {
                                    if (x > 0) {
                                      return Curry._1(onAdultsChange, x);
                                    }
                                    
                                  })
                              })), React.createElement("div", {
                            className: css.field
                          }, React.createElement(Label.make, {
                                placement: /* Left */3,
                                className: css.label,
                                children: null
                              }, React.createElement("span", {
                                    className: css.labelCaption
                                  }, "Children"), React.createElement("span", {
                                    className: css.labelDescription
                                  }, "2-12 years")), React.createElement(Counter.make, {
                                size: /* SM */0,
                                value: input.children,
                                disabled: childrenDisallowed,
                                ariaLabel: "Children",
                                incrementTestId: "guests-dropdown-children-increment",
                                className: css.control,
                                onChange: (function (x) {
                                    if (x >= 0) {
                                      return Curry._1(onChildrenChange, x);
                                    }
                                    
                                  })
                              }), childrenDisallowed ? React.createElement("div", {
                                  className: css.note
                                }, "Sorry, children are not allowed") : null), React.createElement("div", {
                            className: css.field
                          }, React.createElement(Label.make, {
                                placement: /* Left */3,
                                className: css.label,
                                children: null
                              }, React.createElement("span", {
                                    className: css.labelCaption
                                  }, "Infants"), React.createElement("span", {
                                    className: css.labelDescription
                                  }, "0-2 years")), React.createElement(Counter.make, {
                                size: /* SM */0,
                                value: input.infants,
                                disabled: infantsDisallowed,
                                ariaLabel: "Infants",
                                incrementTestId: "guests-dropdown-infants-increment",
                                className: css.control,
                                onChange: (function (x) {
                                    if (x >= 0) {
                                      return Curry._1(onInfantsChange, x);
                                    }
                                    
                                  })
                              }), infantsDisallowed ? React.createElement("div", {
                                  className: css.note
                                }, "Sorry, infants are not allowed") : null), React.createElement(WithTestId.make, {
                            id: "guests-dropdown-pets",
                            children: React.createElement("div", {
                                  className: css.field
                                }, React.createElement(Label.make, {
                                      placement: /* Top */0,
                                      className: css.label,
                                      children: React.createElement("span", {
                                            className: css.labelCaption
                                          }, "Pets")
                                    }), React.createElement(RadioTabs.make, {
                                      className: css.control,
                                      children: null
                                    }, React.createElement(RadioTabs.Tab.make, {
                                          size: /* SM */0,
                                          value: no,
                                          checked: !input.pets,
                                          disabled: petsDisallowed,
                                          onChange: (function ($$event) {
                                              return Curry._1(onPetsChange, fromString($$event.target.value));
                                            }),
                                          children: "No"
                                        }), React.createElement(RadioTabs.Tab.make, {
                                          size: /* SM */0,
                                          value: yes,
                                          checked: input.pets,
                                          disabled: petsDisallowed,
                                          testId: "guests-dropdown-pets-yes",
                                          onChange: (function ($$event) {
                                              return Curry._1(onPetsChange, fromString($$event.target.value));
                                            }),
                                          children: "Yes"
                                        })), petsDisallowed ? React.createElement("div", {
                                        className: css.note
                                      }, "Sorry, pets are not allowed") : null)
                          }), React.createElement(Button.make, {
                            size: /* MD */1,
                            color: /* Primary */0,
                            expanded: true,
                            testId: "guests-dropdown-close",
                            onClick: (function (param) {
                                return Curry._1(popover.hide, undefined);
                              }),
                            children: "Done"
                          }))
                }));
}

var GuestsForm = {
  make: GuestsForm$GuestsForm
};

function GuestsForm$1(Props) {
  var input = Props.input;
  var houseRules = Props.houseRules;
  var popoverPlacement = Props.popoverPlacement;
  var testId = Props.testId;
  var onAdultsChange = Props.onAdultsChange;
  var onChildrenChange = Props.onChildrenChange;
  var onInfantsChange = Props.onInfantsChange;
  var onPetsChange = Props.onPetsChange;
  var tmp = {
    input: input,
    houseRules: houseRules,
    popoverPlacement: popoverPlacement,
    onAdultsChange: onAdultsChange,
    onChildrenChange: onChildrenChange,
    onInfantsChange: onInfantsChange,
    onPetsChange: onPetsChange
  };
  if (testId !== undefined) {
    tmp.testId = Caml_option.valFromOption(testId);
  }
  return React.createElement(Popover.make, {
              className: css.container,
              children: React.createElement(GuestsForm$GuestsForm, tmp)
            });
}

var make = GuestsForm$1;

exports.css = css;
exports.Pets = Pets;
exports.GuestsForm = GuestsForm;
exports.make = make;
/* css Not a pure module */
