// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");

function RightAngleBracketIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var title = Props.title;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Icon.make, {
              title: title,
              size: size,
              className: className,
              children: React.createElement("path", {
                    style: Icon.style(color),
                    clipRule: "evenodd",
                    d: "M5.33435 14.9984 C4.78292 14.6308 4.63391 13.8857 5.00153 13.3343L8.55777 7.99995L5.00153 2.66559 C4.63391 2.11415 4.78292 1.36911 5.33435 1.00148C5.88579 0.633862 6.63083 0.782871 6.99845 1.33431 L10.9985 7.33431C11.2672 7.73738 11.2672 8.26251 10.9985 8.66559L6.99845 14.6656C6.63083 15.217 5.88579 15.366 5.33435 14.9984Z",
                    fillRule: "evenodd"
                  })
            });
}

var make = RightAngleBracketIcon;

exports.make = make;
/* Icon Not a pure module */
