// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var H2 = require("../Heading/H2.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Modal = require("../Modal/Modal.bs.js");
var React = require("react");
var UnclaimedListingModalModuleScss = require("./UnclaimedListingModal.module.scss");

var css = UnclaimedListingModalModuleScss;

function reducer(_state, action) {
  if (action) {
    return /* Hidden */1;
  } else {
    return /* Shown */0;
  }
}

function useModal(param) {
  var match = React.useReducer(reducer, /* Hidden */1);
  var dispatch = match[1];
  return {
          state: match[0],
          show: (function (param) {
              return Curry._1(dispatch, /* Show */0);
            }),
          hide: (function (param) {
              return Curry._1(dispatch, /* Hide */1);
            })
        };
}

function UnclaimedListingModal(Props) {
  var modal = Props.modal;
  var match = modal.state;
  return React.createElement(Modal.make, {
              shown: match ? false : true,
              hide: modal.hide,
              className: css.modal,
              testId: "unclaimed-listing-modal",
              children: null
            }, React.createElement(H2.make, {
                  className: css.h,
                  children: "What does \"Unclaimed listing\" mean?"
                }), React.createElement("div", {
                  className: css.p
                }, "We at HiChee allow hosts to list their properties and also gather data from multiple online sources so you can find info about all Hawaii vacation rentals in one place. Listings that are not managed by their owners are called \"Unclaimed.\""), React.createElement(H2.make, {
                  className: css.h,
                  children: "Can I send inquiries to unclaimed listings?"
                }), React.createElement("div", {
                  className: css.p
                }, "Yes, you can. We\'ll try to find the owner, so you get a host-direct price. It can take up to 24 hours. We\'ll reach out to you even if we didn\'t find the host, and you\'ll be able to make a booking through Airbnb or VRBO."), React.createElement(H2.make, {
                  className: css.h,
                  children: "How can I be sure a host you find is not fake?"
                }), React.createElement("div", {
                  className: css.p
                }, "All hosts on HiChee have been verified by our staff."));
}

var make = UnclaimedListingModal;

exports.css = css;
exports.reducer = reducer;
exports.useModal = useModal;
exports.make = make;
/* css Not a pure module */
