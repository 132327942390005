// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var React = require("react");
var StarIcon = require("../../icons/StarIcon.bs.js");
var RatingWithReviewsCounterModuleScss = require("./RatingWithReviewsCounter.module.scss");

var css = RatingWithReviewsCounterModuleScss;

function ratingText(rating) {
  if (rating % 1.0 === 0.0) {
    return String(rating | 0) + "/5";
  } else {
    return rating.toFixed(1) + "/5";
  }
}

function reviewCountText(reviewsCount, reviewsCountStyle) {
  if (reviewsCountStyle >= -168171613) {
    return "(" + (String(reviewsCount) + ")");
  } else {
    return "(" + (String(reviewsCount) + " reviews)");
  }
}

function RatingWithReviewsCounter(Props) {
  var providerRating = Props.providerRating;
  var classNameOpt = Props.className;
  var sizeOpt = Props.size;
  var iconClassNameOpt = Props.iconClassName;
  var ratingValueClassNameOpt = Props.ratingValueClassName;
  var reviewsCountStyleOpt = Props.reviewsCountStyle;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var size = sizeOpt !== undefined ? sizeOpt : /* SM */1;
  var iconClassName = iconClassNameOpt !== undefined ? iconClassNameOpt : "";
  var ratingValueClassName = ratingValueClassNameOpt !== undefined ? ratingValueClassNameOpt : "";
  var reviewsCountStyle = reviewsCountStyleOpt !== undefined ? reviewsCountStyleOpt : /* CountWithText */-675077726;
  var reviewsCount = providerRating.count;
  return React.createElement("div", {
              className: Cn.$plus(css.reviewsRatingWrapper, className)
            }, React.createElement(StarIcon.make, {
                  size: size,
                  fill: providerRating.count === 0 ? /* Empty */50834029 : /* Full */782112175,
                  className: Cn.$plus(css.starIcon, iconClassName)
                }), reviewsCount === 0 ? React.createElement("span", {
                    className: css.noReviews
                  }, "No reviews") : React.createElement(React.Fragment, undefined, React.createElement("span", {
                        className: Cn.$plus(css.ratingValue, ratingValueClassName)
                      }, ratingText(providerRating.rating)), React.createElement("span", {
                        className: css.reviewsCount
                      }, reviewCountText(reviewsCount, reviewsCountStyle))));
}

var make = RatingWithReviewsCounter;

exports.css = css;
exports.ratingText = ratingText;
exports.reviewCountText = reviewCountText;
exports.make = make;
/* css Not a pure module */
