// @flow
import * as React from 'react';
import _ from 'lodash/fp';
import cx from 'classnames';
import { findDOMNode } from 'react-dom';
import ReactTelInput from 'react-telephone-input';

import { isTest } from 'app/libs/utils/env';

import flags from 'app/assets/images/flags.png';

import './PhoneInput.global.scss';

// FixMe: for some reason jest moduleNameMapper is not working for flags.png when usign ReactDOM.render in spec
const flagsWithStub = isTest && typeof flags !== 'string' ? 'flags_stubbbed.png' : flags;

type Props = {
  size: 'sm' | 'md',
  wide?: boolean,
  value: ?string,
  onChange?: Function,
  onFocus?: Function,
};

const ALL_NON_DIGITS_REGEX = /\D/g;

class PhoneInput extends React.Component<Props> {
  skipTextAutoSelection = () => {
    const thisDOM = findDOMNode(this); // eslint-disable-line react/no-find-dom-node
    const inputElement = thisDOM && _.get('firstChild.firstChild', thisDOM);

    if (inputElement)
      setTimeout(() => {
        inputElement.selectionStart = 10000;
        inputElement.selectionEnd = 10000;
      }, 0);
    this.props.onFocus && this.props.onFocus(); // eslint-disable-line no-unused-expressions
  };

  handleChange = (value: string) => {
    const filteredValue = value.replace(ALL_NON_DIGITS_REGEX, '');

    // handle +1 USA case specially as it is the only default country
    if (filteredValue === '1' && this.props.value !== '' && this.props.onChange) this.props.onChange('');

    if (filteredValue !== this.props.value && this.props.onChange) this.props.onChange(filteredValue);
  };

  render = () => (
    <div className={cx(`react-tel-input-${this.props.size}`, { 'react-tel-input-wide': this.props.wide })}>
      <ReactTelInput
        flagsImagePath={flagsWithStub}
        defaultCountry="us"
        preferredCountries={['us']}
        {...this.props}
        onChange={this.handleChange}
        onFocus={this.skipTextAutoSelection}
      />
    </div>
  );
}

export default PhoneInput;
