// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Screen = require("../../../styleguide/styles/Screen.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var $$MediaQueryList = require("../../../libs/MediaQueryList.bs.js");

function reducer(param, action) {
  return action[0];
}

function useScreen(param) {
  var smallMq = React.useRef(undefined);
  var mediumMq = React.useRef(undefined);
  var largeMq = React.useRef(undefined);
  var match = React.useReducer(reducer, undefined);
  var dispatch = match[1];
  React.useEffect((function () {
          var __x = window;
          var smallMqValue = __x.matchMedia($$Screen.smMq);
          var __x$1 = window;
          var mediumMqValue = __x$1.matchMedia($$Screen.mdMq);
          var __x$2 = window;
          var largeMqValue = __x$2.matchMedia($$Screen.lgMq);
          smallMq.current = Caml_option.some(smallMqValue);
          mediumMq.current = Caml_option.some(mediumMqValue);
          largeMq.current = Caml_option.some(largeMqValue);
          var screen = largeMqValue.matches ? /* LG */2 : (
              mediumMqValue.matches ? /* MD */1 : /* SM */0
            );
          Curry._1(dispatch, /* SetInitialScreen */Block.__(0, [screen]));
          
        }), ([]));
  React.useEffect((function () {
          return Belt_Option.flatMap(smallMq.current, (function (mq) {
                        return $$MediaQueryList.subscribe(mq, (function (mq) {
                                      smallMq.current = Caml_option.some(mq);
                                      if (mq.matches) {
                                        return Curry._1(dispatch, /* UpdateScreen */Block.__(1, [/* SM */0]));
                                      }
                                      
                                    }));
                      }));
        }), ([]));
  React.useEffect((function () {
          return Belt_Option.flatMap(mediumMq.current, (function (mq) {
                        return $$MediaQueryList.subscribe(mq, (function (mq) {
                                      mediumMq.current = Caml_option.some(mq);
                                      if (mq.matches) {
                                        return Curry._1(dispatch, /* UpdateScreen */Block.__(1, [/* MD */1]));
                                      }
                                      
                                    }));
                      }));
        }), ([]));
  React.useEffect((function () {
          return Belt_Option.flatMap(largeMq.current, (function (mq) {
                        return $$MediaQueryList.subscribe(mq, (function (mq) {
                                      largeMq.current = Caml_option.some(mq);
                                      if (mq.matches) {
                                        return Curry._1(dispatch, /* UpdateScreen */Block.__(1, [/* LG */2]));
                                      }
                                      
                                    }));
                      }));
        }), ([]));
  return match[0];
}

exports.reducer = reducer;
exports.useScreen = useScreen;
/* react Not a pure module */
