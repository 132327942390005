import React from 'react';

import VrsLayout from '../components/VrsLayout.bs.js';
import SEO from '../components/Seo';
import Listing from '../hc/reason/bundles/listings-show/ui/Slv__JsBridge.bs.js';

export default ({ pageContext: { listing } }) => (
  <VrsLayout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Listing
      listing={listing}
      user={null}
      railsContext={{
        scheme: 'https',
        host: '', // TODO: get host, if needed
        port: null,
        referer: null,
      }}
      viewContext="microsite"
      authenticateNewUser={(...x) => console.log('authenticateNewUser is called with args:', x)}
      authenticateWithCallback={(...x) => console.log('authenticateWithCallback is called with args:', x)}
    />
  </VrsLayout>
);
