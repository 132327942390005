// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Date = require("../../../libs/Date.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Input = require("../Input/Input.bs.js");
var React = require("react");
var Control = require("../../components/Control/Control.bs.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Popover = require("../../components/Popover/Popover.bs.js");
var Spinner = require("../../components/Spinner/Spinner.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var CalendarIcon = require("../../icons/CalendarIcon.bs.js");
var ReactDayPicker = require("../../../bindings/ReactDayPicker.bs.js");
var ReactDayPicker$1 = require("react-day-picker");
var DateFieldModuleScss = require("./DateField.module.scss");

var css = DateFieldModuleScss;

function toClassName(param) {
  switch (param) {
    case /* Disabled */0 :
        return css.disabled;
    case /* SecondarySelected */1 :
        return css.secondarySelected;
    case /* TertiarySelected */2 :
        return css.tertiarySelected;
    
  }
}

var Modifier = {
  toClassName: toClassName
};

var make = React.forwardRef((function (Props, theRef) {
        var size = Props.size;
        var neighbors = Props.neighbors;
        var date = Props.date;
        var initialMonth = Props.initialMonth;
        var calPlacement = Props.calPlacement;
        var calModifiersOpt = Props.calModifiers;
        var calNote = Props.calNote;
        var calBusyOpt = Props.calBusy;
        var placeholder = Props.placeholder;
        var testId = Props.testId;
        var onDayClick = Props.onDayClick;
        var onMonthChange = Props.onMonthChange;
        var onDatesClear = Props.onDatesClear;
        var onCalendarShow = Props.onCalendarShow;
        var renderDay = Props.renderDay;
        var calModifiers = calModifiersOpt !== undefined ? calModifiersOpt : /* [] */0;
        var calBusy = calBusyOpt !== undefined ? calBusyOpt : false;
        var popover = React.useContext(Popover.Context.x);
        var tmp = {
          size: size,
          neighbors: neighbors,
          value: Belt_Option.getWithDefault(Belt_Option.map(date, (function (x) {
                      return $$Date.Naive.format(x, "MMM D, YYYY");
                    })), ""),
          addon: /* Icon */[
            CalendarIcon,
            /* Right */1
          ],
          onChange: (function (prim) {
              
            }),
          onFocus: (function (param) {
              if (!popover.shown) {
                return Curry._1(popover.show, undefined);
              }
              
            })
        };
        if (testId !== undefined) {
          tmp.testId = Caml_option.valFromOption(testId);
        }
        if (placeholder !== undefined) {
          tmp.placeholder = Caml_option.valFromOption(placeholder);
        }
        var tmp$1 = Belt_Option.map((theRef == null) ? undefined : Caml_option.some(theRef), (function (prim) {
                return prim;
              }));
        if (tmp$1 !== undefined) {
          tmp.ref = Caml_option.valFromOption(tmp$1);
        }
        var tmp$2 = {
          placement: calPlacement,
          withTriangle: true,
          children: null
        };
        if (onCalendarShow !== undefined) {
          tmp$2.onShow = Caml_option.valFromOption(onCalendarShow);
        }
        var tmp$3;
        var exit = 0;
        if (calNote !== undefined || onDatesClear !== undefined) {
          exit = 1;
        } else {
          tmp$3 = null;
        }
        if (exit === 1) {
          tmp$3 = React.createElement("div", {
                className: css.footer
              }, calNote !== undefined ? React.createElement("span", {
                      className: css.note
                    }, Caml_option.valFromOption(calNote)) : React.createElement("span", undefined), onDatesClear !== undefined ? React.createElement(Control.make, {
                      className: css.clearButton,
                      onClick: (function (param) {
                          Curry._1(onDatesClear, undefined);
                          return Curry._1(popover.hide, undefined);
                        }),
                      children: "Clear dates"
                    }) : null);
        }
        return React.createElement(React.Fragment, undefined, React.createElement(Input.$$Text.make, tmp), React.createElement(Popover.Body.make, tmp$2, React.createElement(ReactDayPicker$1.default, ReactDayPicker.makeProps(Caml_option.some(initialMonth ? initialMonth[0] : (
                                      date !== undefined ? Caml_option.valFromOption(date) : new Date()
                                    )), Caml_option.some(new Date()), 1, true, true, undefined, Caml_option.some(Js_dict.fromList(/* :: */[
                                        /* tuple */[
                                          css.selected,
                                          /* `Fn */[
                                            15720,
                                            (function (day) {
                                                return Belt_Option.mapWithDefault(date, false, (function (param) {
                                                              return $$Date.Naive.isSameDay(day, param);
                                                            }));
                                              })
                                          ]
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            css.beforeDate,
                                            /* `Fn */[
                                              15720,
                                              (function (day) {
                                                  return $$Date.Naive.isPast(day);
                                                })
                                            ]
                                          ],
                                          Belt_List.map(calModifiers, (function (param) {
                                                  return /* tuple */[
                                                          toClassName(param[0]),
                                                          param[1]
                                                        ];
                                                }))
                                        ]
                                      ])), Caml_option.some(css), (function (day, param, param$1) {
                                  Curry._1(onDayClick, day);
                                  return Curry._1(popover.hide, undefined);
                                }), Belt_Option.map(onMonthChange, (function (fn, date) {
                                      return Curry._1(fn, date);
                                    })), Belt_Option.map(renderDay, (function (fn, day, modifiers) {
                                      return Curry._2(fn, day, modifiers);
                                    })), undefined, undefined)), tmp$3, calBusy ? React.createElement(Spinner.make, {
                              size: /* MD */1,
                              color: /* Teal */1,
                              bg: /* OpaqueWhiteBg */1,
                              centered: true
                            }) : null));
      }));

var Component = {
  make: make
};

var make$1 = React.forwardRef((function (Props, theRef) {
        var size = Props.size;
        var neighbors = Props.neighbors;
        var className = Props.className;
        var calPlacement = Props.calPlacement;
        var calModifiersOpt = Props.calModifiers;
        var calNote = Props.calNote;
        var calBusy = Props.calBusy;
        var date = Props.date;
        var initialMonth = Props.initialMonth;
        var placeholder = Props.placeholder;
        var testId = Props.testId;
        var onDayClick = Props.onDayClick;
        var onMonthChange = Props.onMonthChange;
        var onDatesClear = Props.onDatesClear;
        var onCalendarShow = Props.onCalendarShow;
        var renderDay = Props.renderDay;
        var calModifiers = calModifiersOpt !== undefined ? calModifiersOpt : /* [] */0;
        var tmp = {
          size: size,
          neighbors: neighbors,
          date: date,
          initialMonth: initialMonth,
          calPlacement: calPlacement,
          calModifiers: calModifiers,
          onDayClick: onDayClick
        };
        if (calNote !== undefined) {
          tmp.calNote = Caml_option.valFromOption(calNote);
        }
        if (calBusy !== undefined) {
          tmp.calBusy = Caml_option.valFromOption(calBusy);
        }
        if (placeholder !== undefined) {
          tmp.placeholder = Caml_option.valFromOption(placeholder);
        }
        if (testId !== undefined) {
          tmp.testId = Caml_option.valFromOption(testId);
        }
        if (onMonthChange !== undefined) {
          tmp.onMonthChange = Caml_option.valFromOption(onMonthChange);
        }
        if (onDatesClear !== undefined) {
          tmp.onDatesClear = Caml_option.valFromOption(onDatesClear);
        }
        if (onCalendarShow !== undefined) {
          tmp.onCalendarShow = Caml_option.valFromOption(onCalendarShow);
        }
        if (renderDay !== undefined) {
          tmp.renderDay = Caml_option.valFromOption(renderDay);
        }
        var tmp$1 = Belt_Option.map((theRef == null) ? undefined : Caml_option.some(theRef), (function (prim) {
                return prim;
              }));
        if (tmp$1 !== undefined) {
          tmp.ref = Caml_option.valFromOption(tmp$1);
        }
        var tmp$2 = {
          children: React.createElement(make, tmp)
        };
        if (className !== undefined) {
          tmp$2.className = Caml_option.valFromOption(className);
        }
        return React.createElement(Popover.make, tmp$2);
      }));

exports.css = css;
exports.Modifier = Modifier;
exports.Component = Component;
exports.make = make$1;
/* css Not a pure module */
