// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Sourcebuster = require("sourcebuster");

function data(param) {
  return {
          firstSourceType: Sourcebuster.get.first.typ,
          firstSourceUtmSource: Sourcebuster.get.first.src,
          firstSourceUtmMedium: Sourcebuster.get.first.mdm,
          firstSourceUtmCampaign: Sourcebuster.get.first.cmp,
          firstSourceUtmContent: Sourcebuster.get.first.cnt,
          firstSourceUtmTerm: Sourcebuster.get.first.trm,
          firstSourceEntrancePoint: Sourcebuster.get.first.ep,
          firstSourceReferer: Sourcebuster.get.first.rf,
          currentSourceType: Sourcebuster.get.current.typ,
          currentSourceUtmSource: Sourcebuster.get.current.src,
          currentSourceUtmMedium: Sourcebuster.get.current.mdm,
          currentSourceUtmCampaign: Sourcebuster.get.current.cmp,
          currentSourceUtmContent: Sourcebuster.get.current.cnt,
          currentSourceUtmTerm: Sourcebuster.get.current.trm,
          currentSourceEntrancePoint: Sourcebuster.get.current.ep,
          currentSourceReferer: Sourcebuster.get.current.rf,
          totalVisitsPriorConversion: Sourcebuster.get.udata.vst
        };
}

exports.data = data;
/* sourcebuster Not a pure module */
