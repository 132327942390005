// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var React = require("react");
var WithTestId = require("../../components/WithTestId/WithTestId.bs.js");
var RadioTabsModuleScss = require("./RadioTabs.module.scss");

var css = RadioTabsModuleScss;

function RadioTabs(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: Cn.$plus(css.tabs, className)
            }, children);
}

function RadioTabs$Tab(Props) {
  Props.size;
  var value = Props.value;
  var checked = Props.checked;
  var disabledOpt = Props.disabled;
  var classNameOpt = Props.className;
  var testId = Props.testId;
  var onChange = Props.onChange;
  var children = Props.children;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("label", {
              className: Cn.$plus(Cn.$plus(css.tab, Cn.on(css.disabled, disabled)), className)
            }, React.createElement(WithTestId.Optional.make, {
                  id: testId,
                  children: React.createElement("input", {
                        className: css.input,
                        checked: checked,
                        type: "radio",
                        value: value,
                        onChange: onChange
                      })
                }), React.createElement(WithTestId.make, {
                  id: "radio-tab-" + (String(value) + ""),
                  children: React.createElement("div", {
                        className: Cn.$plus(Cn.$plus(css.button, css.sm), Cn.on(css.disabled, disabled))
                      }, children)
                }));
}

var Tab = {
  make: RadioTabs$Tab
};

var make = RadioTabs;

exports.css = css;
exports.make = make;
exports.Tab = Tab;
/* css Not a pure module */
