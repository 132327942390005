// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Rest = require("../transport/Rest.bs.js");

function exec(listingId) {
  return Rest.$$fetch(/* `V1 */[
              19227,
              "/listings/" + (String(listingId) + "/favorites")
            ], /* `Post */[
              892711040,
              null
            ], /* Empty */50834029, undefined, undefined);
}

exports.exec = exec;
/* Rest Not a pure module */
