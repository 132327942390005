// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Bool = require("../../libs/Bool.bs.js");
var $$Date = require("../../libs/Date.bs.js");
var Rest = require("../transport/Rest.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Booking = require("../../models/Booking.bs.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromJson(json) {
  return Json_decode.either((function (param) {
                  return Json_decode.map((function (id) {
                                return /* QuoteId */Block.__(0, [id]);
                              }), (function (json) {
                                return Json_decode.field("quoteId", Json_decode.$$int, json);
                              }), param);
                }), (function (param) {
                  return Json_decode.map((function (quote) {
                                return /* Quote */Block.__(1, [quote]);
                              }), (function (json) {
                                return Json_decode.field("quote", Booking.Quote.fromJson, json);
                              }), param);
                }))(json);
}

var Res = {
  fromJson: fromJson
};

function exec(listingId, checkIn, checkOut, adults, children, infants, pets) {
  return Rest.$$fetch(/* `V1 */[
              19227,
              "/trips/quote?listing_id=" + (String(listingId) + ("&check_in=" + ($$Date.Naive.toString(checkIn) + ("&check_out=" + ($$Date.Naive.toString(checkOut) + ("&adults=" + (String(adults) + ("&children=" + (String(children) + ("&infants=" + (String(infants) + ("&pets=" + Bool.toString(pets)))))))))))))
            ], /* Get */3553398, /* `Json */[
              826371656,
              fromJson
            ], undefined, undefined);
}

exports.Res = Res;
exports.exec = exec;
/* Date Not a pure module */
