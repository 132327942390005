// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function ParkingIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var className = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "Parking";
  var tmp = {
    title: title,
    size: size,
    children: React.createElement("g", {
          fill: "none",
          fillRule: "evenodd"
        }, React.createElement("path", {
              style: Icon.style(color),
              d: "M13,2 C13.5522847,2 14,2.44771525 14,3 L14,13 C14,13.5522847 13.5522847,14 13,14 L3,14 C2.44771525,14 2,13.5522847 2,13 L2,3 C2,2.44771525 2.44771525,2 3,2 L13,2 Z M13,3 L3,3 L3,13 L13,13 L13,3 Z M8.5,5 C9.5,5 10,5.69372181 10,7 C10,8.30627819 9.5,9 8.5,9 L7,9 L7,9 L7,11 L6,11 L6,5 L8.5,5 L8.5,5 Z M8,6 L7,6 L7,8 L8,8 C8.75,8 9,7.67159302 9,7 C9,6.32840698 8.75,6 8,6 Z"
            }))
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Icon.make, tmp);
}

var make = ParkingIcon;

exports.make = make;
/* Icon Not a pure module */
