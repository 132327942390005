// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function BathroomsIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var className = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "Bathrooms";
  var tmp = {
    title: title,
    size: size,
    children: React.createElement("path", {
          style: Icon.style(color),
          d: "M2.82352941,0 C2.30373176,0 1.88235294,0.421378824 1.88235294,0.941176471 L1.88235294,7.52941176 L0,7.52941176 L0,13.1764706 C0,14.2160471 0.842757647,15.0588235 1.88235294,15.0588235 L1.88235294,16 L2.82352941,16 L2.82352941,15.0588235 L12.2352941,15.0588235 L12.2352941,16 L13.1764706,16 L13.1764706,15.0588235 C14.2160471,15.0588235 15.0588235,14.2160471 15.0588235,13.1764706 L15.0588235,7.52941176 L2.82352941,7.52941176 L2.82352941,1.41176471 C2.82352941,1.15186824 3.03421176,0.941176471 3.29411765,0.941176471 L5.17647059,0.941176471 C5.43637647,0.941176471 5.64705882,1.15186824 5.64705882,1.41176471 L5.64705882,1.88235294 L4.70588235,2.82352941 L4.70588235,3.76470588 L7.52941176,3.76470588 L7.52941176,2.82352941 L6.58823529,1.88235294 L6.58823529,0.941176471 C6.58823529,0.421378824 6.16687059,0 5.64705882,0 L2.82352941,0 Z M0.941176471,13.1764706 L0.941176471,10.3529412 L1.41176471,10.3529412 L13.6470588,10.3529412 L14.1176471,10.3529412 L14.1176471,13.1764706 C14.1176471,13.6962824 13.6962824,14.1176471 13.1764706,14.1176471 L1.88235294,14.1176471 C1.36255529,14.1176471 0.941176471,13.6962824 0.941176471,13.1764706 Z M1.41176471,9.41176471 L0.941176471,9.41176471 L0.941176471,8.47058824 L14.1176471,8.47058824 L14.1176471,9.41176471 L13.6470588,9.41176471 L1.41176471,9.41176471 Z"
        })
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Icon.make, tmp);
}

var make = BathroomsIcon;

exports.make = make;
/* Icon Not a pure module */
