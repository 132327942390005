// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

function once(fn) {
  var called = {
    contents: false
  };
  return (function (x) {
      if (!called.contents) {
        called.contents = true;
        return Curry._1(fn, x);
      }
      
    });
}

exports.once = once;
/* No side effect */
