// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");

function TooltipWarningIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var title = Props.title;
  return React.createElement(Icon.make, {
              title: title,
              size: size,
              children: React.createElement("path", {
                    style: Icon.style(color),
                    d: "M8 16c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8zm0-1.2c3.755 0 6.8-3.045 6.8-6.8S11.755 1.2 8 1.2 1.2 4.245 1.2 8s3.045 6.8 6.8 6.8zm0-3.6c-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8.442 0 .8.358.8.8 0 .442-.358.8-.8.8zm-.8-5.6c0-.442.355-.8.8-.8.442 0 .8.355.8.8V8c0 .442-.355.8-.8.8-.442 0-.8-.355-.8-.8V5.6z"
                  })
            });
}

var make = TooltipWarningIcon;

exports.make = make;
/* Icon Not a pure module */
