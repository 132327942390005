// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Colors = require("../styles/Colors.bs.js");

var gradientId = "rating-half-gradient";

function StarIcon(Props) {
  var size = Props.size;
  var fill = Props.fill;
  var titleOpt = Props.title;
  var classNameOpt = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Icon.make, {
              title: title,
              size: size,
              className: className,
              children: null
            }, React.createElement("linearGradient", {
                  id: gradientId
                }, React.createElement("stop", {
                      offset: "0%",
                      stopColor: Colors.yellow
                    }), React.createElement("stop", {
                      offset: "40%",
                      stopColor: Colors.yellow
                    }), React.createElement("stop", {
                      offset: "60%",
                      stopColor: Colors.lightGray
                    }), React.createElement("stop", {
                      offset: "100%",
                      stopColor: Colors.lightGray
                    })), React.createElement("path", {
                  d: "M16 6.204l-5.528-0.803-2.472-5.009-2.472 5.009-5.528 0.803 4 3.899-0.944 5.505 4.944-2.599 4.944 2.599-0.944-5.505 4-3.899z",
                  fill: fill !== 782112175 ? (
                      fill >= 803296723 ? "url(#rating-half-gradient)" : Colors.lighterGray
                    ) : Colors.yellow
                }));
}

var make = StarIcon;

exports.gradientId = gradientId;
exports.make = make;
/* Icon Not a pure module */
