// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function AirConditioningIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var className = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "AirConditioning";
  var tmp = {
    title: title,
    size: size,
    children: React.createElement("g", {
          fill: "none",
          fillRule: "evenodd"
        }, React.createElement("path", {
              style: Icon.style(color),
              d: "M13.5,4 C14.3284271,4 15,4.67157288 15,5.5 L15,10.5 C15,11.3284271 14.3284271,12 13.5,12 L2.5,12 C1.67157288,12 1,11.3284271 1,10.5 L1,5.5 C1,4.67157288 1.67157288,4 2.5,4 L13.5,4 Z M13.5,5 L2.5,5 C2.22385763,5 2,5.22385763 2,5.5 L2,10.5 C2,10.7761424 2.22385763,11 2.5,11 L13.5,11 C13.7761424,11 14,10.7761424 14,10.5 L14,5.5 C14,5.22385763 13.7761424,5 13.5,5 Z M10.5,6 C10.7761424,6 11,6.22385763 11,6.5 C11,6.77614237 10.7761424,7 10.5,7 L3.5,7 C3.22385763,7 3,6.77614237 3,6.5 C3,6.22385763 3.22385763,6 3.5,6 L10.5,6 Z M13,8 L13,11 L12,11 L12,9 L4,9 L4,11 L3,11 L3,8 L13,8 Z M3.5,13 C3.77614237,13 4,13.2238576 4,13.5 L4,14.5 C4,14.7761424 3.77614237,15 3.5,15 C3.22385763,15 3,14.7761424 3,14.5 L3,13.5 C3,13.2238576 3.22385763,13 3.5,13 Z M3.5,14 C3.77614237,14 4,13.7761424 4,13.5 L4,14.5 C4,14.2238576 3.77614237,14 3.5,14 C3.22385763,14 3,14.2238576 3,14.5 L3,13.5 C3,13.7761424 3.22385763,14 3.5,14 Z M6.5,13 C6.77614237,13 7,13.2238576 7,13.5 L7,14.5 C7,14.7761424 6.77614237,15 6.5,15 C6.22385763,15 6,14.7761424 6,14.5 L6,13.5 C6,13.2238576 6.22385763,13 6.5,13 Z M6.5,14 C6.77614237,14 7,13.7761424 7,13.5 L7,14.5 C7,14.2238576 6.77614237,14 6.5,14 C6.22385763,14 6,14.2238576 6,14.5 L6,13.5 C6,13.7761424 6.22385763,14 6.5,14 Z M9.5,13 C9.77614237,13 10,13.2238576 10,13.5 L10,14.5 C10,14.7761424 9.77614237,15 9.5,15 C9.22385763,15 9,14.7761424 9,14.5 L9,13.5 C9,13.2238576 9.22385763,13 9.5,13 Z M9.5,14 C9.77614237,14 10,13.7761424 10,13.5 L10,14.5 C10,14.2238576 9.77614237,14 9.5,14 C9.22385763,14 9,14.2238576 9,14.5 L9,13.5 C9,13.7761424 9.22385763,14 9.5,14 Z M12.5,13 C12.7761424,13 13,13.2238576 13,13.5 L13,14.5 C13,14.7761424 12.7761424,15 12.5,15 C12.2238576,15 12,14.7761424 12,14.5 L12,13.5 C12,13.2238576 12.2238576,13 12.5,13 Z M12.5,14 C12.7761424,14 13,13.7761424 13,13.5 L13,14.5 C13,14.2238576 12.7761424,14 12.5,14 C12.2238576,14 12,14.2238576 12,14.5 L12,13.5 C12,13.7761424 12.2238576,14 12.5,14 Z"
            }))
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Icon.make, tmp);
}

var make = AirConditioningIcon;

exports.make = make;
/* Icon Not a pure module */
