// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var React = require("react");
var Js_mapperRt = require("bs-platform/lib/js/js_mapperRt.js");
var SlvBlockModuleScss = require("./SlvBlock.module.scss");

var css = SlvBlockModuleScss;

var jsMapperConstantArray = [
  /* tuple */[
    -1018191652,
    "slv-block--description"
  ],
  /* tuple */[
    -835046650,
    "slv-block--summary"
  ],
  /* tuple */[
    -656729378,
    "slv-block--calendar"
  ],
  /* tuple */[
    -353869381,
    "slv-block--reviews"
  ],
  /* tuple */[
    -145645197,
    "slv-block--rates"
  ],
  /* tuple */[
    -123206494,
    "slv-block--details"
  ],
  /* tuple */[
    75748311,
    "slv-block--rules"
  ],
  /* tuple */[
    252672375,
    "slv-block--amenities"
  ],
  /* tuple */[
    668849890,
    "slv-block--deals"
  ],
  /* tuple */[
    670058401,
    "slv-block--photos"
  ],
  /* tuple */[
    803994504,
    "slv-block--host"
  ],
  /* tuple */[
    1056451157,
    "slv-block--location"
  ]
];

function idToJs(param) {
  return Js_mapperRt.binarySearch(12, param, jsMapperConstantArray);
}

function idFromJs(param) {
  return Js_mapperRt.revSearch(12, jsMapperConstantArray, param);
}

function eq(x1, x2) {
  return idToJs(x1) === idToJs(x2);
}

function SlvBlock(Props) {
  var id = Props.id;
  var children = Props.children;
  return React.createElement("div", {
              className: css.block,
              id: idToJs(id)
            }, children);
}

function SlvBlock$NoIdent(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: css.block
            }, children);
}

var NoIdent = {
  make: SlvBlock$NoIdent
};

function SlvBlock$Title(Props) {
  var children = Props.children;
  return React.createElement("h2", {
              className: css.title
            }, children);
}

var Title = {
  make: SlvBlock$Title
};

function SlvBlock$SubTitle(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("h3", {
              className: Cn.$plus(css.subTitle, className)
            }, children);
}

var SubTitle = {
  make: SlvBlock$SubTitle
};

function SlvBlock$Footer(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement("div", {
              className: Cn.$plus(css.footer, className)
            }, children);
}

var Footer = {
  make: SlvBlock$Footer
};

var make = SlvBlock;

exports.css = css;
exports.idToJs = idToJs;
exports.idFromJs = idFromJs;
exports.eq = eq;
exports.make = make;
exports.NoIdent = NoIdent;
exports.Title = Title;
exports.SubTitle = SubTitle;
exports.Footer = Footer;
/* css Not a pure module */
