// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Modal = require("../../../../styleguide/components/Modal/Modal.bs.js");
var React = require("react");
var SlvBookingMessageModalForm = require("./SlvBookingMessageModalForm.bs.js");
var SlvBookingModalModuleScss = require("./SlvBookingModal.module.scss");
var SlvBookingMessageModalSuccessForNewUser = require("./SlvBookingMessageModalSuccessForNewUser.bs.js");
var SlvBookingMessageModalSuccessForExistingUser = require("./SlvBookingMessageModalSuccessForExistingUser.bs.js");

var css = SlvBookingModalModuleScss;

function SlvBookingMessageModal(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var user = Props.user;
  var screen = Props.screen;
  var viewContext = Props.viewContext;
  var match = booking.modal;
  var match$1 = match.current;
  var match$2 = booking.modal;
  var match$3 = match$2.messageStatus;
  var tmp;
  var exit = 0;
  if (typeof match$3 === "number" || !match$3.tag) {
    exit = 1;
  } else {
    var match$4 = match$3[0];
    if (match$4.tag) {
      exit = 1;
    } else {
      var match$5 = match$4[0];
      var conversationId = match$5.message.conversationId;
      var user$1 = match$5.user;
      tmp = typeof user$1 === "number" ? React.createElement(SlvBookingMessageModalSuccessForExistingUser.make, {
              booking: booking,
              screen: screen,
              conversationId: conversationId
            }) : React.createElement(SlvBookingMessageModalSuccessForNewUser.make, {
              booking: booking,
              conversationId: conversationId,
              user: user$1[1]
            });
    }
  }
  if (exit === 1) {
    tmp = React.createElement(SlvBookingMessageModalForm.make, {
          listing: listing,
          booking: booking,
          user: user,
          screen: screen,
          viewContext: viewContext
        });
  }
  return React.createElement(Modal.make, {
              shown: match$1 !== undefined ? match$1 === 1 : false,
              hide: booking.hideModal,
              className: css.modal,
              children: tmp
            });
}

var make = SlvBookingMessageModal;

exports.css = css;
exports.make = make;
/* css Not a pure module */
