// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Rest = require("../transport/Rest.bs.js");
var Booking = require("../../models/Booking.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function toJson(data) {
  if (data.tag) {
    var data$1 = data[0];
    var __x = data$1.comment;
    return Json_encode.object_(/* :: */[
                /* tuple */[
                  "quoteId",
                  data$1.quoteId
                ],
                /* :: */[
                  /* tuple */[
                    "comment",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), __x)
                  ],
                  /* :: */[
                    /* tuple */[
                      "flexibleDates",
                      data$1.flexibleDates
                    ],
                    /* :: */[
                      /* tuple */[
                        "sbjs",
                        data$1.sbjs
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]);
  }
  var data$2 = data[0];
  var __x$1 = data$2.comment;
  var __x$2 = data$2.phone;
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "quoteId",
                data$2.quoteId
              ],
              /* :: */[
                /* tuple */[
                  "comment",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), __x$1)
                ],
                /* :: */[
                  /* tuple */[
                    "flexibleDates",
                    data$2.flexibleDates
                  ],
                  /* :: */[
                    /* tuple */[
                      "firstName",
                      data$2.firstName
                    ],
                    /* :: */[
                      /* tuple */[
                        "lastName",
                        data$2.lastName
                      ],
                      /* :: */[
                        /* tuple */[
                          "email",
                          data$2.email
                        ],
                        /* :: */[
                          /* tuple */[
                            "phone",
                            Json_encode.nullable((function (prim) {
                                    return prim;
                                  }), __x$2)
                          ],
                          /* :: */[
                            /* tuple */[
                              "sbjs",
                              data$2.sbjs
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function fromJson(json) {
  return {
          booking: Json_decode.field("booking", Booking.fromJson, json),
          navbar: Belt_Option.flatMap(Json_decode.optional((function (__x) {
                      return Json_decode.field("navbar", (function (param) {
                                    return Json_decode.nullable((function (prim) {
                                                  return prim;
                                                }), param);
                                  }), __x);
                    }), json), (function (prim) {
                  if (prim === null) {
                    return ;
                  } else {
                    return Caml_option.some(prim);
                  }
                }))
        };
}

var Res = {
  fromJson: fromJson
};

function exec(req) {
  return Rest.$$fetch(/* `V1 */[
              19227,
              "/trips"
            ], /* `Post */[
              892711040,
              toJson(req)
            ], /* `Json */[
              826371656,
              fromJson
            ], undefined, undefined);
}

var Hc = {
  exec: exec
};

function exec$1(req) {
  return Rest.$$fetch(/* `Integrations */[
              -623562945,
              "/microsites/trips"
            ], /* `Post */[
              892711040,
              toJson(req)
            ], /* `Json */[
              826371656,
              fromJson
            ], undefined, undefined);
}

var Microsites = {
  exec: exec$1
};

exports.Req = Req;
exports.Res = Res;
exports.Hc = Hc;
exports.Microsites = Microsites;
/* Rest Not a pure module */
