// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Modal = require("../../../../styleguide/components/Modal/Modal.bs.js");
var React = require("react");
var SlvBookingInquiryModalForm = require("./SlvBookingInquiryModalForm.bs.js");
var SlvBookingModalModuleScss = require("./SlvBookingModal.module.scss");
var SlvBookingInquiryModalSuccessForNewUser = require("./SlvBookingInquiryModalSuccessForNewUser.bs.js");
var SlvBookingInquiryModalSuccessForExistingUser = require("./SlvBookingInquiryModalSuccessForExistingUser.bs.js");

var css = SlvBookingModalModuleScss;

function SlvBookingInquiryModal(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var user = Props.user;
  var screen = Props.screen;
  var viewContext = Props.viewContext;
  var unclaimedListingModal = Props.unclaimedListingModal;
  var match = booking.modal;
  var match$1 = match.current;
  var match$2 = booking.modal;
  var match$3 = match$2.inquiryStatus;
  var tmp;
  var exit = 0;
  if (typeof match$3 === "number" || !match$3.tag) {
    exit = 1;
  } else {
    var match$4 = match$3[0];
    if (match$4.tag) {
      exit = 1;
    } else {
      var match$5 = match$4[0];
      var match$6 = match$5.booking;
      var conversationId = match$6.conversationId;
      var bookingId = match$6.id;
      var user$1 = match$5.user;
      tmp = typeof user$1 === "number" ? React.createElement(SlvBookingInquiryModalSuccessForExistingUser.make, {
              booking: booking,
              screen: screen,
              bookingId: bookingId,
              conversationId: conversationId
            }) : React.createElement(SlvBookingInquiryModalSuccessForNewUser.make, {
              booking: booking,
              bookingId: bookingId,
              conversationId: conversationId,
              user: user$1[1]
            });
    }
  }
  if (exit === 1) {
    tmp = React.createElement(SlvBookingInquiryModalForm.make, {
          listing: listing,
          booking: booking,
          user: user,
          viewContext: viewContext,
          unclaimedListingModal: unclaimedListingModal
        });
  }
  return React.createElement(Modal.make, {
              shown: match$1 !== undefined ? match$1 === 0 : false,
              hide: booking.hideModal,
              className: css.modal,
              testId: "booking-confirmation-modal",
              children: tmp
            });
}

var make = SlvBookingInquiryModal;

exports.css = css;
exports.make = make;
/* css Not a pure module */
