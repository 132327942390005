// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function SelectArrowDownIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var className = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "Select";
  var tmp = {
    title: title,
    size: size,
    children: React.createElement("polygon", {
          style: Icon.style(color),
          points: "2 4 8 10 14 4 15 5 8 12 1 5"
        })
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Icon.make, tmp);
}

var make = SelectArrowDownIcon;

exports.make = make;
/* Icon Not a pure module */
