// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Modal = require("../../../../styleguide/components/Modal/Modal.bs.js");
var React = require("react");
var SlvBookingMessageModalSuccessMessage = require("./SlvBookingMessageModalSuccessMessage.bs.js");
var SlvBookingMessageModalSuccessForExistingUserModuleScss = require("./SlvBookingMessageModalSuccessForExistingUser.module.scss");

var css = SlvBookingMessageModalSuccessForExistingUserModuleScss;

function SlvBookingMessageModalSuccessForExistingUser(Props) {
  var booking = Props.booking;
  var screen = Props.screen;
  var conversationId = Props.conversationId;
  return React.createElement("div", {
              className: css.wrapper
            }, React.createElement(Modal.Title.make, {
                  screen: screen,
                  className: css.title,
                  children: "Your message has been sent!"
                }), React.createElement(SlvBookingMessageModalSuccessMessage.make, {
                  booking: booking,
                  conversationId: conversationId
                }));
}

var make = SlvBookingMessageModalSuccessForExistingUser;

exports.css = css;
exports.make = make;
/* css Not a pure module */
