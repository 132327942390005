// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Fn = require("../../../libs/Fn.bs.js");
var Qs = require("../../../bindings/Qs.bs.js");
var Qs$1 = require("qs");
var Api = require("../../../api/Api.bs.js");
var Gtm = require("../../../libs/Gtm.bs.js");
var Opt = require("../../../libs/Opt.bs.js");
var Bool = require("../../../libs/Bool.bs.js");
var $$Date = require("../../../libs/Date.bs.js");
var Sbjs = require("../../../libs/Sbjs.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Hooks = require("../../../libs/Hooks.bs.js");
var React = require("react");
var $$RegExp = require("../../../libs/RegExp.bs.js");
var Belt_Id = require("bs-platform/lib/js/belt_Id.js");
var $$Promise = require("../../../bindings/Promise.bs.js");
var Belt_Int = require("bs-platform/lib/js/belt_Int.js");
var Belt_Set = require("bs-platform/lib/js/belt_Set.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Debouncer = require("re-debouncer/src/Debouncer.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var NonSecure = require("nanoid/non-secure");
var Difference_in_calendar_months = require("date-fns/difference_in_calendar_months");

function toString(param) {
  if (typeof param === "number") {
    if (param >= 203839477) {
      if (param !== 459612177) {
        if (param >= 710459028) {
          if (param >= 947537993) {
            return "Sorry, you can’t bring pets with you";
          } else {
            return "Sorry, children are not allowed";
          }
        } else if (param >= 580045672) {
          return "Check out date can’t be before check in";
        } else {
          return "Pick check out date";
        }
      } else {
        return "You can’t check in in the past";
      }
    } else if (param !== -140884172) {
      if (param >= 129262778) {
        if (param >= 183515014) {
          return "You can’t check in and check out on the same day";
        } else {
          return "Pick check in date";
        }
      } else if (param >= 33060331) {
        return "These dates are not available";
      } else {
        return "Sorry, infants are not allowed";
      }
    } else {
      return "Pick check in and check out dates";
    }
  }
  if (param[0] >= -87491142) {
    var minStay = param[1];
    var nights = minStay === 1 ? "night" : "nights";
    return "Minimum stay is " + (String(minStay) + (" " + (String(nights) + "")));
  }
  var maxGuests = param[1];
  var guests = maxGuests === 1 ? "guest" : "guests";
  return "This listing can accommodate max " + (String(maxGuests) + (" " + (String(guests) + "")));
}

var ValidationError = {
  toString: toString
};

function quote(param) {
  if (!param) {
    return ;
  }
  var match = param[0];
  if (match[0] >= 59168818) {
    var match$1 = match[1];
    if (match$1.tag) {
      return ;
    }
    var match$2 = match$1[0];
    if (typeof match$2 === "number") {
      return ;
    } else {
      return match$2[1];
    }
  }
  var match$3 = match[1][0];
  if (typeof match$3 === "number") {
    return ;
  } else {
    return match$3[1][0];
  }
}

function displayError(param) {
  if (!param) {
    return ;
  }
  var match = param[0];
  if (match[0] < 59168818) {
    return ;
  }
  var match$1 = match[1];
  if (!match$1.tag) {
    return ;
  }
  var match$2 = match$1[0];
  if (typeof match$2 === "number") {
    if (match$2 >= 984370086) {
      return "Something went wrong during quote fetch";
    } else {
      return "Something went wrong during calendar data fetch";
    }
  } else {
    return toString(match$2[1]);
  }
}

var Status = {
  quote: quote,
  displayError: displayError
};

function yearFromDate(x) {
  return $$Date.Naive.year(x);
}

function monthFromDate(x) {
  return $$Date.Naive.month(x) + 1 | 0;
}

function make(x) {
  var year = String($$Date.Naive.year(x));
  var month = String(monthFromDate(x));
  return "" + (String(year) + ("-" + (String(month) + "")));
}

var cmp = Caml_obj.caml_compare;

var Comparable = Belt_Id.MakeComparable({
      cmp: cmp
    });

function make$1(param) {
  return Belt_Set.make(Comparable);
}

var $$Set = {
  make: make$1
};

var Month = {
  make: make,
  Comparable: Comparable,
  $$Set: $$Set
};

function getDay(days, day) {
  return Belt_MapString.get(days, $$Date.Naive.toString(day));
}

function mergeLoadedMonths(year, month, quantity, months) {
  var startDate = new Date(year, month - 1 | 0);
  var months$1 = months;
  for(var i = 0; i < quantity; ++i){
    months$1 = Belt_Set.add(months$1, make($$Date.Naive.addMonths(startDate, i)));
  }
  return months$1;
}

function mergeCalendarDays(days, dict) {
  return Belt_Array.reduce(days, dict, (function (dict, day) {
                return Belt_MapString.set(dict, day.onDate, day);
              }));
}

var Calendar = {
  yearFromDate: yearFromDate,
  monthFromDate: monthFromDate,
  Month: Month,
  getDay: getDay,
  mergeLoadedMonths: mergeLoadedMonths,
  mergeCalendarDays: mergeCalendarDays
};

var Defaults = {
  adults: 1,
  children: 0,
  infants: 0,
  pets: false,
  flexibleDates: false
};

var checkIn = "check_in";

var checkOut = "check_out";

var adults = "adults";

var children = "children";

var infants = "infants";

var pets = "pets";

var flexibleDates = "flexible_dates";

var UrlKeys = {
  checkIn: checkIn,
  checkOut: checkOut,
  adults: adults,
  children: children,
  infants: infants,
  pets: pets,
  flexibleDates: flexibleDates
};

var Validation = { };

var $$default = {
  checkIn: undefined,
  checkOut: undefined,
  adults: 1,
  children: 0,
  infants: 0,
  pets: false,
  flexibleDates: false,
  comment: undefined
};

function toUrlParams(state) {
  var x = state.checkIn;
  var x$1 = state.checkOut;
  return Qs$1.stringify(Json_encode.object_(/* :: */[
                  /* tuple */[
                    checkIn,
                    x !== undefined ? $$Date.Naive.toJson(Caml_option.valFromOption(x)) : ""
                  ],
                  /* :: */[
                    /* tuple */[
                      checkOut,
                      x$1 !== undefined ? $$Date.Naive.toJson(Caml_option.valFromOption(x$1)) : ""
                    ],
                    /* :: */[
                      /* tuple */[
                        adults,
                        String(state.adults)
                      ],
                      /* :: */[
                        /* tuple */[
                          children,
                          String(state.children)
                        ],
                        /* :: */[
                          /* tuple */[
                            infants,
                            String(state.infants)
                          ],
                          /* :: */[
                            /* tuple */[
                              pets,
                              Bool.toString(state.pets)
                            ],
                            /* :: */[
                              /* tuple */[
                                flexibleDates,
                                Bool.toString(state.flexibleDates)
                              ],
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

function fromUrlParams(json) {
  return {
          checkIn: Belt_Option.flatMap(Belt_Option.flatMap(Json_decode.optional((function (param) {
                          return Json_decode.field(checkIn, (function (param) {
                                        return Json_decode.nullable(Json_decode.string, param);
                                      }), param);
                        }), json), (function (prim) {
                      if (prim === null) {
                        return ;
                      } else {
                        return Caml_option.some(prim);
                      }
                    })), (function (x) {
                  if (x === "") {
                    return ;
                  } else {
                    return Caml_option.some($$Date.Naive.fromString(x));
                  }
                })),
          checkOut: Belt_Option.flatMap(Belt_Option.flatMap(Json_decode.optional((function (param) {
                          return Json_decode.field(checkOut, (function (param) {
                                        return Json_decode.nullable(Json_decode.string, param);
                                      }), param);
                        }), json), (function (prim) {
                      if (prim === null) {
                        return ;
                      } else {
                        return Caml_option.some(prim);
                      }
                    })), (function (x) {
                  if (x === "") {
                    return ;
                  } else {
                    return Caml_option.some($$Date.Naive.fromString(x));
                  }
                })),
          adults: Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Json_decode.optional((function (param) {
                              return Json_decode.field(adults, (function (param) {
                                            return Json_decode.nullable(Json_decode.string, param);
                                          }), param);
                            }), json), (function (prim) {
                          if (prim === null) {
                            return ;
                          } else {
                            return Caml_option.some(prim);
                          }
                        })), Belt_Int.fromString), 1),
          children: Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Json_decode.optional((function (param) {
                              return Json_decode.field(children, (function (param) {
                                            return Json_decode.nullable(Json_decode.string, param);
                                          }), param);
                            }), json), (function (prim) {
                          if (prim === null) {
                            return ;
                          } else {
                            return Caml_option.some(prim);
                          }
                        })), Belt_Int.fromString), 0),
          infants: Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Json_decode.optional((function (param) {
                              return Json_decode.field(infants, (function (param) {
                                            return Json_decode.nullable(Json_decode.string, param);
                                          }), param);
                            }), json), (function (prim) {
                          if (prim === null) {
                            return ;
                          } else {
                            return Caml_option.some(prim);
                          }
                        })), Belt_Int.fromString), 0),
          pets: Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Json_decode.optional((function (param) {
                              return Json_decode.field(pets, (function (param) {
                                            return Json_decode.nullable(Json_decode.string, param);
                                          }), param);
                            }), json), (function (prim) {
                          if (prim === null) {
                            return ;
                          } else {
                            return Caml_option.some(prim);
                          }
                        })), Bool.fromString), false),
          flexibleDates: Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Json_decode.optional((function (param) {
                              return Json_decode.field(flexibleDates, (function (param) {
                                            return Json_decode.nullable(Json_decode.string, param);
                                          }), param);
                            }), json), (function (prim) {
                          if (prim === null) {
                            return ;
                          } else {
                            return Caml_option.some(prim);
                          }
                        })), Bool.fromString), false),
          comment: undefined
        };
}

function isDayInPastRelativeToListingTimezone(day, listingCurrentDate) {
  return $$Date.Naive.isBefore($$Date.Naive.startOfDay(day), $$Date.Naive.startOfDay(listingCurrentDate));
}

function validateDates(state, listing) {
  var match = state.checkIn;
  var match$1 = state.checkOut;
  if (match === undefined) {
    if (match$1 !== undefined) {
      return /* Error */Block.__(1, [/* NoCheckInDate */129262778]);
    } else {
      return /* Error */Block.__(1, [/* NoCheckInAndCheckOutDates */-140884172]);
    }
  }
  if (match$1 === undefined) {
    return /* Error */Block.__(1, [/* NoCheckOutDate */203839477]);
  }
  var checkOut = Caml_option.valFromOption(match$1);
  var checkIn = Caml_option.valFromOption(match);
  if ($$Date.Naive.isSameDay(checkIn, checkOut)) {
    return /* Error */Block.__(1, [/* CheckInAndCheckOutSameDay */183515014]);
  } else if (isDayInPastRelativeToListingTimezone(checkIn, listing.currentDate)) {
    return /* Error */Block.__(1, [/* CheckInInThePast */459612177]);
  } else if ($$Date.Naive.isBefore(checkOut, checkIn)) {
    return /* Error */Block.__(1, [/* CheckOutBerforeCheckIn */580045672]);
  } else {
    return /* Ok */Block.__(0, [undefined]);
  }
}

function isDayAvailble(calendarDay, sameDayCheckInAndOut, positionInRange) {
  if (calendarDay.morningState.tag) {
    if (calendarDay.eveningState.tag || !sameDayCheckInAndOut || positionInRange !== 332064784) {
      return /* Error */Block.__(1, [undefined]);
    } else {
      return /* Ok */Block.__(0, [undefined]);
    }
  } else if (calendarDay.eveningState.tag && (!sameDayCheckInAndOut || positionInRange < 847656566)) {
    return /* Error */Block.__(1, [undefined]);
  } else {
    return /* Ok */Block.__(0, [undefined]);
  }
}

function areAllDaysAvailable(checkIn, checkOut, calendarDays, listing) {
  var result = /* Ok */Block.__(0, [undefined]);
  var iteration = 0;
  var daysDiff = $$Date.Naive.differenceInCalendarDays(checkOut, checkIn);
  while(Belt_Result.isOk(result) && daysDiff >= iteration) {
    var day = $$Date.Naive.addDays(checkIn, iteration);
    var calendarDay = getDay(calendarDays, day);
    var positionInRange = $$Date.Naive.isSameDay(day, checkIn) ? /* First */332064784 : (
        $$Date.Naive.isSameDay(day, checkOut) ? /* Last */847656566 : /* InBetween */-293030845
      );
    var match = Belt_Option.map(calendarDay, (function(positionInRange){
        return function (x) {
          return isDayAvailble(x, listing.sameDayCheckInAndOut, positionInRange);
        }
        }(positionInRange)));
    if (!(match !== undefined && !match.tag)) {
      result = /* Error */Block.__(1, [undefined]);
    }
    iteration = iteration + 1 | 0;
  };
  return result;
}

function validateMinStayAndAviability(state, calendarDays, listing) {
  var match = state.checkIn;
  var match$1 = state.checkOut;
  if (match === undefined) {
    if (match$1 !== undefined) {
      return /* Error */Block.__(1, [/* NoCheckInDate */129262778]);
    } else {
      return /* Error */Block.__(1, [/* NoCheckInAndCheckOutDates */-140884172]);
    }
  }
  if (match$1 === undefined) {
    return /* Error */Block.__(1, [/* NoCheckOutDate */203839477]);
  }
  var checkOut = Caml_option.valFromOption(match$1);
  var checkIn = Caml_option.valFromOption(match);
  var checkInCalendarDay = Belt_MapString.get(calendarDays, $$Date.Naive.toString(checkIn));
  if (checkInCalendarDay === undefined) {
    return /* Error */Block.__(1, [/* NoCheckInDate */129262778]);
  }
  if ($$Date.Naive.differenceInDays(checkOut, checkIn) < checkInCalendarDay.minimumStay) {
    return /* Error */Block.__(1, [/* `MinStayViolation */[
                -87491142,
                checkInCalendarDay.minimumStay
              ]]);
  }
  var match$2 = areAllDaysAvailable(checkIn, checkOut, calendarDays, listing);
  if (match$2.tag) {
    return /* Error */Block.__(1, [/* DatesUnavailable */33060331]);
  } else {
    return /* Ok */Block.__(0, [undefined]);
  }
}

function validateGuests(state, listing) {
  var maxGuests = listing.maxGuests;
  var infants = state.infants;
  var children = state.children;
  var match = listing.systemHouseRules;
  var exit = 0;
  if (match.children.status !== 1) {
    exit = 2;
  } else {
    if (children > 0) {
      return /* Error */Block.__(1, [/* ChildrenNotAllowed */710459028]);
    }
    exit = 2;
  }
  if (exit === 2) {
    var exit$1 = 0;
    if (match.infants.status !== 1) {
      exit$1 = 3;
    } else {
      if (infants > 0) {
        return /* Error */Block.__(1, [/* InfantsNotAllowed */-465866846]);
      }
      exit$1 = 3;
    }
    if (exit$1 === 3 && match.pets.status === 1 && state.pets) {
      return /* Error */Block.__(1, [/* PetsNotAllowed */947537993]);
    }
    
  }
  if (maxGuests !== undefined && maxGuests < ((state.adults + children | 0) + infants | 0)) {
    return /* Error */Block.__(1, [/* `MaxGuestsViolation */[
                -735056538,
                maxGuests
              ]]);
  } else {
    return /* Ok */Block.__(0, [undefined]);
  }
}

function validate(input, listing, calendarDays) {
  return Belt_Result.flatMap(Belt_Result.flatMap(validateDates(input, listing), (function (param) {
                    return validateMinStayAndAviability(input, calendarDays, listing);
                  })), (function (param) {
                return validateGuests(input, listing);
              }));
}

function syncToUrl(input) {
  var __x = window.history;
  __x.replaceState(window.history.state, "", window.location.href.split("?")[0] + ("?" + toUrlParams(input)));
  
}

var Input = {
  Defaults: Defaults,
  UrlKeys: UrlKeys,
  Validation: Validation,
  $$default: $$default,
  toUrlParams: toUrlParams,
  fromUrlParams: fromUrlParams,
  isDayInPastRelativeToListingTimezone: isDayInPastRelativeToListingTimezone,
  validateDates: validateDates,
  isDayAvailble: isDayAvailble,
  areAllDaysAvailable: areAllDaysAvailable,
  validateMinStayAndAviability: validateMinStayAndAviability,
  validateGuests: validateGuests,
  validate: validate,
  syncToUrl: syncToUrl
};

var $$fetch = Debouncer.make(500, (function (param) {
        var onError = param.onError;
        var onPart = param.onPart;
        var onComplete = param.onComplete;
        var intervalId = param.intervalId;
        var input = param.input;
        var cancelRequests = function (param) {
          return Belt_Option.mapWithDefault(intervalId.current, undefined, (function (prim) {
                        clearInterval(prim);
                        
                      }));
        };
        return $$Promise.wait(Api.getQuoteId(input.listingId, input.checkIn, input.checkOut, input.adults, input.children, input.infants, input.pets), (function (param) {
                      if (param.tag) {
                        return Curry._1(onError, undefined);
                      }
                      var quote = param[0];
                      var quoteId;
                      if (quote.tag) {
                        var quote$1 = quote[0];
                        if (quote$1.status === 1) {
                          return Curry._1(onComplete, quote$1);
                        }
                        quoteId = quote$1.id;
                      } else {
                        quoteId = quote[0];
                      }
                      var attempts = {
                        contents: 0
                      };
                      intervalId.current = Caml_option.some(setInterval((function (param) {
                                  return $$Promise.wait(Api.getQuote(quoteId), (function (quote) {
                                                if (quote.tag) {
                                                  cancelRequests(undefined);
                                                  return Curry._1(onError, undefined);
                                                }
                                                var quote$1 = quote[0];
                                                var match = quote$1.status;
                                                switch (match) {
                                                  case /* Pending */0 :
                                                      attempts.contents = attempts.contents + 1 | 0;
                                                      if (attempts.contents <= 120) {
                                                        var match$1 = quote$1.providers;
                                                        if (match$1.length !== 0) {
                                                          return Curry._1(onPart, quote$1);
                                                        } else {
                                                          return ;
                                                        }
                                                      }
                                                      cancelRequests(undefined);
                                                      return Curry._1(onError, undefined);
                                                  case /* Ready */1 :
                                                      cancelRequests(undefined);
                                                      return Curry._1(onComplete, quote$1);
                                                  case /* Failed */2 :
                                                      cancelRequests(undefined);
                                                      return Curry._1(onError, undefined);
                                                  
                                                }
                                              }));
                                }), 500));
                      
                    }));
      }));

var Quote = {
  intervalBetweenQuoteRequests: 500,
  maxAttempts: 120,
  $$fetch: $$fetch
};

var empty = {
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  phone: undefined
};

function validateFirstName(firstName) {
  if (firstName !== undefined && firstName !== "") {
    return /* Ok */Block.__(0, [undefined]);
  } else {
    return /* Error */Block.__(1, ["First Name is required"]);
  }
}

function validateLastName(lastName) {
  if (lastName !== undefined && lastName !== "") {
    return /* Ok */Block.__(0, [undefined]);
  } else {
    return /* Error */Block.__(1, ["Last Name is required"]);
  }
}

function validateEmail(email) {
  if (email !== undefined && email !== "") {
    if ($$RegExp.email(undefined).test(email)) {
      return /* Ok */Block.__(0, [undefined]);
    } else {
      return /* Error */Block.__(1, ["Email is invalid"]);
    }
  } else {
    return /* Error */Block.__(1, ["Email is required"]);
  }
}

function validateFields(input) {
  return {
          firstName: validateFirstName(input.firstName),
          lastName: validateLastName(input.lastName),
          email: validateEmail(input.email)
        };
}

function validate$1(input) {
  var match = validateFields(input);
  var match$1 = match.firstName;
  if (match$1 === undefined) {
    return /* Error */Block.__(1, [undefined]);
  }
  if (match$1.tag) {
    return /* Error */Block.__(1, [undefined]);
  }
  var match$2 = match.lastName;
  if (match$2 === undefined) {
    return /* Error */Block.__(1, [undefined]);
  }
  if (match$2.tag) {
    return /* Error */Block.__(1, [undefined]);
  }
  var match$3 = match.email;
  if (match$3 !== undefined && !match$3.tag) {
    return /* Ok */Block.__(0, [undefined]);
  } else {
    return /* Error */Block.__(1, [undefined]);
  }
}

var NewUser = {
  empty: empty,
  validateFirstName: validateFirstName,
  validateLastName: validateLastName,
  validateEmail: validateEmail,
  validateFields: validateFields,
  validate: validate$1
};

function validateMessage(message) {
  if (message !== undefined && message !== "") {
    return /* Ok */Block.__(0, [undefined]);
  } else {
    return /* Error */Block.__(1, ["Message is required"]);
  }
}

var MessageInput = {
  validateMessage: validateMessage
};

var Modal = { };

var notifyOnBookingWidgetInteraction = Fn.once((function (listingId) {
        return Gtm.Slv.bookingWidgetInteraction(listingId);
      }));

var notifyOnInquiryModalShown = Fn.once((function (listingId) {
        return Gtm.Slv.inquiryModalShown(listingId);
      }));

var notifyOnInquiryModalFormInteraction = Fn.once((function (listingId) {
        return Gtm.Slv.inquiryModalFormInteraction(listingId);
      }));

var notifyOnInquiryModalFormSubmission = Fn.once((function (listingId) {
        return Gtm.Slv.inquiryModalFormSubmitted(listingId);
      }));

var notifyOnMessageModalShown = Fn.once((function (listingId) {
        return Gtm.Slv.messageModalShown(listingId);
      }));

var notifyOnMessageModalFormInteraction = Fn.once((function (listingId) {
        return Gtm.Slv.messageModalFormInteraction(listingId);
      }));

var notifyOnMessageModalFormSubmission = Fn.once((function (listingId) {
        return Gtm.Slv.messageModalFormSubmitted(listingId);
      }));

var notifyOnPhoneShow = Fn.once((function (listingId) {
        return Gtm.Slv.phoneShown(listingId);
      }));

var Gtm$1 = {
  notifyOnBookingWidgetInteraction: notifyOnBookingWidgetInteraction,
  notifyOnInquiryModalShown: notifyOnInquiryModalShown,
  notifyOnInquiryModalFormInteraction: notifyOnInquiryModalFormInteraction,
  notifyOnInquiryModalFormSubmission: notifyOnInquiryModalFormSubmission,
  notifyOnMessageModalShown: notifyOnMessageModalShown,
  notifyOnMessageModalFormInteraction: notifyOnMessageModalFormInteraction,
  notifyOnMessageModalFormSubmission: notifyOnMessageModalFormSubmission,
  notifyOnPhoneShow: notifyOnPhoneShow
};

function useBooking(listing, user, viewContext) {
  var quoteRequestIntervalId = React.useRef(undefined);
  var initialState = React.useMemo((function () {
          return {
                  status: /* DerivingInitialStateFromUrlAndLoadingCalendarData */0,
                  input: $$default,
                  calendar: {
                    status: /* FetchingCalendarDays */0,
                    months: Belt_Set.make(Comparable),
                    days: undefined
                  },
                  modal: {
                    current: undefined,
                    inquiryStatus: /* Editing */Block.__(0, [/* BeforeSubmission */-377245621]),
                    messageStatus: /* Editing */Block.__(0, [/* BeforeSubmission */-377245621])
                  },
                  newUser: empty
                };
        }), ([]));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* MountWithoutInitialInputAndCalendarData */0 :
                  var init = state.calendar;
                  return /* Update */Block.__(0, [{
                              status: /* Ready */[/* `Hibernate */[
                                  59168818,
                                  /* Ok */Block.__(0, [/* NoQuote */-28913893])
                                ]],
                              input: state.input,
                              calendar: {
                                status: /* Ready */[/* Ok */Block.__(0, [undefined])],
                                months: init.months,
                                days: init.days
                              },
                              modal: state.modal,
                              newUser: state.newUser
                            }]);
              case /* FailCalendarDaysFetchingOnMountWithoutInitialInput */1 :
              case /* FailCalendarDaysFetching */2 :
                  break;
              case /* HideModal */3 :
                  var init$1 = state.modal;
                  return /* Update */Block.__(0, [{
                              status: state.status,
                              input: state.input,
                              calendar: state.calendar,
                              modal: {
                                current: undefined,
                                inquiryStatus: init$1.inquiryStatus,
                                messageStatus: init$1.messageStatus
                              },
                              newUser: state.newUser
                            }]);
              case /* SubmitInquiry */4 :
                  var match = user.id;
                  var userStatus = match !== undefined ? /* Ok */Block.__(0, [undefined]) : validate$1(state.newUser);
                  var match$1 = state.modal;
                  var match$2 = state.status;
                  var exit = 0;
                  var messageStatus;
                  var match$3 = match$1.current;
                  var exit$1 = 0;
                  var exit$2 = 0;
                  if (match$3 === 0) {
                    var match$4 = match$1.inquiryStatus;
                    var exit$3 = 0;
                    var exit$4 = 0;
                    if (typeof match$4 === "number") {
                      return /* NoUpdate */0;
                    }
                    if (match$4.tag) {
                      if (!match$4[0].tag) {
                        return /* NoUpdate */0;
                      }
                      exit$4 = 6;
                    } else {
                      exit$4 = 6;
                    }
                    if (exit$4 === 6) {
                      if (match$2) {
                        var match$5 = match$2[0];
                        if (typeof match$5 === "number" || match$5[0] !== 59168818) {
                          exit$3 = 5;
                        } else {
                          var match$6 = match$5[1];
                          if (match$6.tag) {
                            exit$3 = 5;
                          } else {
                            if (match$6[0] === -28913893) {
                              return /* NoUpdate */0;
                            }
                            exit$3 = 5;
                          }
                        }
                      } else {
                        exit$3 = 5;
                      }
                    }
                    if (exit$3 === 5) {
                      if (match$2) {
                        var match$7 = match$2[0];
                        if (typeof match$7 === "number" || match$7[0] !== 59168818) {
                          exit$2 = 4;
                        } else {
                          var match$8 = match$7[1];
                          var messageStatus$1 = match$1.messageStatus;
                          if (match$8.tag) {
                            messageStatus = messageStatus$1;
                            exit = 2;
                          } else {
                            var match$9 = match$8[0];
                            if (typeof match$9 === "number" || match$9[0] !== -249588964) {
                              exit$1 = 3;
                            } else {
                              var quote = match$9[1];
                              if (!userStatus.tag) {
                                return /* UpdateWithSideEffects */Block.__(1, [
                                          {
                                            status: state.status,
                                            input: state.input,
                                            calendar: state.calendar,
                                            modal: {
                                              current: /* InquiryModal */0,
                                              inquiryStatus: /* Submitting */0,
                                              messageStatus: messageStatus$1
                                            },
                                            newUser: state.newUser
                                          },
                                          (function (param) {
                                              var dispatch = param.dispatch;
                                              var match = user.id;
                                              if (match !== undefined) {
                                                var payload = /* WithoutNewUser */Block.__(1, [{
                                                      quoteId: quote.id,
                                                      comment: state.input.comment,
                                                      flexibleDates: state.input.flexibleDates,
                                                      sbjs: Sbjs.data(undefined)
                                                    }]);
                                                return $$Promise.wait(viewContext >= 2 ? Api.postTripFromMicrosite(payload) : Api.postTripFromHc(payload), (function (param) {
                                                              if (param.tag) {
                                                                return Curry._1(dispatch, /* FailInquirySubmission */5);
                                                              } else {
                                                                return Curry._1(dispatch, /* SucceedInquirySubmissionWithoutNewUser */Block.__(15, [param[0].booking]));
                                                              }
                                                            }));
                                              }
                                              var firstName = Belt_Option.getExn(state.newUser.firstName);
                                              var lastName = Belt_Option.getExn(state.newUser.lastName);
                                              var email = Belt_Option.getExn(state.newUser.email);
                                              var phone = state.newUser.phone;
                                              var payload$1 = /* WithNewUser */Block.__(0, [{
                                                    quoteId: quote.id,
                                                    comment: state.input.comment,
                                                    flexibleDates: state.input.flexibleDates,
                                                    firstName: firstName,
                                                    lastName: lastName,
                                                    email: email,
                                                    phone: phone,
                                                    sbjs: Sbjs.data(undefined)
                                                  }]);
                                              return $$Promise.wait(viewContext >= 2 ? Api.postTripFromMicrosite(payload$1) : Api.postTripFromHc(payload$1), (function (param) {
                                                            if (param.tag) {
                                                              return Curry._1(dispatch, /* FailInquirySubmission */5);
                                                            }
                                                            var match = param[0];
                                                            var user = {
                                                              firstName: firstName,
                                                              lastName: lastName,
                                                              email: email,
                                                              phone: phone
                                                            };
                                                            return Curry._1(dispatch, /* SucceedInquirySubmissionWithNewUser */Block.__(16, [
                                                                          user,
                                                                          match.booking,
                                                                          match.navbar
                                                                        ]));
                                                          }));
                                            })
                                        ]);
                              }
                              exit$1 = 3;
                            }
                          }
                        }
                      } else {
                        exit$2 = 4;
                      }
                    }
                    
                  } else {
                    exit$2 = 4;
                  }
                  if (exit$2 === 4) {
                    if (!match$2) {
                      return /* NoUpdate */0;
                    }
                    var match$10 = match$2[0];
                    if (typeof match$10 === "number") {
                      exit$1 = 3;
                    } else {
                      if (match$10[0] === -358891244) {
                        return /* NoUpdate */0;
                      }
                      exit$1 = 3;
                    }
                  }
                  if (exit$1 === 3) {
                    if (match$3 === undefined) {
                      return /* NoUpdate */0;
                    }
                    if (match$3 !== 0) {
                      return /* NoUpdate */0;
                    }
                    messageStatus = match$1.messageStatus;
                    exit = 2;
                  }
                  if (exit === 2) {
                    return /* Update */Block.__(0, [{
                                status: state.status,
                                input: state.input,
                                calendar: state.calendar,
                                modal: {
                                  current: /* InquiryModal */0,
                                  inquiryStatus: /* Editing */Block.__(0, [/* AfterInvalidSubmissionAttempt */468803782]),
                                  messageStatus: messageStatus
                                },
                                newUser: state.newUser
                              }]);
                  }
                  break;
              case /* FailInquirySubmission */5 :
                  var init$2 = state.modal;
                  return /* Update */Block.__(0, [{
                              status: state.status,
                              input: state.input,
                              calendar: state.calendar,
                              modal: {
                                current: init$2.current,
                                inquiryStatus: /* Submitted */Block.__(1, [/* Error */Block.__(1, [undefined])]),
                                messageStatus: init$2.messageStatus
                              },
                              newUser: state.newUser
                            }]);
              case /* FailMessageSubmission */6 :
                  var init$3 = state.modal;
                  return /* Update */Block.__(0, [{
                              status: state.status,
                              input: state.input,
                              calendar: state.calendar,
                              modal: {
                                current: init$3.current,
                                inquiryStatus: init$3.inquiryStatus,
                                messageStatus: /* Submitted */Block.__(1, [/* Error */Block.__(1, [undefined])])
                              },
                              newUser: state.newUser
                            }]);
              
            }
          } else {
            switch (action.tag | 0) {
              case /* MountWithoutInitialInputAndWithCalendarData */0 :
                  return /* Update */Block.__(0, [{
                              status: /* Ready */[/* `Hibernate */[
                                  59168818,
                                  /* Ok */Block.__(0, [/* NoQuote */-28913893])
                                ]],
                              input: state.input,
                              calendar: {
                                status: /* Ready */[/* Ok */Block.__(0, [undefined])],
                                months: action[0],
                                days: action[1]
                              },
                              modal: state.modal,
                              newUser: state.newUser
                            }]);
              case /* MountWithValidInitialInputAndCalendarData */1 :
                  return /* UpdateWithSideEffects */Block.__(1, [
                            {
                              status: state.status,
                              input: action[0],
                              calendar: {
                                status: /* Ready */[/* Ok */Block.__(0, [undefined])],
                                months: action[1],
                                days: action[2]
                              },
                              modal: state.modal,
                              newUser: state.newUser
                            },
                            (function (param) {
                                syncToUrl(param.state.input);
                                return Curry._1(param.dispatch, /* FetchQuote */Block.__(7, [NonSecure()]));
                              })
                          ]);
              case /* MountWithInvalidInitialInputAndCalendarData */2 :
                  return /* UpdateWithSideEffects */Block.__(1, [
                            {
                              status: /* Ready */[/* `Hibernate */[
                                  59168818,
                                  /* Error */Block.__(1, [action[1]])
                                ]],
                              input: action[0],
                              calendar: {
                                status: /* Ready */[/* Ok */Block.__(0, [undefined])],
                                months: action[2],
                                days: action[3]
                              },
                              modal: state.modal,
                              newUser: state.newUser
                            },
                            (function (param) {
                                return syncToUrl(param.state.input);
                              })
                          ]);
              case /* MountWithInitialInputAndWithoutCalendarData */3 :
                  var init$4 = state.calendar;
                  return /* UpdateWithSideEffects */Block.__(1, [
                            {
                              status: /* Ready */[/* `Hibernate */[
                                  59168818,
                                  /* Ok */Block.__(0, [/* NoQuote */-28913893])
                                ]],
                              input: action[0],
                              calendar: {
                                status: /* Ready */[/* Ok */Block.__(0, [undefined])],
                                months: init$4.months,
                                days: init$4.days
                              },
                              modal: state.modal,
                              newUser: state.newUser
                            },
                            (function (param) {
                                return syncToUrl(param.state.input);
                              })
                          ]);
              case /* FailCalendarDaysFetchingOnMountWithInitialInput */4 :
                  var init$5 = state.calendar;
                  return /* Update */Block.__(0, [{
                              status: /* Ready */[/* `Hibernate */[
                                  59168818,
                                  /* Error */Block.__(1, [/* FetchCalendarMonthsError */-363007107])
                                ]],
                              input: action[0],
                              calendar: {
                                status: /* Ready */[/* Error */Block.__(1, [/* FetchCalendarMonthsError */0])],
                                months: init$5.months,
                                days: init$5.days
                              },
                              modal: state.modal,
                              newUser: state.newUser
                            }]);
              case /* FetchCalendarDays */5 :
                  var quantity = action[1];
                  var date = action[0];
                  var init$6 = state.calendar;
                  return /* UpdateWithSideEffects */Block.__(1, [
                            {
                              status: state.status,
                              input: state.input,
                              calendar: {
                                status: /* FetchingCalendarDays */0,
                                months: init$6.months,
                                days: init$6.days
                              },
                              modal: state.modal,
                              newUser: state.newUser
                            },
                            (function (param) {
                                var dispatch = param.dispatch;
                                return $$Promise.wait(Api.getCalendarMonthsForGuest(listing.id, $$Date.Naive.year(date), monthFromDate(date), quantity), (function (res) {
                                              if (res.tag) {
                                                return Curry._1(dispatch, /* FailCalendarDaysFetching */2);
                                              }
                                              var months = Belt_Array.map(Belt_Array.range(1, quantity), (function (n) {
                                                      return make($$Date.Naive.addMonths(date, n - 1 | 0));
                                                    }));
                                              return Curry._1(dispatch, /* SetCalendarDays */Block.__(6, [
                                                            months,
                                                            res[0]
                                                          ]));
                                            }));
                              })
                          ]);
              case /* SetCalendarDays */6 :
                  var arg = state.calendar.days;
                  return /* Update */Block.__(0, [{
                              status: state.status,
                              input: state.input,
                              calendar: {
                                status: /* Ready */[/* Ok */Block.__(0, [undefined])],
                                months: Belt_Array.reduce(action[0], state.calendar.months, Belt_Set.add),
                                days: (function (param) {
                                      return mergeCalendarDays(param, arg);
                                    })(action[1])
                              },
                              modal: state.modal,
                              newUser: state.newUser
                            }]);
              case /* FetchQuote */7 :
                  var token = action[0];
                  return /* UpdateWithSideEffects */Block.__(1, [
                            {
                              status: /* Ready */[/* `FetchingQuote */[
                                  -358891244,
                                  /* tuple */[
                                    /* NoQuote */-28913893,
                                    token
                                  ]
                                ]],
                              input: state.input,
                              calendar: state.calendar,
                              modal: state.modal,
                              newUser: state.newUser
                            },
                            (function (param) {
                                var dispatch = param.dispatch;
                                var state = param.state;
                                Belt_Option.mapWithDefault(quoteRequestIntervalId.current, undefined, (function (prim) {
                                        clearInterval(prim);
                                        
                                      }));
                                return Curry._1($$fetch, {
                                            input: {
                                              listingId: listing.id,
                                              checkIn: Belt_Option.getExn(state.input.checkIn),
                                              checkOut: Belt_Option.getExn(state.input.checkOut),
                                              adults: state.input.adults,
                                              children: state.input.children,
                                              infants: state.input.infants,
                                              pets: state.input.pets
                                            },
                                            intervalId: quoteRequestIntervalId,
                                            onComplete: (function (quote) {
                                                return Curry._1(dispatch, /* SetFinalQuote */Block.__(9, [
                                                              quote,
                                                              token
                                                            ]));
                                              }),
                                            onPart: (function (quote) {
                                                return Curry._1(dispatch, /* SetIntermediateQuote */Block.__(8, [
                                                              quote,
                                                              token
                                                            ]));
                                              }),
                                            onError: (function (param) {
                                                return Curry._1(dispatch, /* FailQuoteFetching */Block.__(10, [token]));
                                              })
                                          });
                              })
                          ]);
              case /* SetIntermediateQuote */8 :
                  var token$1 = action[1];
                  var quote$1 = action[0];
                  var match$11 = state.status;
                  if (!match$11) {
                    return /* NoUpdate */0;
                  }
                  var match$12 = match$11[0];
                  if (match$12[0] >= 59168818 || token$1 !== match$12[1][1]) {
                    return /* NoUpdate */0;
                  } else {
                    return /* Update */Block.__(0, [{
                                status: /* Ready */[/* `FetchingQuote */[
                                    -358891244,
                                    /* tuple */[
                                      /* `InterimQuote */[
                                        116360508,
                                        /* tuple */[
                                          quote$1,
                                          Belt_Array.some(quote$1.providers, (function (provider) {
                                                  var match = provider.name;
                                                  return match === 0;
                                                })) ? /* WithHc */186332865 : /* WithoutHc */-910462141
                                        ]
                                      ],
                                      token$1
                                    ]
                                  ]],
                                input: state.input,
                                calendar: state.calendar,
                                modal: state.modal,
                                newUser: state.newUser
                              }]);
                  }
              case /* SetFinalQuote */9 :
                  var match$13 = state.status;
                  if (!match$13) {
                    return /* NoUpdate */0;
                  }
                  var match$14 = match$13[0];
                  if (match$14[0] >= 59168818 || action[1] !== match$14[1][1]) {
                    return /* NoUpdate */0;
                  } else {
                    return /* Update */Block.__(0, [{
                                status: /* Ready */[/* `Hibernate */[
                                    59168818,
                                    /* Ok */Block.__(0, [/* `Quote */[
                                          -249588964,
                                          action[0]
                                        ]])
                                  ]],
                                input: state.input,
                                calendar: state.calendar,
                                modal: state.modal,
                                newUser: state.newUser
                              }]);
                  }
              case /* FailQuoteFetching */10 :
                  var match$15 = state.status;
                  if (!match$15) {
                    return /* NoUpdate */0;
                  }
                  var match$16 = match$15[0];
                  if (match$16[0] >= 59168818 || action[0] !== match$16[1][1]) {
                    return /* NoUpdate */0;
                  } else {
                    return /* Update */Block.__(0, [{
                                status: /* Ready */[/* `Hibernate */[
                                    59168818,
                                    /* Error */Block.__(1, [/* FetchQuoteError */984370086])
                                  ]],
                                input: state.input,
                                calendar: state.calendar,
                                modal: state.modal,
                                newUser: state.newUser
                              }]);
                  }
              case /* UpdateInputAndFetchQuote */11 :
                  var onError = action[1];
                  var input = action[0];
                  var match$17 = state.status;
                  if (!match$17) {
                    return /* NoUpdate */0;
                  }
                  var validationResult = validate(input, listing, state.calendar.days);
                  if (!validationResult.tag) {
                    return /* UpdateWithSideEffects */Block.__(1, [
                              {
                                status: state.status,
                                input: input,
                                calendar: state.calendar,
                                modal: state.modal,
                                newUser: state.newUser
                              },
                              (function (param) {
                                  syncToUrl(param.state.input);
                                  return Curry._1(param.dispatch, /* FetchQuote */Block.__(7, [NonSecure()]));
                                })
                            ]);
                  }
                  var error = validationResult[0];
                  return /* UpdateWithSideEffects */Block.__(1, [
                            {
                              status: /* Ready */[/* `Hibernate */[
                                  59168818,
                                  /* Error */Block.__(1, [/* `ValidationError */[
                                        -58570577,
                                        error
                                      ]])
                                ]],
                              input: input,
                              calendar: state.calendar,
                              modal: state.modal,
                              newUser: state.newUser
                            },
                            (function (param) {
                                syncToUrl(param.state.input);
                                if (onError !== undefined) {
                                  return Curry._1(onError, error);
                                }
                                
                              })
                          ]);
              case /* UpdateInputWithoutQuoteFetching */12 :
                  var input$1 = action[0];
                  var status = state.status;
                  if (!status) {
                    return /* NoUpdate */0;
                  }
                  var validationResult$1 = validate(input$1, listing, state.calendar.days);
                  var tmp;
                  tmp = validationResult$1.tag ? /* Ready */[/* `Hibernate */[
                        59168818,
                        /* Error */Block.__(1, [/* `ValidationError */[
                              -58570577,
                              validationResult$1[0]
                            ]])
                      ]] : status;
                  return /* UpdateWithSideEffects */Block.__(1, [
                            {
                              status: tmp,
                              input: input$1,
                              calendar: state.calendar,
                              modal: state.modal,
                              newUser: state.newUser
                            },
                            (function (param) {
                                return syncToUrl(param.state.input);
                              })
                          ]);
              case /* ShowModal */13 :
                  var init$7 = state.modal;
                  return /* Update */Block.__(0, [{
                              status: state.status,
                              input: state.input,
                              calendar: state.calendar,
                              modal: {
                                current: action[0],
                                inquiryStatus: init$7.inquiryStatus,
                                messageStatus: init$7.messageStatus
                              },
                              newUser: state.newUser
                            }]);
              case /* UpdateNewUser */14 :
                  var match$18 = state.modal;
                  var match$19 = user.id;
                  var exit$5 = 0;
                  var match$20 = match$18.current;
                  if (match$19 !== undefined) {
                    return /* NoUpdate */0;
                  }
                  if (match$20 === undefined) {
                    return /* NoUpdate */0;
                  }
                  switch (match$20) {
                    case /* InquiryModal */0 :
                        var match$21 = match$18.inquiryStatus;
                        if (typeof match$21 === "number") {
                          return /* NoUpdate */0;
                        }
                        if (match$21.tag) {
                          if (!match$21[0].tag) {
                            return /* NoUpdate */0;
                          }
                          exit$5 = 2;
                        } else {
                          exit$5 = 2;
                        }
                        break;
                    case /* MessageModal */1 :
                        var match$22 = match$18.messageStatus;
                        if (typeof match$22 === "number") {
                          return /* NoUpdate */0;
                        }
                        if (match$22.tag) {
                          if (!match$22[0].tag) {
                            return /* NoUpdate */0;
                          }
                          exit$5 = 2;
                        } else {
                          exit$5 = 2;
                        }
                        break;
                    case /* OwnListingWarningModal */2 :
                        return /* NoUpdate */0;
                    
                  }
                  if (exit$5 === 2) {
                    return /* Update */Block.__(0, [{
                                status: state.status,
                                input: state.input,
                                calendar: state.calendar,
                                modal: state.modal,
                                newUser: action[0]
                              }]);
                  }
                  break;
              case /* SucceedInquirySubmissionWithoutNewUser */15 :
                  var init$8 = state.modal;
                  return /* UpdateWithSideEffects */Block.__(1, [
                            {
                              status: state.status,
                              input: state.input,
                              calendar: state.calendar,
                              modal: {
                                current: init$8.current,
                                inquiryStatus: /* Submitted */Block.__(1, [/* Ok */Block.__(0, [{
                                          user: /* Existing */-399322453,
                                          booking: action[0]
                                        }])]),
                                messageStatus: init$8.messageStatus
                              },
                              newUser: state.newUser
                            },
                            (function (param) {
                                return Curry._1(notifyOnInquiryModalFormSubmission, listing.id);
                              })
                          ]);
              case /* SucceedInquirySubmissionWithNewUser */16 :
                  var navbar = action[2];
                  var init$9 = state.modal;
                  return /* UpdateWithSideEffects */Block.__(1, [
                            {
                              status: state.status,
                              input: state.input,
                              calendar: state.calendar,
                              modal: {
                                current: init$9.current,
                                inquiryStatus: /* Submitted */Block.__(1, [/* Ok */Block.__(0, [{
                                          user: /* `New */[
                                            3901504,
                                            action[0]
                                          ],
                                          booking: action[1]
                                        }])]),
                                messageStatus: init$9.messageStatus
                              },
                              newUser: state.newUser
                            },
                            (function (param) {
                                if (navbar !== undefined) {
                                  Curry._1(user.authenticate, Caml_option.valFromOption(navbar));
                                }
                                return Curry._1(notifyOnInquiryModalFormSubmission, listing.id);
                              })
                          ]);
              case /* SubmitMessage */17 :
                  var input$2 = action[0];
                  var match$23 = user.id;
                  var userStatus$1 = match$23 !== undefined ? /* Ok */Block.__(0, [undefined]) : validate$1(state.newUser);
                  var match$24 = state.modal;
                  var match$25 = validateMessage(state.input.comment);
                  var exit$6 = 0;
                  var inquiryStatus;
                  var match$26 = match$24.current;
                  if (match$26 === undefined) {
                    return /* NoUpdate */0;
                  }
                  if (match$26 !== 1) {
                    return /* NoUpdate */0;
                  }
                  var match$27 = match$24.messageStatus;
                  var inquiryStatus$1 = match$24.inquiryStatus;
                  var exit$7 = 0;
                  if (typeof match$27 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (match$27.tag) {
                    if (!match$27[0].tag) {
                      return /* NoUpdate */0;
                    }
                    exit$7 = 3;
                  } else {
                    exit$7 = 3;
                  }
                  if (exit$7 === 3) {
                    if (userStatus$1.tag) {
                      inquiryStatus = inquiryStatus$1;
                      exit$6 = 2;
                    } else {
                      if (!match$25.tag) {
                        return /* UpdateWithSideEffects */Block.__(1, [
                                  {
                                    status: state.status,
                                    input: state.input,
                                    calendar: state.calendar,
                                    modal: {
                                      current: /* MessageModal */1,
                                      inquiryStatus: inquiryStatus$1,
                                      messageStatus: /* Submitting */0
                                    },
                                    newUser: state.newUser
                                  },
                                  (function (param) {
                                      var dispatch = param.dispatch;
                                      var userId = user.id;
                                      if (userId !== undefined) {
                                        var payload = /* WithoutNewUser */Block.__(1, [{
                                              message: Belt_Option.getExn(state.input.comment),
                                              listingId: listing.id,
                                              userId: userId,
                                              checkIn: input$2.checkIn,
                                              checkOut: input$2.checkOut,
                                              adults: input$2.adults,
                                              children: input$2.children,
                                              infants: input$2.infants,
                                              pets: input$2.pets,
                                              sbjs: Sbjs.data(undefined),
                                              fromSlv: true
                                            }]);
                                        return $$Promise.wait(viewContext >= 2 ? Api.postMessageFromMicrosite(payload) : Api.postMessageFromHc(payload), (function (param) {
                                                      if (param.tag) {
                                                        return Curry._1(dispatch, /* FailMessageSubmission */6);
                                                      } else {
                                                        return Curry._1(dispatch, /* SucceedMessageSubmissionWithoutNewUser */Block.__(18, [param[0].message]));
                                                      }
                                                    }));
                                      }
                                      var firstName = Belt_Option.getExn(state.newUser.firstName);
                                      var lastName = Belt_Option.getExn(state.newUser.lastName);
                                      var email = Belt_Option.getExn(state.newUser.email);
                                      var phone = state.newUser.phone;
                                      var payload$1 = /* WithNewUser */Block.__(0, [{
                                            message: Belt_Option.getExn(state.input.comment),
                                            listingId: listing.id,
                                            firstName: firstName,
                                            lastName: lastName,
                                            email: email,
                                            phone: phone,
                                            checkIn: input$2.checkIn,
                                            checkOut: input$2.checkOut,
                                            adults: input$2.adults,
                                            children: input$2.children,
                                            infants: input$2.infants,
                                            pets: input$2.pets,
                                            sbjs: Sbjs.data(undefined),
                                            fromSlv: true
                                          }]);
                                      return $$Promise.wait(viewContext >= 2 ? Api.postMessageFromMicrosite(payload$1) : Api.postMessageFromHc(payload$1), (function (param) {
                                                    if (param.tag) {
                                                      return Curry._1(dispatch, /* FailMessageSubmission */6);
                                                    }
                                                    var match = param[0];
                                                    var user = {
                                                      firstName: firstName,
                                                      lastName: lastName,
                                                      email: email,
                                                      phone: phone
                                                    };
                                                    return Curry._1(dispatch, /* SucceedMessageSubmissionWithNewUser */Block.__(19, [
                                                                  user,
                                                                  match.message,
                                                                  match.navbar
                                                                ]));
                                                  }));
                                    })
                                ]);
                      }
                      inquiryStatus = inquiryStatus$1;
                      exit$6 = 2;
                    }
                  }
                  if (exit$6 === 2) {
                    return /* Update */Block.__(0, [{
                                status: state.status,
                                input: state.input,
                                calendar: state.calendar,
                                modal: {
                                  current: /* MessageModal */1,
                                  inquiryStatus: inquiryStatus,
                                  messageStatus: /* Editing */Block.__(0, [/* AfterInvalidSubmissionAttempt */468803782])
                                },
                                newUser: state.newUser
                              }]);
                  }
                  break;
              case /* SucceedMessageSubmissionWithoutNewUser */18 :
                  var init$10 = state.modal;
                  return /* UpdateWithSideEffects */Block.__(1, [
                            {
                              status: state.status,
                              input: state.input,
                              calendar: state.calendar,
                              modal: {
                                current: init$10.current,
                                inquiryStatus: init$10.inquiryStatus,
                                messageStatus: /* Submitted */Block.__(1, [/* Ok */Block.__(0, [{
                                          user: /* Existing */-399322453,
                                          message: action[0]
                                        }])])
                              },
                              newUser: state.newUser
                            },
                            (function (param) {
                                return Curry._1(notifyOnMessageModalFormSubmission, listing.id);
                              })
                          ]);
              case /* SucceedMessageSubmissionWithNewUser */19 :
                  var navbar$1 = action[2];
                  var init$11 = state.modal;
                  return /* UpdateWithSideEffects */Block.__(1, [
                            {
                              status: state.status,
                              input: state.input,
                              calendar: state.calendar,
                              modal: {
                                current: init$11.current,
                                inquiryStatus: init$11.inquiryStatus,
                                messageStatus: /* Submitted */Block.__(1, [/* Ok */Block.__(0, [{
                                          user: /* `New */[
                                            3901504,
                                            action[0]
                                          ],
                                          message: action[1]
                                        }])])
                              },
                              newUser: state.newUser
                            },
                            (function (param) {
                                if (navbar$1 !== undefined) {
                                  Curry._1(user.authenticate, Caml_option.valFromOption(navbar$1));
                                }
                                return Curry._1(notifyOnMessageModalFormSubmission, listing.id);
                              })
                          ]);
              
            }
          }
          var init$12 = state.calendar;
          return /* Update */Block.__(0, [{
                      status: /* Ready */[/* `Hibernate */[
                          59168818,
                          /* Error */Block.__(1, [/* FetchCalendarMonthsError */-363007107])
                        ]],
                      input: state.input,
                      calendar: {
                        status: /* Ready */[/* Error */Block.__(1, [/* FetchCalendarMonthsError */0])],
                        months: init$12.months,
                        days: init$12.days
                      },
                      modal: state.modal,
                      newUser: state.newUser
                    }]);
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          var query = window.location.search;
          var params;
          switch (query) {
            case "" :
            case "?" :
                params = undefined;
                break;
            default:
              params = Caml_option.some(Qs.parseWithOptions(true, query));
          }
          var input = Belt_Option.flatMap(params, (function (params) {
                  var input;
                  try {
                    input = fromUrlParams(params);
                  }
                  catch (exn){
                    return ;
                  }
                  return input;
                }));
          var match = listing.legalRestriction;
          if (match !== undefined) {
            if (input !== undefined) {
              Curry._1(dispatch, /* MountWithInitialInputAndWithoutCalendarData */Block.__(3, [input]));
            } else {
              Curry._1(dispatch, /* MountWithoutInitialInputAndCalendarData */0);
            }
          } else {
            var calDataCheckIn = Belt_Option.getWithDefault(Belt_Option.flatMap(input, (function (input) {
                        return Belt_Option.flatMap(input.checkIn, (function (checkIn) {
                                      if ($$Date.Naive.isPast(checkIn)) {
                                        return ;
                                      } else {
                                        return Caml_option.some(checkIn);
                                      }
                                    }));
                      })), $$Date.Naive.make(undefined));
            var calDataCheckOut = Belt_Option.flatMap(input, (function (input) {
                    return Belt_Option.flatMap(input.checkOut, (function (checkOut) {
                                  if ($$Date.Naive.isPast(checkOut)) {
                                    return ;
                                  } else {
                                    return Caml_option.some(checkOut);
                                  }
                                }));
                  }));
            var calDataYear = $$Date.Naive.year(calDataCheckIn);
            var calDataMonth = monthFromDate(calDataCheckIn);
            var calDataQuantity;
            if (calDataCheckOut !== undefined) {
              var x = Difference_in_calendar_months(Caml_option.valFromOption(calDataCheckOut), calDataCheckIn) | 0;
              calDataQuantity = x !== 0 ? x + 1 | 0 : 2;
            } else {
              calDataQuantity = 2;
            }
            $$Promise.wait(Api.getCalendarMonthsForGuest(listing.id, calDataYear, calDataMonth, calDataQuantity), (function (res) {
                    if (res.tag) {
                      if (input !== undefined) {
                        return Curry._1(dispatch, /* FailCalendarDaysFetchingOnMountWithInitialInput */Block.__(4, [input]));
                      } else {
                        return Curry._1(dispatch, /* FailCalendarDaysFetchingOnMountWithoutInitialInput */1);
                      }
                    }
                    var arg = state.calendar.months;
                    var calendarMonths = Curry._1((function (param) {
                                return (function (param$1) {
                                    return (function (param$2) {
                                        return mergeLoadedMonths(param, param$1, param$2, arg);
                                      });
                                  });
                              })(calDataYear)(calDataMonth), calDataQuantity);
                    var arg$1 = state.calendar.days;
                    var calendarDays = (function (param) {
                          return mergeCalendarDays(param, arg$1);
                        })(res[0]);
                    if (input === undefined) {
                      return Curry._1(dispatch, /* MountWithoutInitialInputAndWithCalendarData */Block.__(0, [
                                    calendarMonths,
                                    calendarDays
                                  ]));
                    }
                    var validationResult = validate(input, listing, calendarDays);
                    if (validationResult.tag) {
                      return Curry._1(dispatch, /* MountWithInvalidInitialInputAndCalendarData */Block.__(2, [
                                    input,
                                    /* `ValidationError */[
                                      -58570577,
                                      validationResult[0]
                                    ],
                                    calendarMonths,
                                    calendarDays
                                  ]));
                    }
                    var error = validateMinStayAndAviability(input, calendarDays, listing);
                    if (error.tag) {
                      return Curry._1(dispatch, /* MountWithInvalidInitialInputAndCalendarData */Block.__(2, [
                                    input,
                                    /* `ValidationError */[
                                      -58570577,
                                      error[0]
                                    ],
                                    calendarMonths,
                                    calendarDays
                                  ]));
                    } else {
                      return Curry._1(dispatch, /* MountWithValidInitialInputAndCalendarData */Block.__(1, [
                                    input,
                                    calendarMonths,
                                    calendarDays
                                  ]));
                    }
                  }));
          }
          
        }), ([]));
  React.useEffect((function () {
          return (function (param) {
                    return Belt_Option.mapWithDefault(quoteRequestIntervalId.current, undefined, (function (prim) {
                                  clearInterval(prim);
                                  
                                }));
                  });
        }), ([]));
  var message = React.useMemo((function () {
          var match = state.modal;
          var match$1 = match.current;
          var tmp;
          if (match$1 === 1) {
            var match$2 = match.messageStatus;
            tmp = typeof match$2 === "number" || match$2.tag || match$2[0] < 468803782 ? undefined : validateMessage(state.input.comment);
          } else {
            tmp = undefined;
          }
          return {
                  result: tmp
                };
        }), /* tuple */[
        state.input.comment,
        state.modal
      ]);
  var newUser = React.useMemo((function () {
          var match = state.modal;
          var match$1 = match.current;
          var tmp;
          if (match$1 !== undefined) {
            switch (match$1) {
              case /* InquiryModal */0 :
                  var match$2 = match.inquiryStatus;
                  tmp = typeof match$2 === "number" ? ({
                        firstName: undefined,
                        lastName: undefined,
                        email: undefined
                      }) : (
                      match$2.tag ? (
                          match$2[0].tag ? validateFields(state.newUser) : ({
                                firstName: undefined,
                                lastName: undefined,
                                email: undefined
                              })
                        ) : (
                          match$2[0] >= 468803782 ? validateFields(state.newUser) : ({
                                firstName: undefined,
                                lastName: undefined,
                                email: undefined
                              })
                        )
                    );
                  break;
              case /* MessageModal */1 :
                  var match$3 = match.messageStatus;
                  tmp = typeof match$3 === "number" ? ({
                        firstName: undefined,
                        lastName: undefined,
                        email: undefined
                      }) : (
                      match$3.tag ? (
                          match$3[0].tag ? validateFields(state.newUser) : ({
                                firstName: undefined,
                                lastName: undefined,
                                email: undefined
                              })
                        ) : (
                          match$3[0] >= 468803782 ? validateFields(state.newUser) : ({
                                firstName: undefined,
                                lastName: undefined,
                                email: undefined
                              })
                        )
                    );
                  break;
              case /* OwnListingWarningModal */2 :
                  tmp = {
                    firstName: undefined,
                    lastName: undefined,
                    email: undefined
                  };
                  break;
              
            }
          } else {
            tmp = {
              firstName: undefined,
              lastName: undefined,
              email: undefined
            };
          }
          return {
                  input: state.newUser,
                  results: tmp
                };
        }), /* tuple */[
        state.newUser,
        state.modal
      ]);
  var fetchCalendarDays = React.useCallback((function (date) {
          return (function (quantity) {
              var shouldFetch = Belt_Array.some(Belt_Array.range(1, quantity), (function (n) {
                      var date$1 = $$Date.Naive.addMonths(date, n - 1 | 0);
                      return !Belt_Set.has(state.calendar.months, make(date$1));
                    }));
              if (shouldFetch) {
                return Curry._1(dispatch, /* FetchCalendarDays */Block.__(5, [
                              date,
                              quantity
                            ]));
              }
              
            });
        }), [state.calendar.months]);
  var updateCheckIn = React.useCallback((function (day) {
          return (function (onError) {
              var init = state.input;
              Curry._1(dispatch, /* UpdateInputAndFetchQuote */Block.__(11, [
                      {
                        checkIn: Caml_option.some(day),
                        checkOut: init.checkOut,
                        adults: init.adults,
                        children: init.children,
                        infants: init.infants,
                        pets: init.pets,
                        flexibleDates: init.flexibleDates,
                        comment: init.comment
                      },
                      onError
                    ]));
              return Curry._1(notifyOnBookingWidgetInteraction, listing.id);
            });
        }), [state.input]);
  var updateCheckOut = React.useCallback((function (day) {
          return (function (onError) {
              var init = state.input;
              Curry._1(dispatch, /* UpdateInputAndFetchQuote */Block.__(11, [
                      {
                        checkIn: init.checkIn,
                        checkOut: Caml_option.some(day),
                        adults: init.adults,
                        children: init.children,
                        infants: init.infants,
                        pets: init.pets,
                        flexibleDates: init.flexibleDates,
                        comment: init.comment
                      },
                      onError
                    ]));
              return Curry._1(notifyOnBookingWidgetInteraction, listing.id);
            });
        }), [state.input]);
  var updateFlexibleDates = React.useCallback((function (flexibleDates) {
          var init = state.input;
          Curry._1(dispatch, /* UpdateInputWithoutQuoteFetching */Block.__(12, [{
                    checkIn: init.checkIn,
                    checkOut: init.checkOut,
                    adults: init.adults,
                    children: init.children,
                    infants: init.infants,
                    pets: init.pets,
                    flexibleDates: flexibleDates,
                    comment: init.comment
                  }]));
          return Curry._1(notifyOnBookingWidgetInteraction, listing.id);
        }), [state.input]);
  var clearDates = React.useCallback((function (param) {
          var init = state.input;
          Curry._1(dispatch, /* UpdateInputWithoutQuoteFetching */Block.__(12, [{
                    checkIn: undefined,
                    checkOut: undefined,
                    adults: init.adults,
                    children: init.children,
                    infants: init.infants,
                    pets: init.pets,
                    flexibleDates: init.flexibleDates,
                    comment: init.comment
                  }]));
          return Curry._1(notifyOnBookingWidgetInteraction, listing.id);
        }), [state.input]);
  var updateAdults = React.useCallback((function (adults) {
          var init = state.input;
          Curry._1(dispatch, /* UpdateInputAndFetchQuote */Block.__(11, [
                  {
                    checkIn: init.checkIn,
                    checkOut: init.checkOut,
                    adults: adults > 0 ? adults : 1,
                    children: init.children,
                    infants: init.infants,
                    pets: init.pets,
                    flexibleDates: init.flexibleDates,
                    comment: init.comment
                  },
                  undefined
                ]));
          return Curry._1(notifyOnBookingWidgetInteraction, listing.id);
        }), [state.input]);
  var updateChildren = React.useCallback((function (children) {
          var init = state.input;
          Curry._1(dispatch, /* UpdateInputAndFetchQuote */Block.__(11, [
                  {
                    checkIn: init.checkIn,
                    checkOut: init.checkOut,
                    adults: init.adults,
                    children: children >= 0 ? children : 0,
                    infants: init.infants,
                    pets: init.pets,
                    flexibleDates: init.flexibleDates,
                    comment: init.comment
                  },
                  undefined
                ]));
          return Curry._1(notifyOnBookingWidgetInteraction, listing.id);
        }), [state.input]);
  var updateInfants = React.useCallback((function (infants) {
          var init = state.input;
          Curry._1(dispatch, /* UpdateInputAndFetchQuote */Block.__(11, [
                  {
                    checkIn: init.checkIn,
                    checkOut: init.checkOut,
                    adults: init.adults,
                    children: init.children,
                    infants: infants >= 0 ? infants : 0,
                    pets: init.pets,
                    flexibleDates: init.flexibleDates,
                    comment: init.comment
                  },
                  undefined
                ]));
          return Curry._1(notifyOnBookingWidgetInteraction, listing.id);
        }), [state.input]);
  var updatePets = React.useCallback((function (pets) {
          var init = state.input;
          Curry._1(dispatch, /* UpdateInputAndFetchQuote */Block.__(11, [
                  {
                    checkIn: init.checkIn,
                    checkOut: init.checkOut,
                    adults: init.adults,
                    children: init.children,
                    infants: init.infants,
                    pets: pets,
                    flexibleDates: init.flexibleDates,
                    comment: init.comment
                  },
                  undefined
                ]));
          return Curry._1(notifyOnBookingWidgetInteraction, listing.id);
        }), [state.input]);
  var updateComment = React.useCallback((function (comment) {
          return (function (modal) {
              var init = state.input;
              Curry._1(dispatch, /* UpdateInputWithoutQuoteFetching */Block.__(12, [{
                        checkIn: init.checkIn,
                        checkOut: init.checkOut,
                        adults: init.adults,
                        children: init.children,
                        infants: init.infants,
                        pets: init.pets,
                        flexibleDates: init.flexibleDates,
                        comment: Opt.fromString(comment)
                      }]));
              if (modal >= 722162950) {
                return Curry._1(notifyOnMessageModalFormInteraction, listing.id);
              } else {
                return Curry._1(notifyOnInquiryModalFormInteraction, listing.id);
              }
            });
        }), [state.input]);
  var updateNewUserFirstName = React.useCallback((function (firstName) {
          return (function (modal) {
              var init = state.newUser;
              Curry._1(dispatch, /* UpdateNewUser */Block.__(14, [{
                        firstName: Opt.fromString(firstName),
                        lastName: init.lastName,
                        email: init.email,
                        phone: init.phone
                      }]));
              if (modal >= 722162950) {
                return Curry._1(notifyOnMessageModalFormInteraction, listing.id);
              } else {
                return Curry._1(notifyOnInquiryModalFormInteraction, listing.id);
              }
            });
        }), [state.newUser]);
  var updateNewUserLastName = React.useCallback((function (lastName) {
          return (function (modal) {
              var init = state.newUser;
              Curry._1(dispatch, /* UpdateNewUser */Block.__(14, [{
                        firstName: init.firstName,
                        lastName: Opt.fromString(lastName),
                        email: init.email,
                        phone: init.phone
                      }]));
              if (modal >= 722162950) {
                return Curry._1(notifyOnMessageModalFormInteraction, listing.id);
              } else {
                return Curry._1(notifyOnInquiryModalFormInteraction, listing.id);
              }
            });
        }), [state.newUser]);
  var updateNewUserEmail = React.useCallback((function (email) {
          return (function (modal) {
              var init = state.newUser;
              Curry._1(dispatch, /* UpdateNewUser */Block.__(14, [{
                        firstName: init.firstName,
                        lastName: init.lastName,
                        email: Opt.fromString(email),
                        phone: init.phone
                      }]));
              if (modal >= 722162950) {
                return Curry._1(notifyOnMessageModalFormInteraction, listing.id);
              } else {
                return Curry._1(notifyOnInquiryModalFormInteraction, listing.id);
              }
            });
        }), [state.newUser]);
  var updateNewUserPhone = React.useCallback((function (phone) {
          return (function (modal) {
              var init = state.newUser;
              Curry._1(dispatch, /* UpdateNewUser */Block.__(14, [{
                        firstName: init.firstName,
                        lastName: init.lastName,
                        email: init.email,
                        phone: Opt.fromString(phone)
                      }]));
              if (modal >= 722162950) {
                return Curry._1(notifyOnMessageModalFormInteraction, listing.id);
              } else {
                return Curry._1(notifyOnInquiryModalFormInteraction, listing.id);
              }
            });
        }), [state.newUser]);
  var showInquiryModal = React.useCallback((function (param) {
          var match = state.status;
          if (listing.userContext.isManagedByCurrentUser) {
            return Curry._1(dispatch, /* ShowModal */Block.__(13, [/* OwnListingWarningModal */2]));
          }
          if (!match) {
            return ;
          }
          var match$1 = match[0];
          if (match$1[0] >= 59168818) {
            var match$2 = match$1[1];
            if (match$2.tag) {
              typeof match$2[0] === "number";
            } else if (typeof match$2[0] !== "number") {
              Curry._1(dispatch, /* ShowModal */Block.__(13, [/* InquiryModal */0]));
              return Curry._1(notifyOnInquiryModalShown, listing.id);
            }
            
          } else if (typeof match$1[1][0] !== "number") {
            Curry._1(dispatch, /* ShowModal */Block.__(13, [/* InquiryModal */0]));
            return Curry._1(notifyOnInquiryModalShown, listing.id);
          }
          Curry._1(dispatch, /* ShowModal */Block.__(13, [/* MessageModal */1]));
          return Curry._1(notifyOnInquiryModalShown, listing.id);
        }), /* tuple */[
        state.status,
        listing.userContext.isManagedByCurrentUser
      ]);
  var submitInquiry = React.useCallback((function (param) {
          return Curry._1(dispatch, /* SubmitInquiry */4);
        }), ([]));
  var showMessageModal = React.useCallback((function (param) {
          if (listing.userContext.isManagedByCurrentUser) {
            return Curry._1(dispatch, /* ShowModal */Block.__(13, [/* OwnListingWarningModal */2]));
          } else {
            Curry._1(dispatch, /* ShowModal */Block.__(13, [/* MessageModal */1]));
            return Curry._1(notifyOnMessageModalShown, listing.id);
          }
        }), [listing.userContext.isManagedByCurrentUser]);
  var submitMessage = React.useCallback((function (input) {
          return Curry._1(dispatch, /* SubmitMessage */Block.__(17, [input]));
        }), ([]));
  var hideModal = React.useCallback((function (param) {
          var match = state.modal;
          var match$1 = match.current;
          if (match$1 === undefined) {
            return ;
          }
          switch (match$1) {
            case /* InquiryModal */0 :
                if (typeof match.inquiryStatus === "number") {
                  return ;
                } else {
                  return Curry._1(dispatch, /* HideModal */3);
                }
            case /* MessageModal */1 :
                if (typeof match.messageStatus === "number") {
                  return ;
                } else {
                  return Curry._1(dispatch, /* HideModal */3);
                }
            case /* OwnListingWarningModal */2 :
                return Curry._1(dispatch, /* HideModal */3);
            
          }
        }), [state.modal]);
  var notifyGtmOnMessageModalFormInteraction = React.useCallback((function (param) {
          return Curry._1(notifyOnMessageModalFormInteraction, listing.id);
        }), ([]));
  var notifyGtmOnPhoneShow = React.useCallback((function (param) {
          return Curry._1(notifyOnPhoneShow, listing.id);
        }), ([]));
  return {
          status: state.status,
          input: state.input,
          calendar: state.calendar,
          modal: state.modal,
          message: message,
          newUser: newUser,
          fetchCalendarDays: fetchCalendarDays,
          updateCheckIn: updateCheckIn,
          updateCheckOut: updateCheckOut,
          updateFlexibleDates: updateFlexibleDates,
          clearDates: clearDates,
          updateAdults: updateAdults,
          updateChildren: updateChildren,
          updateInfants: updateInfants,
          updatePets: updatePets,
          updateComment: updateComment,
          updateNewUserFirstName: updateNewUserFirstName,
          updateNewUserLastName: updateNewUserLastName,
          updateNewUserEmail: updateNewUserEmail,
          updateNewUserPhone: updateNewUserPhone,
          isDayInPastRelativeToListingTimezone: isDayInPastRelativeToListingTimezone,
          showInquiryModal: showInquiryModal,
          submitInquiry: submitInquiry,
          showMessageModal: showMessageModal,
          submitMessage: submitMessage,
          hideModal: hideModal,
          notifyGtmOnPhoneShow: notifyGtmOnPhoneShow,
          notifyGtmOnMessageModalFormInteraction: notifyGtmOnMessageModalFormInteraction
        };
}

exports.ValidationError = ValidationError;
exports.Status = Status;
exports.Calendar = Calendar;
exports.Input = Input;
exports.Quote = Quote;
exports.NewUser = NewUser;
exports.MessageInput = MessageInput;
exports.Modal = Modal;
exports.Gtm = Gtm$1;
exports.useBooking = useBooking;
/* Comparable Not a pure module */
