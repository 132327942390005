// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function KitchenIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var className = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "Kitchen";
  var tmp = {
    title: title,
    size: size,
    children: React.createElement("g", {
          fill: "none",
          fillRule: "evenodd"
        }, React.createElement("path", {
              style: Icon.style(color),
              d: "M10,4 L11,4 L11,12 L10,12 L10,4 Z M12,5 L13,5 L13,6 L12,6 L12,5 Z M12,7 L13,7 L13,9 L12,9 L12,7 Z M2,3 L14,3 C14.5522847,3 15,3.44771525 15,4 L15,12 C15,12.5522847 14.5522847,13 14,13 L2,13 C1.44771525,13 1,12.5522847 1,12 L1,4 C1,3.44771525 1.44771525,3 2,3 Z M2,4 L2,12 L14,12 L14,4 L2,4 Z M4,5 L8,5 C8.55228475,5 9,5.44771525 9,6 L9,10 C9,10.5522847 8.55228475,11 8,11 L4,11 C3.44771525,11 3,10.5522847 3,10 L3,6 C3,5.44771525 3.44771525,5 4,5 Z M4,6 L4,10 L8,10 L8,6 L4,6 Z"
            }))
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Icon.make, tmp);
}

var make = KitchenIcon;

exports.make = make;
/* Icon Not a pure module */
