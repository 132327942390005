// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Hr = require("../../../../styleguide/components/Hr/Hr.bs.js");
var $$Text = require("../../../../styleguide/components/Text/Text.bs.js");
var React = require("react");
var Listing = require("../../../../models/Listing.bs.js");
var SlvBlock = require("../../components/SlvBlock/SlvBlock.bs.js");
var SlvTable = require("../../components/SlvTable/SlvTable.bs.js");
var ReactIntl = require("react-intl");
var PropertyType = require("../../../../models/PropertyType.bs.js");
var SlvDetailsModuleScss = require("./SlvDetails.module.scss");

var css = SlvDetailsModuleScss;

function SlvDetails(Props) {
  var listing = Props.listing;
  var propertyType = listing.propertyType;
  var x = listing.maxGuests;
  var x$1 = listing.bedrooms;
  var x$2 = listing.beds;
  var x$3 = listing.bathrooms;
  var rentalPermitLicense = listing.rentalPermitLicense;
  return React.createElement(SlvBlock.make, {
              id: /* Details */-123206494,
              children: null
            }, React.createElement(SlvBlock.Title.make, {
                  children: React.createElement(ReactIntl.FormattedMessage, {
                        id: "slv.details.title",
                        defaultMessage: "Details"
                      })
                }), React.createElement("div", {
                  className: css.groups
                }, React.createElement(SlvTable.make, {
                      className: css.group,
                      children: null
                    }, React.createElement(SlvTable.Row.make, {
                          children: null
                        }, React.createElement(SlvTable.Cell.make, {
                              children: null
                            }, React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.details.room-type.label",
                                  defaultMessage: "Room type"
                                }), ":"), React.createElement(SlvTable.Cell.make, {
                              children: Listing.RoomType.label(listing.roomType)
                            })), React.createElement(SlvTable.Row.make, {
                          children: null
                        }, React.createElement(SlvTable.Cell.make, {
                              children: null
                            }, React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.details.property-type.label",
                                  defaultMessage: "Property type"
                                }), ":"), React.createElement(SlvTable.Cell.make, {
                              children: propertyType !== undefined ? PropertyType.label(propertyType) : React.createElement($$Text.Ellipsis.make, {
                                      faded: true
                                    })
                            })), React.createElement(SlvTable.Row.make, {
                          children: null
                        }, React.createElement(SlvTable.Cell.make, {
                              children: null
                            }, React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.details.accommodates.label",
                                  defaultMessage: "Accommodates"
                                }), ":"), React.createElement(SlvTable.Cell.make, {
                              children: x !== undefined ? String(x) : React.createElement($$Text.Ellipsis.make, {
                                      faded: true
                                    })
                            }))), React.createElement(SlvTable.make, {
                      className: css.group,
                      children: null
                    }, React.createElement(SlvTable.Row.make, {
                          children: null
                        }, React.createElement(SlvTable.Cell.make, {
                              children: null
                            }, React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.details.bedrooms.label",
                                  defaultMessage: "Bedrooms"
                                }), ":"), React.createElement(SlvTable.Cell.make, {
                              children: x$1 !== undefined ? String(x$1) : React.createElement($$Text.Ellipsis.make, {
                                      faded: true
                                    })
                            })), React.createElement(SlvTable.Row.make, {
                          children: null
                        }, React.createElement(SlvTable.Cell.make, {
                              children: null
                            }, React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.details.beds.label",
                                  defaultMessage: "Beds"
                                }), ":"), React.createElement(SlvTable.Cell.make, {
                              children: x$2 !== undefined ? String(x$2) : React.createElement($$Text.Ellipsis.make, {
                                      faded: true
                                    })
                            })), React.createElement(SlvTable.Row.make, {
                          children: null
                        }, React.createElement(SlvTable.Cell.make, {
                              children: null
                            }, React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.details.bathrooms.label",
                                  defaultMessage: "Bathrooms"
                                }), ":"), React.createElement(SlvTable.Cell.make, {
                              children: x$3 !== undefined ? String(x$3) : React.createElement($$Text.Ellipsis.make, {
                                      faded: true
                                    })
                            })))), rentalPermitLicense !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Hr.make, { }), React.createElement("div", {
                        className: css.groups
                      }, React.createElement(SlvTable.make, {
                            className: css.group,
                            children: React.createElement(SlvTable.Row.make, {
                                  children: null
                                }, React.createElement(SlvTable.Cell.make, {
                                      children: React.createElement("strong", undefined, React.createElement(ReactIntl.FormattedMessage, {
                                                id: "slv.details.rental-permit-license.label",
                                                defaultMessage: "Permit number"
                                              }), ":")
                                    }), React.createElement(SlvTable.Cell.make, {
                                      children: React.createElement("strong", undefined, rentalPermitLicense)
                                    }))
                          }), React.createElement("div", {
                            className: css.group
                          }))) : null);
}

var make = SlvDetails;

exports.css = css;
exports.make = make;
/* css Not a pure module */
