// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function topLeftZoomControlPosition(param) {
  return google.maps.ControlPosition.TOP_LEFT;
}

function defined(param) {
  return typeof google !== "undefined";
}

exports.topLeftZoomControlPosition = topLeftZoomControlPosition;
exports.defined = defined;
/* No side effect */
