// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Env = require("../../libs/Env.bs.js");
var Csrf = require("../Csrf.bs.js");
var Http = require("../Http.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.js");
var Sentry = require("../../bindings/Sentry.bs.js");
var $$Promise = require("../../bindings/Promise.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var BrowserLogger = require("bs-log/src/BrowserLogger.bs.js");
var AjaxRequestTrackerJs = require("app/libs/utils/api/ajaxRequestTracker.js");

function toString(url) {
  var host = Env.apiHost !== undefined ? Env.apiHost : "";
  if (url[0] >= 19227) {
    return "" + (String(host) + ("/api/v1" + (String(url[1]) + "")));
  } else {
    return "" + (String(host) + ("/integrations" + (String(url[1]) + "")));
  }
}

var Url = {
  toString: toString
};

function $$fetch$1(url, req, res, fingerprint, param) {
  var url$1 = toString(url);
  var body = Http.Method.body(req);
  var requestTracker = AjaxRequestTrackerJs.default(url$1, body);
  if (Env.env !== 1) {
    
  } else {
    requestTracker.start();
  }
  var headers;
  var exit = 0;
  exit = typeof req === "number" || !(req[0] !== 527250507 || req[1] !== undefined) ? 2 : 1;
  switch (exit) {
    case 1 :
        headers = typeof res === "number" ? [/* tuple */[
              "Content-Type",
              Http.ContentType.toString(/* Json */1)
            ]] : [
            /* tuple */[
              "Accept",
              Http.ContentType.toString(Http.$$Response.toContentType(res))
            ],
            /* tuple */[
              "Content-Type",
              Http.ContentType.toString(/* Json */1)
            ]
          ];
        break;
    case 2 :
        headers = typeof res === "number" ? [] : [/* tuple */[
              "Accept",
              Http.ContentType.toString(Http.$$Response.toContentType(res))
            ]];
        break;
    
  }
  var token = Csrf.token(undefined);
  return $$Promise.$$catch($$Promise.andThen(fetch(url$1, Fetch.RequestInit.make(Http.Method.toFetch(req), Caml_option.some((token !== undefined ? (headers.push(/* tuple */[
                                          "X-CSRF-Token",
                                          token
                                        ]), undefined) : undefined, headers)), Belt_Option.map(body, (function (body) {
                                  return JSON.stringify(body);
                                })), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)), (function (response) {
                    var meth = Http.Method.toString(req);
                    if (Env.env !== 1) {
                      
                    } else {
                      requestTracker.end(response.status);
                    }
                    var match = response.ok;
                    var exit = 0;
                    if (typeof res === "number") {
                      if (match) {
                        return $$Promise.resolve(/* Ok */Block.__(0, [undefined]));
                      }
                      exit = 1;
                    } else if (res[0] >= 936573133) {
                      if (match) {
                        var parse = res[1];
                        return $$Promise.map(response.text(), (function (text) {
                                      return /* Ok */Block.__(0, [Curry._1(parse, text)]);
                                    }));
                      }
                      exit = 1;
                    } else {
                      if (match) {
                        var parse$1 = res[1];
                        return $$Promise.map(response.json(), (function (json) {
                                      return /* Ok */Block.__(0, [Curry._1(parse$1, json)]);
                                    }));
                      }
                      exit = 1;
                    }
                    if (exit === 1) {
                      var match$1 = Http.ContentType.fromHeaders(response.headers);
                      var exit$1 = 0;
                      if (match$1 !== undefined) {
                        if (typeof match$1 === "number") {
                          if (match$1 !== 0) {
                            return $$Promise.map(response.json(), (function (json) {
                                          var code = response.status;
                                          var match = Http.$$Error.server(code);
                                          if (fingerprint !== undefined) {
                                            var fingerprint$1 = Caml_option.valFromOption(fingerprint);
                                            if (match) {
                                              BrowserLogger.warnWithData7("Rest", "Fetch::RequestError (" + (String(meth) + (": " + (String(url$1) + ")"))), /* tuple */[
                                                    "url",
                                                    url$1
                                                  ], /* tuple */[
                                                    "code",
                                                    code
                                                  ], /* tuple */[
                                                    "status",
                                                    response.statusText
                                                  ], /* tuple */[
                                                    "contentType",
                                                    "json"
                                                  ], /* tuple */[
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], /* tuple */[
                                                    "responseBody",
                                                    json
                                                  ], /* tuple */[
                                                    Sentry.fingerprintKey,
                                                    fingerprint$1
                                                  ]);
                                            } else {
                                              BrowserLogger.errorWithData7("Rest", "Fetch::RequestError (" + (String(meth) + (": " + (String(url$1) + ")"))), /* tuple */[
                                                    "url",
                                                    url$1
                                                  ], /* tuple */[
                                                    "code",
                                                    code
                                                  ], /* tuple */[
                                                    "status",
                                                    response.statusText
                                                  ], /* tuple */[
                                                    "contentType",
                                                    "json"
                                                  ], /* tuple */[
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], /* tuple */[
                                                    "responseBody",
                                                    json
                                                  ], /* tuple */[
                                                    Sentry.fingerprintKey,
                                                    fingerprint$1
                                                  ]);
                                            }
                                          } else if (match) {
                                            BrowserLogger.warnWithData6("Rest", "Fetch::RequestError (" + (String(meth) + (": " + (String(url$1) + ")"))), /* tuple */[
                                                  "url",
                                                  url$1
                                                ], /* tuple */[
                                                  "code",
                                                  code
                                                ], /* tuple */[
                                                  "status",
                                                  response.statusText
                                                ], /* tuple */[
                                                  "contentType",
                                                  "json"
                                                ], /* tuple */[
                                                  "requestBody",
                                                  Http.Method.body(req)
                                                ], /* tuple */[
                                                  "responseBody",
                                                  json
                                                ]);
                                          } else {
                                            BrowserLogger.errorWithData6("Rest", "Fetch::RequestError (" + (String(meth) + (": " + (String(url$1) + ")"))), /* tuple */[
                                                  "url",
                                                  url$1
                                                ], /* tuple */[
                                                  "code",
                                                  code
                                                ], /* tuple */[
                                                  "status",
                                                  response.statusText
                                                ], /* tuple */[
                                                  "contentType",
                                                  "json"
                                                ], /* tuple */[
                                                  "requestBody",
                                                  Http.Method.body(req)
                                                ], /* tuple */[
                                                  "responseBody",
                                                  json
                                                ]);
                                          }
                                          return /* Error */Block.__(1, [undefined]);
                                        }));
                          } else {
                            return $$Promise.map(response.text(), (function (text) {
                                          var code = response.status;
                                          var match = Http.$$Error.server(code);
                                          if (fingerprint !== undefined) {
                                            var fingerprint$1 = Caml_option.valFromOption(fingerprint);
                                            if (match) {
                                              BrowserLogger.warnWithData7("Rest", "Fetch::RequestError (" + (String(meth) + (": " + (String(url$1) + ")"))), /* tuple */[
                                                    "url",
                                                    url$1
                                                  ], /* tuple */[
                                                    "code",
                                                    code
                                                  ], /* tuple */[
                                                    "status",
                                                    response.statusText
                                                  ], /* tuple */[
                                                    "contentType",
                                                    "text"
                                                  ], /* tuple */[
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], /* tuple */[
                                                    "responseBody",
                                                    text
                                                  ], /* tuple */[
                                                    Sentry.fingerprintKey,
                                                    fingerprint$1
                                                  ]);
                                            } else {
                                              BrowserLogger.errorWithData7("Rest", "Fetch::RequestError (" + (String(meth) + (": " + (String(url$1) + ")"))), /* tuple */[
                                                    "url",
                                                    url$1
                                                  ], /* tuple */[
                                                    "code",
                                                    code
                                                  ], /* tuple */[
                                                    "status",
                                                    response.statusText
                                                  ], /* tuple */[
                                                    "contentType",
                                                    "text"
                                                  ], /* tuple */[
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], /* tuple */[
                                                    "responseBody",
                                                    text
                                                  ], /* tuple */[
                                                    Sentry.fingerprintKey,
                                                    fingerprint$1
                                                  ]);
                                            }
                                          } else if (match) {
                                            BrowserLogger.warnWithData6("Rest", "Fetch::RequestError (" + (String(meth) + (": " + (String(url$1) + ")"))), /* tuple */[
                                                  "url",
                                                  url$1
                                                ], /* tuple */[
                                                  "code",
                                                  code
                                                ], /* tuple */[
                                                  "status",
                                                  response.statusText
                                                ], /* tuple */[
                                                  "contentType",
                                                  "text"
                                                ], /* tuple */[
                                                  "requestBody",
                                                  Http.Method.body(req)
                                                ], /* tuple */[
                                                  "responseBody",
                                                  text
                                                ]);
                                          } else {
                                            BrowserLogger.errorWithData6("Rest", "Fetch::RequestError (" + (String(meth) + (": " + (String(url$1) + ")"))), /* tuple */[
                                                  "url",
                                                  url$1
                                                ], /* tuple */[
                                                  "code",
                                                  code
                                                ], /* tuple */[
                                                  "status",
                                                  response.statusText
                                                ], /* tuple */[
                                                  "contentType",
                                                  "text"
                                                ], /* tuple */[
                                                  "requestBody",
                                                  Http.Method.body(req)
                                                ], /* tuple */[
                                                  "responseBody",
                                                  text
                                                ]);
                                          }
                                          return /* Error */Block.__(1, [undefined]);
                                        }));
                          }
                        }
                        exit$1 = 2;
                      } else {
                        exit$1 = 2;
                      }
                      if (exit$1 === 2) {
                        var code = response.status;
                        var match$2 = Http.$$Error.server(code);
                        if (fingerprint !== undefined) {
                          var fingerprint$1 = Caml_option.valFromOption(fingerprint);
                          if (match$2) {
                            BrowserLogger.warnWithData6("Rest", "Fetch::RequestError (" + (String(meth) + (": " + (String(url$1) + ")"))), /* tuple */[
                                  "url",
                                  url$1
                                ], /* tuple */[
                                  "code",
                                  code
                                ], /* tuple */[
                                  "status",
                                  response.statusText
                                ], /* tuple */[
                                  "contentType",
                                  "N/A"
                                ], /* tuple */[
                                  "requestBody",
                                  Http.Method.body(req)
                                ], /* tuple */[
                                  Sentry.fingerprintKey,
                                  fingerprint$1
                                ]);
                          } else {
                            BrowserLogger.errorWithData6("Rest", "Fetch::RequestError (" + (String(meth) + (": " + (String(url$1) + ")"))), /* tuple */[
                                  "url",
                                  url$1
                                ], /* tuple */[
                                  "code",
                                  code
                                ], /* tuple */[
                                  "status",
                                  response.statusText
                                ], /* tuple */[
                                  "contentType",
                                  "N/A"
                                ], /* tuple */[
                                  "requestBody",
                                  Http.Method.body(req)
                                ], /* tuple */[
                                  Sentry.fingerprintKey,
                                  fingerprint$1
                                ]);
                          }
                        } else if (match$2) {
                          BrowserLogger.warnWithData5("Rest", "Fetch::RequestError (" + (String(meth) + (": " + (String(url$1) + ")"))), /* tuple */[
                                "url",
                                url$1
                              ], /* tuple */[
                                "code",
                                code
                              ], /* tuple */[
                                "status",
                                response.statusText
                              ], /* tuple */[
                                "contentType",
                                "N/A"
                              ], /* tuple */[
                                "requestBody",
                                Http.Method.body(req)
                              ]);
                        } else {
                          BrowserLogger.errorWithData5("Rest", "Fetch::RequestError (" + (String(meth) + (": " + (String(url$1) + ")"))), /* tuple */[
                                "url",
                                url$1
                              ], /* tuple */[
                                "code",
                                code
                              ], /* tuple */[
                                "status",
                                response.statusText
                              ], /* tuple */[
                                "contentType",
                                "N/A"
                              ], /* tuple */[
                                "requestBody",
                                Http.Method.body(req)
                              ]);
                        }
                        return $$Promise.resolve(/* Error */Block.__(1, [undefined]));
                      }
                      
                    }
                    
                  })), (function (error) {
                var meth = Http.Method.toString(req);
                if (fingerprint !== undefined) {
                  BrowserLogger.errorWithData4("Rest", "Fetch::RequestError (" + (String(meth) + (": " + (String(url$1) + ")"))), /* tuple */[
                        "url",
                        url$1
                      ], /* tuple */[
                        "requestBody",
                        Http.Method.body(req)
                      ], /* tuple */[
                        "error",
                        error
                      ], /* tuple */[
                        Sentry.fingerprintKey,
                        Caml_option.valFromOption(fingerprint)
                      ]);
                } else {
                  BrowserLogger.errorWithData3("Rest", "Fetch::RequestError (" + (String(meth) + (": " + (String(url$1) + ")"))), /* tuple */[
                        "url",
                        url$1
                      ], /* tuple */[
                        "requestBody",
                        Http.Method.body(req)
                      ], /* tuple */[
                        "error",
                        error
                      ]);
                }
                if (Env.env !== 1) {
                  
                } else {
                  requestTracker.end(undefined);
                }
                return $$Promise.resolve(/* Error */Block.__(1, [undefined]));
              }));
}

exports.Url = Url;
exports.$$fetch = $$fetch$1;
/* Env Not a pure module */
