// @flow
import countryData from 'country-telephone-data';
import { formatNumber } from 'react-telephone-input';

type Props = {
  phoneNumber: ?string,
};

const Phone = (props: Props) => {
  const phoneNumber = props.phoneNumber || '';
  const isoByLen = len => countryData.allCountryCodes[phoneNumber.substring(0, len)];

  const countryIso = (isoByLen(4) || isoByLen(3) || isoByLen(2) || isoByLen(1) || [''])[0];
  const country = countryData.allCountries.find(foundCountry => foundCountry.iso2 === countryIso);
  const formattedNumber = formatNumber(phoneNumber, country ? country.format : '', true);

  return formattedNumber === '+' ? '' : formattedNumber;
};

export default Phone;
