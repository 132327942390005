// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var TextModuleScss = require("./Text.module.scss");

var css = TextModuleScss;

function Text$Ellipsis(Props) {
  var fadedOpt = Props.faded;
  var faded = fadedOpt !== undefined ? fadedOpt : false;
  var tmp = { };
  var tmp$1 = faded ? css.faded : undefined;
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("span", tmp, "...");
}

var Ellipsis = {
  make: Text$Ellipsis
};

exports.css = css;
exports.Ellipsis = Ellipsis;
/* css Not a pure module */
