// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Route = require("../../../../Route.bs.js");
var React = require("react");
var Button = require("../../../../styleguide/components/Button/Button.bs.js");

function SlvNavbarEditListingButton(Props) {
  var listingId = Props.listingId;
  return React.createElement(Button.AsNativeLink.make, {
              href: Route.editListing(listingId),
              size: /* MD */1,
              color: /* Teal */2,
              ghost: true,
              children: "Edit listing"
            });
}

var make = SlvNavbarEditListingButton;

exports.make = make;
/* Route Not a pure module */
