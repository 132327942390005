// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var LocaleString = { };

function formatWithFractionDigits(x, digits) {
  return x.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: digits
            });
}

function formatWithoutFractionDigits(x) {
  return x.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            });
}

exports.LocaleString = LocaleString;
exports.formatWithFractionDigits = formatWithFractionDigits;
exports.formatWithoutFractionDigits = formatWithoutFractionDigits;
/* No side effect */
