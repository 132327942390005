// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function WifiIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var className = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "Wifi";
  var tmp = {
    title: title,
    size: size,
    children: React.createElement("g", {
          fill: "none",
          fillRule: "evenodd"
        }, React.createElement("path", {
              style: Icon.style(color),
              d: "M8,11.0658379 C8.47338693,11.0658379 8.85714286,11.4495938 8.85714286,11.9229807 C8.85714286,12.3963676 8.47338693,12.7801236 8,12.7801236 C7.52661307,12.7801236 7.14285714,12.3963676 7.14285714,11.9229807 C7.14285714,11.4495938 7.52661307,11.0658379 8,11.0658379 Z M7.99869565,8.47620347 C8.87468977,8.47620347 9.71395623,8.82814135 10.3279432,9.45294983 C10.5408472,9.66960621 10.5378053,10.0178337 10.3211489,10.2307376 C10.1044925,10.4436416 9.75626507,10.4405996 9.54336111,10.2239433 C9.13618905,9.80959484 8.57962052,9.57620347 7.99869565,9.57620347 C7.41777078,9.57620347 6.86120225,9.80959484 6.4540302,10.2239433 C6.24112624,10.4405996 5.89289879,10.4436416 5.67624241,10.2307376 C5.45958604,10.0178337 5.45654411,9.66960621 5.66944806,9.45294983 C6.28343507,8.82814135 7.12270154,8.47620347 7.99869565,8.47620347 Z M8.00130435,5.79460329 C9.68456557,5.79460329 11.2975772,6.46936687 12.4794511,7.66792537 C12.6927287,7.88421389 12.690288,8.23244607 12.4739994,8.44572373 C12.2577109,8.65900138 11.9094787,8.65656059 11.6962011,8.44027208 C10.7210428,7.45134738 9.39015486,6.89460329 8.00130435,6.89460329 C6.61245384,6.89460329 5.2815659,7.45134738 4.30640761,8.44027208 C4.09312995,8.65656059 3.74489777,8.65900138 3.52860925,8.44572373 C3.31232074,8.23244607 3.30987995,7.88421389 3.52315761,7.66792537 C4.70503148,6.46936687 6.31804313,5.79460329 8.00130435,5.79460329 Z M8,3.2 C10.403662,3.2 12.7066606,4.16516121 14.3921265,5.87886937 C14.6051229,6.09543484 14.6022296,6.44366356 14.3856641,6.65665998 C14.1690987,6.8696564 13.82087,6.86676312 13.6078735,6.65019765 C12.1291982,5.14674514 10.1087556,4.3 8,4.3 C5.89124444,4.3 3.87080175,5.14674514 2.39212647,6.65019765 C2.17913005,6.86676312 1.83090133,6.8696564 1.61433586,6.65665998 C1.39777039,6.44366356 1.3948771,6.09543484 1.60787353,5.87886937 C3.29333939,4.16516121 5.59633804,3.2 8,3.2 Z"
            }))
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Icon.make, tmp);
}

var make = WifiIcon;

exports.make = make;
/* Icon Not a pure module */
