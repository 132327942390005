// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var BadgeModuleScss = require("./Badge.module.scss");

var css = BadgeModuleScss;

function Badge(Props) {
  var $$float = Props.float;
  var children = Props.children;
  return React.createElement("span", {
              className: css.badge,
              style: {
                float: $$float !== undefined ? (
                    $$float ? "right" : "left"
                  ) : "none"
              }
            }, children);
}

var make = Badge;

exports.css = css;
exports.make = make;
/* css Not a pure module */
