// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function BedsIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var className = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "Beds";
  var tmp = {
    title: title,
    size: size,
    children: React.createElement("path", {
          style: Icon.style(color),
          d: "M2,0.5 C2,0.22386 2.22386,0 2.5,0 L8,0 L8,1 L3,1 L3,6 L5,6 L5,3.5 C5,3.22385 5.22385,3 5.5,3 L8,3 L8,4 L6,4 L6,6 L10,6 L10,4 L8,4 L8,3 L10.5,3 C10.77615,3 11,3.22385 11,3.5 L11,6 L13,6 L13,1 L8,1 L8,0 L13.5,0 C13.77615,0 14,0.22386 14,0.5 L14,6 L15.5,6 C15.77615,6 16,6.22385 16,6.5 L16,15 L15,15 L15,13 L8,13 L8,12 L15,12 L15,7 L1,7 L1,12 L8,12 L8,13 L1,13 L1,15 L0,15 L0,6.5 C0,6.22385 0.22386,6 0.5,6 L2,6 L2,0.5 Z"
        })
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Icon.make, tmp);
}

var make = BedsIcon;

exports.make = make;
/* Icon Not a pure module */
