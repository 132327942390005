// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var React = require("react");
var WithTestId = require("../../components/WithTestId/WithTestId.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var InputModuleScss = require("./Input.module.scss");
var SelectArrowDownIcon = require("../../icons/SelectArrowDownIcon.bs.js");

var css = InputModuleScss;

function placement(param) {
  return param[1];
}

var Addon = {
  placement: placement
};

function cn(size, status, neighbors, addon) {
  var tmp;
  switch (size) {
    case /* SM */0 :
        tmp = css.size_sm;
        break;
    case /* MD */1 :
        tmp = css.size_md;
        break;
    case /* LG */2 :
        tmp = css.size_lg;
        break;
    
  }
  var tmp$1;
  if (neighbors !== undefined) {
    switch (neighbors) {
      case /* Left */0 :
          tmp$1 = css.neighbors_left;
          break;
      case /* Right */1 :
          tmp$1 = css.neighbors_right;
          break;
      case /* LeftAndRight */2 :
          tmp$1 = css.neighbors_leftAndRight;
          break;
      case /* Top */3 :
          tmp$1 = css.neighbors_top;
          break;
      case /* Bottom */4 :
          tmp$1 = css.neighbors_bottom;
          break;
      case /* TopAndBottom */5 :
          tmp$1 = css.neighbors_topAndBottom;
          break;
      
    }
  } else {
    tmp$1 = css.neighbors_none;
  }
  var tmp$2;
  if (addon !== undefined) {
    if (addon) {
      switch (size) {
        case /* SM */0 :
            tmp$2 = css.innerIndent_right_sm;
            break;
        case /* MD */1 :
            tmp$2 = css.innerIndent_right_md;
            break;
        case /* LG */2 :
            tmp$2 = css.innerIndent_right_lg;
            break;
        
      }
    } else {
      switch (size) {
        case /* SM */0 :
            tmp$2 = css.innerIndent_left_sm;
            break;
        case /* MD */1 :
            tmp$2 = css.innerIndent_left_md;
            break;
        case /* LG */2 :
            tmp$2 = css.innerIndent_left_lg;
            break;
        
      }
    }
  } else {
    tmp$2 = css.innerIndent_none;
  }
  return Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus(css.input, tmp), status !== undefined ? (
                        status ? css.status_failure : css.status_success
                      ) : ""), tmp$1), tmp$2);
}

function Input$Icon(Props) {
  var icon = Props.icon;
  var size = Props.size;
  var placement = Props.placement;
  var tmp;
  if (placement) {
    switch (size) {
      case /* SM */0 :
          tmp = css.addonPlacement_right_sm;
          break;
      case /* MD */1 :
          tmp = css.addonPlacement_right_md;
          break;
      case /* LG */2 :
          tmp = css.addonPlacement_right_lg;
          break;
      
    }
  } else {
    switch (size) {
      case /* SM */0 :
          tmp = css.addonPlacement_left_sm;
          break;
      case /* MD */1 :
          tmp = css.addonPlacement_left_md;
          break;
      case /* LG */2 :
          tmp = css.addonPlacement_left_lg;
          break;
      
    }
  }
  return React.createElement(icon.make, {
              size: size + 1 | 0,
              color: /* Gray */8,
              className: Cn.$plus(css.addon, tmp)
            });
}

var Icon = {
  make: Input$Icon
};

var make = React.forwardRef((function (Props, theRef) {
        var id = Props.id;
        var testId = Props.testId;
        var type_Opt = Props.type_;
        var size = Props.size;
        var neighbors = Props.neighbors;
        var value = Props.value;
        var placeholder = Props.placeholder;
        var status = Props.status;
        var addon = Props.addon;
        var disabledOpt = Props.disabled;
        var autoFocus = Props.autoFocus;
        var classNameOpt = Props.className;
        var ariaLabel = Props.ariaLabel;
        var onChange = Props.onChange;
        var onFocus = Props.onFocus;
        var type_ = type_Opt !== undefined ? type_Opt : "text";
        var disabled = disabledOpt !== undefined ? disabledOpt : false;
        var className = classNameOpt !== undefined ? classNameOpt : "";
        var tmp = {
          className: cn(size, status, neighbors, Belt_Option.map(addon, placement)),
          disabled: disabled,
          type: type_,
          value: value,
          onChange: onChange
        };
        var tmp$1 = Belt_Option.map((theRef == null) ? undefined : Caml_option.some(theRef), (function (prim) {
                return prim;
              }));
        if (tmp$1 !== undefined) {
          tmp.ref = Caml_option.valFromOption(tmp$1);
        }
        if (ariaLabel !== undefined) {
          tmp["aria-label"] = Caml_option.valFromOption(ariaLabel);
        }
        if (id !== undefined) {
          tmp.id = Caml_option.valFromOption(id);
        }
        if (autoFocus !== undefined) {
          tmp.autoFocus = Caml_option.valFromOption(autoFocus);
        }
        if (placeholder !== undefined) {
          tmp.placeholder = Caml_option.valFromOption(placeholder);
        }
        if (onFocus !== undefined) {
          tmp.onFocus = Caml_option.valFromOption(onFocus);
        }
        return React.createElement("div", {
                    className: Cn.$plus(css.wrapper, className)
                  }, React.createElement(WithTestId.Optional.make, {
                        id: testId,
                        children: React.createElement("input", tmp)
                      }), addon !== undefined ? React.createElement(Input$Icon, {
                          icon: addon[0],
                          size: size,
                          placement: addon[1]
                        }) : null);
      }));

var $$Text = {
  make: make
};

var make$1 = React.forwardRef((function (Props, theRef) {
        var id = Props.id;
        var testId = Props.testId;
        var rows = Props.rows;
        var size = Props.size;
        var status = Props.status;
        var neighbors = Props.neighbors;
        var value = Props.value;
        var placeholder = Props.placeholder;
        var disabledOpt = Props.disabled;
        var autoFocus = Props.autoFocus;
        var classNameOpt = Props.className;
        var onChange = Props.onChange;
        var onFocus = Props.onFocus;
        var disabled = disabledOpt !== undefined ? disabledOpt : false;
        var className = classNameOpt !== undefined ? classNameOpt : "";
        var tmp = {
          className: cn(size, status, neighbors, undefined),
          disabled: disabled,
          rows: rows,
          value: value,
          onChange: onChange
        };
        var tmp$1 = Belt_Option.map((theRef == null) ? undefined : Caml_option.some(theRef), (function (prim) {
                return prim;
              }));
        if (tmp$1 !== undefined) {
          tmp.ref = Caml_option.valFromOption(tmp$1);
        }
        if (id !== undefined) {
          tmp.id = Caml_option.valFromOption(id);
        }
        if (autoFocus !== undefined) {
          tmp.autoFocus = Caml_option.valFromOption(autoFocus);
        }
        if (placeholder !== undefined) {
          tmp.placeholder = Caml_option.valFromOption(placeholder);
        }
        if (onFocus !== undefined) {
          tmp.onFocus = Caml_option.valFromOption(onFocus);
        }
        return React.createElement("div", {
                    className: Cn.$plus(css.wrapper, className)
                  }, React.createElement(WithTestId.Optional.make, {
                        id: testId,
                        children: React.createElement("textarea", tmp)
                      }));
      }));

var Textarea = {
  make: make$1
};

var defaultValue = "";

function Input$Select(Props) {
  var id = Props.id;
  var testId = Props.testId;
  var size = Props.size;
  var neighbors = Props.neighbors;
  var status = Props.status;
  var classNameOpt = Props.className;
  var value = Props.value;
  var readOnly = Props.readOnly;
  var onChange = Props.onChange;
  var onClick = Props.onClick;
  var onMouseDown = Props.onMouseDown;
  var onMouseUp = Props.onMouseUp;
  var onKeyDown = Props.onKeyDown;
  var onKeyUp = Props.onKeyUp;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    className: cn(size, status, neighbors, /* Right */1),
    value: Belt_Option.getWithDefault(value, defaultValue),
    onChange: onChange
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (readOnly !== undefined) {
    tmp.readOnly = Caml_option.valFromOption(readOnly);
  }
  if (onKeyDown !== undefined) {
    tmp.onKeyDown = Caml_option.valFromOption(onKeyDown);
  }
  if (onKeyUp !== undefined) {
    tmp.onKeyUp = Caml_option.valFromOption(onKeyUp);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onMouseDown !== undefined) {
    tmp.onMouseDown = Caml_option.valFromOption(onMouseDown);
  }
  if (onMouseUp !== undefined) {
    tmp.onMouseUp = Caml_option.valFromOption(onMouseUp);
  }
  return React.createElement("div", {
              className: Cn.$plus(css.wrapper, className)
            }, React.createElement(WithTestId.Optional.make, {
                  id: testId,
                  children: React.createElement("select", tmp, children)
                }), React.createElement(Input$Icon, {
                  icon: SelectArrowDownIcon,
                  size: size,
                  placement: /* Right */1
                }));
}

function Input$Select$Option(Props) {
  var value = Props.value;
  var children = Props.children;
  return React.createElement("option", {
              value: value
            }, children);
}

var $$Option = {
  make: Input$Select$Option
};

function Input$Select$DefaultOption(Props) {
  var children = Props.children;
  return React.createElement("option", {
              hidden: true,
              default: true,
              disabled: true,
              value: defaultValue
            }, children);
}

var DefaultOption = {
  make: Input$Select$DefaultOption
};

var Select = {
  defaultValue: defaultValue,
  make: Input$Select,
  $$Option: $$Option,
  DefaultOption: DefaultOption
};

exports.css = css;
exports.Addon = Addon;
exports.cn = cn;
exports.Icon = Icon;
exports.$$Text = $$Text;
exports.Textarea = Textarea;
exports.Select = Select;
/* css Not a pure module */
