// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

function all(prim) {
  return Promise.all(prim);
}

function resolve(prim) {
  return Promise.resolve(prim);
}

function reject(prim) {
  return Promise.reject(prim);
}

function andThen(p, fn) {
  return p.then(Curry.__1(fn));
}

function map(p, fn) {
  return p.then((function (x) {
                return Promise.resolve(Curry._1(fn, x));
              }));
}

function wait(p, fn) {
  p.then((function (x) {
          return Promise.resolve(Curry._1(fn, x));
        }));
  
}

function $$catch(p, fn) {
  return p.catch(Curry.__1(fn));
}

exports.all = all;
exports.resolve = resolve;
exports.reject = reject;
exports.andThen = andThen;
exports.map = map;
exports.wait = wait;
exports.$$catch = $$catch;
/* No side effect */
