// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var A = require("../Link/A.bs.js");
var Cn = require("re-classnames/src/Cn.bs.js");
var Hour = require("../../../models/Hour.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Control = require("../Control/Control.bs.js");
var Timezone = require("../../../models/Timezone.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var PhoneNumber = require("../PhoneNumber/PhoneNumber.bs.js");
var MaskedPhoneModuleScss = require("./MaskedPhone.module.scss");

var css = MaskedPhoneModuleScss;

function reducer(_state, action) {
  return /* Shown */0;
}

function MaskedPhone(Props) {
  var phone = Props.phone;
  var classNameOpt = Props.className;
  var onShown = Props.onShown;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useReducer(reducer, /* Hidden */1);
  var dispatch = match[1];
  var state = match[0];
  var preferredContactTimeStart = React.useMemo((function () {
          var x = phone.preferredContactTimeStart;
          if (x !== undefined) {
            return Hour.toString(x);
          } else {
            return Hour.toString(/* h0 */23240);
          }
        }), [phone.preferredContactTimeStart]);
  var preferredContactTimeEnd = React.useMemo((function () {
          var x = phone.preferredContactTimeEnd;
          if (x !== undefined) {
            return Hour.toString(x);
          } else {
            return Hour.toString(/* h0 */23240);
          }
        }), [phone.preferredContactTimeEnd]);
  var preferredContactTimezone = React.useMemo((function () {
          var x = phone.preferredContactTimezone;
          if (x !== undefined) {
            return Timezone.toString(x);
          } else {
            return Timezone.toString(/* tz0 */5795818);
          }
        }), [phone.preferredContactTimezone]);
  React.useEffect((function () {
          if (state) {
            
          } else {
            Belt_Option.getWithDefault(Belt_Option.map(onShown, (function (fn) {
                        return Curry._1(fn, undefined);
                      })), undefined);
          }
          
        }), [state]);
  if (state) {
    return React.createElement("div", {
                className: Cn.$plus(css.container, className)
              }, React.createElement("div", {
                    className: css.gradientContainer
                  }, React.createElement("div", {
                        className: css.phone
                      }, React.createElement(PhoneNumber.make, {
                            value: phone.phoneNumber.substring(0, 6)
                          })), React.createElement("div", {
                        className: css.gradient
                      })), React.createElement(Control.Underlined.make, {
                    color: /* Teal */0,
                    className: css.showLink,
                    onClick: (function (param) {
                        return Curry._1(dispatch, /* Show */0);
                      }),
                    children: "Show"
                  }));
  } else {
    return React.createElement("div", {
                className: Cn.$plus(Cn.$plus(css.container, css.column), className)
              }, React.createElement("div", {
                    className: css.phone
                  }, React.createElement(A.make, {
                        href: "tel:+" + phone.phoneNumber,
                        color: /* General */0,
                        children: React.createElement(PhoneNumber.make, {
                              value: phone.phoneNumber
                            })
                      })), React.createElement("div", {
                    className: css.time
                  }, preferredContactTimeStart, " - ", preferredContactTimeEnd, " ", preferredContactTimezone));
  }
}

var make = MaskedPhone;

exports.css = css;
exports.reducer = reducer;
exports.make = make;
/* css Not a pure module */
