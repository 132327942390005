// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function CheckMarkIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var title = Props.title;
  var className = Props.className;
  var tmp = {
    title: title,
    size: size,
    children: React.createElement("path", {
          style: Icon.style(color),
          d: "M2.24 6.48L5.76 10l8-8L16 4.24 5.76 14.48 0 8.72"
        })
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Icon.make, tmp);
}

var make = CheckMarkIcon;

exports.make = make;
/* Icon Not a pure module */
