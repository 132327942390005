// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var SlvHtmlTextModuleScss = require("./SlvHtmlText.module.scss");

var css = SlvHtmlTextModuleScss;

function paragraphs(x) {
  return Belt_Array.keep(x.split("\n"), (function (param) {
                if (param === "") {
                  return false;
                } else {
                  return true;
                }
              }));
}

function SlvHtmlText(Props) {
  var htmlText = Props.htmlText;
  return React.createElement("div", undefined, Belt_Array.mapWithIndex(paragraphs(htmlText), (function (idx, paragraph) {
                    return React.createElement("p", {
                                key: String(idx),
                                className: css.paragraph,
                                dangerouslySetInnerHTML: {
                                  __html: paragraph
                                }
                              });
                  })));
}

var make = SlvHtmlText;

exports.css = css;
exports.paragraphs = paragraphs;
exports.make = make;
/* css Not a pure module */
