// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function toString(x) {
  if (x) {
    return "true";
  } else {
    return "false";
  }
}

function fromString(param) {
  switch (param) {
    case "false" :
        return false;
    case "true" :
        return true;
    default:
      return ;
  }
}

exports.toString = toString;
exports.fromString = fromString;
/* No side effect */
