// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function make(scheme, host, port, referer) {
  return {
          scheme: scheme,
          host: host,
          port: port,
          referer: referer
        };
}

exports.make = make;
/* No side effect */
