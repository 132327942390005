// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function classNames(css) {
  return {
          appear: css.appear,
          appearActive: css.appearActive,
          enter: css.enter,
          enterActive: css.enterActive,
          exit: css.exit,
          exitActive: css.exitActive
        };
}

exports.classNames = classNames;
/* No side effect */
