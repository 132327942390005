// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");

function CrossIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var title = titleOpt !== undefined ? titleOpt : "Close";
  return React.createElement(Icon.make, {
              title: title,
              size: size,
              children: React.createElement("path", {
                    style: Icon.style(color),
                    d: "M8 6.857L1.143 0 0 1.143 6.857 8 0 14.857 1.143 16 8 9.143 14.857 16 16 14.857 9.143 8 16 1.143 14.857 0 8 6.857",
                    fillRule: "evenodd"
                  })
            });
}

var make = CrossIcon;

exports.make = make;
/* Icon Not a pure module */
