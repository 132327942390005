// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var A = require("../../../../styleguide/components/Link/A.bs.js");
var Vrbp = require("../../../../models/Vrbp.bs.js");
var Route = require("../../../../Route.bs.js");
var React = require("react");
var SlvBlock = require("../../components/SlvBlock/SlvBlock.bs.js");
var SlvBooking = require("../SlvBooking.bs.js");
var ReactIntl = require("react-intl");
var SlvReviewsModuleScss = require("./SlvReviews.module.scss");
var RatingWithReviewsCounter = require("../../../../styleguide/components/Rating/RatingWithReviewsCounter.bs.js");

var css = SlvReviewsModuleScss;

function SlvReviews$ProviderRow(Props) {
  var listingId = Props.listingId;
  var provider = Props.provider;
  var quote = Props.quote;
  var providerRating = Props.providerRating;
  var linkText = Props.linkText;
  return React.createElement("div", {
              className: css.providerRowWrapper
            }, React.createElement("div", {
                  className: css.providerNameAndLinkWrapper
                }, React.createElement("div", {
                      className: css.providerName
                    }, Vrbp.Provider.toView(provider)), React.createElement(A.make, {
                      href: quote !== undefined ? Route.transferPageWithBookingData(listingId, provider, quote.checkIn, quote.checkOut, quote.adults, quote.children, quote.infants, quote.pets) : Route.transferPage(listingId, provider),
                      target: /* Blank */[/* ReferrerAllowed */781962729],
                      color: /* Teal */1,
                      className: css.providerLink,
                      children: linkText
                    })), React.createElement(RatingWithReviewsCounter.make, {
                  providerRating: providerRating,
                  className: css.providerRating,
                  size: /* LG */3,
                  iconClassName: css.providerRatingIcon
                }));
}

var ProviderRow = {
  make: SlvReviews$ProviderRow
};

function SlvReviews(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var quote = SlvBooking.Status.quote(booking.status);
  var providerRating = listing.providersRatings.vrbo;
  var providerRating$1 = listing.providersRatings.airbnb;
  return React.createElement(SlvBlock.make, {
              id: /* Reviews */-353869381,
              children: null
            }, React.createElement(SlvBlock.Title.make, {
                  children: React.createElement(ReactIntl.FormattedMessage, {
                        id: "slv.reviews.title",
                        defaultMessage: "Reviews"
                      })
                }), React.createElement("div", {
                  className: css.providersRatingsWrapper
                }, React.createElement(RatingWithReviewsCounter.make, {
                      providerRating: listing.providersRatings.metaRating,
                      className: css.averageRating,
                      iconClassName: css.averageRatingStarIcon,
                      ratingValueClassName: css.averageRatingCount
                    }), providerRating !== undefined ? React.createElement(SlvReviews$ProviderRow, {
                        listingId: listing.id,
                        provider: /* Vrbo */2,
                        quote: quote,
                        providerRating: providerRating,
                        linkText: "View reviews on VRBO"
                      }) : null, providerRating$1 !== undefined ? React.createElement(SlvReviews$ProviderRow, {
                        listingId: listing.id,
                        provider: /* Airbnb */1,
                        quote: quote,
                        providerRating: providerRating$1,
                        linkText: "View reviews on Airbnb"
                      }) : null));
}

var make = SlvReviews;

exports.css = css;
exports.ProviderRow = ProviderRow;
exports.make = make;
/* css Not a pure module */
