// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Rest = require("../transport/Rest.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var CalendarDay = require("../../models/CalendarDay.bs.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromJson(json) {
  return Belt_Array.concatMany(Json_decode.field("calendarMonths", (function (param) {
                    return Json_decode.array((function (param) {
                                  return Json_decode.field("days", (function (param) {
                                                return Json_decode.array(CalendarDay.Guest.fromJson, param);
                                              }), param);
                                }), param);
                  }), json));
}

var Res = {
  fromJson: fromJson
};

function exec(listingId, year, month, quantity) {
  return Rest.$$fetch(/* `V1 */[
              19227,
              "/listings/" + (String(listingId) + ("/calendar-months/" + (String(year) + ("/" + (String(month) + ("?count=" + String(quantity)))))))
            ], /* Get */3553398, /* `Json */[
              826371656,
              fromJson
            ], [
              "listings",
              "calendar-months"
            ], undefined);
}

exports.Res = Res;
exports.exec = exec;
/* Rest Not a pure module */
