// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Date = require("../libs/Date.bs.js");
var Host = require("./Host.bs.js");
var I18n = require("../libs/I18n.bs.js");
var Vrbp = require("./Vrbp.bs.js");
var Guest = require("./Guest.bs.js");
var Photo = require("./Photo.bs.js");
var CustomFee = require("./CustomFee.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromString(x) {
  switch (x) {
    case "accepted" :
        return /* Accepted */1;
    case "canceled_by_guest" :
        return /* CanceledByGuest */4;
    case "canceled_by_host" :
        return /* CanceledByHost */3;
    case "declined" :
        return /* Declined */2;
    case "pending" :
        return /* Pending */0;
    default:
      return Pervasives.failwith("Unknown booking status: " + (String(x) + ""));
  }
}

function fromJson(json) {
  return fromString(Json_decode.string(json));
}

var State = {
  fromString: fromString,
  fromJson: fromJson
};

function fromJs(js) {
  var owner = js.owner;
  return {
          id: js.id,
          title: js.title,
          coverPhoto: Belt_Option.map(Caml_option.nullable_to_opt(js.coverPhoto), Photo.fromJs),
          city: js.city,
          state: js.state,
          country: js.country,
          htmlUrl: Caml_option.nullable_to_opt(js.htmlUrl),
          deleted: js.deleted,
          owner: !(owner == null) ? Host.fromJson(owner) : undefined,
          photos: Belt_Option.map(Caml_option.nullable_to_opt(js.photos), (function (x) {
                  return Belt_Array.map(x, Photo.fromJs);
                }))
        };
}

function fromJson$1(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          title: Json_decode.field("title", Json_decode.string, json),
          coverPhoto: Json_decode.field("coverPhoto", (function (param) {
                  return Json_decode.optional(Photo.fromJson, param);
                }), json),
          city: Json_decode.field("city", Json_decode.string, json),
          state: Json_decode.field("state", Json_decode.string, json),
          country: Json_decode.field("country", Json_decode.string, json),
          htmlUrl: Json_decode.field("htmlUrl", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          deleted: Json_decode.field("deleted", Json_decode.bool, json),
          owner: Json_decode.field("owner", (function (param) {
                  return Json_decode.optional(Host.fromJson, param);
                }), json),
          photos: Json_decode.optional((function (__x) {
                  return Json_decode.field("photos", (function (param) {
                                return Json_decode.array(Photo.fromJson, param);
                              }), __x);
                }), json)
        };
}

var Listing = {
  fromJs: fromJs,
  fromJson: fromJson$1
};

function fromString$1(status) {
  switch (status) {
    case "failed" :
        return /* Failed */2;
    case "pending" :
        return /* Pending */0;
    case "ready" :
        return /* Ready */1;
    default:
      return Pervasives.failwith("Unknown quote status: " + (String(status) + ""));
  }
}

function fromJson$2(json) {
  return fromString$1(Json_decode.string(json));
}

var Status = {
  fromString: fromString$1,
  fromJson: fromJson$2
};

function fromJson$3(json) {
  var provider = Json_decode.field("provider", (function (param) {
          return Json_decode.optional(Vrbp.Provider.fromJson, param);
        }), json);
  var providers = Json_decode.field("providers", (function (param) {
          return Json_decode.array(Vrbp.ProviderBag.fromJson, param);
        }), json);
  var bestPrice = Vrbp.BestPrice.fromQuote(provider, providers);
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          status: Json_decode.field("status", fromJson$2, json),
          checkIn: Json_decode.field("checkIn", $$Date.Naive.fromJson, json),
          checkOut: Json_decode.field("checkOut", $$Date.Naive.fromJson, json),
          adults: Json_decode.field("adults", Json_decode.$$int, json),
          children: Json_decode.field("children", Json_decode.$$int, json),
          infants: Json_decode.field("infants", Json_decode.$$int, json),
          pets: Json_decode.field("pets", Json_decode.bool, json),
          bestPrice: bestPrice,
          provider: Json_decode.field("provider", (function (param) {
                  return Json_decode.optional(Vrbp.Provider.fromJson, param);
                }), json),
          providers: Json_decode.field("providers", (function (param) {
                  return Json_decode.array(Vrbp.ProviderBag.fromJson, param);
                }), json),
          numberOfNights: Json_decode.field("numberOfNights", Json_decode.$$int, json),
          pricePerNight: Json_decode.field("pricePerNight", Json_decode.$$float, json),
          avgPricePerNight: Json_decode.field("avgPricePerNight", Json_decode.$$float, json),
          rent: Json_decode.field("rent", Json_decode.$$float, json),
          turnoverFeesAndTaxes: Json_decode.field("turnoverFeesAndTaxes", Json_decode.$$float, json),
          savings: Json_decode.field("savings", Json_decode.$$float, json),
          deposit: Json_decode.field("deposit", Json_decode.$$float, json),
          total: Json_decode.field("total", Json_decode.$$float, json)
        };
}

var Quote = {
  Status: Status,
  fromJson: fromJson$3
};

function fromJs$1(js) {
  return {
          id: js.id,
          checkIn: js.checkIn,
          checkOut: js.checkOut,
          adults: js.adults,
          children: js.children,
          cleaningFee: js.cleaningFee,
          deposit: js.deposit,
          numberOfNights: js.numberOfNights,
          totalPrice: js.totalPrice,
          avgPricePerNight: js.avgPricePerNight,
          pricePerNight: js.pricePerNight,
          totalTaxes: js.totalTaxes,
          taxRate: js.taxRate,
          totalDiscount: js.totalDiscount,
          houseRules: js.houseRules,
          state: fromString(js.state),
          active: js.active,
          extraCharges: js.extraCharges,
          customFees: Belt_Option.map(Caml_option.nullable_to_opt(js.customFees), (function (x) {
                  return Belt_Array.map(x, CustomFee.Guest.fromJs);
                })),
          guest: Guest.fromJs(js.guest),
          listing: fromJs(js.listing),
          flexibleDates: js.flexibleDates,
          conversationId: js.conversationId,
          comment: Caml_option.nullable_to_opt(js.comment),
          createdAt: js.createdAt
        };
}

function fromJson$4(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          checkIn: Json_decode.field("checkIn", $$Date.Naive.fromJson, json),
          checkOut: Json_decode.field("checkOut", $$Date.Naive.fromJson, json),
          adults: Json_decode.field("adults", Json_decode.$$int, json),
          children: Json_decode.field("children", Json_decode.$$int, json),
          cleaningFee: Json_decode.field("cleaningFee", Json_decode.$$float, json),
          deposit: Json_decode.field("deposit", Json_decode.$$float, json),
          numberOfNights: Json_decode.field("numberOfNights", Json_decode.$$int, json),
          totalPrice: Json_decode.field("totalPrice", Json_decode.$$float, json),
          avgPricePerNight: Json_decode.field("avgPricePerNight", Json_decode.$$float, json),
          pricePerNight: Json_decode.field("pricePerNight", Json_decode.$$float, json),
          totalTaxes: Json_decode.field("totalTaxes", Json_decode.$$float, json),
          taxRate: Json_decode.field("taxRate", Json_decode.$$float, json),
          totalDiscount: Json_decode.field("totalDiscount", Json_decode.$$float, json),
          houseRules: Json_decode.field("houseRules", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          state: Json_decode.field("state", fromJson, json),
          active: Json_decode.field("active", Json_decode.bool, json),
          extraCharges: Json_decode.field("extraCharges", (function (param) {
                  return Json_decode.dict(Json_decode.$$float, param);
                }), json),
          customFees: Json_decode.field("customFees", (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.array(CustomFee.Guest.fromJson, param);
                              }), param);
                }), json),
          guest: Json_decode.field("guest", Guest.fromJson, json),
          listing: Json_decode.field("listing", fromJson$1, json),
          flexibleDates: Json_decode.field("flexibleDates", Json_decode.bool, json),
          conversationId: Json_decode.field("conversationId", Json_decode.$$int, json),
          comment: Json_decode.field("comment", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          createdAt: Json_decode.field("createdAt", $$Date.Utc.fromJson, json)
        };
}

function guestsLabel(adults, children, infants, pets) {
  var sum = (adults + children | 0) + infants | 0;
  var guests = I18n.inflect(sum, "guest", "guests");
  if (pets) {
    return "" + (String(guests) + ", with pets");
  } else {
    return guests;
  }
}

var dateTemplate = "YYYY-MM-DD";

exports.dateTemplate = dateTemplate;
exports.State = State;
exports.Listing = Listing;
exports.Quote = Quote;
exports.fromJs = fromJs$1;
exports.fromJson = fromJson$4;
exports.guestsLabel = guestsLabel;
/* Date Not a pure module */
