// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function BedroomsIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var className = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "Bedrooms";
  var tmp = {
    title: title,
    size: size,
    children: React.createElement("path", {
          style: Icon.style(color),
          d: "M13,0 L3,0 C2.447715,0 2,0.447715 2,1 L2,15 C2,15.5523 2.447715,16 3,16 L13,16 C13.5523,16 14,15.5523 14,15 L14,1 C14,0.447715 13.5523,0 13,0 Z M3,15 L3,1 L13,1 L13,15 L3,15 Z M11.5,2 L4.5,2 C4.22385,2 4,2.22386 4,2.5 L4,13.5 C4,13.77615 4.22385,14 4.5,14 L11.5,14 C11.77615,14 12,13.77615 12,13.5 L12,2.5 C12,2.22386 11.77615,2 11.5,2 Z M5,13 L5,3 L11,3 L11,8 L9,8 L9,9 L11,9 L11,13 L5,13 Z"
        })
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Icon.make, tmp);
}

var make = BedroomsIcon;

exports.make = make;
/* Icon Not a pure module */
