// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Image = require("../Image/Image.bs.js");
var Style = require("../../../libs/Style.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function Avatar$User(Props) {
  var user = Props.user;
  var sizeOpt = Props.size;
  var className = Props.className;
  var size = sizeOpt !== undefined ? sizeOpt : 48;
  switch (user.tag | 0) {
    case /* Owner */0 :
        var user$1 = user[0];
        var tmp = {
          style: {
            height: Style.pxOfInt(size),
            width: Style.pxOfInt(size)
          },
          href: user$1.url
        };
        if (className !== undefined) {
          tmp.className = Caml_option.valFromOption(className);
        }
        return React.createElement("a", tmp, React.createElement($$Image.make, {
                        url: user$1.avatarUrl,
                        fetchWidth: size,
                        fetchHeight: size,
                        alt: user$1.firstName + (" " + user$1.lastName),
                        crop: /* Fill */2
                      }));
    case /* MetaOwnerUnclaimed */1 :
        return null;
    case /* MetaOwnerClaimed */2 :
        var user$2 = user[0];
        var tmp$1 = {
          style: {
            height: Style.pxOfInt(size),
            width: Style.pxOfInt(size)
          }
        };
        if (className !== undefined) {
          tmp$1.className = Caml_option.valFromOption(className);
        }
        return React.createElement("div", tmp$1, React.createElement($$Image.make, {
                        url: user$2.avatarUrl,
                        fetchWidth: size,
                        fetchHeight: size,
                        alt: user$2.firstName + (" " + user$2.lastName),
                        crop: /* Fill */2
                      }));
    
  }
}

var User = {
  make: Avatar$User
};

function Avatar$Incognito(Props) {
  var alt = Props.alt;
  var cloudinaryUrl = Props.cloudinaryUrl;
  var sizeOpt = Props.size;
  var className = Props.className;
  var size = sizeOpt !== undefined ? sizeOpt : 48;
  var tmp = {
    style: {
      height: Style.pxOfInt(size),
      width: Style.pxOfInt(size)
    }
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement("div", tmp, React.createElement($$Image.make, {
                  url: "" + (String(cloudinaryUrl) + "default_avatar.png"),
                  fetchWidth: size,
                  fetchHeight: size,
                  alt: alt,
                  crop: /* Fill */2
                }));
}

var Incognito = {
  make: Avatar$Incognito
};

var defaultSize = 48;

exports.defaultSize = defaultSize;
exports.User = User;
exports.Incognito = Incognito;
/* Image Not a pure module */
