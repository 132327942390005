// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");

function TooltipQuestionIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var title = titleOpt !== undefined ? titleOpt : "";
  return React.createElement(Icon.make, {
              title: title,
              size: size,
              children: null
            }, React.createElement("path", {
                  style: Icon.style(color),
                  d: "M15 8c0-3.866-3.134-7-7-7S1 4.134 1 8s3.134 7 7 7 7-3.134 7-7zM0 8c0-4.418 3.582-8 8-8s8 3.582 8 8-3.582 8-8 8-8-3.582-8-8z"
                }), React.createElement("path", {
                  style: Icon.style(color),
                  d: "M7.535 10.06c-.003-.128-.005-.225-.005-.29 0-.38.053-.706.16-.982.08-.208.207-.417.382-.628.13-.154.36-.38.696-.674.335-.296.552-.53.652-.707.1-.18.15-.37.15-.58 0-.38-.146-.71-.44-.99-.293-.29-.653-.43-1.08-.43-.41 0-.755.13-1.03.38-.276.26-.457.66-.543 1.21l-.994-.12c.09-.734.356-1.297.798-1.687.45-.39 1.03-.585 1.76-.585.77 0 1.39.21 1.84.63.46.42.69.924.69 1.52 0 .342-.08.66-.24.95-.16.29-.47.642-.94 1.06-.316.276-.52.482-.62.615-.094.134-.17.286-.212.458-.046.174-.072.45-.08.84h-.93l-.01.006zM7.475 12v-1.1h1.102V12H7.475z"
                }));
}

var make = TooltipQuestionIcon;

exports.make = make;
/* Icon Not a pure module */
