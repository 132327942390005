// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Rest = require("../transport/Rest.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function toJson(data) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "hostBestPrice",
                data.hostBestPrice
              ],
              /* [] */0
            ]);
}

var Req = {
  toJson: toJson
};

function exec(userId, body) {
  return Rest.$$fetch(/* `V1 */[
              19227,
              "/users/" + (String(userId) + "/host-best-price")
            ], /* `Patch */[
              -796625240,
              toJson(body)
            ], /* Empty */50834029, undefined, undefined);
}

exports.Req = Req;
exports.exec = exec;
/* Rest Not a pure module */
