// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function subscribe(mq, handler) {
  mq.addListener(handler);
  return (function (param) {
            mq.removeListener(handler);
            
          });
}

exports.subscribe = subscribe;
/* No side effect */
