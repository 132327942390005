// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Hooks = require("../../../libs/Hooks.bs.js");
var React = require("react");
var Events = require("../../../libs/Events.bs.js");
var Portal = require("../Portal/Portal.bs.js");
var Control = require("../Control/Control.bs.js");
var CrossIcon = require("../../icons/CrossIcon.bs.js");
var FadeInOut = require("../../animations/FadeInOut/FadeInOut.bs.js");
var FallInOut = require("../../animations/FallInOut/FallInOut.bs.js");
var WithTestId = require("../WithTestId/WithTestId.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ModalModuleScss = require("./Modal.module.scss");

var css = ModalModuleScss;

function Modal(Props) {
  var shown = Props.shown;
  var hide = Props.hide;
  var classNameOpt = Props.className;
  var testId = Props.testId;
  var onEnter = Props.onEnter;
  var onEntering = Props.onEntering;
  var onEntered = Props.onEntered;
  var onExit = Props.onExit;
  var onExiting = Props.onExiting;
  var onExited = Props.onExited;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var container = React.useRef(null);
  var match = Hooks.useReducer({
        shown: shown
      }, (function (_state, action) {
          if (action) {
            return /* UpdateWithSideEffects */Block.__(1, [
                      {
                        shown: false
                      },
                      (function (param) {
                          return Belt_Option.mapWithDefault(onExited, undefined, (function (fn) {
                                        return Curry._1(fn, undefined);
                                      }));
                        })
                    ]);
          } else {
            return /* Update */Block.__(0, [{
                        shown: true
                      }]);
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          var match = state.shown;
          if (shown && !match) {
            Curry._1(dispatch, /* OpenPortal */0);
          }
          
        }), /* tuple */[
        shown,
        state.shown
      ]);
  React.useEffect((function () {
          if (state.shown) {
            return Events.subscribeToKeyDown((function (__x) {
                          return Events.onDomEscKey(__x, hide);
                        }));
          }
          
        }), [state.shown]);
  var hideOnClickOutside = React.useCallback((function ($$event) {
          var match = state.shown;
          var match$1 = container.current;
          if (!match) {
            return ;
          }
          if (match$1 == null) {
            return ;
          }
          var target = $$event.target;
          if (!match$1.contains(target)) {
            return Curry._1(hide, undefined);
          }
          
        }), /* tuple */[
        state.shown,
        hide
      ]);
  if (!state.shown) {
    return null;
  }
  var tmp = {
    show: shown,
    appear: true,
    enter: true,
    exit: true,
    mountOnEnter: true,
    unmountOnExit: true,
    onExited: (function (param) {
        return Curry._1(dispatch, /* ClosePortal */1);
      }),
    children: React.createElement(WithTestId.Optional.make, {
          id: testId,
          children: React.createElement("div", {
                ref: container,
                className: Cn.$plus(Cn.$plus(css.modal, "modal-dialog"), className)
              }, React.createElement(Control.make, {
                    testId: "close-modal",
                    className: css.closeControl,
                    onClick: (function (param) {
                        return Curry._1(hide, undefined);
                      }),
                    children: React.createElement(CrossIcon.make, {
                          size: /* SM */1,
                          color: /* Gray */8
                        })
                  }), children)
        })
  };
  if (onEnter !== undefined) {
    tmp.onEnter = Caml_option.valFromOption(onEnter);
  }
  if (onEntering !== undefined) {
    tmp.onEntering = Caml_option.valFromOption(onEntering);
  }
  if (onEntered !== undefined) {
    tmp.onEntered = Caml_option.valFromOption(onEntered);
  }
  if (onExit !== undefined) {
    tmp.onExit = Caml_option.valFromOption(onExit);
  }
  if (onExiting !== undefined) {
    tmp.onExiting = Caml_option.valFromOption(onExiting);
  }
  return React.createElement(Portal.make, {
              children: React.createElement("div", {
                    className: css.modalContainer,
                    onClick: hideOnClickOutside
                  }, React.createElement(FadeInOut.make, {
                        show: shown,
                        appear: true,
                        enter: true,
                        exit: true,
                        mountOnEnter: true,
                        unmountOnExit: true,
                        children: React.createElement("div", {
                              className: css.modalOverlay
                            })
                      }), React.createElement(FallInOut.make, tmp))
            });
}

function Modal$Title(Props) {
  var screen = Props.screen;
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("h2", {
              className: Cn.$plus(screen !== undefined && screen < 2 ? css.titleMobile : css.titleNoMobile, className)
            }, children);
}

var Title = {
  make: Modal$Title
};

function Modal$Panels(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: Cn.$plus(css.panels, className)
            }, children);
}

var Panels = {
  make: Modal$Panels
};

function Modal$PanelsTitle(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(React.Fragment, undefined, React.createElement("h2", {
                  className: Cn.$plus(css.titlePanels, className)
                }, children), React.createElement("div", {
                  className: css.emptyTitle
                }));
}

var PanelsTitle = {
  make: Modal$PanelsTitle
};

function Modal$MainPanel(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: Cn.$plus(css.mainPanel, className)
            }, children);
}

var MainPanel = {
  make: Modal$MainPanel
};

function Modal$SidePanel(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: Cn.$plus(css.sidePanel, className)
            }, children);
}

var SidePanel = {
  make: Modal$SidePanel
};

function Modal$Block(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: Cn.$plus(css.block, className)
            }, children);
}

var Block$1 = {
  make: Modal$Block
};

function Modal$Footer(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: Cn.$plus(css.footer, className)
            }, children);
}

var Footer = {
  make: Modal$Footer
};

var make = Modal;

exports.css = css;
exports.make = make;
exports.Title = Title;
exports.Panels = Panels;
exports.PanelsTitle = PanelsTitle;
exports.MainPanel = MainPanel;
exports.SidePanel = SidePanel;
exports.Block = Block$1;
exports.Footer = Footer;
/* css Not a pure module */
