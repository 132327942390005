// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function VrboIcon(Props) {
  var titleOpt = Props.title;
  var size = Props.size;
  var className = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "Vrbo";
  var tmp = {
    title: title,
    size: size,
    children: React.createElement("g", {
          fill: "none",
          fillRule: "evenodd"
        }, React.createElement("rect", {
              height: "16",
              width: "16",
              x: "0",
              y: "0"
            }), React.createElement("path", {
              d: "M4.56605056,3 C4.65004446,3.59371293 4.76985502,4.18195178 4.92484371,4.76158159 L4.92484371,4.76158159 L6.71880946,13 L6.31516716,13 L4.52120141,4.78813809 C4.28200598,3.81735025 4.19230769,3.37769253 3.8933134,3.01770434 L3.8933134,3.01770434 L3.8933134,3 Z M3.22057624,3 C3.50163088,3.20064916 3.82454471,3.46031278 4.13250883,4.87075834 L4.13250883,4.87075834 L5.91750476,12.9970493 L5.51386246,12.9970493 L3.74082631,4.92092063 C3.48070128,3.76718796 3.15778744,3.27441723 2.5,3.01770434 L2.5,3.01770434 L2.5,3 Z M5.66036967,3 C5.55273172,3.33933314 5.49293286,3.83505459 5.75604784,4.9179699 L5.75604784,4.9179699 L7.2510193,11.8846267 L6.98491438,12.4334612 L5.31652623,4.73502508 C5.15980268,4.16984488 5.08332778,3.58593186 5.08929057,3 L5.08929057,3 Z M6.85036695,3 L6.85036695,3.01770434 C6.47662408,3.34818531 6.38094591,3.926527 6.60519163,5.03894954 L6.60519163,5.03894954 L7.8011688,10.7722042 L7.53506388,11.3269401 L6.17463985,4.93272352 C5.94740419,3.83210387 6.00720304,3.41605193 6.20453928,3 L6.20453928,3 L6.85036695,3 Z M13.5,3 L13.5,3.02655651 C11.9213101,3.49572145 11.2246534,4.39569194 10.0017668,6.65594571 L10.0017668,6.65594571 L9.9748573,6.64119209 C11.0332971,4.39569194 11.5385974,3.48982001 12.3877412,3 L12.3877412,3 L13.5,3 Z M11.6193259,3 L11.6193259,3.01770434 C11.2097037,3.62850398 10.9465887,4.28061375 9.94196793,6.61463559 L9.94196793,6.61463559 L9.91505844,6.59693125 C10.5728459,4.82059605 10.8897798,3.95898495 10.8060614,3.02360578 L10.8060614,3.02360578 L10.8060614,3 L11.6193259,3 Z M10.1692036,3 C10.7313129,3.56358808 10.5250068,4.48716436 9.88814895,6.57922691 L9.88814895,6.57922691 L9.86123947,6.56152257 C10.4592281,4.22455001 10.2738516,3.33048097 9.37686871,3.01770434 L9.37686871,3.01770434 L9.37686871,3 Z",
              fill: "#245ABC",
              fillRule: "nonzero"
            }))
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Icon.make, tmp);
}

var make = VrboIcon;

exports.make = make;
/* Icon Not a pure module */
