// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Html = require("../../../../libs/Html.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Bubble = require("../../../../styleguide/svgs/Bubble.bs.js");
var Button = require("../../../../styleguide/components/Button/Button.bs.js");
var Mascot = require("../../../../styleguide/svgs/Mascot.bs.js");
var Control = require("../../../../styleguide/components/Control/Control.bs.js");
var SlvVrbp = require("../SlvVrbp.bs.js");
var Currency = require("../../../../styleguide/components/Currency/Currency.bs.js");
var SlvBlock = require("../../components/SlvBlock/SlvBlock.bs.js");
var SafetyIcon = require("../../../../styleguide/icons/SafetyIcon.bs.js");
var SlvHostUnknownModuleScss = require("./SlvHostUnknown.module.scss");
var IntercomMessengerJs = require("app/libs/utils/intercomMessenger.js");

var css = SlvHostUnknownModuleScss;

function SlvHostUnknown(Props) {
  var listing = Props.listing;
  var booking = Props.booking;
  var unclaimedListingModal = Props.unclaimedListingModal;
  var priceStatus = SlvVrbp.status(booking.status, listing.vrbpData);
  var tmp;
  if (priceStatus !== undefined && priceStatus) {
    var bestPrice = priceStatus[0].bestPrice;
    tmp = typeof bestPrice === "number" || bestPrice.tag ? null : React.createElement(React.Fragment, undefined, React.createElement("span", undefined, " and"), React.createElement("span", {
                className: css.savings
              }, " save ", React.createElement(Currency.WithoutCents.make, {
                    children: bestPrice[/* profit */0]
                  })));
  } else {
    tmp = null;
  }
  return React.createElement(SlvBlock.make, {
              id: /* Host */803994504,
              children: null
            }, React.createElement(SlvBlock.Title.make, {
                  children: "About the host"
                }), React.createElement("div", {
                  className: css.messageContainer
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: css.message
                        }, "The listing has not yet been claimed by the owner but you can still send an inquiry. We'll try to find the owner so you get a direct price", tmp, ". Can take up to 24 hours.", React.createElement("div", {
                              className: css.learnMoreLink
                            }, React.createElement(Control.make, {
                                  testId: "host-learn-more",
                                  onClick: (function (param) {
                                      return Curry._1(unclaimedListingModal.show, undefined);
                                    }),
                                  children: Html.nbsp + "Learn more"
                                }))), React.createElement("div", {
                          className: css.safetyMessage
                        }, React.createElement(SafetyIcon.make, {
                              size: /* LG */3,
                              color: /* Teal */1,
                              className: css.safetyIcon
                            }), "We take your safety seriously and verify every host"), React.createElement(Button.make, {
                          size: /* MD */1,
                          color: /* Silver */1,
                          className: css.hostButton,
                          onClick: (function (param) {
                              IntercomMessengerJs.showIntercom();
                              
                            }),
                          children: "I" + (Html.apos + "m the owner")
                        })), React.createElement("div", {
                      className: css.mascotContainer
                    }, React.createElement("div", {
                          className: css.ovalContainer
                        }, React.createElement(Mascot.make, {
                              className: css.mascot
                            })), React.createElement(Bubble.Small.make, {
                          className: css.bubble,
                          color: /* LightTeal */2,
                          text: "Hi!"
                        }))));
}

var make = SlvHostUnknown;

exports.css = css;
exports.make = make;
/* css Not a pure module */
