// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var I18n = require("../../../../libs/I18n.bs.js");
var $$Text = require("../../../../styleguide/components/Text/Text.bs.js");
var React = require("react");
var SlvBlock = require("../../components/SlvBlock/SlvBlock.bs.js");
var SlvTable = require("../../components/SlvTable/SlvTable.bs.js");
var ReactIntl = require("react-intl");
var SlvRatesModuleScss = require("./SlvRates.module.scss");

var css = SlvRatesModuleScss;

function SlvRates$Block(Props) {
  var listing = Props.listing;
  var pricePerNight = listing.samplePrice;
  var weeklyDiscount = listing.weeklyDiscount;
  var monthlyDiscount = listing.monthlyDiscount;
  var minimumStay = listing.minimumStay;
  var deposit = listing.deposit;
  var cleaningFee = listing.cleaningFee;
  return React.createElement(SlvBlock.make, {
              id: /* Rates */-145645197,
              children: null
            }, React.createElement(SlvBlock.Title.make, {
                  children: React.createElement(ReactIntl.FormattedMessage, {
                        id: "slv.rates.title",
                        defaultMessage: "Rates"
                      })
                }), React.createElement("div", {
                  className: css.container
                }, React.createElement(SlvTable.make, {
                      children: null
                    }, React.createElement(SlvTable.Row.make, {
                          children: null
                        }, React.createElement(SlvTable.Cell.make, {
                              children: null
                            }, React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.rates.price-per-night.label",
                                  defaultMessage: "Price per night"
                                }), ":"), React.createElement(SlvTable.Cell.make, {
                              children: pricePerNight !== undefined ? React.createElement(ReactIntl.FormattedNumber, {
                                      value: pricePerNight,
                                      style: "currency",
                                      currency: I18n.currency
                                    }) : React.createElement($$Text.Ellipsis.make, {
                                      faded: true
                                    })
                            })), React.createElement(SlvTable.Row.make, {
                          children: null
                        }, React.createElement(SlvTable.Cell.make, {
                              children: null
                            }, React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.rates.weekly-discount.label",
                                  defaultMessage: "Weekly discount"
                                }), ":"), React.createElement(SlvTable.Cell.make, {
                              children: weeklyDiscount !== undefined ? React.createElement(ReactIntl.FormattedNumber, {
                                      value: weeklyDiscount,
                                      style: "percent"
                                    }) : React.createElement($$Text.Ellipsis.make, {
                                      faded: true
                                    })
                            })), React.createElement(SlvTable.Row.make, {
                          children: null
                        }, React.createElement(SlvTable.Cell.make, {
                              children: null
                            }, React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.rates.monthly-discount.label",
                                  defaultMessage: "Monthly discount"
                                }), ":"), React.createElement(SlvTable.Cell.make, {
                              children: monthlyDiscount !== undefined ? React.createElement(ReactIntl.FormattedNumber, {
                                      value: monthlyDiscount,
                                      style: "percent"
                                    }) : React.createElement($$Text.Ellipsis.make, {
                                      faded: true
                                    })
                            }))), React.createElement(SlvTable.make, {
                      children: null
                    }, React.createElement(SlvTable.Row.make, {
                          children: null
                        }, React.createElement(SlvTable.Cell.make, {
                              children: null
                            }, React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.rates.minimum-stay.label",
                                  defaultMessage: "Minimum stay"
                                }), ":"), React.createElement(SlvTable.Cell.make, {
                              children: minimumStay !== undefined ? React.createElement(ReactIntl.FormattedMessage, {
                                      id: "slv.rates.minimum-stay.value",
                                      defaultMessage: "{minimumStay, plural, one {# night} few {# nights} many {# nights} other {# nights}}",
                                      values: {
                                        minimumStay: minimumStay
                                      }
                                    }) : React.createElement($$Text.Ellipsis.make, {
                                      faded: true
                                    })
                            })), React.createElement(SlvTable.Row.make, {
                          children: null
                        }, React.createElement(SlvTable.Cell.make, {
                              children: null
                            }, React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.rates.deposit.label",
                                  defaultMessage: "Refundable deposit"
                                }), ":"), React.createElement(SlvTable.Cell.make, {
                              children: deposit !== undefined ? React.createElement(ReactIntl.FormattedNumber, {
                                      value: deposit,
                                      style: "currency",
                                      currency: I18n.currency
                                    }) : React.createElement($$Text.Ellipsis.make, {
                                      faded: true
                                    })
                            })), React.createElement(SlvTable.Row.make, {
                          children: null
                        }, React.createElement(SlvTable.Cell.make, {
                              children: null
                            }, React.createElement(ReactIntl.FormattedMessage, {
                                  id: "slv.rates.cleaning-fee.label",
                                  defaultMessage: "Cleaning fee"
                                }), ":"), React.createElement(SlvTable.Cell.make, {
                              children: cleaningFee !== undefined ? React.createElement(ReactIntl.FormattedNumber, {
                                      value: cleaningFee,
                                      style: "currency",
                                      currency: I18n.currency
                                    }) : React.createElement($$Text.Ellipsis.make, {
                                      faded: true
                                    })
                            })))));
}

var Block = {
  make: SlvRates$Block
};

function SlvRates(Props) {
  var listing = Props.listing;
  var match = listing.legalRestriction;
  if (match !== undefined) {
    return null;
  } else {
    return React.createElement(SlvRates$Block, {
                listing: listing
              });
  }
}

var make = SlvRates;

exports.css = css;
exports.Block = Block;
exports.make = make;
/* css Not a pure module */
