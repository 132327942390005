// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.bs.js");
var LinkModuleScss = require("./Link.module.scss");

var css = LinkModuleScss;

function className(param) {
  if (param) {
    return css.tealColor;
  } else {
    return css.generalColor;
  }
}

var Color = {
  className: className
};

function Link(Props) {
  var path = Props.path;
  var color = Props.color;
  var classNameOpt = Props.className;
  var style = Props.style;
  var onClick = Props.onClick;
  var onMouseDown = Props.onMouseDown;
  var onTouchStart = Props.onTouchStart;
  var children = Props.children;
  var className$1 = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    className: Cn.$plus(Cn.$plus(css.link, className(color)), className$1),
    href: path,
    onClick: (function ($$event) {
        Belt_Option.map(onClick, (function (fn) {
                return Curry._1(fn, $$event);
              }));
        if (!$$event.defaultPrevented && $$event.button === 0 && !$$event.altKey && !$$event.ctrlKey && !$$event.metaKey && !$$event.shiftKey) {
          $$event.preventDefault();
          return ReasonReactRouter.push(path);
        }
        
      })
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (onMouseDown !== undefined) {
    tmp.onMouseDown = Caml_option.valFromOption(onMouseDown);
  }
  if (onTouchStart !== undefined) {
    tmp.onTouchStart = Caml_option.valFromOption(onTouchStart);
  }
  return React.createElement("a", tmp, children);
}

var make = Link;

exports.css = css;
exports.Color = Color;
exports.make = make;
/* css Not a pure module */
