// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var GetQuoteRequest = require("./requests/GetQuoteRequest.bs.js");
var PostTripRequest = require("./requests/PostTripRequest.bs.js");
var GetQuoteIdRequest = require("./requests/GetQuoteIdRequest.bs.js");
var PostMessageRequest = require("./requests/PostMessageRequest.bs.js");
var FavoriteListingRequest = require("./requests/FavoriteListingRequest.bs.js");
var UnfavoriteListingRequest = require("./requests/UnfavoriteListingRequest.bs.js");
var GetContextualListingRequest = require("./requests/GetContextualListingRequest.bs.js");
var PostListingItemClickRequest = require("./requests/PostListingItemClickRequest.bs.js");
var UserHostBestPriceUpdateRequest = require("./requests/UserHostBestPriceUpdateRequest.bs.js");
var GetCalendarMonthsForGuestRequest = require("./requests/GetCalendarMonthsForGuestRequest.bs.js");

var postTripFromHc = PostTripRequest.Hc.exec;

var postTripFromMicrosite = PostTripRequest.Microsites.exec;

var postMessageFromHc = PostMessageRequest.Hc.exec;

var postMessageFromMicrosite = PostMessageRequest.Microsites.exec;

var postListingItemClickFromHc = PostListingItemClickRequest.Hc.exec;

var postListingItemClickFromMicrosite = PostListingItemClickRequest.Microsites.exec;

var getContextualListing = GetContextualListingRequest.exec;

var getQuote = GetQuoteRequest.exec;

var getQuoteId = GetQuoteIdRequest.exec;

var getCalendarMonthsForGuest = GetCalendarMonthsForGuestRequest.exec;

var updateUserHostBestPrice = UserHostBestPriceUpdateRequest.exec;

var favoriteListing = FavoriteListingRequest.exec;

var unfavoriteListing = UnfavoriteListingRequest.exec;

exports.getContextualListing = getContextualListing;
exports.getQuote = getQuote;
exports.getQuoteId = getQuoteId;
exports.getCalendarMonthsForGuest = getCalendarMonthsForGuest;
exports.postTripFromHc = postTripFromHc;
exports.postTripFromMicrosite = postTripFromMicrosite;
exports.postMessageFromHc = postMessageFromHc;
exports.postMessageFromMicrosite = postMessageFromMicrosite;
exports.updateUserHostBestPrice = updateUserHostBestPrice;
exports.favoriteListing = favoriteListing;
exports.unfavoriteListing = unfavoriteListing;
exports.postListingItemClickFromHc = postListingItemClickFromHc;
exports.postListingItemClickFromMicrosite = postListingItemClickFromMicrosite;
/* GetQuoteRequest Not a pure module */
