// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var React = require("react");
var Bubble = require("../../svgs/Bubble.bs.js");
var Mascot = require("../../svgs/Mascot.bs.js");
var MascotWithMessageScss = require("./MascotWithMessage.scss");

var css = MascotWithMessageScss;

function MascotWithMessage(Props) {
  var message = Props.message;
  var classNameOpt = Props.className;
  var bubbleWrapperClassNameOpt = Props.bubbleWrapperClassName;
  var bubbleColor = Props.bubbleColor;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var bubbleWrapperClassName = bubbleWrapperClassNameOpt !== undefined ? bubbleWrapperClassNameOpt : "";
  return React.createElement("div", {
              className: Cn.$plus(css.container, className)
            }, React.createElement(Mascot.make, {
                  className: css.mascot
                }), React.createElement("div", {
                  className: Cn.$plus(css.bubbleWrapper, bubbleWrapperClassName)
                }, React.createElement("span", {
                      className: css.bubbleLeftSectionImageWrapper
                    }, React.createElement(Bubble.make, {
                          className: Cn.$plus(css.bubble, css.hiddenMobile),
                          color: bubbleColor
                        }), React.createElement(Bubble.Mobile.make, {
                          className: Cn.$plus(css.bubble, css.hiddenDesktop),
                          color: bubbleColor
                        })), React.createElement("div", {
                      className: css.messageWrapper
                    }, React.createElement("div", {
                          className: css.message
                        }, message))));
}

var make = MascotWithMessage;

exports.css = css;
exports.make = make;
/* css Not a pure module */
