import _ from 'lodash/fp';

export default class Enum {
  constructor(enums) {
    const enumItems = [];

    // FIXME: Maybe someday (for-of -> reduce)
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(enums)) {
      const enumItem = enums[key];
      const isFlat = typeof enumItem !== 'object';

      if (!isFlat) enumItem.key = key;
      if (_.isUndefined(this.isFlat)) this.isFlat = isFlat;

      this[key] = enumItem;
      enumItems.push(this[key]);
    }

    this.enumItems = enumItems;
    Object.freeze(this);
  }

  get = key => {
    if (_.isUndefined(this[key])) {
      throw new Error(`[Enum]: Can't find ${key}. Check spelling and make sure it exists in this Enum.`);
    }

    return this[key];
  };

  items = () => this.enumItems;

  orderedItems = ({ by, order } = { by: 'position' }) => {
    const iteratees = this.isFlat ? null : by;
    return _.orderBy(iteratees, order, this.enumItems);
  };

  values = () => this.enumItems.map(item => item.value);

  fromValue = value => this.enumItems.find(item => item.value === value);

  fromPosition = position => this.enumItems.find(item => item.position === position);
}
