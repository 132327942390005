// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Url = require("./libs/Url.bs.js");
var $$Date = require("./libs/Date.bs.js");
var Vrbp = require("./models/Vrbp.bs.js");

function listing(listingId) {
  return "/listings/" + String(listingId);
}

function trip(bookingId) {
  return "/trips/" + String(bookingId);
}

function editListing(listingId) {
  return "/manage/listings/" + String(listingId);
}

function conversation(conversationId) {
  return "/conversations/" + String(conversationId);
}

function editProfile(userUrl) {
  return userUrl + "/edit";
}

function transferPage(listingId, provider) {
  return Url.withQuery("/listings/" + String(listingId), /* :: */[
              /* tuple */[
                "provider",
                Vrbp.Provider.toString(provider)
              ],
              /* [] */0
            ]);
}

function transferPageWithBookingData(listingId, provider, checkIn, checkOut, adults, children, infants, pets) {
  return Url.withQuery("/listings/" + String(listingId), /* :: */[
              /* tuple */[
                "provider",
                Vrbp.Provider.toString(provider)
              ],
              /* :: */[
                /* tuple */[
                  "check_in",
                  $$Date.Naive.toString(checkIn)
                ],
                /* :: */[
                  /* tuple */[
                    "check_out",
                    $$Date.Naive.toString(checkOut)
                  ],
                  /* :: */[
                    /* tuple */[
                      "adults",
                      String(adults)
                    ],
                    /* :: */[
                      /* tuple */[
                        "children",
                        String(children)
                      ],
                      /* :: */[
                        /* tuple */[
                          "infants",
                          String(infants)
                        ],
                        /* :: */[
                          /* tuple */[
                            "pets",
                            pets ? "true" : "false"
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function userListings(userId) {
  return "/listings?hostId=" + String(userId);
}

var defaultSearch = "/listings/usa/hawaii/";

var trips = "/trips";

var editAccount = "/account/edit";

var termsOfService = "/terms-of-service";

var privacyPolicy = "/privacy-policy";

exports.listing = listing;
exports.defaultSearch = defaultSearch;
exports.trips = trips;
exports.trip = trip;
exports.editListing = editListing;
exports.conversation = conversation;
exports.editAccount = editAccount;
exports.editProfile = editProfile;
exports.transferPage = transferPage;
exports.transferPageWithBookingData = transferPageWithBookingData;
exports.termsOfService = termsOfService;
exports.privacyPolicy = privacyPolicy;
exports.userListings = userListings;
/* Date Not a pure module */
