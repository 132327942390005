// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("bs-platform/lib/js/pervasives.js");

function fromJs(x) {
  switch (x) {
    case "live" :
        return /* Live */0;
    case "microsite" :
        return /* Microsite */2;
    case "preview" :
        return /* Preview */1;
    default:
      return Pervasives.failwith("Unknown view context: " + (String(x) + ""));
  }
}

exports.fromJs = fromJs;
/* No side effect */
