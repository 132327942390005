// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function Form(Props) {
  var className = Props.className;
  var onSubmit = Props.onSubmit;
  var children = Props.children;
  var tmp = {
    onSubmit: (function ($$event) {
        $$event.preventDefault();
        return Curry._1(onSubmit, undefined);
      })
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement("form", tmp, children);
}

var make = Form;

exports.make = make;
/* react Not a pure module */
