// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          description: Json_decode.field("description", Json_decode.string, json),
          value: Json_decode.field("value", Json_decode.$$float, json),
          enabled: Json_decode.field("enabled", Json_decode.bool, json)
        };
}

var Host = {
  fromJson: fromJson
};

function fromJs(js) {
  return {
          id: js.id,
          description: js.description,
          value: js.value
        };
}

function fromJson$1(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          description: Json_decode.field("description", Json_decode.string, json),
          value: Json_decode.field("value", Json_decode.$$float, json)
        };
}

var Guest = {
  fromJs: fromJs,
  fromJson: fromJson$1
};

exports.Host = Host;
exports.Guest = Guest;
/* No side effect */
