// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Photo = require("../../../../models/Photo.bs.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var WithDataProps = require("../../../../styleguide/components/WithDataProps/WithDataProps.bs.js");
var SlvPhotoModuleScss = require("./SlvPhoto.module.scss");

var css = SlvPhotoModuleScss;

function SlvPhoto(Props) {
  var photo = Props.photo;
  var width = Props.width;
  var height = Props.height;
  var hidden = Props.hidden;
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useMemo((function () {
          return Photo.dimensions(Belt_Option.getExn(photo.width), Belt_Option.getExn(photo.height));
        }), /* tuple */[
        photo.width,
        photo.height
      ]);
  var fullHeight = match[1];
  var fullWidth = match[0];
  var fullUrl = React.useMemo((function () {
          return Photo.url(photo.cloudUrl, fullWidth, fullHeight);
        }), /* tuple */[
        photo.cloudUrl,
        fullWidth,
        fullHeight
      ]);
  var thumbUrl = React.useMemo((function () {
          return Photo.url(photo.cloudUrl, width, height);
        }), /* tuple */[
        photo.cloudUrl,
        width,
        height
      ]);
  var description = photo.description;
  return React.createElement("figure", {
              className: Cn.$plus(className, Cn.on(css.hidden, hidden)),
              itemProp: "associatedMedia",
              itemScope: true,
              itemType: "http://schema.org/ImageObject"
            }, React.createElement(WithDataProps.make, {
                  props: {
                    "data-size": "" + (String(fullWidth) + ("x" + (String(fullHeight) + ""))),
                    "data-photourl": fullUrl
                  },
                  children: React.createElement("a", {
                        itemProp: "contentUrl",
                        href: fullUrl,
                        rel: "nofollow"
                      }, React.createElement("img", {
                            itemProp: "thumbnail",
                            alt: Belt_Option.getWithDefault(photo.description, "Listing photo"),
                            height: String(height),
                            src: thumbUrl,
                            width: String(width)
                          }), children)
                }), description !== undefined ? React.createElement("figcaption", {
                    className: css.hidden,
                    itemProp: "caption description"
                  }, description) : null);
}

var make = SlvPhoto;

exports.css = css;
exports.make = make;
/* css Not a pure module */
