// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Date = require("../../libs/Date.bs.js");
var Rest = require("../transport/Rest.bs.js");
var Message = require("../../models/Message.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function toJson(data) {
  if (data.tag) {
    var data$1 = data[0];
    var __x = data$1.checkIn;
    var __x$1 = data$1.checkOut;
    return Json_encode.object_(/* :: */[
                /* tuple */[
                  "message",
                  data$1.message
                ],
                /* :: */[
                  /* tuple */[
                    "listingId",
                    data$1.listingId
                  ],
                  /* :: */[
                    /* tuple */[
                      "userId",
                      data$1.userId
                    ],
                    /* :: */[
                      /* tuple */[
                        "checkIn",
                        Json_encode.nullable($$Date.Naive.toJson, __x)
                      ],
                      /* :: */[
                        /* tuple */[
                          "checkOut",
                          Json_encode.nullable($$Date.Naive.toJson, __x$1)
                        ],
                        /* :: */[
                          /* tuple */[
                            "adults",
                            data$1.adults
                          ],
                          /* :: */[
                            /* tuple */[
                              "children",
                              data$1.children
                            ],
                            /* :: */[
                              /* tuple */[
                                "infants",
                                data$1.infants
                              ],
                              /* :: */[
                                /* tuple */[
                                  "pets",
                                  data$1.pets
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "sbjs",
                                    data$1.sbjs
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "fromSlv",
                                      data$1.fromSlv
                                    ],
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]);
  }
  var data$2 = data[0];
  var __x$2 = data$2.phone;
  var __x$3 = data$2.checkIn;
  var __x$4 = data$2.checkOut;
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "message",
                data$2.message
              ],
              /* :: */[
                /* tuple */[
                  "listingId",
                  data$2.listingId
                ],
                /* :: */[
                  /* tuple */[
                    "firstName",
                    data$2.firstName
                  ],
                  /* :: */[
                    /* tuple */[
                      "lastName",
                      data$2.lastName
                    ],
                    /* :: */[
                      /* tuple */[
                        "email",
                        data$2.email
                      ],
                      /* :: */[
                        /* tuple */[
                          "phone",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), __x$2)
                        ],
                        /* :: */[
                          /* tuple */[
                            "checkIn",
                            Json_encode.nullable($$Date.Naive.toJson, __x$3)
                          ],
                          /* :: */[
                            /* tuple */[
                              "checkOut",
                              Json_encode.nullable($$Date.Naive.toJson, __x$4)
                            ],
                            /* :: */[
                              /* tuple */[
                                "adults",
                                data$2.adults
                              ],
                              /* :: */[
                                /* tuple */[
                                  "children",
                                  data$2.children
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "infants",
                                    data$2.infants
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "pets",
                                      data$2.pets
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "sbjs",
                                        data$2.sbjs
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "fromSlv",
                                          data$2.fromSlv
                                        ],
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function fromJson(json) {
  return {
          message: Json_decode.field("message", Message.fromJson, json),
          navbar: Belt_Option.flatMap(Json_decode.optional((function (__x) {
                      return Json_decode.field("navbar", (function (param) {
                                    return Json_decode.nullable((function (prim) {
                                                  return prim;
                                                }), param);
                                  }), __x);
                    }), json), (function (prim) {
                  if (prim === null) {
                    return ;
                  } else {
                    return Caml_option.some(prim);
                  }
                }))
        };
}

var Res = {
  fromJson: fromJson
};

function exec(req) {
  return Rest.$$fetch(/* `V1 */[
              19227,
              "/messages"
            ], /* `Post */[
              892711040,
              toJson(req)
            ], /* `Json */[
              826371656,
              fromJson
            ], undefined, undefined);
}

var Hc = {
  exec: exec
};

function exec$1(req) {
  return Rest.$$fetch(/* `Integrations */[
              -623562945,
              "/microsites/messages"
            ], /* `Post */[
              892711040,
              toJson(req)
            ], /* `Json */[
              826371656,
              fromJson
            ], undefined, undefined);
}

var Microsites = {
  exec: exec$1
};

exports.Req = Req;
exports.Res = Res;
exports.Hc = Hc;
exports.Microsites = Microsites;
/* Date Not a pure module */
