// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function WithDataProps(Props) {
  var props = Props.props;
  var children = Props.children;
  return React.cloneElement(children, props);
}

var make = WithDataProps;

exports.make = make;
/* react Not a pure module */
