// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var $$Image = require("../../../../styleguide/components/Image/Image.bs.js");
var Photo = require("../../../../models/Photo.bs.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var WithDataProps = require("../../../../styleguide/components/WithDataProps/WithDataProps.bs.js");
var SlvHeroPhotoModuleScss = require("./SlvHeroPhoto.module.scss");

var css = SlvHeroPhotoModuleScss;

function SlvHeroPhoto(Props) {
  var photo = Props.photo;
  var hero = Props.hero;
  var match = React.useMemo((function () {
          return Photo.dimensions(Belt_Option.getExn(photo.width), Belt_Option.getExn(photo.height));
        }), /* tuple */[
        photo.width,
        photo.height
      ]);
  var height = match[1];
  var width = match[0];
  var fullUrl = React.useMemo((function () {
          return Photo.url(photo.cloudUrl, width, height);
        }), /* tuple */[
        photo.cloudUrl,
        width,
        height
      ]);
  return React.createElement(React.Fragment, undefined, React.createElement("figure", {
                  className: Cn.$plus(css.wrapper, Cn.on(css.hidden, !hero)),
                  itemProp: "associatedMedia",
                  itemScope: true,
                  itemType: "http://schema.org/ImageObject"
                }, React.createElement(WithDataProps.make, {
                      props: {
                        "data-size": "" + (String(width) + ("x" + (String(height) + ""))),
                        "data-photourl": fullUrl
                      },
                      children: React.createElement("a", {
                            itemProp: "contentUrl",
                            href: fullUrl,
                            rel: "nofollow"
                          }, React.createElement("img", {
                                className: css.hidden,
                                alt: "show full-screen, listing image",
                                src: fullUrl
                              }), hero ? React.createElement($$Image.Background.make, {
                                  url: photo.cloudUrl,
                                  width: width,
                                  height: height
                                }) : null)
                    })));
}

var make = SlvHeroPhoto;

exports.css = css;
exports.make = make;
/* css Not a pure module */
