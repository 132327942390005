// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Events = require("../../../libs/Events.bs.js");
var Control = require("../Control/Control.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var NavbarModuleScss = require("./Navbar.module.scss");

var css = NavbarModuleScss;

var make = React.forwardRef((function (Props, theRef) {
        var classNameOpt = Props.className;
        var children = Props.children;
        var className = classNameOpt !== undefined ? classNameOpt : "";
        var tmp = {
          className: Cn.$plus(css.wrapper, className)
        };
        var tmp$1 = Belt_Option.map((theRef == null) ? undefined : Caml_option.some(theRef), (function (prim) {
                return prim;
              }));
        if (tmp$1 !== undefined) {
          tmp.ref = Caml_option.valFromOption(tmp$1);
        }
        return React.createElement("div", tmp, React.createElement("div", {
                        className: css.container
                      }, children));
      }));

var Navbar = {
  make: make
};

function Navbar$Block(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: Cn.$plus(css.block, className)
            }, children);
}

var Block = {
  make: Navbar$Block
};

function Navbar$Item(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: css.itemWrapper
            }, React.createElement("span", {
                  className: css.itemLabel
                }, children));
}

var Item = {
  make: Navbar$Item
};

function Navbar$Control(Props) {
  var active = Props.active;
  var onClick = Props.onClick;
  var children = Props.children;
  return React.createElement(Control.make, {
              className: Cn.$plus(css.link, Cn.on(css.itemActive, active)),
              onClick: onClick,
              children: React.createElement(Navbar$Item, {
                    children: children
                  })
            });
}

var Control$1 = {
  make: Navbar$Control
};

function reducer(_state, action) {
  return action[0];
}

function Navbar$Sticky(Props) {
  var children = Props.children;
  var navbar = React.useRef(null);
  var match = React.useReducer(reducer, /* NonSticky */1);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          return Events.subscribeToScroll((function (param) {
                        var el = navbar.current;
                        if (el == null) {
                          return ;
                        }
                        var navbarHeight = el.offsetHeight;
                        var scrolled = window.pageYOffset;
                        if (state) {
                          if (scrolled >= navbarHeight) {
                            return Curry._1(dispatch, /* Update */[/* Sticky */0]);
                          } else {
                            return ;
                          }
                        } else if (scrolled < navbarHeight) {
                          return Curry._1(dispatch, /* Update */[/* NonSticky */1]);
                        } else {
                          return ;
                        }
                      }));
        }), [state]);
  return React.createElement(make, {
              className: Cn.$plus(css.stickyNavbar, state ? css.nonSticky : css.sticky),
              children: children,
              ref: navbar
            });
}

var Sticky = {
  reducer: reducer,
  make: Navbar$Sticky
};

exports.css = css;
exports.Navbar = Navbar;
exports.Block = Block;
exports.Item = Item;
exports.Control = Control$1;
exports.Sticky = Sticky;
exports.make = make;
/* css Not a pure module */
