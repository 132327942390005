// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromJson(user, authenticateNewUser, authenticateWithCallback) {
  return {
          id: !(user == null) ? Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), user) : undefined,
          authenticate: authenticateNewUser,
          authenticateWithCallback: authenticateWithCallback
        };
}

exports.fromJson = fromJson;
/* No side effect */
