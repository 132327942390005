// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var React = require("react");
var SlvTableModuleScss = require("./SlvTable.module.scss");

var css = SlvTableModuleScss;

function SlvTable(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: Cn.$plus(css.table, className)
            }, children);
}

function SlvTable$Row(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: css.row
            }, children);
}

var Row = {
  make: SlvTable$Row
};

function SlvTable$Cell(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: css.cell
            }, children);
}

var Cell = {
  make: SlvTable$Cell
};

var make = SlvTable;

exports.css = css;
exports.make = make;
exports.Row = Row;
exports.Cell = Cell;
/* css Not a pure module */
