// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Input = require("../Input/Input.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function NumberField(Props) {
  var id = Props.id;
  var size = Props.size;
  var value = Props.value;
  var placeholder = Props.placeholder;
  var status = Props.status;
  var addon = Props.addon;
  var disabled = Props.disabled;
  var autoFocus = Props.autoFocus;
  var className = Props.className;
  var testId = Props.testId;
  var ariaLabel = Props.ariaLabel;
  var onChange = Props.onChange;
  var tmp = {
    type_: "number",
    size: size,
    value: value,
    onChange: onChange
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (testId !== undefined) {
    tmp.testId = Caml_option.valFromOption(testId);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(placeholder);
  }
  if (status !== undefined) {
    tmp.status = Caml_option.valFromOption(status);
  }
  if (addon !== undefined) {
    tmp.addon = Caml_option.valFromOption(addon);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (autoFocus !== undefined) {
    tmp.autoFocus = Caml_option.valFromOption(autoFocus);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (ariaLabel !== undefined) {
    tmp.ariaLabel = Caml_option.valFromOption(ariaLabel);
  }
  return React.createElement(Input.$$Text.make, tmp);
}

var make = NumberField;

exports.make = make;
/* Input Not a pure module */
