// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Animations = require("../Animations.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReactTransitionGroup = require("react-transition-group");
var FadeInOutModuleScss = require("./FadeInOut.module.scss");

var css = FadeInOutModuleScss;

var transitionClassNames = Animations.classNames(css);

function FadeInOut(Props) {
  var show = Props.show;
  var appear = Props.appear;
  var enter = Props.enter;
  var exit = Props.exit;
  var mountOnEnter = Props.mountOnEnter;
  var unmountOnExit = Props.unmountOnExit;
  var timeoutOpt = Props.timeout;
  var onEnter = Props.onEnter;
  var onEntering = Props.onEntering;
  var onEntered = Props.onEntered;
  var onExit = Props.onExit;
  var onExiting = Props.onExiting;
  var onExited = Props.onExited;
  var children = Props.children;
  var timeout = timeoutOpt !== undefined ? timeoutOpt : 100;
  var tmp = {
    timeout: timeout,
    classNames: transitionClassNames,
    children: children
  };
  if (show !== undefined) {
    tmp.in = Caml_option.valFromOption(show);
  }
  if (appear !== undefined) {
    tmp.appear = Caml_option.valFromOption(appear);
  }
  if (enter !== undefined) {
    tmp.enter = Caml_option.valFromOption(enter);
  }
  if (exit !== undefined) {
    tmp.exit = Caml_option.valFromOption(exit);
  }
  if (mountOnEnter !== undefined) {
    tmp.mountOnEnter = Caml_option.valFromOption(mountOnEnter);
  }
  if (unmountOnExit !== undefined) {
    tmp.unmountOnExit = Caml_option.valFromOption(unmountOnExit);
  }
  if (onEnter !== undefined) {
    tmp.onEnter = Caml_option.valFromOption(onEnter);
  }
  if (onEntering !== undefined) {
    tmp.onEntering = Caml_option.valFromOption(onEntering);
  }
  if (onEntered !== undefined) {
    tmp.onEntered = Caml_option.valFromOption(onEntered);
  }
  if (onExit !== undefined) {
    tmp.onExit = Caml_option.valFromOption(onExit);
  }
  if (onExiting !== undefined) {
    tmp.onExiting = Caml_option.valFromOption(onExiting);
  }
  if (onExited !== undefined) {
    tmp.onExited = Caml_option.valFromOption(onExited);
  }
  return React.createElement(ReactTransitionGroup.CSSTransition, tmp);
}

var transitionDuration = 100;

var make = FadeInOut;

exports.css = css;
exports.transitionDuration = transitionDuration;
exports.transitionClassNames = transitionClassNames;
exports.make = make;
/* css Not a pure module */
