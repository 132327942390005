// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var H1 = require("../../../../styleguide/components/Heading/H1.bs.js");
var Hr = require("../../../../styleguide/components/Hr/Hr.bs.js");
var I18n = require("../../../../libs/I18n.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Control = require("../../../../styleguide/components/Control/Control.bs.js");
var Listing = require("../../../../models/Listing.bs.js");
var Tooltip = require("../../../../styleguide/components/Tooltip/Tooltip.bs.js");
var BedsIcon = require("../../../../styleguide/icons/BedsIcon.bs.js");
var SlvBlock = require("../../components/SlvBlock/SlvBlock.bs.js");
var GuestsIcon = require("../../../../styleguide/icons/GuestsIcon.bs.js");
var SlvHtmlText = require("../../components/SlvHtmlText/SlvHtmlText.bs.js");
var BedroomsIcon = require("../../../../styleguide/icons/BedroomsIcon.bs.js");
var BathroomsIcon = require("../../../../styleguide/icons/BathroomsIcon.bs.js");
var TooltipQuestionIcon = require("../../../../styleguide/icons/TooltipQuestionIcon.bs.js");
var RatingWithReviewsCounter = require("../../../../styleguide/components/Rating/RatingWithReviewsCounter.bs.js");
var SlvHeadlineModuleScss = require("./SlvHeadline.module.scss");

var css = SlvHeadlineModuleScss;

function SlvHeadline$Feature(Props) {
  var icon = Props.icon;
  var children = Props.children;
  return React.createElement("div", {
              className: css.feature
            }, React.createElement(icon.make, {
                  size: /* XL */4,
                  color: /* DarkBlue */6,
                  className: css.featureIcon
                }), React.createElement("span", undefined, children));
}

var Feature = {
  make: SlvHeadline$Feature
};

function subtitle(listing) {
  var roomType = Listing.RoomType.label(listing.roomType);
  var match = listing.city;
  var match$1 = listing.state;
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return "" + (String(roomType) + (" in " + (String(match) + (", " + (String(match$1) + "")))));
    } else {
      return "" + (String(roomType) + (" in " + (String(match) + "")));
    }
  } else if (match$1 !== undefined) {
    return "" + (String(roomType) + (" in " + (String(match$1) + "")));
  } else {
    return roomType;
  }
}

function SlvHeadline(Props) {
  var listing = Props.listing;
  var unclaimedListingModal = Props.unclaimedListingModal;
  var match = listing.kind;
  var match$1 = listing.providersRatings.metaRating.count;
  var bedrooms = listing.bedrooms;
  var bathrooms = listing.bathrooms;
  var beds = listing.beds;
  var guests = listing.maxGuests;
  var summary = listing.summary;
  return React.createElement(SlvBlock.make, {
              id: /* Summary */-835046650,
              children: null
            }, match && match[0] < 809617083 ? React.createElement(Tooltip.make, {
                    content: React.createElement("div", {
                          className: css.unclaimedListingBadgeTooltip
                        }, React.createElement("div", {
                              className: css.unclaimedListingBadgeTooltipText
                            }, "The listing has not yet been claimed by the owner so we don’t have the exect total price for your stay. The estimated price is calculated based on the lowest Airbnb or VRBO price minus the website service fees."), React.createElement("div", {
                              className: css.unclaimedListingBadgeTooltipLearnMoreLink
                            }, React.createElement(Control.make, {
                                  onClick: (function (param) {
                                      return Curry._1(unclaimedListingModal.show, undefined);
                                    }),
                                  children: "Learn more"
                                }))),
                    side: /* Top */0,
                    className: css.unclaimedListingBadge,
                    children: null
                  }, React.createElement("span", {
                        className: css.unclaimedListingBadgeText
                      }, "Unclaimed listing"), React.createElement(TooltipQuestionIcon.make, {
                        size: /* MD */2,
                        color: /* White */0,
                        title: "Unclaimed listing"
                      })) : null, React.createElement("div", {
                  className: css.header
                }, React.createElement("div", {
                      className: css.subtitle
                    }, subtitle(listing)), match$1 !== 0 ? React.createElement(RatingWithReviewsCounter.make, {
                        providerRating: listing.providersRatings.metaRating,
                        className: css.rating
                      }) : null), React.createElement(H1.make, {
                  className: css.title,
                  children: listing.title
                }), React.createElement("div", {
                  className: css.features
                }, bedrooms !== undefined ? React.createElement(SlvHeadline$Feature, {
                        icon: BedroomsIcon,
                        children: I18n.inflect(bedrooms, "bedroom", "bedrooms")
                      }) : null, bathrooms !== undefined ? React.createElement(SlvHeadline$Feature, {
                        icon: BathroomsIcon,
                        children: I18n.inflectFloat(bathrooms, "bathroom", "bathrooms")
                      }) : null, beds !== undefined ? React.createElement(SlvHeadline$Feature, {
                        icon: BedsIcon,
                        children: I18n.inflect(beds, "bed", "beds")
                      }) : null, guests !== undefined ? React.createElement(SlvHeadline$Feature, {
                        icon: GuestsIcon,
                        children: I18n.inflect(guests, "guest", "guests")
                      }) : null), summary !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Hr.make, {
                        className: css.summaryDelimiter
                      }), React.createElement(SlvHtmlText.make, {
                        htmlText: summary
                      })) : null);
}

var make = SlvHeadline;

exports.css = css;
exports.Feature = Feature;
exports.subtitle = subtitle;
exports.make = make;
/* css Not a pure module */
