// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var SlvBlock = require("../../components/SlvBlock/SlvBlock.bs.js");
var SlvPhoto = require("./SlvPhoto.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var SlvGallery = require("./SlvGallery.bs.js");
var ReactIntl = require("react-intl");
var SlvPhotosModuleScss = require("./SlvPhotos.module.scss");

var css = SlvPhotosModuleScss;

function ensureInt(x) {
  if (x !== (x | 0)) {
    return Pervasives.failwith("Rethink input dimensions for photo grid");
  } else {
    return x;
  }
}

var thumbWidth = ensureInt((676 - 300 - 16 * 2) / 2);

var thumbHeight = ensureInt((300 - 16) / 2);

function SlvPhotos$Block(Props) {
  var photos = Props.photos;
  var screen = Props.screen;
  var listingId = Props.listingId;
  var isManagedByCurrentUser = Props.isManagedByCurrentUser;
  if (photos.length === 0) {
    return null;
  }
  var totalPhotos = React.useMemo((function () {
          return photos.length;
        }), [photos]);
  var visiblePhotos = React.useMemo((function () {
          if (screen !== 0) {
            return 5;
          } else {
            return 4;
          }
        }), [screen]);
  var hiddenPhotos = totalPhotos > visiblePhotos ? totalPhotos - visiblePhotos | 0 : 0;
  return React.createElement(SlvBlock.make, {
              id: /* Photos */670058401,
              children: null
            }, React.createElement(SlvBlock.Title.make, {
                  children: React.createElement(ReactIntl.FormattedMessage, {
                        id: "slv.photos.title",
                        defaultMessage: "Photos"
                      })
                }), React.createElement(SlvGallery.make, {
                  id: "listing--gallery--grid",
                  listingId: listingId,
                  className: css.grid,
                  isManagedByCurrentUser: isManagedByCurrentUser,
                  children: Belt_Array.mapWithIndex(photos, (function (idx, photo) {
                          return React.createElement(SlvPhoto.make, {
                                      photo: photo,
                                      width: (
                                        screen !== 0 ? (
                                            idx !== 0 ? thumbWidth : 300
                                          ) : 300
                                      ) | 0,
                                      height: (
                                        screen !== 0 ? (
                                            idx !== 0 ? thumbHeight : 300
                                          ) : 300
                                      ) | 0,
                                      hidden: idx >= visiblePhotos,
                                      children: hiddenPhotos > 0 && idx === (visiblePhotos - 1 | 0) ? React.createElement("div", {
                                              className: css.moreToGo
                                            }, React.createElement("span", {
                                                  className: css.moreToGoAmount
                                                }, "+", String(hiddenPhotos))) : null,
                                      key: String(photo.id)
                                    });
                        }))
                }));
}

var Block = {
  make: SlvPhotos$Block
};

function SlvPhotos(Props) {
  var listing = Props.listing;
  var screen = Props.screen;
  var match = listing.photos;
  if (match !== undefined && screen !== undefined) {
    return React.createElement(SlvPhotos$Block, {
                photos: match,
                screen: screen,
                listingId: listing.id,
                isManagedByCurrentUser: listing.userContext.isManagedByCurrentUser
              });
  } else {
    return null;
  }
}

var visiblePhotos = 5;

var mobileVisiblePhotos = 4;

var containerWidth = 676;

var thumbsMargin = 16;

var mainThumbSize = 300;

var mobileThumbSize = 300;

var make = SlvPhotos;

exports.css = css;
exports.visiblePhotos = visiblePhotos;
exports.mobileVisiblePhotos = mobileVisiblePhotos;
exports.containerWidth = containerWidth;
exports.thumbsMargin = thumbsMargin;
exports.mainThumbSize = mainThumbSize;
exports.mobileThumbSize = mobileThumbSize;
exports.ensureInt = ensureInt;
exports.thumbWidth = thumbWidth;
exports.thumbHeight = thumbHeight;
exports.Block = Block;
exports.make = make;
/* css Not a pure module */
