// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Amenity = require("../../../../models/Amenity.bs.js");
var Control = require("../../../../styleguide/components/Control/Control.bs.js");
var SlvBlock = require("../../components/SlvBlock/SlvBlock.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReactIntl = require("react-intl");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var CheckMarkIcon = require("../../../../styleguide/icons/CheckMarkIcon.bs.js");
var SlvAmenitiesModuleScss = require("./SlvAmenities.module.scss");

var css = SlvAmenitiesModuleScss;

function reducer(param, action) {
  return /* Expanded */0;
}

function excerpt(groups) {
  return Belt_Array.reduce(groups, /* tuple */[
                0,
                []
              ], (function (param, param$1) {
                  var acc = param[1];
                  var total = param[0];
                  if (total === 4) {
                    return /* tuple */[
                            total,
                            acc
                          ];
                  }
                  var amenities = Belt_Array.slice(param$1[1], 0, 4 - total | 0);
                  acc.push(/* tuple */[
                        param$1[0],
                        amenities
                      ]);
                  return /* tuple */[
                          total + amenities.length | 0,
                          acc
                        ];
                }))[1];
}

function SlvAmenities$AmenitiesGroup(Props) {
  var title = Props.title;
  var amenities = Props.amenities;
  if (amenities.length !== 0) {
    return React.createElement(React.Fragment, undefined, title !== undefined ? React.createElement(SlvBlock.SubTitle.make, {
                      children: Caml_option.valFromOption(title)
                    }) : null, React.createElement("div", {
                    className: css.group
                  }, Belt_Array.map(amenities, (function (amenity) {
                          return React.createElement("div", {
                                      key: amenity.label,
                                      className: css.amenity
                                    }, React.createElement("div", {
                                          className: css.icon
                                        }, React.createElement(CheckMarkIcon.make, {
                                              size: /* MD */2,
                                              color: /* LighterGray */10,
                                              title: "Ok"
                                            })), React.createElement("div", {
                                          className: css.label
                                        }, amenity.label));
                        }))));
  } else {
    return null;
  }
}

var AmenitiesGroup = {
  make: SlvAmenities$AmenitiesGroup
};

function SlvAmenities$PrioritizedAmenities(Props) {
  var items = Props.items;
  var screen = Props.screen;
  return React.createElement("div", {
              className: css.top
            }, Belt_Array.map(items, (function (amenity) {
                    var Icon = amenity.icon;
                    return React.createElement("div", {
                                key: String(amenity.id),
                                className: css.topAmenity
                              }, React.createElement(Icon.make, {
                                    size: screen !== undefined && screen < 2 ? /* MD */2 : /* LG */3,
                                    color: /* DarkBlue */6
                                  }), React.createElement("span", {
                                    className: css.topAmenityLabel
                                  }, amenity.label));
                  })));
}

var PrioritizedAmenities = {
  make: SlvAmenities$PrioritizedAmenities
};

function SlvAmenities$Block(Props) {
  var amenityIds = Props.amenityIds;
  var screen = Props.screen;
  var initialState = React.useMemo((function () {
          if (amenityIds.length > 4) {
            return /* Collapsed */1;
          } else {
            return /* Expanded */0;
          }
        }), ([]));
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var prioritizedAmenities = React.useMemo((function () {
          return Amenity.prioritized(amenityIds);
        }), ([]));
  var regularAmenities = React.useMemo((function () {
          if (state) {
            return excerpt(Amenity.regular(amenityIds));
          } else {
            return Amenity.regular(amenityIds);
          }
        }), [state]);
  return React.createElement(SlvBlock.make, {
              id: /* Amenities */252672375,
              children: null
            }, React.createElement(SlvBlock.Title.make, {
                  children: React.createElement(ReactIntl.FormattedMessage, {
                        id: "slv.amenities.title",
                        defaultMessage: "Amenities"
                      })
                }), React.createElement(SlvAmenities$PrioritizedAmenities, {
                  items: prioritizedAmenities,
                  screen: screen
                }), Belt_Array.map(regularAmenities, (function (param) {
                    var group = param[0];
                    var tmp;
                    switch (group) {
                      case /* Common */0 :
                          tmp = undefined;
                          break;
                      case /* GoodFor */1 :
                          tmp = Caml_option.some(React.createElement(ReactIntl.FormattedMessage, {
                                    id: "slv.amenities.good-for-group.title",
                                    defaultMessage: "Good for"
                                  }));
                          break;
                      case /* Spaces */2 :
                          tmp = Caml_option.some(React.createElement(ReactIntl.FormattedMessage, {
                                    id: "slv.amenities.spaces-group.title",
                                    defaultMessage: "Spaces"
                                  }));
                          break;
                      case /* Safety */3 :
                          tmp = Caml_option.some(React.createElement(ReactIntl.FormattedMessage, {
                                    id: "slv.amenities.safety-group.title",
                                    defaultMessage: "Safety"
                                  }));
                          break;
                      
                    }
                    return React.createElement(SlvAmenities$AmenitiesGroup, {
                                title: tmp,
                                amenities: param[1],
                                key: Amenity.Group.toString(group)
                              });
                  })), state ? React.createElement(Control.Underlined.make, {
                    color: /* Teal */0,
                    onClick: (function (param) {
                        return Curry._1(dispatch, /* Expand */0);
                      }),
                    children: React.createElement(ReactIntl.FormattedMessage, {
                          id: "slv.amenities.show-all-control",
                          defaultMessage: "Show all"
                        })
                  }) : null);
}

var Block = {
  make: SlvAmenities$Block
};

function SlvAmenities(Props) {
  var listing = Props.listing;
  var screen = Props.screen;
  var amenityIds = listing.amenityIds;
  if (amenityIds !== undefined) {
    return React.createElement(SlvAmenities$Block, {
                amenityIds: amenityIds,
                screen: screen
              });
  } else {
    return null;
  }
}

var excerptSize = 4;

var make = SlvAmenities;

exports.css = css;
exports.reducer = reducer;
exports.excerptSize = excerptSize;
exports.excerpt = excerpt;
exports.AmenitiesGroup = AmenitiesGroup;
exports.PrioritizedAmenities = PrioritizedAmenities;
exports.Block = Block;
exports.make = make;
/* css Not a pure module */
