// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Style = require("../../../libs/Style.bs.js");
var React = require("react");
var Portal = require("../Portal/Portal.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var TooltipModuleScss = require("./Tooltip.module.scss");

var css = TooltipModuleScss;

function toTooltipClassName(param) {
  switch (param) {
    case /* Top */0 :
        return css.positionTop;
    case /* Bottom */1 :
        return css.positionBottom;
    case /* Left */2 :
        return css.positionLeft;
    case /* Right */3 :
        return css.positionRight;
    
  }
}

function toTriangleClassName(param) {
  switch (param) {
    case /* Top */0 :
        return css.triangleBottom;
    case /* Bottom */1 :
        return css.triangleTop;
    case /* Left */2 :
        return css.triangleRight;
    case /* Right */3 :
        return css.triangleLeft;
    
  }
}

var Side = {
  toTooltipClassName: toTooltipClassName,
  toTriangleClassName: toTriangleClassName
};

var Position = { };

function reducer(_state, action) {
  return action;
}

function Tooltip(Props) {
  var id = Props.id;
  var content = Props.content;
  var side = Props.side;
  var neverShowOpt = Props.hideWhen;
  var classNameOpt = Props.className;
  var triggerStyle = Props.triggerStyle;
  var children = Props.children;
  var neverShow = neverShowOpt !== undefined ? neverShowOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tooltipParentRef = React.useRef(null);
  var tooltipRef = React.useRef(null);
  var match = React.useReducer(reducer, /* NotVisible */0);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (state !== 1) {
            
          } else {
            Curry._1(dispatch, /* ShowTooltip */2);
          }
          
        }), [state]);
  var getClientPosition = function (param) {
    return {
            height: document.documentElement.clientHeight,
            width: document.documentElement.clientWidth
          };
  };
  var tooltipParentElement = Belt_Option.map(Caml_option.nullable_to_opt(tooltipParentRef.current), (function (el) {
          return el.getBoundingClientRect();
        }));
  var parent;
  if (tooltipParentElement !== undefined) {
    var element = Caml_option.valFromOption(tooltipParentElement);
    parent = {
      left: element.left,
      right: element.right,
      width: element.width,
      height: element.height,
      top: element.top,
      bottom: element.bottom
    };
  } else {
    parent = undefined;
  }
  var getTooltipLeftCoord = function (param) {
    var tooltipElement = Belt_Option.map(Caml_option.nullable_to_opt(tooltipRef.current), (function (el) {
            return el.getBoundingClientRect();
          }));
    if (tooltipElement !== undefined) {
      return Caml_option.valFromOption(tooltipElement).left;
    }
    
  };
  var getTooltipPositionAdjustment = function (param) {
    var tooltipLeftCoord = getTooltipLeftCoord(undefined);
    if (tooltipLeftCoord !== undefined) {
      if (tooltipLeftCoord >= 0) {
        return 0;
      } else {
        return 20 - tooltipLeftCoord;
      }
    } else {
      return 0;
    }
  };
  var getStyle = function (element) {
    var client = getClientPosition(undefined);
    var triangleDelta = element ? 4 : 0;
    var leftTooltipDelta = element ? 0 : (
        state >= 2 ? getTooltipPositionAdjustment(undefined) : 0
      );
    var opacity;
    switch (state) {
      case /* NotVisible */0 :
      case /* RenderedNotVisible */1 :
          opacity = "0";
          break;
      case /* RenderedVisible */2 :
          opacity = "1";
          break;
      
    }
    if (parent === undefined) {
      return { };
    }
    switch (side) {
      case /* Top */0 :
          return {
                  bottom: Style.pxOfFloat(client.height - parent.top + triangleDelta),
                  left: Style.pxOfFloat(parent.left + parent.width / 2 + leftTooltipDelta),
                  opacity: opacity
                };
      case /* Bottom */1 :
          return {
                  left: Style.pxOfFloat(parent.left + parent.width / 2 + leftTooltipDelta),
                  top: Style.pxOfFloat(parent.bottom + triangleDelta),
                  opacity: opacity
                };
      case /* Left */2 :
          return {
                  right: Style.pxOfFloat(client.width - parent.left + triangleDelta),
                  top: Style.pxOfFloat(parent.bottom - parent.height / 2),
                  opacity: opacity
                };
      case /* Right */3 :
          return {
                  left: Style.pxOfFloat(parent.right + triangleDelta),
                  top: Style.pxOfFloat(parent.bottom - parent.height / 2),
                  opacity: opacity
                };
      
    }
  };
  var getTooltip = function (param) {
    var tmp = { };
    if (id !== undefined) {
      tmp.id = Caml_option.valFromOption(id);
    }
    return React.createElement("div", tmp, React.createElement("div", {
                    ref: tooltipRef,
                    className: Cn.$plus(css.container, toTooltipClassName(side)),
                    style: getStyle(/* Tooltip */0)
                  }, React.createElement("div", {
                        className: css.tooltip
                      }, content)), React.createElement("div", {
                    className: Cn.$plus(css.triangle, toTriangleClassName(side)),
                    style: getStyle(/* Triangle */1)
                  }));
  };
  var tmp = {
    ref: tooltipParentRef,
    className: className,
    onFocus: (function (_event) {
        return Curry._1(dispatch, /* RenderTooltip */1);
      }),
    onBlur: (function (_event) {
        return Curry._1(dispatch, /* HideTooltip */0);
      }),
    onMouseEnter: (function (_event) {
        return Curry._1(dispatch, /* RenderTooltip */1);
      }),
    onMouseLeave: (function (_event) {
        return Curry._1(dispatch, /* HideTooltip */0);
      })
  };
  if (triggerStyle !== undefined) {
    tmp.style = Caml_option.valFromOption(triggerStyle);
  }
  return React.createElement("div", tmp, children, neverShow || state === 0 ? null : React.createElement(Portal.make, {
                    children: getTooltip(undefined)
                  }));
}

var make = Tooltip;

exports.css = css;
exports.Side = Side;
exports.Position = Position;
exports.reducer = reducer;
exports.make = make;
/* css Not a pure module */
