// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");

function ExpanderIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var title = Props.title;
  return React.createElement(Icon.make, {
              title: title,
              size: size,
              children: React.createElement("path", {
                    style: Icon.style(color),
                    clipRule: "evenodd",
                    d: "M0.292222 0.500453C0.752232 -0.0745597 1.59128 -0.167788 2.1663 0.292222L8 4.95919L13.8337 0.292222C14.4087 -0.167788 15.2478 -0.0745597 15.7078 0.500453C16.1678 1.07546 16.0746 1.91452 15.4995 2.37453L8.83292 7.70783C8.34597 8.09739 7.65403 8.09739 7.16708 7.70783L0.500453 2.37453C-0.0745597 1.91452 -0.167788 1.07546 0.292222 0.500453Z",
                    fillRule: "evenodd",
                    transform: "translate(0 4)"
                  })
            });
}

var make = ExpanderIcon;

exports.make = make;
/* Icon Not a pure module */
