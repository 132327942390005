// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Route = require("../../../../Route.bs.js");
var React = require("react");
var Events = require("../../../../libs/Events.bs.js");
var Layout = require("../../../../styleguide/styles/Layout.bs.js");
var MoveTo = require("../../../../bindings/MoveTo.bs.js");
var Navbar = require("../../../../styleguide/components/Navbar/Navbar.bs.js");
var Control = require("../../../../styleguide/components/Control/Control.bs.js");
var SlvBlock = require("../../components/SlvBlock/SlvBlock.bs.js");
var Debouncer = require("re-debouncer/src/Debouncer.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReactIntl = require("react-intl");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var AngleLeftIcon = require("../../../../styleguide/icons/AngleLeftIcon.bs.js");
var SlvNavbarNavigationModuleScss = require("./SlvNavbarNavigation.module.scss");

var css = SlvNavbarNavigationModuleScss;

function searchRootRegExp(domain) {
  return new RegExp("^" + (domain + "/listings/?$"));
}

function serachWithParamsRegExp(domain) {
  return new RegExp("^" + (domain + "/listings/?\\D"));
}

function isFromSearch(domain, referer) {
  if (searchRootRegExp(domain).test(referer)) {
    return true;
  } else {
    return serachWithParamsRegExp(domain).test(referer);
  }
}

function SlvNavbarNavigation$SmallScreen(Props) {
  var meta = Props.meta;
  var domain = React.useMemo((function () {
          var host = meta.host;
          var scheme = meta.scheme;
          var port = Belt_Option.map(meta.port, (function (prim) {
                  return String(prim);
                }));
          if (port !== undefined) {
            return "" + (String(scheme) + ("://" + (String(host) + (":" + (String(port) + "")))));
          } else {
            return "" + (String(scheme) + ("://" + (String(host) + "")));
          }
        }), ([]));
  return React.createElement(Control.make, {
              className: css.backButton,
              onClick: (function (param) {
                  var referer = meta.referer;
                  window.location = referer !== undefined && isFromSearch(domain, referer) ? referer : Route.defaultSearch;
                  
                }),
              children: null
            }, React.createElement(AngleLeftIcon.make, {
                  size: /* SM */1,
                  color: /* Gray */8,
                  title: "Back"
                }), React.createElement("span", {
                  className: css.backButtonLabel
                }, "Back to search"));
}

var SmallScreen = {
  searchRootRegExp: searchRootRegExp,
  serachWithParamsRegExp: serachWithParamsRegExp,
  isFromSearch: isFromSearch,
  make: SlvNavbarNavigation$SmallScreen
};

var blocks = [
  /* Summary */-835046650,
  /* DealsOnOtherSites */668849890,
  /* Details */-123206494,
  /* Amenities */252672375,
  /* Rates */-145645197,
  /* Calendar */-656729378,
  /* Photos */670058401,
  /* Description */-1018191652,
  /* Location */1056451157,
  /* Rules */75748311,
  /* Reviews */-353869381,
  /* Host */803994504
];

function reducer(state, action) {
  if (action.tag) {
    return {
            blocks: state.blocks,
            activeBlock: action[0]
          };
  } else {
    return {
            blocks: action[0],
            activeBlock: state.activeBlock
          };
  }
}

var scrollPadding = Layout.navbarHeight + 3 | 0;

var initialState_blocks = [];

var initialState = {
  blocks: initialState_blocks,
  activeBlock: undefined
};

function getBlockElement(block) {
  var __x = document;
  return Caml_option.nullable_to_opt(__x.getElementById(SlvBlock.idToJs(block)));
}

function SlvNavbarNavigation$LargeScreen(Props) {
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var mover = React.useMemo((function () {
          return MoveTo.make(scrollPadding - 10 | 0);
        }), ([]));
  var active = React.useCallback((function (block) {
          return Belt_Option.mapWithDefault(state.activeBlock, false, (function (activeBlock) {
                        return SlvBlock.eq(block, activeBlock);
                      }));
        }), [state.activeBlock]);
  var move = React.useCallback((function (block) {
          var __x = document;
          var el = __x.getElementById(SlvBlock.idToJs(block));
          if (!(el == null)) {
            mover.move(el);
            return ;
          }
          
        }), ([]));
  var updateActiveBlock = React.useRef(Debouncer.make(20, (function (param) {
              var activeBlock = param[1];
              var blocks = param[0];
              var scrolled = window.pageYOffset;
              var nextActiveBlock = blocks.find((function (block, idx) {
                      var element = getBlockElement(block);
                      if (element === undefined) {
                        return false;
                      }
                      var partiallyOffTheScreenFactor = scrollPadding + 100;
                      var elementRect = Caml_option.valFromOption(element).getBoundingClientRect();
                      var match = elementRect.top;
                      var match$1 = elementRect.bottom;
                      if (match > window.innerHeight && match$1 > window.innerHeight) {
                        return false;
                      }
                      if (match < partiallyOffTheScreenFactor && match$1 < partiallyOffTheScreenFactor) {
                        return false;
                      }
                      var elementTopPosition = scrolled + match - scrollPadding;
                      var nextElement = Belt_Option.flatMap(Belt_Array.get(blocks, idx + 1 | 0), getBlockElement);
                      var nextBreakpointPosition;
                      if (nextElement !== undefined) {
                        var nextElementRect = Caml_option.valFromOption(nextElement).getBoundingClientRect();
                        nextBreakpointPosition = scrolled + nextElementRect.top - scrollPadding;
                      } else {
                        nextBreakpointPosition = scrolled + match + elementRect.height;
                      }
                      if (scrolled < elementTopPosition) {
                        return true;
                      } else {
                        return scrolled < nextBreakpointPosition;
                      }
                    }));
              var nextActiveBlock$1 = (nextActiveBlock == null) ? undefined : Caml_option.some(nextActiveBlock);
              if (activeBlock !== undefined) {
                if (!(nextActiveBlock == null) && SlvBlock.eq(activeBlock, nextActiveBlock)) {
                  return ;
                } else {
                  return Curry._1(dispatch, /* UpdateActiveBlock */Block.__(1, [nextActiveBlock$1]));
                }
              } else if (!(nextActiveBlock == null)) {
                return Curry._1(dispatch, /* UpdateActiveBlock */Block.__(1, [nextActiveBlock$1]));
              } else {
                return ;
              }
            })));
  React.useEffect((function () {
          var blocks$1 = Belt_Array.keep(blocks, (function (block) {
                  var __x = document;
                  var el = __x.getElementById(SlvBlock.idToJs(block));
                  return !(el == null);
                }));
          Curry._1(dispatch, /* UpdateBlocks */Block.__(0, [blocks$1]));
          
        }), ([]));
  React.useEffect((function () {
          return Events.subscribeToScroll((function (param) {
                        return Curry._1(updateActiveBlock.current, /* tuple */[
                                    state.blocks,
                                    state.activeBlock
                                  ]);
                      }));
        }), /* tuple */[
        state.blocks,
        state.activeBlock
      ]);
  return React.createElement(React.Fragment, undefined, Belt_Array.map(state.blocks, (function (block) {
                    return React.createElement(Navbar.Control.make, {
                                active: Curry._1(active, block),
                                onClick: (function (param) {
                                    return Curry._1(move, block);
                                  }),
                                children: block >= 75748311 ? (
                                    block >= 668849890 ? (
                                        block >= 803994504 ? (
                                            block >= 1056451157 ? React.createElement(ReactIntl.FormattedMessage, {
                                                    id: "slv.location.title",
                                                    defaultMessage: "Location"
                                                  }) : React.createElement(ReactIntl.FormattedMessage, {
                                                    id: "slv.host.title-navigation",
                                                    defaultMessage: "Host"
                                                  })
                                          ) : (
                                            block >= 670058401 ? React.createElement(ReactIntl.FormattedMessage, {
                                                    id: "slv.photos.title",
                                                    defaultMessage: "Photos"
                                                  }) : React.createElement(ReactIntl.FormattedMessage, {
                                                    id: "slv.deals-on-other-sites.title-navigation",
                                                    defaultMessage: "Deals"
                                                  })
                                          )
                                      ) : (
                                        block >= 252672375 ? React.createElement(ReactIntl.FormattedMessage, {
                                                id: "slv.amenities.title",
                                                defaultMessage: "Amenities"
                                              }) : React.createElement(ReactIntl.FormattedMessage, {
                                                id: "slv.rules.title-navigation",
                                                defaultMessage: "Rules"
                                              })
                                      )
                                  ) : (
                                    block >= -656729378 ? (
                                        block >= -145645197 ? (
                                            block >= -123206494 ? React.createElement(ReactIntl.FormattedMessage, {
                                                    id: "slv.details.title",
                                                    defaultMessage: "Details"
                                                  }) : React.createElement(ReactIntl.FormattedMessage, {
                                                    id: "slv.rates.title",
                                                    defaultMessage: "Rates"
                                                  })
                                          ) : (
                                            block >= -353869381 ? React.createElement(ReactIntl.FormattedMessage, {
                                                    id: "slv.reviews.title",
                                                    defaultMessage: "Reviews"
                                                  }) : React.createElement(ReactIntl.FormattedMessage, {
                                                    id: "slv.calendar.title",
                                                    defaultMessage: "Availability"
                                                  })
                                          )
                                      ) : (
                                        block >= -835046650 ? React.createElement(ReactIntl.FormattedMessage, {
                                                id: "slv.summary.title",
                                                defaultMessage: "Summary"
                                              }) : React.createElement(ReactIntl.FormattedMessage, {
                                                id: "slv.description.title",
                                                defaultMessage: "Description"
                                              })
                                      )
                                  ),
                                key: SlvBlock.idToJs(block)
                              });
                  })));
}

var LargeScreen = {
  blocks: blocks,
  reducer: reducer,
  scrollPadding: scrollPadding,
  initialState: initialState,
  getBlockElement: getBlockElement,
  make: SlvNavbarNavigation$LargeScreen
};

exports.css = css;
exports.SmallScreen = SmallScreen;
exports.LargeScreen = LargeScreen;
/* css Not a pure module */
