// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function SafetyIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var className = Props.className;
  var title = titleOpt !== undefined ? titleOpt : "Safety";
  var tmp = {
    title: title,
    size: size,
    children: React.createElement("path", {
          style: Icon.style(color),
          d: "M7.83830958,0.686571667 L2.50497625,2.019905 C2.20819825,2.0940995 2,2.36075491 2,2.66666667 L2,8 C2,9.97963232 3.09717311,11.7625386 4.89433026,13.3350511 C5.50662669,13.8708105 6.15992273,14.3444501 6.81333404,14.7528322 L7.17780686,14.9740345 C7.40005405,15.1046714 7.57880044,15.2014229 7.7018576,15.2629515 C7.8895426,15.356794 8.1104574,15.356794 8.2981424,15.2629515 L8.52297151,15.1444086 C8.69867079,15.0479915 8.92396509,14.9170202 9.18666596,14.7528322 C9.84007727,14.3444501 10.4933733,13.8708105 11.1056697,13.3350511 C12.9028269,11.7625386 14,9.97963232 14,8 L14,2.66666667 C14,2.36075491 13.7918018,2.0940995 13.4950238,2.019905 L8.16169042,0.686571667 C8.05553042,0.660031667 7.94446958,0.660031667 7.83830958,0.686571667 Z M8,2.02066667 L12.6666667,3.18733333 L12.6666667,8 C12.6666667,9.52036768 11.7638398,10.9874614 10.2276636,12.3316155 C9.67329336,12.8166895 9.0765894,13.2492999 8.48000071,13.6221678 L8.17958702,13.8046766 L8,13.908 L7.82041298,13.8046766 C7.72457428,13.7481714 7.62421622,13.6873034 7.51999929,13.6221678 C6.9234106,13.2492999 6.32670664,12.8166895 5.77233641,12.3316155 L5.59791136,12.1759792 C4.16804754,10.8745292 3.33333333,9.461892 3.33333333,8 L3.33333333,3.18733333 L8,2.02066667 Z"
        })
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Icon.make, tmp);
}

var make = SafetyIcon;

exports.make = make;
/* Icon Not a pure module */
