// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var React = require("react");
var HrModuleScss = require("./Hr.module.scss");

var css = HrModuleScss;

function Hr(Props) {
  var colorOpt = Props.color;
  var classNameOpt = Props.className;
  var color = colorOpt !== undefined ? colorOpt : /* LightGray */1;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("hr", {
              className: Cn.$plus(Cn.$plus(css.hr, color ? css.lightGray : css.gray), className)
            });
}

var make = Hr;

exports.css = css;
exports.make = make;
/* css Not a pure module */
