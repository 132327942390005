// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");
var HeartIconModuleScss = require("./HeartIcon.module.scss");

var css = HeartIconModuleScss;

function HeartIcon(Props) {
  var size = Props.size;
  var favoritedOpt = Props.favorited;
  var titleOpt = Props.title;
  var favorited = favoritedOpt !== undefined ? favoritedOpt : false;
  var title = titleOpt !== undefined ? titleOpt : "Favorite";
  return React.createElement(Icon.make, {
              title: title,
              size: size,
              className: favorited ? css.heartFull : css.heartEmpty,
              children: React.createElement("path", {
                    d: "M8 15.118s6.78-5.176 7.748-8C16.718 4.294 14.778 1 11.874 1S8 2.882 8 2.882 7.03 1 4.126 1C1.22 1-.716 4.294.252 7.118c.97 2.824 7.748 8 7.748 8z"
                  })
            });
}

var make = HeartIcon;

exports.css = css;
exports.make = make;
/* css Not a pure module */
