// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Rest = require("../transport/Rest.bs.js");
var Listing = require("../../models/Listing.bs.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromJson(json) {
  return Json_decode.field("listing", Listing.Contextual.fromJson, json);
}

var Res = {
  fromJson: fromJson
};

function exec(listingId) {
  return Rest.$$fetch(/* `V1 */[
              19227,
              "/contextual-listings/" + String(listingId)
            ], /* Get */3553398, /* `Json */[
              826371656,
              fromJson
            ], undefined, undefined);
}

exports.Res = Res;
exports.exec = exec;
/* Rest Not a pure module */
