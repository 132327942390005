// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReactRef = require("../../../bindings/ReactRef.bs.js");
var CheckboxModuleScss = require("./Checkbox.module.scss");

var css = CheckboxModuleScss;

function Checkbox(Props) {
  var id = Props.id;
  var size = Props.size;
  var checked = Props.checked;
  var disabledOpt = Props.disabled;
  var visuallyDisabledOpt = Props.visuallyDisabled;
  var classNameOpt = Props.className;
  var onChange = Props.onChange;
  var onClick = Props.onClick;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var visuallyDisabled = visuallyDisabledOpt !== undefined ? visuallyDisabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var checkbox = React.useRef(null);
  var handleClick = React.useCallback((function ($$event) {
          if (onClick !== undefined) {
            Curry._1(onClick, $$event);
          }
          return ReactRef.blur(checkbox);
        }), [checkbox]);
  return React.createElement("div", {
              className: Cn.$plus(Cn.$plus(css.wrapper, size ? css.sizeMd : css.sizeSm), className)
            }, React.createElement("input", {
                  ref: checkbox,
                  className: Cn.$plus(css.checkbox, Cn.on(css.visuallyDisabled, visuallyDisabled)),
                  id: id,
                  checked: checked,
                  disabled: disabled || visuallyDisabled,
                  type: "checkbox",
                  onChange: onChange,
                  onClick: handleClick
                }), React.createElement("label", {
                  className: css.label,
                  htmlFor: id
                }));
}

var make = Checkbox;

exports.css = css;
exports.make = make;
/* css Not a pure module */
