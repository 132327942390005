// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var React = require("react");
var WithTestId = require("../WithTestId/WithTestId.bs.js");
var AlertModuleScss = require("./Alert.module.scss");

var css = AlertModuleScss;

function className(param) {
  switch (param) {
    case /* Info */0 :
        return css.info;
    case /* Success */1 :
        return css.success;
    case /* Warning */2 :
        return css.warning;
    case /* Danger */3 :
        return css.danger;
    
  }
}

var Level = {
  className: className
};

function Alert(Props) {
  var level = Props.level;
  var classNameOpt = Props.className;
  var testId = Props.testId;
  var children = Props.children;
  var className$1 = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(WithTestId.Optional.make, {
              id: testId,
              children: React.createElement("div", {
                    className: Cn.$plus(Cn.$plus(css.alert, className(level)), className$1)
                  }, children)
            });
}

var make = Alert;

exports.css = css;
exports.Level = Level;
exports.make = make;
/* css Not a pure module */
