// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function isDate(x) {
  if (typeof x === "object") {
    return typeof x.getMonth !== "undefined";
  } else {
    return false;
  }
}

function isBool(x) {
  return typeof x === "boolean";
}

function isRange(x) {
  if (typeof x === "object") {
    return isDate(x.from);
  } else {
    return false;
  }
}

function isFn(x) {
  return typeof x === "function";
}

var JS = {
  isDate: isDate,
  isBool: isBool,
  isRange: isRange,
  isFn: isFn
};

function toJs(x) {
  if (x[0] >= 15720) {
    return x[1];
  }
  var match = x[1];
  return {
          from: match[0],
          to: match[1]
        };
}

function fromJs(x) {
  if (isDate(x)) {
    return /* `Date */[
            758940238,
            x
          ];
  } else if (typeof x === "boolean") {
    return /* `Bool */[
            737456202,
            x
          ];
  } else if (typeof x === "function") {
    return /* `Fn */[
            15720,
            x
          ];
  } else if (isRange(x)) {
    return /* `Range */[
            -145943139,
            /* tuple */[
              x.from,
              x.to
            ]
          ];
  } else {
    return Pervasives.failwith("Unknown type of modifier: " + (String(x) + ""));
  }
}

var Modifier = {
  JS: JS,
  toJs: toJs,
  fromJs: fromJs
};

function makeProps(initialMonth, fromMonth, numberOfMonths, showOutsideDays, enableOutsideDaysClick, selectedDays, modifiers, classNames, onDayClick, onMonthChange, renderDay, key, param) {
  var tmp = { };
  if (initialMonth !== undefined) {
    tmp.initialMonth = Caml_option.valFromOption(initialMonth);
  }
  if (fromMonth !== undefined) {
    tmp.fromMonth = Caml_option.valFromOption(fromMonth);
  }
  if (numberOfMonths !== undefined) {
    tmp.numberOfMonths = Caml_option.valFromOption(numberOfMonths);
  }
  if (showOutsideDays !== undefined) {
    tmp.showOutsideDays = Caml_option.valFromOption(showOutsideDays);
  }
  if (enableOutsideDaysClick !== undefined) {
    tmp.enableOutsideDaysClick = Caml_option.valFromOption(enableOutsideDaysClick);
  }
  var tmp$1 = Belt_Option.map(selectedDays, (function (x) {
          return toJs(x);
        }));
  if (tmp$1 !== undefined) {
    tmp.selectedDays = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Belt_Option.map(modifiers, (function (x) {
          return Js_dict.map(toJs, x);
        }));
  if (tmp$2 !== undefined) {
    tmp.modifiers = Caml_option.valFromOption(tmp$2);
  }
  if (classNames !== undefined) {
    tmp.classNames = Caml_option.valFromOption(classNames);
  }
  var tmp$3 = Belt_Option.map(onDayClick, (function (onDayClick, day, modifiersJs, $$event) {
          return Curry._3(onDayClick, day, Js_dict.map(fromJs, modifiersJs), $$event);
        }));
  if (tmp$3 !== undefined) {
    tmp.onDayClick = Caml_option.valFromOption(tmp$3);
  }
  if (onMonthChange !== undefined) {
    tmp.onMonthChange = Caml_option.valFromOption(onMonthChange);
  }
  if (renderDay !== undefined) {
    tmp.renderDay = Caml_option.valFromOption(renderDay);
  }
  if (key !== undefined) {
    tmp.key = Caml_option.valFromOption(key);
  }
  return tmp;
}

exports.Modifier = Modifier;
exports.makeProps = makeProps;
/* No side effect */
